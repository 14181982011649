import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, DatePicker, Form, InputNumber, Modal } from 'antd';

import { formatDateToSubmit } from 'utilities/datesAndTimes';

import {
  setPendingDeletedPayments,
  setUpdatedFuturePayments,
} from './paymentPlanState';
import { usePaymentPlanState } from './paymentPlanState';
import { isAfterTodayPaymentCutoff } from './PaymentPlanDetailsStep';

const { Item } = Form;

const EditPaymentModal = ({
  transactions,
  initialValues,
  setInitialValues,
  type,
  hide,
  ...modalProps
}) => {
  const {
    pendingDeletedPayments,
    futurePayments,
    offer,
    updatedFuturePayments,
  } = usePaymentPlanState();

  const [editPaymentForm] = Form.useForm();

  const [updatedPaymentDateExists, setUpdatedPaymentDateExists] =
    useState(false);

  const onDateChange = (date) => {
    const paymentDateExists = transactions.find((transaction) =>
      dayjs(transaction.date).isSame(date, 'day'),
    );

    if (paymentDateExists) {
      return setUpdatedPaymentDateExists(true);
    }

    setUpdatedPaymentDateExists(false);
  };

  const onClose = () => {
    editPaymentForm.resetFields();
    setInitialValues({});
    hide();
  };

  const onDelete = () => {
    const payments = updatedFuturePayments.length
      ? updatedFuturePayments
      : futurePayments;

    const updatedPayments = payments.filter(
      (payment) => !dayjs(payment.date).isSame(dayjs(initialValues.date)),
    );

    const pendingDeletedPayment = payments
      .filter((payment) =>
        dayjs(payment.date).isSame(dayjs(initialValues.date)),
      )
      .map((payment) => ({
        amount: payment.amount,
        date: formatDateToSubmit(payment.date),
        status: 'delete',
      }));

    setPendingDeletedPayments([
      ...pendingDeletedPayments,
      pendingDeletedPayment,
    ]);
    setUpdatedFuturePayments(updatedPayments);
    onClose();
  };

  const getUpdatedPayments = (payments, updatedPayment) => {
    return payments
      .map((payment) => {
        if (dayjs(payment.date).isSame(dayjs(initialValues.date))) {
          return updatedPayment;
        } else {
          return payment;
        }
      })
      .sort((a, b) => dayjs(a.date) - dayjs(b.date));
  };

  const onFinish = async ({ amount, date }) => {
    await editPaymentForm.validateFields();

    const futurePaymentPlan = updatedFuturePayments.length
      ? updatedFuturePayments
      : futurePayments;

    if (type === 'edit') {
      const updatedFuturePayments = getUpdatedPayments(futurePaymentPlan, {
        amount: Math.round(amount * 100),
        date: formatDateToSubmit(date),
        status: 'edit',
      });

      setUpdatedFuturePayments(updatedFuturePayments);
    }

    if (type === 'add') {
      const futurePaymentPlan = updatedFuturePayments.length
        ? updatedFuturePayments
        : futurePayments;
      const updatedPayments = [
        ...futurePaymentPlan,
        {
          amount: Math.round(amount * 100),
          date: formatDateToSubmit(date),
          status: 'add',
        },
      ].sort((a, b) => dayjs(a.date) - dayjs(b.date));

      setUpdatedFuturePayments(updatedPayments);
    }

    onClose();
  };

  const buttons = [
    <Button
      form="editPaymentForm"
      type="primary"
      htmlType="submit"
      key="confirm"
    >
      Confirm Payment Details
    </Button>,
  ];

  if (type === 'edit') {
    buttons.unshift(
      <Button key="delete" danger onClick={onDelete}>
        Delete This Payment
      </Button>,
    );
  }

  const dateIsAfterOfferExpiration = (date) => {
    return date.isAfter(offer.expiration);
  };

  useEffect(() => {
    editPaymentForm.setFieldsValue({
      amount: initialValues.amount,
      date: initialValues.date,
    });
  }, [initialValues]);

  return (
    <Modal
      {...modalProps}
      title="Edit Payment"
      onCancel={onClose}
      footer={buttons}
    >
      <Form
        id="editPaymentForm"
        name="editPaymentForm"
        form={editPaymentForm}
        onFinish={onFinish}
      >
        <Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: true,
              message: 'Please enter a payment amount.',
            },
          ]}
        >
          <InputNumber min="0.00" step="0.01" />
        </Item>
        <Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
              message: 'Please enter a payment date.',
            },
            {
              /* eslint-disable no-unused-vars */
              validator: () => {
                return new Promise((resolve, reject) => {
                  if (updatedPaymentDateExists) {
                    reject();
                  }
                  resolve();
                });
              },
              message:
                'The customer already has a payment scheduled for this date. Please select a different date.',
            },
          ]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            onChange={onDateChange}
            disabledDate={(d) =>
              !d ||
              d.isSameOrBefore(dayjs()) ||
              dateIsAfterOfferExpiration(d) ||
              isAfterTodayPaymentCutoff(d)
            }
            style={{ marginBottom: 'var(--spacing-sm)' }}
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default EditPaymentModal;
