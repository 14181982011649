import { useParams } from 'react-router-dom';
import { Col, Row, Skeleton as AntSkeleton, Tooltip } from 'antd';
import styled from '@emotion/styled';

import CustomerAccountStatus from 'components/CustomerAccountStatus/CustomerAccountStatus';
import DisplayValue from 'components/DisplayValue/DisplayValue';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useAccountQuery from 'hooks/useAccountQuery';
import { formatDollarsFromCents, minDueWarningHelper } from 'utilities/helpers';
import { getDueDate } from 'utilities/datesAndTimes';
import { SIZE, STATUS } from 'utilities/constants';

const Skeleton = styled(AntSkeleton)`
  padding-top: var(--spacing-xxs);
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
`;

const SnapshotValueContainer = styled.div`
  padding: var(--spacing-sm) var(--spacing-sm) 0 var(--spacing-md);
  border-left: 1px solid var(--border-light);
  min-height: 100%;
`;

const SnapshotValue = ({ children, label }) => {
  return (
    <SnapshotValueContainer>
      <DisplayValue label={label} size={SIZE.LG}>
        {children}
      </DisplayValue>
    </SnapshotValueContainer>
  );
};

const SnapshotSkeleton = ({ children, loading }) => {
  return loading ? (
    <Row>
      {Array(5)
        .fill(null)
        .map((value, index) => {
          return (
            <Col key={index} flex={1}>
              <SnapshotValueContainer>
                <Skeleton
                  active={true}
                  loading={true}
                  title={false}
                  paragraph={{ rows: 2, width: ['70%', '90%'] }}
                ></Skeleton>
              </SnapshotValueContainer>
            </Col>
          );
        })}
    </Row>
  ) : (
    children
  );
};

const CreditAccountSnapshot = () => {
  const { accountId, customerId } = useParams();

  const { accountQuery, account, simpleAccount } = useAccountQuery(
    customerId,
    accountId,
  );

  const { shouldHighlightMinDue, minDueFormatted, highMinDueWarningMessage } =
    minDueWarningHelper(
      account.paymentInfo?.minimumDue,
      account.balanceInfo?.currentBalance,
    );

  let renderMinDue = () => {
    let minDue = (
      <SnapshotValue
        label="Total Min Due"
        status={shouldHighlightMinDue ? STATUS.ERROR : ''}
      >
        {minDueFormatted}
      </SnapshotValue>
    );
    if (highMinDueWarningMessage) {
      minDue = (
        <Tooltip title={highMinDueWarningMessage} placement={'bottom'}>
          <div>
            {/*this empty div is required for Tooltip to work properly*/}
            {minDue}
          </div>
        </Tooltip>
      );
    }
    return minDue;
  };

  return (
    <QueryBoundary
      query={accountQuery}
      skeleton={SnapshotSkeleton}
      errorMessage="Failed to load snapshot data."
      messageHeight="100px"
    >
      <Row>
        <Col flex={1}>
          <SnapshotValue label="Account Balance">
            {formatDollarsFromCents(account.balanceInfo?.currentBalance)}
          </SnapshotValue>
        </Col>
        <Col flex={1}>
          <SnapshotValue label="Due Date">
            {getDueDate(account?.paymentInfo)}
          </SnapshotValue>
        </Col>
        <Col flex={1}>{renderMinDue()}</Col>
        <Col flex={1}>
          <SnapshotValue label="Available Credit">
            {formatDollarsFromCents(account.balanceInfo?.availableCredit)}
          </SnapshotValue>
        </Col>
        <Col flex={1} style={{ maxWidth: '400px' }}>
          <SnapshotValue label="Account Status">
            <CustomerAccountStatus
              accountStatus={account.statuses}
              createdAt={simpleAccount?.createdAt}
            ></CustomerAccountStatus>
          </SnapshotValue>
        </Col>
      </Row>
    </QueryBoundary>
  );
};

export default CreditAccountSnapshot;
