import { gql } from '@apollo/client';

import { ACCOUNT_FIELDS_FRAGMENT } from './account';

const CUSTOMER_META_FIELDS_FRAGMENT = gql`
  fragment CustomerMetaFields on Customer {
    id
    birthDate
    lastFourSsn
    contactInfo {
      firstName
      middleName
      lastName
      email
      rawPhoneNumber
      address {
        city
        postalCode
        state
        street1
        street2
      }
    }
  }
`;

const SIMPLE_ACCOUNT_FIELDS_FRAGMENT = gql`
  fragment SimpleAccountFields on Customer {
    accounts {
      id
      createdAt
      externalAccountId
      externalCustomerId
      productApplicationId
      product {
        productId: id
        name
        subProduct {
          code
          name
        }
      }
    }
  }
`;

const CUSTOMER_META = gql`
  ${CUSTOMER_META_FIELDS_FRAGMENT}
  query GetCustomerMeta($customerId: String!) {
    customer(customerId: $customerId) {
      ...CustomerMetaFields
    }
  }
`;

const CUSTOMER_OVERVIEW = gql`
  ${CUSTOMER_META_FIELDS_FRAGMENT}
  ${SIMPLE_ACCOUNT_FIELDS_FRAGMENT}
  query GetCustomerOverview($customerId: String!) {
    customer(customerId: $customerId) {
      ...CustomerMetaFields
      ...SimpleAccountFields
    }
  }
`;

// Provide no creditAccounts variable to retrieve all creditAccounts
const CUSTOMER_WITH_FULL_ACCOUNTS = gql`
  ${CUSTOMER_META_FIELDS_FRAGMENT}
  ${SIMPLE_ACCOUNT_FIELDS_FRAGMENT}
  ${ACCOUNT_FIELDS_FRAGMENT}

  query GetCustomerWithFullAccounts(
    $customerId: String!
    $creditAccountIds: [String!]
  ) {
    customer(customerId: $customerId) {
      ...CustomerMetaFields
      ...SimpleAccountFields

      creditAccounts(accountIds: $creditAccountIds) {
        ...AccountFields
      }
    }
  }
`;

const CUSTOMER_PREFERENCES = gql`
  query GetCustomerCommPreferences($customerId: String!) {
    customer(customerId: $customerId) {
      id
      contactInfo {
        phoneNumber
      }
      communicationPreferences {
        channel {
          allMessages
          campaignEmails
          campaignSms
          homeCall
          transactionalEmails
          transactionalSms
          workCall
        }
        language
        ceaseDesist {
          notificationDate
          communicationFrom
        }
      }
      electronicCommunicationsConsent {
        hasElectronicConsent
      }
    }
  }
`;

const CUSTOMER_FINANCIAL_INFO = gql`
  query GetCustomerFinancialInfo($customerId: String!) {
    customer(customerId: $customerId) {
      id
      financialInfo {
        annualIncome
        lastUpdated
        monthlyLiving
        shouldGetCLIPTout
      }
    }
  }
`;

export {
  CUSTOMER_META,
  CUSTOMER_OVERVIEW,
  CUSTOMER_WITH_FULL_ACCOUNTS,
  CUSTOMER_PREFERENCES,
  CUSTOMER_FINANCIAL_INFO,
};
