import { cloneElement, useMemo, useState } from 'react';
import { Alert, Modal as AntModal, Form, Radio, Spin, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import _uniqBy from 'lodash/uniqBy';

import { useTransactionState } from './transactionState';
import TransactionTable from '../TransactionTable/TransactionTable';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useAccountQuery from 'hooks/useAccountQuery';
import useAgent from 'hooks/useAgent';
import { ACCOUNT_STATUSES } from 'queries/account';

import { UPDATE_ACCOUNT_STATUS } from 'mutations/account';
import { MARK_AS_FRAUD } from 'mutations/transactions';

import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { COLORS } from 'utilities/styles';
import {
  ACCOUNT_STATUS_CODE,
  ACCOUNT_STATUS_OPERATION,
  BREAKPOINT,
  INDEFINITE_EXPIRATION_DATE,
  TRANSACTION_TYPE,
  STATUS,
  SIZE,
} from 'utilities/constants';

const { Paragraph, Title } = Typography;

const Modal = styled(AntModal)`
  min-width: 100%;
  top: var(--spacing-lg);

  @media (min-width: ${BREAKPOINT.MD_PX}) {
    min-width: 1000px;
  }
`;

const TableWithHighlights = styled(TransactionTable)`
  .ant-table-row.ant-table-row-selected.ineligible-transaction {
    .ant-table-cell {
      background-color: ${COLORS.mustard4};
    }
    &:hover {
      .ant-table-cell {
        background-color: ${COLORS.yellow2};
      }
    }
  }
  .ant-table-row.outdated-transaction {
    .ant-checkbox-wrapper {
      pointer-events: none;
    }
    .ant-table-cell {
      background-color: ${COLORS.grey4};
    }
    &:hover {
      .ant-table-cell {
        background-color: ${COLORS.grey3};
      }
    }
  }
`;

const FraudAuthorizationReviewModal = ({ hide, ...modalProps }) => {
  const { accountId, customerId } = useParams();
  const history = useHistory();
  const agent = useAgent();
  const { selectedTransactions } = useTransactionState();
  const { account } = useAccountQuery(customerId, accountId);

  const [processing, setProcessing] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [unactionableEntriesSelected, setUnactionableEntriesSelected] =
    useState(false);
  const [highlightNoSelectionsMessage, setHighlightNoSelectionsMessage] =
    useState(false);

  const [selectedEntries, setSelectedEntries] = useState([]);
  const [successMessageList, setSuccessMessageList] = useState([]);
  const [failureMessageList, setFailureMessageList] = useState([]);
  const [activeCardWatchStatus, setActiveCardWatchStatus] = useState();

  const [fraudAuthForm] = Form.useForm();
  const containsFraud = Form.useWatch('containsFraud', fraudAuthForm);
  const customerWantsNewCard = Form.useWatch(
    'customerWantsNewCard',
    fraudAuthForm,
  );
  const removeStatus = Form.useWatch('removeStatus', fraudAuthForm);
  const retryTransactions = Form.useWatch('retryTransactions', fraudAuthForm);

  const noActionToTake = useMemo(() => {
    let removeCW = false;
    if (activeCardWatchStatus) {
      removeCW = removeStatus;
    }
    return (
      containsFraud === false &&
      retryTransactions === false &&
      removeCW === false
    );
  }, [activeCardWatchStatus, containsFraud, retryTransactions, removeStatus]);

  const [actionableEntries, unactionableEntries, outdatedEntries] =
    useMemo(() => {
      const actionable = selectedTransactions.filter((entry) => {
        return entry.type !== TRANSACTION_TYPE.PAYMENT;
      });

      const unactionable = actionable.filter((entry) => {
        if (entry.amount === 0) {
          return false;
        }

        if (!entry?.isAuthorization) {
          return true;
        }

        return !entry.isDeclined;
      });

      const accountLast4 = account?.cardDetails?.last4;
      const outdated = actionable.filter((entry) => {
        if (entry?.isAuthorization && !entry?.isFraudAuthorization) {
          return entry.cardLast4 && entry.cardLast4 !== accountLast4;
        }
        return false;
      });

      return [actionable, unactionable, outdated];
    }, [selectedTransactions, account]);

  const rowSelectionConfig = useMemo(() => {
    return {
      selectedRowKeys: selectedEntries.map((entry) => entry.key),
      renderCell: (checked, record, index, node) => {
        return cloneElement(node, {
          'data-testid': `fraud-check-${index}`,
          disabled: outdatedEntries.includes(record),
          checked: selectedEntries.includes(record),
        });
      },
      onSelect: (transaction, isSelected) => {
        handleSelections([transaction], isSelected);
      },
      onSelectAll: (isSelected, current, updated) => {
        if (
          outdatedEntries.length &&
          outdatedEntries.length === updated.length
        ) {
          handleSelections(current, false);
          return;
        } else {
          handleSelections(updated, isSelected);
        }
      },
      onSelectMultiple: (isSelected, rows, changeRows) => {
        handleSelections(changeRows, isSelected);
      },
    };
  }, [selectedEntries, outdatedEntries]);

  const noFraudSelections = containsFraud && !selectedEntries.length;

  const fraudStatusesQuery = useQuery(ACCOUNT_STATUSES, {
    variables: {
      customerId,
      accountIds: [accountId],
    },
    skip: containsFraud === undefined,
    onCompleted: (data) => {
      const accountStatusData = (data?.accountFraudStatuses || []).find(
        (statusObj) => statusObj.accountId === accountId,
      );
      const accountStatuses = accountStatusData?.accountStatuses || [];
      setActiveCardWatchStatus(
        [...accountStatuses].find((status) => {
          return status.statusCode === ACCOUNT_STATUS_CODE.CW;
        }),
      );
    },
  });

  const [markAsFraud] = useMutation(MARK_AS_FRAUD);

  const [updateAccountStatus] = useMutation(UPDATE_ACCOUNT_STATUS, {
    refetchQueries: ['GetAccountStatuses', 'GetCustomerWithFullAccounts'],
  });

  const applyRowClass = (transaction) => {
    let rowClasses = [];

    if (containsFraud) {
      const isSelected = selectedEntries.includes(transaction);
      if (isSelected && unactionableEntries.includes(transaction)) {
        rowClasses.push('ineligible-transaction');
      }
    }

    if (outdatedEntries.includes(transaction)) {
      rowClasses.push('outdated-transaction');
    }

    return rowClasses.concat(' ');
  };

  const closeModal = () => {
    hide();
    fraudAuthForm.resetFields();
    setSubmissionSuccess(false);
    setSelectedEntries([]);
  };

  const handleSelections = (updatedEntries, isSelected) => {
    let selectionsUpdate = [...selectedEntries];
    if (isSelected) {
      if (outdatedEntries.length) {
        updatedEntries = updatedEntries.filter(
          (entry) => !outdatedEntries.includes(entry),
        );
      }
      selectionsUpdate = _uniqBy(
        [...selectionsUpdate, ...updatedEntries],
        'key',
      );
    } else {
      selectionsUpdate = selectionsUpdate.filter(
        (entry) => !updatedEntries.includes(entry),
      );
    }

    setSelectedEntries(selectionsUpdate);

    const hasUnactionableEntries = !!selectionsUpdate.find((entry) => {
      return unactionableEntries.includes(entry);
    });
    setUnactionableEntriesSelected(hasUnactionableEntries);
  };

  const handleFraudSubmit = async () => {
    const successMessages = [];

    const authorizationReferences = selectedEntries
      .filter((entry) => {
        return (
          (entry?.isAuthorization && entry?.isDeclined) || entry.amount === 0
        );
      })
      .filter((selection, index, timestamps) => {
        return (
          timestamps.findIndex((entry) => {
            return dayjs(selection.dateTimestamp).isSame(
              dayjs(entry.dateTimestamp),
              'second',
            );
          }) === index
        );
      })
      .map((entry) => {
        return {
          transactionId: entry.transactionId,
          timestamp: entry.dateTimestamp,
        };
      });

    if (authorizationReferences.length) {
      try {
        await markAsFraud({
          variables: {
            customerId,
            accountId,
            references: authorizationReferences,
          },
        });
        successMessages.push('Authorizations Marked as Fraud');
      } catch (error) {
        setFailureMessageList([
          'Failed to mark authorization(s) as fraud. Please try again.',
        ]);
        // This is the highest priority request. If it fails, back out and let the agent try again.
        return;
      }
    }

    if (!activeCardWatchStatus) {
      try {
        await updateAccountStatus({
          variables: {
            customerId,
            accountIds: [accountId],
            agentName: agent?.user?.name || 'anonymous',
            operation: ACCOUNT_STATUS_OPERATION.APPLIED,
            reasonCode: `${ACCOUNT_STATUS_CODE.CARD_WATCH}_10`,
            statusCode: ACCOUNT_STATUS_CODE.CARD_WATCH,
            startDate: formatDateToSubmit(new Date()),
            stopDate: formatDateToSubmit(INDEFINITE_EXPIRATION_DATE),
          },
        });
        successMessages.push('Card Watch Status Applied');
      } catch (error) {
        setFailureMessageList([
          'Failed to apply CW10 status. Please handle this manually on the Account Statuses tab.',
        ]);
      }
    }

    setSuccessMessageList(successMessages);
    setSubmissionSuccess(true);
  };

  const handleNoFraudSubmit = async (formValues) => {
    let hasSuccess = false;
    const successMessages = [];
    const failureMessages = [];

    if (activeCardWatchStatus && formValues.removeStatus) {
      try {
        await updateAccountStatus({
          variables: {
            customerId,
            accountIds: [accountId],
            agentName: agent?.user?.name,
            operation: ACCOUNT_STATUS_OPERATION.REMOVED,
            statusCode: ACCOUNT_STATUS_CODE.CARD_WATCH,
            reasonCode: `${ACCOUNT_STATUS_CODE.CARD_WATCH}_${activeCardWatchStatus.reasonCode}`,
          },
        });
        successMessages.push('Card Watch Status Removed');
        hasSuccess = true;
      } catch (error) {
        failureMessages.push(
          'Failed to remove Card Watch status. Please handle this manually on the Account Statuses tab.',
        );
      }
    }

    if (formValues.retryTransactions) {
      try {
        await updateAccountStatus({
          variables: {
            customerId,
            accountIds: [accountId],
            agentName: agent?.user?.name,
            operation: ACCOUNT_STATUS_OPERATION.APPLIED,
            reasonCode: `${ACCOUNT_STATUS_CODE.TIERED_WATCH}_15`,
            statusCode: ACCOUNT_STATUS_CODE.TIERED_WATCH,
            startDate: formatDateToSubmit(new Date()),
            stopDate: formatDateToSubmit(dayjs().add(48, 'hour')),
          },
        });
        successMessages.push('Bypass Status Applied');
        hasSuccess = true;
      } catch (error) {
        failureMessages.push(
          'Failed to apply account bypass TW-15 status. Please handle this manually on the Account Statuses tab.',
        );
      }
    }
    setSuccessMessageList(successMessages);
    setFailureMessageList(failureMessages);
    setSubmissionSuccess(hasSuccess);
  };

  const handleSubmit = async () => {
    if (containsFraud && noFraudSelections) {
      setHighlightNoSelectionsMessage(true);
      return;
    }

    setSuccessMessageList([]);
    setFailureMessageList([]);
    setHighlightNoSelectionsMessage(false);

    let formValues;
    try {
      formValues = await fraudAuthForm.validateFields();
    } catch {
      return;
    }

    setProcessing(true);

    if (containsFraud) {
      await handleFraudSubmit(formValues);
    } else {
      await handleNoFraudSubmit(formValues);
    }

    setProcessing(false);
  };

  const proceedToSendCard = () => {
    history.push(`./send-card?bypassFraud=true`);
    closeModal();
  };

  const renderSuccessMessage = () => {
    const buttons = [];

    if (containsFraud) {
      customerWantsNewCard &&
        buttons.push({
          text: 'Proceed to Send Card',
          onClick: proceedToSendCard,
        });
    }

    if (successMessageList.length) {
      const text = successMessageList.reduce((lines, currentValue, index) => {
        lines.push(
          <span key={`success-message-${index}`}>{currentValue}</span>,
        );
        if (index !== successMessageList.length - 1) {
          lines.push(<br key={`break-${index}`} />);
        }
        return lines;
      }, []);

      return (
        <SectionMessage
          data-testid="success-message"
          buttons={buttons}
          status={STATUS.SUCCESS}
          size={SIZE.SM}
          text={text}
          cover={true}
          visible={submissionSuccess}
        ></SectionMessage>
      );
    }
  };

  const renderFailureMessages = () => {
    if (failureMessageList.length) {
      return failureMessageList.map((message, index) => {
        return (
          <Alert
            banner
            key={`failure-message-${index}`}
            type="error"
            message={message}
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
        );
      });
    }
  };

  const renderPreSubmissionInfo = () => {
    if (processing || submissionSuccess) {
      return;
    }

    let message = '';
    let alertType = 'info';

    if (noActionToTake) {
      message = `No further action is needed. You can exit this process.`;
      alertType = 'warning';
    } else if (containsFraud === false) {
      if (removeStatus) {
        message = `any active Card Watch statuses will be removed and the Cardholder will be able to use their card as normal.`;
      }
      if (retryTransactions) {
        message = `${message} ${
          message ? 'A' : 'a'
        } bypass status will be applied to the account for 48 hours, and you will need to capture the details of the transaction the Cardholder is retrying in your interaction notes. `;
      }
      if (message) {
        message = `Upon submission, ${message}`;
      }
    } else if (containsFraud && !noFraudSelections) {
      message = `Upon submission, if a Card Watch status does not already exist on the account, a CW-10 status will be applied to this account and the customer's card will be locked.`;
    }

    return message ? (
      <Alert
        banner
        type={alertType}
        message={message}
        style={{ marginBottom: 'var(--spacing-xs)' }}
      ></Alert>
    ) : null;
  };

  const renderPostFraudSubmissionWarning = () => {
    let alert;
    if (submissionSuccess && containsFraud) {
      let message = '';
      let description;
      if (unactionableEntriesSelected) {
        message = 'PROCEED TO QFD';
        description =
          'Please proceed to QFD to create a case, or if you are not part of FDI, please forward this case to the FDI team. Before creating a case in QFD, ensure the address and email on file are accurate and review the active account statuses.';
      } else if (!customerWantsNewCard) {
        message =
          'Please ensure the card is locked and inform the Cardholder that they will need to request a new card before being able to use the card for any future purchases.';
      }

      alert = message ? (
        <Alert
          banner
          type="warning"
          description={description}
          message={message}
          style={{ marginBottom: 'var(--spacing-xs)' }}
        />
      ) : undefined;
    }

    return alert;
  };

  const hideNewCardRadio =
    containsFraud !== true || unactionableEntriesSelected;
  const hideNonFraudRadios = containsFraud !== false;

  return (
    <Modal
      {...modalProps}
      centered
      okText="Submit"
      onOk={handleSubmit}
      onCancel={closeModal}
      okButtonProps={{
        disabled:
          noActionToTake ||
          processing ||
          submissionSuccess ||
          fraudStatusesQuery.loading,
        loading: processing,
        hidden: !actionableEntries.length,
      }}
      title="Fraud Authorization Review"
      destroyOnClose={true}
    >
      {actionableEntries.length ? (
        <>
          <Paragraph>
            Review the following authorizations and transactions with the
            customer and select any that you have identified as fraudulent.
          </Paragraph>
          <TableWithHighlights
            data={actionableEntries}
            pagination={false}
            rowSelection={rowSelectionConfig}
            rowClassName={applyRowClass}
            showSelectionActions={true}
            size="small"
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
          <div
            style={{
              position: 'relative',
              minHeight:
                submissionSuccess && successMessageList.length ? '160px' : '',
            }}
          >
            {renderSuccessMessage()}

            <Form
              form={fraudAuthForm}
              name="cardStatus"
              disabled={processing || submissionSuccess}
            >
              {outdatedEntries.length ? (
                <Alert
                  banner
                  type="info"
                  message="Some authorizations above are disabled because they're for an outdated account card number."
                  style={{ marginBottom: 'var(--spacing-md)' }}
                />
              ) : null}

              {noFraudSelections ? (
                <Alert
                  banner
                  type={highlightNoSelectionsMessage ? 'error' : 'warning'}
                  message="Please select any transaction or authorizations above that you find to be fraudulent."
                  style={{ marginBottom: 'var(--spacing-sm)' }}
                />
              ) : null}

              <Spin spinning={fraudStatusesQuery.loading}>
                <Form.Item
                  data-testid="fraud-form-item"
                  label="Are any of these authorizations or transactions fraudulent?"
                  name="containsFraud"
                  rules={[
                    { required: true, message: 'Please make a selection' },
                  ]}
                >
                  <Radio.Group>
                    <Radio data-testid="contains-fraud-radio" value={true}>
                      Yes
                    </Radio>
                    <Radio data-testid="no-fraud-radio" value={false}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {containsFraud &&
                unactionableEntriesSelected &&
                !submissionSuccess ? (
                  <Alert
                    banner
                    data-testid="ineligle-selections-warning"
                    type="warning"
                    message={
                      <>
                        <Paragraph>
                          By clicking “Continue” the declined authorizations and
                          approved authorizations of $0 are marked as fraudulent
                          automatically.
                        </Paragraph>

                        <Title level={5}>Please Remember:</Title>

                        <Paragraph>
                          <strong>
                            For Recent Approved Authorizations and Transactions
                            Over $0
                          </strong>
                          <br />
                          Must be entered in QFD.
                        </Paragraph>

                        <Paragraph>
                          <strong>Approved but Unposted Authorizations:</strong>
                          <br />
                          Cannot be claimed in QFD if the authorization hasn’t
                          posted within 7 days or if the pending authorization
                          is not showing in QFD.
                          <br />
                          <i>Advise Customer</i> to monitor their account for
                          any additional fraud transactions that post. If any
                          appear, they should contact us to add the transaction
                          to the fraud claim.
                        </Paragraph>
                      </>
                    }
                    style={{
                      marginTop: 'calc(var(--spacing-md) * -1)',
                      marginBottom: 'var(--spacing-sm)',
                    }}
                  />
                ) : null}

                <Form.Item
                  data-testid="new-card-form-item"
                  label="Does the cardholder want a new card?"
                  name="customerWantsNewCard"
                  rules={[
                    {
                      required: !hideNewCardRadio,
                      message: 'Please make a selection',
                    },
                  ]}
                  hidden={hideNewCardRadio}
                >
                  <Radio.Group>
                    <Radio data-testid="yes-new-card-radio" value={true}>
                      Yes
                    </Radio>
                    <Radio data-testid="no-new-card-radio" value={false}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  data-testid="remove-status-form-item"
                  label="Remove the CW status?"
                  name="removeStatus"
                  rules={[
                    {
                      required: !hideNonFraudRadios && activeCardWatchStatus,
                      message: 'Please make a selection',
                    },
                  ]}
                  hidden={hideNonFraudRadios || !activeCardWatchStatus}
                >
                  <Radio.Group>
                    <Radio data-testid="yes-remove-status-radio" value={true}>
                      Yes
                    </Radio>
                    <Radio data-testid="no-remove-status-radio" value={false}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  data-testid="retry-form-item"
                  label="Does the Cardholder intend to retry the transaction?"
                  name="retryTransactions"
                  rules={[
                    {
                      required: !hideNonFraudRadios,
                      message: 'Please make a selection',
                    },
                  ]}
                  hidden={hideNonFraudRadios}
                >
                  <Radio.Group>
                    <Radio
                      data-testid="yes-retry-transactions-radio"
                      value={true}
                    >
                      Yes
                    </Radio>
                    <Radio
                      data-testid="no-retry-transactions-radio"
                      value={false}
                    >
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Spin>
            </Form>
          </div>
          {renderPreSubmissionInfo()}
          {renderPostFraudSubmissionWarning()}
          {renderFailureMessages()}
        </>
      ) : (
        <SectionMessage
          data-testid="no-selections-message"
          status={STATUS.WARNING}
          size={SIZE.SM}
          text="No actionable Purchases or Authorizations have been selected"
        ></SectionMessage>
      )}
    </Modal>
  );
};

export default FraudAuthorizationReviewModal;
