import dayjs from 'dayjs';

import {
  createReactiveState,
  createAddressReviewHandlers,
  createStepHandlers,
  createSubmissionHandlers,
  getAddressReviewProperties,
  getStepProperties,
  getSubmissionProperties,
  renderPendingStep,
  renderPositiveStep,
  renderWarningStep,
  renderUnableToProceed,
} from 'utilities/stateHelpers';

import AccountStandingStep from './AccountStandingStep';
import AddressReviewStepWrapper from './AddressReviewStepWrapper';
import CardStatusStep from './CardStatusStep';
import CardLossDetailsStep from './CardLossDetailsStep';
import FraudReviewStep from './FraudReviewStep';
import SubmissionStep from './SubmissionStep';

export const FRAUDULENT_ACTIVITY_STATUS = Object.freeze({
  YES: 'yes',
  NO: 'no',
  REPORTED: 'reported',
});

const ACCOUNT_STANDING_STEP = {
  title: 'Account Standing Review',
  component: AccountStandingStep,
  renderPreprocess: () => {
    const { accountInGoodStanding } = sendCardStateVar();
    if (accountInGoodStanding === false) {
      return renderUnableToProceed();
    }
    return renderPendingStep('Verifying account standing');
  },
  renderPostprocess: () => {
    return renderPositiveStep('Account is in good standing');
  },
};

const ADDRESS_REVIEW_STEP = {
  title: 'Address Review',
  component: AddressReviewStepWrapper,
  renderPreprocess: () => {
    return renderPendingStep('Reviewing customer address');
  },
  renderPostprocess: () => {
    const { addressIsCurrent } = sendCardStateVar();
    return renderPositiveStep(
      addressIsCurrent ? 'Address is current' : 'Address updated',
    );
  },
};

const CARD_LOSS_DETAILS_STEP = {
  title: 'Card Loss Details',
  component: CardLossDetailsStep,
  renderPreprocess: () => {
    return renderPendingStep('Collecting card loss details');
  },
  renderPostprocess: () => {
    return renderPositiveStep('Card loss details collected');
  },
};

const CARD_STATUS_STEP = {
  title: 'Card Status',
  component: CardStatusStep,
  renderPreprocess: () => {
    return renderPendingStep('Collecting card loss details');
  },
  renderPostprocess: () => {
    const { customerHasCard } = sendCardStateVar();
    return customerHasCard
      ? renderPositiveStep('Customer is in possession of card')
      : renderWarningStep('Card has been lost or stolen');
  },
};

const FRAUD_REVIEW_STEP = {
  title: 'Fraud Review',
  component: FraudReviewStep,
  renderPreprocess: () => {
    const { fraudulentActivityStatus } = sendCardStateVar();
    if (fraudulentActivityStatus === FRAUDULENT_ACTIVITY_STATUS.YES) {
      return renderUnableToProceed();
    }
    return renderPendingStep('Reviewing purchases for fraudulent activity');
  },
  renderPostprocess: () => {
    return renderPositiveStep('Account is free of recent fraudulent activity');
  },
};

const SUBMISSION_STEP = {
  title: 'Submit Send Card Request',
  component: SubmissionStep,
  renderPreprocess: () => {
    const { submissionCompleted } = sendCardStateVar();
    return submissionCompleted
      ? renderPositiveStep('Card Requested')
      : renderPendingStep('Complete Send Card request');
  },
  renderPostprocess: () => {},
};

export const SEND_CARD_STEPS = Object.freeze({
  ACCOUNT_STANDING: ACCOUNT_STANDING_STEP,
  ADDRESS_REVIEW: ADDRESS_REVIEW_STEP,
  CARD_LOSS_DETAILS: CARD_LOSS_DETAILS_STEP,
  CARD_STATUS: CARD_STATUS_STEP,
  FRAUD_REVIEW: FRAUD_REVIEW_STEP,
  SUBMISSION: SUBMISSION_STEP,
});

const initializeState = () => {
  return {
    ...getStepProperties(SEND_CARD_STEPS.CARD_STATUS),
    ...getAddressReviewProperties(),
    ...getSubmissionProperties(),
    accountInGoodStanding: undefined,
    cardLossDetails: {
      dateTimeDiscovered: dayjs(),
    },
    customerHasCard: undefined,
    expediteShipping: false,
    fraudulentActivityStatus: undefined,
    reissueDecline: false,
  };
};

const sendCardState = createReactiveState(initializeState);
const { createPropertySetter } = sendCardState;
export const {
  resetState: resetSendCardState,
  stateVar: sendCardStateVar,
  useState: useSendCardState,
} = sendCardState;

export const { setNextStep, goToPreviousStep } = createStepHandlers(
  sendCardState,
  Object.values(SEND_CARD_STEPS),
);

export const { setAddressIsCurrent, setAddressUpdated, setInitialAddressInfo } =
  createAddressReviewHandlers(sendCardState);

export const { setSubmissionInitiated, setSubmissionCompleted } =
  createSubmissionHandlers(sendCardState);

export const setAccountInGoodStanding = createPropertySetter(
  'accountInGoodStanding',
);

export const setCardLossDetails = createPropertySetter('cardLossDetails');

export const setCustomerHasCard = createPropertySetter('customerHasCard');

export const setExpediteShipping = createPropertySetter('expediteShipping');

export const setFraudulentActivityStatus = createPropertySetter(
  'fraudulentActivityStatus',
);

export const setReissueDecline = createPropertySetter('reissueDecline');
