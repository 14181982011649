import { gql } from '@apollo/client';

const ADDRESS_SUGGESTIONS = gql`
  query GetAddressSuggestions($address: String!) {
    findAddress(address: $address) {
      city
      secondary
      state
      street
      zip
    }
  }
`;

export { ADDRESS_SUGGESTIONS };
