import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Alert, Button, Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import AgentNote from './AgentNote';

import Loading from 'components/Loading/Loading';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useProfileViews from 'hooks/useProfileViews';
import { PAGE_VIEWS_QUERY } from 'queries/notes';

const ProfileViews = () => {
  const bodyHeight = '400px';
  const INITIAL_PAGE = 0;
  const { customerId, applicationId } = useParams();
  const rootRef = useRef(null);
  const { profileViews, setProfileViews } = useProfileViews();
  const [nextPage, setNextPage] = useState(INITIAL_PAGE + 1);
  const [showLoadMoreFailure, setShowLoadMoreFailure] = useState(false);

  const profileViewsTimeline =
    profileViews.views &&
    profileViews.views?.map((profileView, index) => {
      return {
        dot: <ClockCircleOutlined />,
        children: (
          <div key={`interaction-itm-${index}`} data-testid="interaction-item">
            <AgentNote interaction={profileView} rootRef={rootRef} />
          </div>
        ),
      };
    });

  const [getProfileViews, { loading: profileViewsLoading }] = useLazyQuery(
    PAGE_VIEWS_QUERY,
    {
      variables: {
        customerId,
        applicationId,
        page: INITIAL_PAGE,
        size: 100,
      },
      onCompleted: (data) => {
        const { views } = data;
        if (views) {
          const profileViewsWithMessageAndTz = views.map((view) => ({
            ...view,
            timestamp: view.toMcInteractionDto.timestamp,
            message: view.toMcInteractionDto.message,
          }));

          setProfileViews({
            views: profileViews?.views
              ? [...profileViews.views, ...profileViewsWithMessageAndTz]
              : profileViewsWithMessageAndTz,
          });

          const isOnLastPage = views?.length < 100;
          setNextPage((prev) => (isOnLastPage ? -1 : prev + 1));
        }
      },
      onError: () => {
        if (nextPage > 1) {
          setShowLoadMoreFailure(true);
        }
      },
    },
  );

  useEffect(() => {
    setProfileViews({
      views: [],
    });
  }, [customerId]);

  useEffect(() => {
    getProfileViews();
  }, [customerId]);

  const fetchNextPage = () => {
    if (nextPage === -1) {
      return;
    }

    setShowLoadMoreFailure(false);
    getProfileViews({
      variables: {
        customerId,
        page: nextPage,
        size: 100,
      },
    });
  };

  const showSkeleton = profileViewsLoading && nextPage === 1;
  const loadMoreButtonText = nextPage !== -1 ? 'Load More' : 'No More Views';

  return (
    <div style={{ overflow: 'auto', paddingTop: 'var(--spacing-sm)' }}>
      <QueryBoundary
        messageHeight={bodyHeight}
        errorMessage="Failed to load profile views."
        query={{
          loading: showSkeleton,
          called: true,
          data: {},
        }}
        skeleton={{
          title: false,
          paragraph: { rows: 12 },
        }}
      >
        <Timeline
          mode="left"
          items={profileViewsTimeline}
          style={{ paddingLeft: '2px', paddingTop: '2px' }}
        />

        {showLoadMoreFailure ? (
          <Alert
            type="error"
            message="Failed to load more profile views. Please try again."
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
        ) : null}

        {profileViewsLoading ? (
          <Loading />
        ) : (
          <>
            <Button
              type="primary"
              onClick={fetchNextPage}
              disabled={nextPage === -1}
            >
              {loadMoreButtonText}
            </Button>
          </>
        )}
      </QueryBoundary>
    </div>
  );
};

export default ProfileViews;
