import { useMemo } from 'react';
import { Button, Flex } from 'antd';
import useModalControls from 'hooks/useModalControls';

import { Modal, Typography } from 'antd';
import { formatDollarsFromCents } from 'utilities/helpers';

const ReferralsDetailModal = ({ referrals = {}, ...modalProps }) => {
  const { approvedReferrals, totalAmountEarned } = useMemo(() => {
    let approvedReferrals = 0;
    let totalAmountEarned = 0;

    if (referrals) {
      const referredCustomers = referrals?.referredCustomers || [];
      approvedReferrals = referredCustomers.length;
      referredCustomers.forEach((referral) => {
        totalAmountEarned += referral.amountStatementCredit;
      });
    }
    return {
      approvedReferrals,
      totalAmountEarned: formatDollarsFromCents(totalAmountEarned),
    };
  }, [referrals]);

  return (
    <Modal
      centered
      width={480}
      cancelButtonProps={{ hidden: true }}
      title="Referrals Details"
      {...modalProps}
    >
      <div style={{ paddingTop: 'var(--spacing-md)' }}>
        <Typography.Paragraph data-testid="approved-referrals">
          Count of Approved Referrals:{' '}
          <strong data-testid="approved-count">{approvedReferrals}</strong>
        </Typography.Paragraph>
        <Typography.Paragraph data-testid="total-amount-earned">
          Total Amount Earned:{' '}
          <strong data-testid="total-earned">{totalAmountEarned}</strong>
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

const Referrals = ({ referrals }) => {
  const referralsDetailsModal = useModalControls();

  return (
    <>
      <ReferralsDetailModal referrals={referrals} {...referralsDetailsModal} />
      <Flex
        align="center"
        justify="space-between"
        gap="var(--spacing-xxs)"
        wrap="wrap"
      >
        <div> {referrals ? 'Eligible' : 'Not Eligible'} </div>
        {referrals && (
          <Button
            type="link"
            size="small"
            onClick={referralsDetailsModal.show}
            style={{ padding: 0 }}
          >
            View Details
          </Button>
        )}
      </Flex>
    </>
  );
};

export default Referrals;
