import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'antd';
import styled from '@emotion/styled';

import { COLORS } from 'utilities/styles';

const TooltipContainer = styled.div`
  .ant-tooltip-inner {
    background: ${COLORS.red4};
    border: 1px solid ${COLORS.red1};
    color: ${COLORS.black};
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  .ant-tooltip-arrow {
    &::before {
      background: ${COLORS.red1};
    }
  }
`;

const ErrorTooltip = ({ children, ...tooltipProps }) => {
  const containerRef = useRef();
  return (
    <>
      {createPortal(<TooltipContainer ref={containerRef} />, document.body)}
      <Tooltip
        getPopupContainer={() => {
          return containerRef.current;
        }}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
    </>
  );
};

export default ErrorTooltip;
