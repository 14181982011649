import { Card } from 'antd';

import DontKnow from 'assets/not-found/dont-know.gif';
import ImLost from 'assets/not-found/im-lost.gif';
import Plankton from 'assets/not-found/plankton.gif';
import ThatFace from 'assets/not-found/that-face.gif';
import WayOff from 'assets/not-found/way-off.gif';
import YoureLost from 'assets/not-found/youre-lost.gif';

const images = [DontKnow, ImLost, Plankton, ThatFace, WayOff, YoureLost];

const NotFoundPage = () => {
  let coinflip = ~~(Math.random() * images.length);
  let image = images[coinflip];
  return (
    <div style={{ margin: '0 auto 10px', textAlign: 'center', width: '480px' }}>
      <img src={image} alt="Are you lost?" style={{ width: '480px' }} />
      <Card bordered={false} style={{ marginTop: '10px' }}>
        <span data-testid="404-message">404 &mdash; That URL isn't real!</span>
      </Card>
    </div>
  );
};

export default NotFoundPage;
