import { DatePicker, Input, Form } from 'antd';
import useAgent from 'hooks/useAgent';

const FormItem = ({ label, name, type }) => {
  return (
    <Form.Item label={label} name={name} className="form-item">
      {type === 'date' ? (
        <DatePicker
          placeholder="MM/DD/YYYY"
          format={'M/D/YYYY'}
          style={{ width: 400 }}
        />
      ) : (
        <Input name={name} style={{ width: 400 }} />
      )}
    </Form.Item>
  );
};

const ApplicationSearchForm = () => {
  const agent = useAgent();

  return (
    <div>
      {agent?.isSuperUser && (
        <FormItem label="Full SSN" name="fullSsn" type="text" />
      )}
      <FormItem label="Email" name="email" type="text" />
    </div>
  );
};

export default ApplicationSearchForm;
