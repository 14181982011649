import { gql } from '@apollo/client';

const GET_NOTES_QUERY = gql`
  query GetAgentNotes($applicationId: String, $customerId: String) {
    notesV3(applicationId: $applicationId, customerId: $customerId) {
      id
      applicationId
      customerId
      agentId
      agentName
      message
      isSticky
      product
      createdTz
      updatedTz
    }
  }
`;

const PAGE_VIEWS_QUERY = gql`
  query GetPageViews(
    $applicationId: String
    $customerId: String
    $page: Int
    $size: Int
  ) {
    views: agentViewsV3(
      applicationId: $applicationId
      customerId: $customerId
      page: $page
      size: $size
    ) {
      agentId
      agentName
      createdTz
      customerId
      id
      updatedTz
      toMcInteractionDto {
        agentName
        isSticky
        message
        product
        timestamp
      }
    }
  }
`;

export { GET_NOTES_QUERY, PAGE_VIEWS_QUERY };
