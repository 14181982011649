import { useState } from 'react';
import { Col, Grid, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import AccountOverview from './AccountOverview';
import CreditOverview from './CreditOverview';
import DebitOverview from './DebitOverview';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import { CUSTOMER_WITH_FULL_ACCOUNTS } from 'queries/customer';
import { PRODUCT } from 'utilities/constants';

const PRODUCT_ORDER = {
  [PRODUCT.CREDIT_CARD]: 1,
  [PRODUCT.DEBIT]: 2,
};

const AccountListSkeleton = ({ children, loading }) => {
  let breakpoints = Grid.useBreakpoint();
  let columns = breakpoints.xxl ? 3 : breakpoints.lg ? 2 : 1;
  let accounts = [];
  for (let index = 0; index < columns; index++) {
    accounts.push(
      <Col md={24} lg={12} xxl={8} key={`account-placeholder-${index}`}>
        <AccountOverview
          placeholder={true}
          query={{ called: false, loading: true }}
        >
          <span />
        </AccountOverview>
      </Col>,
    );
  }
  return loading ? <Row gutter={[10, 10]}>{accounts}</Row> : children;
};

const CustomerOverviewPage = () => {
  let { customerId } = useParams();
  let [simpleAccounts, setSimpleAccounts] = useState([]);
  let [creditAccounts, setCreditAccounts] = useState([]);

  let overviewQuery = useQuery(CUSTOMER_WITH_FULL_ACCOUNTS, {
    variables: { customerId },
    onCompleted: ({ customer }) => {
      // Sort accounts in order of importance
      let customerAccounts = customer.accounts || [];
      setSimpleAccounts(
        [...customerAccounts].sort((account1, account2) => {
          return (
            (PRODUCT_ORDER[account1.product.name] || Infinity) -
            (PRODUCT_ORDER[account2.product.name] || Infinity)
          );
        }),
      );
      setCreditAccounts(customer.creditAccounts);
    },
  });

  let renderAccountOverview = (simpleAccount) => {
    let OverviewComponent;
    let params = {
      accountId: simpleAccount.id,
      simpleAccount,
    };
    switch (simpleAccount.product.name) {
      case PRODUCT.CREDIT_CARD:
        OverviewComponent = CreditOverview;
        params.account = creditAccounts.find(
          (creditAccount) => creditAccount.id === simpleAccount.id,
        );
        break;
      case PRODUCT.DEBIT:
        OverviewComponent = DebitOverview;
        break;
      default:
        OverviewComponent = AccountOverview;
        params.accountLabel = simpleAccount.product.name;
    }
    return (
      <Col md={24} lg={12} xxl={8} key={`account-overview-${simpleAccount.id}`}>
        <OverviewComponent {...params}></OverviewComponent>
      </Col>
    );
  };

  return (
    <QueryBoundary
      errorMessage="Failed to load Account Overviews."
      query={overviewQuery}
      skeleton={AccountListSkeleton}
    >
      <Row gutter={[10, 10]}>{simpleAccounts.map(renderAccountOverview)}</Row>
    </QueryBoundary>
  );
};

export default CustomerOverviewPage;
