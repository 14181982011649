import { gql } from '@apollo/client';

const CUSTOMER_SEARCH = gql`
  query SearchCustomers(
    $customerSearchRequestBody: CustomerSearchRequestBodyInput!
  ) {
    searchCustomer(customerSearchRequestBody: $customerSearchRequestBody) {
      accountIds
      birthDate
      customerId
      email
      firstName
      lastName
      lastFourSsn
      state
      customerType
    }
  }
`;

export { CUSTOMER_SEARCH };
