import { useParams } from 'react-router-dom';

import AddressReview from '../AddressReviewStep';
import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_EVENTS } from 'utilities/constants';

import {
  setAddressIsCurrent,
  setAddressUpdated,
  setInitialAddressInfo,
  setNextStep,
  resetSendCardState,
  useSendCardState,
  SEND_CARD_STEPS,
} from './sendCardState';

const AddressReviewStep = () => {
  const { trackEvent } = useAnalytics();
  const { accountId, customerId } = useParams();
  const { initialAddressInfo, addressIsCurrent, addressUpdated } =
    useSendCardState();

  const handleContinue = (returnMailStatus) => {
    trackEvent(ANALYTICS_EVENTS.SEND_CARD_ADDRESS_REVIEW, {
      customerId,
      accountId,
      addressUpdated,
      hasReturnMailStatus: !!returnMailStatus,
    });
    setNextStep(SEND_CARD_STEPS.SUBMISSION);
  };

  return (
    <AddressReview
      checkReturnMailStatus={true}
      addressIsCurrent={addressIsCurrent}
      addressUpdated={addressUpdated}
      initialAddressInfo={initialAddressInfo}
      setAddressIsCurrent={setAddressIsCurrent}
      setAddressUpdated={setAddressUpdated}
      setInitialAddressInfo={setInitialAddressInfo}
      onContinue={handleContinue}
      onExit={resetSendCardState}
    />
  );
};

export default AddressReviewStep;
