import { gql } from '@apollo/client';

const SEND_AUTOMATED_ZENDESK_MESSAGE = gql`
  mutation SendZendeskCommunication(
    $customerId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $macroId: String!
  ) {
    sendCommunication(
      customerId: $customerId
      email: $email
      firstName: $firstName
      lastName: $lastName
      macroId: $macroId
    ) {
      success
      url
    }
  }
`;

export { SEND_AUTOMATED_ZENDESK_MESSAGE };
