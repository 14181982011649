import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import config from 'config';
import useAnalytics from 'hooks/useAnalytics';
import { formatPathForAnalytics } from 'utilities/helpers';

const SegmentProvider = (props) => {
  const location = useLocation();
  const { trackPage, resetUser, identifyUser } = useAnalytics();
  const { authState, oktaAuth } = useOktaAuth();
  const { customerId } = useParams();

  // Track each page change
  useEffect(() => {
    const page = formatPathForAnalytics(location.pathname);
    trackPage(page, { customerId });
  }, [location]);

  // Identify user
  useEffect(() => {
    if (authState && oktaAuth) {
      const fetchUser = async () => {
        try {
          const oktaUser = await oktaAuth.getUser();
          identifyUser(oktaUser?.requester_id, oktaUser);
        } catch (err) {
          resetUser();
          console.error('There was a problem getting user details', err);
        }
      };

      fetchUser();
    }
  }, [authState, oktaAuth]);

  const segmentScript = `!(function () {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
          ];
          analytics.factory = function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              t.unshift(e);
              analytics.push(t);
              return analytics;
            };
          };
          for (var e = 0; e < analytics.methods.length; e++) {
            var key = analytics.methods[e];
            analytics[key] = analytics.factory(key);
          }
          analytics.load = function (key, e) {
            var t = document.createElement('script');
            t.type = 'text/javascript';
            t.async = !0;
            t.src =
              'https://cdn.segment.com/analytics.js/v1/' +
              key +
              '/analytics.min.js';
            var n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = e;
          };
          analytics._writeKey = '${config.SEGMENT_KEY}';
          analytics.SNIPPET_VERSION = '4.13.2';
          analytics.load('${config.SEGMENT_KEY}');
        }
    })()`;

  return (
    <HelmetProvider>
      <Helmet>
        <script type="text/javascript">{segmentScript}</script>
      </Helmet>
      {props.children}
    </HelmetProvider>
  );
};

export default SegmentProvider;
