import { createContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { setSentryAgentInfo } from 'services/sentry';
import { AGENT_ROLES } from 'utilities/constants';

export const AgentContext = createContext(null);

const AgentProvider = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [agent, setAgent] = useState({
    hasAccess: false,
    isCommandManager: false,
    isSuperUser: false,
    loaded: false,
    roles: [],
    user: {},
  });

  const updateAgent = (update) => {
    setAgent(Object.assign({}, agent, update));
    setSentryAgentInfo(update.user);
  };

  const fetchAgent = async () => {
    try {
      const oktaUser = await oktaAuth.getUser();

      const hasAccess = !!authState.idToken?.claims?.groups?.find(
        (group) => group.toLowerCase() === 'ci tool access',
      );

      const isCommandManager =
        authState.idToken?.claims?.roles.toLowerCase() === 'command-manager';

      const isSuperUser = !!authState.idToken?.claims?.groups?.find(
        (group) => group.toLowerCase() === 'ci tool super users',
      );

      const roles = [];
      hasAccess && roles.push(AGENT_ROLES.AGENT);
      isCommandManager && roles.push(AGENT_ROLES.COMMAND_MANAGER);
      isSuperUser && roles.push(AGENT_ROLES.SUPER_USER);

      updateAgent({
        hasAccess,
        isCommandManager,
        isSuperUser,
        loaded: true,
        roles,
        user: oktaUser,
      });
    } catch (err) {
      console.error('There was a problem getting user details', err);
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated && oktaAuth) {
      fetchAgent();
    }

    //Maybe reset?
    // const authenticated = authState?.isAuthenticated;
    // if (!authenticated) {
    //   updateAgent({ roles: [], user: null, hasAccess: false})
    // } else {
    //   fetchAgent();
    // }
  }, [authState, oktaAuth]);

  return (
    <AgentContext.Provider value={agent}>{children}</AgentContext.Provider>
  );
};

export default AgentProvider;
