import { Card, Layout } from 'antd';
import styled from '@emotion/styled';

const StyledSider = styled(Layout.Sider)(({ align }) => {
  let alignmentStyles = ``;
  if (align === 'right') {
    alignmentStyles = `
      right: 0;
    `;
  }

  // Need to use mc-fixed-sider class name for these to properly apply
  return `
    &.mc-fixed-sider {
      background: transparent;
      border-radius: var(--spacing-xs);
      margin-right: var(--spacing-xs);
      position: fixed;
      top: calc(var(--header-height) + var(--spacing-xs));
      overflow-y: scroll;
      bottom: var(--spacing-xs);
      height: calc(100vh - var(--header-height) - (var(--spacing-xs)*2));
      ${alignmentStyles}
    }
  `;
});

const FixedSider = ({
  children,
  width = 'var(--meta-width)',
  ...siderProps
}) => {
  return (
    <StyledSider
      className="mc-fixed-sider"
      position="fixed"
      theme="light"
      width={width}
      {...siderProps}
    >
      <Card
        bordered={false}
        style={{
          minHeight: '100%',
        }}
        styles={{ body: { padding: 0 } }}
      >
        {children}
      </Card>
    </StyledSider>
  );
};

export default FixedSider;
