import { useState } from 'react';

// Hook that tracks the React.onScroll event and fires a callback the first time it scrolls past the scrollLimit,
// then extends the limit to track further scrolling.
// For example if an element has a 1000px height and the scrollInterval is 300:
// *Callback will execute once every time the users scrolls past 300px intervals (at 300, 600 and 900)
// *If the user scrolls all the way to the bottom, callback will fire 3 times.
// *If the user scrolls to the middle (500) then callback will fire 1 time.
// *If the user scrolls all the way to the bottom, then back all the way up then all the way down again callback will only execute 3 times.
const useScrollLimit = (scrollInterval, callback) => {
  const [scrollLimit, setScrollLimit] = useState(scrollInterval);

  const onScroll = (e) => {
    if (e?.target?.scrollTop > scrollLimit) {
      callback(scrollLimit);
      setScrollLimit((prevScrollLimit) => prevScrollLimit + scrollInterval);
    }
  };

  return { onScroll };
};

export default useScrollLimit;
