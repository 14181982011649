import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Flex,
  Layout,
  Menu,
  Tag,
  Dropdown,
  Space,
  Typography,
} from 'antd';
import { DownOutlined, PhoneFilled } from '@ant-design/icons';
import styled from '@emotion/styled';

import missionControlLogo from 'assets/mission-control.svg';

import AddNotes from 'components/AddNotes/AddNotes';

import useAgent from 'hooks/useAgent';
import useAWSConnectInfo from 'hooks/useAWSConnectInfo';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useModalControls from 'hooks/useModalControls';
import useNavigationKeyManager from 'hooks/useNavigationKeyManager';
import useSidebar from 'hooks/useSidebar';

import { CUSTOMER_TYPE } from 'utilities/constants';
import { COLORS } from 'utilities/styles';

import { useFlags } from 'launchdarkly-react-client-sdk';

const Logo = styled.img`
  float: left;
  margin-right: 50px;
  height: 64px;
`;

const Header = () => {
  const bulkNoteModal = useModalControls();

  const agent = useAgent();
  const { sidebarOpen, toggleSidebarOpen } = useSidebar();
  const { customerInfo, customerId, applicationId } = useCustomerInfo();
  const { agentStatusColors } = useAWSConnectInfo();
  const { isFdrComplaints } = useFlags();

  const [recentCustomerId, setRecentCustomerId] = useState('');
  const [recentApplicationId, setRecentApplicationId] = useState('');

  let navigationItems = [
    {
      key: 'search',
      path: '/search',
      exact: 'true',
      label: <Link to="/search">Search</Link>,
    },
  ];

  customerInfo.customerType === CUSTOMER_TYPE.CUSTOMER &&
    navigationItems.push({
      key: 'customerOverview',
      path: '/customers/:customerId',
      exact: 'false',
      label: (
        <Link to={`/customers/${recentCustomerId}`}>Customer Overview</Link>
      ),
    });

  customerInfo.customerType === CUSTOMER_TYPE.APPLICANT &&
    navigationItems.push({
      key: 'applicationOverview',
      path: '/applications/:applicationId',
      exact: 'false',
      label: (
        <Link to={`/applications/${recentApplicationId}`}>
          Application Overview
        </Link>
      ),
    });

  if (isFdrComplaints) {
    navigationItems.push({
      label: <Link to="/fraud-files-search">Fraud Files Search</Link>,
      key: 'fraudFilesSearch',
      path: '/fraud-files-search',
      exact: 'true',
    });
  }

  const [activeRouteKey] = useNavigationKeyManager(navigationItems);

  const getUserBadge = () => {
    // Look into refactoring this. Dropdown is awkward here. Bulk notes should be moved to the notes pane.
    return agent.isSuperUser ? (
      <div>
        <Dropdown
          menu={{
            items: [
              {
                label: 'Add bulk note',
                onClick: bulkNoteModal.show,
              },
            ],
          }}
          trigger={['click']}
        >
          <Tag
            color={COLORS.blue1}
            style={{ cursor: 'pointer', marginRight: 0 }}
          >
            Super User
            <DownOutlined style={{ marginLeft: 'var(--spacing-xs)' }} />
          </Tag>
        </Dropdown>
      </div>
    ) : agent.isCommandManager ? (
      <Tag color={COLORS.green1}>Command Manager</Tag>
    ) : agent.hasAccess ? (
      <Tag color={COLORS.green1}>Agent</Tag>
    ) : (
      <Tag color={COLORS.red1}>No Access</Tag>
    );
  };

  useEffect(() => {
    if (customerId) {
      setRecentCustomerId(customerId);
    }
    if (applicationId) {
      setRecentApplicationId(applicationId);
    }
  }, [customerId, applicationId]);

  return (
    <Layout.Header
      style={{
        backgroundColor: COLORS.haze4,
        display: 'flex',
        position: 'fixed',
        top: 0,
        zIndex: 5,
        width: '100%',
        height: 'var(--header-height)',
        borderBottom: 'var(--border-light)',
        boxShadow: 'var(--shadow-base)',
        justifyContent: 'space-between',
      }}
    >
      <Link to="/">
        <Logo src={missionControlLogo} alt="Mission Control" />
      </Link>
      <Menu
        theme="light"
        mode="horizontal"
        style={{ flexGrow: 1 }}
        selectedKeys={activeRouteKey}
        items={navigationItems}
      />
      <Flex gap="var(--spacing-md)" align="center">
        <Typography.Text>
          {agent.user ? agent.user.name : 'anonymous'}
        </Typography.Text>
        {getUserBadge()}
        <Space.Compact>
          <Button
            disabled
            style={{
              backgroundColor: agentStatusColors.background,
              borderColor: agentStatusColors.background,
              cursor: 'auto',
            }}
          >
            <PhoneFilled
              style={{
                fontSize: '1.4rem',
                color: agentStatusColors.foreground,
              }}
              color={agentStatusColors.foreground}
            />
          </Button>
          <Button onClick={toggleSidebarOpen}>
            {sidebarOpen ? 'Hide' : 'Show'}
          </Button>
        </Space.Compact>
      </Flex>
      <AddNotes {...bulkNoteModal} />
    </Layout.Header>
  );
};

export default Header;
