import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Flex, Modal, Typography, Popconfirm } from 'antd';
import { LikeFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import CondensedTable from 'components/CondensedTable/CondensedTable';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import useModalControls from 'hooks/useModalControls';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { PAYMENTS } from 'queries/payments';
import { UPDATE_SETTLEMENT_PAYMENT_PLAN } from 'mutations/settlements';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { getEftaText, formatDollarsFromCents } from 'utilities/helpers';
import { SETTLEMENT_OFFER_STATES, SIZE, STATUS } from 'utilities/constants';
import { COLORS } from 'utilities/styles';

import EditPaymentModal from './EditPaymentModal';
import EFTAForm from './EFTAForm';
import {
  setPendingDeletedPayments,
  setPayments,
  setUpdatedFuturePayments,
  usePaymentPlanState,
} from './paymentPlanState';
import {
  getTotalPaymentsAndBalanceMsg,
  updatePaymentsWithPaymentState,
} from './settlementPaymentUtils';

const { Title } = Typography;
const { confirm } = Modal;

export const PaymentsTableWithHighlights = styled(CondensedTable)`
  .ant-table-row.edited-payment {
    .ant-table-cell {
      background-color: ${COLORS.mustard4};
    }
    &:hover {
      .ant-table-cell {
        background-color: ${COLORS.yellow2};
      }
    }
  }
  .ant-table-row.deleted-payment {
    &::after {
      content: ' ';
      position: absolute;
      display: inline-block;
      left: 0;
      border-bottom: 2px solid ${COLORS.black};
      width: 100%;
      padding: 10px;
    }
    .ant-table-cell {
      background-color: ${COLORS.red4};
    }
    &:hover {
      .ant-table-cell {
        background-color: ${COLORS.red3};
      }
    }
  }
`;

export const applyRowClass = (payment) => {
  if (payment.status === 'edit' || payment.status === 'add') {
    return 'edited-payment';
  }
  if (payment.status === 'delete') {
    return 'deleted-payment';
  }

  return '';
};

const UpcomingPaymentsTable = ({ thirdParty }) => {
  const { accountId, customerId } = useParams();
  const client = useApolloClient();

  const {
    balance,
    pendingDeletedPayments,
    eftaDetails,
    futurePayments,
    offer,
    payments,
    updatedFuturePayments,
  } = usePaymentPlanState();

  const isPendingCloseOut =
    offer?.state === SETTLEMENT_OFFER_STATES.PENDING_CLOSE_OUT;

  const { name: thirdPartyName, email: thirdPartyEmail } = thirdParty;

  const editPaymentModal = useModalControls();
  const eftaModal = useModalControls();

  const [paymentPlanSchedule, setPaymentPlanSchedule] = useState([]);

  const [editPaymentFormInitialValues, setEditPaymentFormInitialValues] =
    useState({});

  const [updatePaymentType, setUpdatePaymentType] = useState('');

  const [submitNewPaymentPlanErrorMsg, setSubmitNewPaymentPlanErrorMsg] =
    useState('');
  const [submitNewPaymentPlanSuccessMsg, setSubmitNewPaymentPlanSuccessMsg] =
    useTimeoutValue('', 3000);

  const onAddPayment = () => {
    setUpdatePaymentType('add');
    editPaymentModal.show();
  };

  const settlementPaymentsDateRange = {
    fromDate: formatDateToSubmit(
      dayjs(offer.acceptanceExpiration).subtract(1, 'day'),
    ),
    toDate: formatDateToSubmit(dayjs().add(1, 'day')),
  };

  const [getPayments, getPaymentsQuery] = useLazyQuery(PAYMENTS, {
    variables: {
      accountId,
      customerId,
      fromDate: settlementPaymentsDateRange.fromDate,
      toDate: settlementPaymentsDateRange.toDate,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ account }) => {
      const paymentsWithPaymentState = updatePaymentsWithPaymentState(
        payments,
        account.payments,
      );

      setPayments(paymentsWithPaymentState);
    },
  });

  useEffect(() => {
    getPayments();
  }, [customerId, accountId]);

  const [submitNewPaymentPlan, { loading: submitNewPaymentPlanLoading }] =
    useMutation(UPDATE_SETTLEMENT_PAYMENT_PLAN, {
      onCompleted: () => {
        setPendingDeletedPayments([]);
        setSubmitNewPaymentPlanSuccessMsg('Payment plan successfully updated!');
        setUpdatedFuturePayments([]);
        setTimeout(() => {
          eftaModal.hide();
        }, 2500);
      },
      onError: () => {
        setSubmitNewPaymentPlanErrorMsg(
          'Failed to update payment plan. Please try again.',
        );
      },
      refetchQueries: ['GetSettlementOffers'],
    });

  const [cancelPayments, { loading: cancelingPayments }] = useMutation(
    UPDATE_SETTLEMENT_PAYMENT_PLAN,
    {
      onCompleted: () => {
        setSubmitNewPaymentPlanSuccessMsg(
          'Payment Plan successfully canceled!',
        );
        setTimeout(() => {
          setSubmitNewPaymentPlanSuccessMsg('');
          // to prevent refetch before timeout finishes
          client.refetchQueries({ include: ['GetSettlementOffers'] });
        }, 2500);
      },
      onError: () => {
        setSubmitNewPaymentPlanErrorMsg(
          'Failed to delete payment plan. Please try again.',
        );
      },
    },
  );

  const onCancelRemainingPayments = async () => {
    const variables = {
      authorizationText: '',
      customerId,
      transactions: [],
      offerId: offer.offerId,
    };

    await cancelPayments({ variables });
  };

  const confirmCancelRemainingPayments = () => {
    confirm({
      title:
        'Are you sure you want to cancel all remaining scheduled payments?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      onOk() {
        return onCancelRemainingPayments();
      },
      onCancel() {},
    });
  };

  const { customerName, email, cardLast4, fundingAccountLast4 } = eftaDetails;

  const onConfirmNewSchedule = async () => {
    const updatedPaymentSchedule = updatedFuturePayments.map((payment) => ({
      amount: payment.amount,
      date: payment.date,
    }));

    const eftaText = getEftaText({
      eftaDetails: {
        cardLast4,
        customerName: thirdPartyName ?? customerName,
        fundingAccountLast4,
        email: thirdPartyEmail ?? email,
      },
      todaysDate: dayjs().format('L'),
      paymentPlanSchedule: updatedPaymentSchedule,
    });

    const variables = {
      authorizationText: eftaText,
      customerId,
      transactions: updatedPaymentSchedule,
      offerId: offer.offerId,
    };

    await submitNewPaymentPlan({ variables });
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      render: (date) => {
        return dayjs(date).format('LL');
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (_, record) => (
        <>
          {formatDollarsFromCents(record.amount)}
          {!record.type && !isPendingCloseOut && (
            <Button
              type="text"
              onClick={() => {
                setEditPaymentFormInitialValues({
                  amount: Number((record.amount / 100).toFixed(2)),
                  date: dayjs(record.date),
                });
                setUpdatePaymentType('edit');
                editPaymentModal.show();
              }}
              style={{ marginLeft: 'var(--spacing-md)' }}
            >
              Edit Payment
            </Button>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status',
      filters: [
        { text: 'Posted', value: 'POSTED' },
        { text: 'Pending', value: 'PENDING' },
        { text: 'Returned', value: 'RETURNED' },
        { text: 'Scheduled', value: 'SCHEDULED' },
      ],
      defaultFilteredValue: ['SCHEDULED', 'SCHEDULED DEBIT'],
      filterMode: 'tree',
      onFilter: (value, record) => {
        if (value === 'SCHEDULED' && !record.type) {
          return true;
        }
        return record.type === value;
      },
      sorter: (a, b) => {
        const statusA = a.type || 'SCHEDULED';
        const statusB = b.type || 'SCHEDULED';
        return statusA.localeCompare(statusB);
      },
      render: (_, record) => {
        if (record.type) {
          return record.type;
        } else {
          return 'SCHEDULED';
        }
      },
    },
  ];

  const paymentsExceedOrUnderBalanceAlert = useMemo(() => {
    if (balance?.remainingBalance && updatedFuturePayments) {
      return getTotalPaymentsAndBalanceMsg({
        balance,
        futurePayments,
        updatedFuturePayments,
        payments,
        offer: offer.offerType,
      });
    }
  }, [balance, updatedFuturePayments, payments]);

  useEffect(() => {
    const scheduledPayments = updatedFuturePayments.length
      ? updatedFuturePayments
      : futurePayments;

    const tableData = payments
      ? payments?.concat(scheduledPayments).concat(...pendingDeletedPayments)
      : scheduledPayments.concat(...pendingDeletedPayments);

    setPaymentPlanSchedule(
      tableData.sort((a, b) => dayjs(a.date) - dayjs(b.date)),
    );
  }, [futurePayments, updatedFuturePayments, payments]);

  return (
    <Flex vertical={true}>
      {isPendingCloseOut && (
        <Alert
          banner
          showIcon
          icon={<LikeFilled />}
          type="info"
          message={
            <>
              <strong>
                The account is PENDING COMPLETION of their settlement offer.
              </strong>{' '}
              Customers may cancel their remaining scheduled payments.
            </>
          }
          style={{ marginBottom: 'var(--spacing-sm)' }}
        />
      )}

      <Title level={5}>Payments</Title>
      <QueryBoundary
        query={getPaymentsQuery}
        mode={QueryBoundary.MODE.MESSAGE}
        loadingMessage="Loading Payments"
      >
        <PaymentsTableWithHighlights
          rowClassName={applyRowClass}
          columns={columns}
          data={paymentPlanSchedule}
          length={7}
          style={{ marginBottom: 'var(--spacing-sm)' }}
        />
      </QueryBoundary>

      {!isPendingCloseOut && (
        <Flex gap="var(--spacing-sm)" justify="right">
          <Button onClick={onAddPayment}>Add a Payment</Button>
          <Button
            type="primary"
            disabled={
              !updatedFuturePayments.length ||
              paymentsExceedOrUnderBalanceAlert?.type === 'error'
            }
            onClick={eftaModal.show}
          >
            Confirm New Schedule
          </Button>
        </Flex>
      )}

      {isPendingCloseOut && (
        <Button
          danger
          onClick={confirmCancelRemainingPayments}
          loading={cancelingPayments}
          style={{ marginTop: 'var(--spacing-lg)' }}
        >
          Cancel Remaining Scheduled Payments
        </Button>
      )}

      {!isPendingCloseOut && !paymentsExceedOrUnderBalanceAlert && (
        <Alert
          banner
          showIcon
          type="info"
          message="Customers can always edit their scheduled payments as long as they stay within the plan’s due date
             and as along as the amounts total to the remaining balance."
          style={{ marginTop: 'var(--spacing-sm)' }}
        />
      )}

      {paymentsExceedOrUnderBalanceAlert && (
        <Alert
          banner
          type={paymentsExceedOrUnderBalanceAlert.type}
          message={paymentsExceedOrUnderBalanceAlert.message}
          style={{ marginTop: 'var(--spacing-sm)' }}
        />
      )}

      {submitNewPaymentPlanSuccessMsg && (
        <SectionMessage
          cover={true}
          status={STATUS.SUCCESS}
          size={SIZE.MD}
          text={submitNewPaymentPlanSuccessMsg}
          visible={submitNewPaymentPlanSuccessMsg}
        />
      )}

      <EditPaymentModal
        {...editPaymentModal}
        transactions={paymentPlanSchedule}
        initialValues={editPaymentFormInitialValues}
        setInitialValues={setEditPaymentFormInitialValues}
        type={updatePaymentType}
      />
      <Modal
        {...eftaModal}
        width={600}
        onCancel={() => {
          setSubmitNewPaymentPlanErrorMsg('');
          setSubmitNewPaymentPlanSuccessMsg('');
          eftaModal.hide();
        }}
        footer={[]}
        destroyOnClose={true}
      >
        <EFTAForm
          eftaDetails={{
            cardLast4,
            customerName: thirdPartyName ?? customerName,
            email: thirdPartyEmail ?? email,
            fundingAccountLast4,
          }}
          onFinish={onConfirmNewSchedule}
          mutation={{
            loading: submitNewPaymentPlanLoading,
            error: submitNewPaymentPlanErrorMsg,
            success: submitNewPaymentPlanSuccessMsg,
          }}
        />
      </Modal>
    </Flex>
  );
};

export default UpcomingPaymentsTable;
