import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { CUSTOMER_PREFERENCES } from 'queries/customer';

// Hook that returns a boolean whether we are allowed to communicate (email, calls, text) with this customer or not
// returns null initially until the CUSTOMER_PREFERENCES query resolves. Then returns true or false.
const useCeaseAndDesistStatus = () => {
  const { customerId } = useParams();

  const [ceaseAndDesistEnabled, setCeaseAndDesistStatusEnabled] =
    useState(null);

  useQuery(CUSTOMER_PREFERENCES, {
    variables: { customerId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) =>
      setCeaseAndDesistStatusEnabled(
        !!data?.customer?.communicationPreferences?.ceaseDesist,
      ),
  });

  return { ceaseAndDesistEnabled };
};

export default useCeaseAndDesistStatus;
