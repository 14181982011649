import { gql } from '@apollo/client';

const GET_CASE_TYPE_OPTIONS = gql`
  query GetCaseTypeOptions {
    caseWorkOptions {
      caseTypes {
        cycleDateRequired
        requestedDueDateRequired
        label
        team
        value
      }
      teams {
        label
        value
      }
      hardshipTypes {
        label
        value
      }
    }
  }
`;

export { GET_CASE_TYPE_OPTIONS };
