import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Alert, Button, Checkbox, Divider, Flex, Form, Typography } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import { SETTLEMENTS_CADENCE_VALUES, SIZE, STATUS } from 'utilities/constants';

import PaymentScheduleTable from './PaymentScheduleTable';
import { useRecoveriesState } from './recoveriesState';

const { Item } = Form;
const { Text, Title, Paragraph } = Typography;

const EFTAForm = ({
  eftaDetails,
  onFinish,
  mutation,
  resetErrorMessage,
  resetSuccessMessage,
}) => {
  const { paymentCadence } = useRecoveriesState();

  const [eftaForm] = Form.useForm();

  const { cardLast4, customerName, email, fundingAccountLast4 } = eftaDetails;
  const today = dayjs().format('L');

  const oneTimeDebit =
    paymentCadence?.cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT;
  const lumpSumPayment =
    paymentCadence?.cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME ||
    paymentCadence?.paymentPlanSchedule?.length === 1;

  const oneTimeOrPaymentPlan =
    oneTimeDebit || lumpSumPayment ? 'a payment' : 'payments';

  const achOrDebitText = oneTimeDebit ? (
    <Text>
      <strong>make a one time electronic debit</strong> from a debit card for
      the following amount:
    </Text>
  ) : (
    <Text>
      <strong>
        make {lumpSumPayment ? 'an' : 'a recurring'} electronic debit
      </strong>{' '}
      from the bank account ending in {fundingAccountLast4} for the following{' '}
      {lumpSumPayment ? 'date and amount' : 'dates and amounts'}:
    </Text>
  );

  const onConfirm = async () => {
    await eftaForm.validateFields();

    onFinish();
  };

  useEffect(() => {
    return () => {
      if (resetErrorMessage && resetSuccessMessage) {
        resetErrorMessage('');
        resetSuccessMessage('');
      }
      eftaForm.setFields([{ name: 'efta', value: undefined }]);
    };
  }, []);

  return (
    <Flex vertical={true}>
      <Title level={5}>Read EFTA</Title>
      <Alert
        banner
        type="info"
        showIcon={true}
        message={`Confirm email address before continuing: ${email}`}
        style={{ marginBottom: 'var(--spacing-md)' }}
      />
      <Text type="secondary">So, to confirm:</Text>
      <Form
        name="eftaForm"
        form={eftaForm}
        layout="vertical"
        onFinish={onConfirm}
      >
        <Flex vertical={true} style={{ marginTop: 'var(--spacing-sm)' }}>
          <Paragraph
            style={{
              textAlign: 'justify',
              textJustify: 'inter-word',
            }}
          >
            <Text>
              To make {oneTimeOrPaymentPlan} towards the balance on the Mission
              Lane Visa, ending in <strong>{cardLast4}</strong>, you{' '}
              {customerName}, today {today}, authorize Mission Lane to{' '}
              {achOrDebitText}
            </Text>
          </Paragraph>
          <Title level={5}>Payment Schedule</Title>
          <PaymentScheduleTable />

          {paymentCadence.cadence !==
          SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT ? (
            <>
              <Divider />
              <Paragraph
                style={{
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }}
              >
                To cancel or change {oneTimeOrPaymentPlan}, call us at least one
                business day before the payment date at (888) 695-8536 from 8:30
                AM to 8 PM Eastern Time, Monday through Friday.{' '}
              </Paragraph>
            </>
          ) : (
            <>
              <br />
            </>
          )}

          <Item
            name="efta"
            valuePropName="checked"
            rules={[
              {
                /* eslint-disable no-unused-vars */
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (!value) {
                      reject();
                    }
                    resolve();
                  });
                },
                message:
                  'Customer or 3rd party must verbally authorize payment to continue.',
              },
            ]}
            style={{ marginBottom: 'var(--spacing-sm)' }}
          >
            <Checkbox>
              Do you authorize [this payment/these payments]? [Wait for
              response]
            </Checkbox>
          </Item>

          <Alert
            banner
            type="warning"
            showIcon
            message="Customers or 3rd parties must verbally acknowledge the above disclosure before moving forward."
            style={{ marginBottom: 'var(--spacing-sm)' }}
          />
          <Text>
            Thank you, you’ve successfully authorized {oneTimeOrPaymentPlan}.
            We’ll send a confirmation email to {email}
          </Text>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 'var(--spacing-md)' }}
            loading={mutation.loading}
          >
            Confirm
          </Button>
          {mutation.success ? (
            <SectionMessage
              data-testid="efta-confirmed"
              cover={true}
              status={STATUS.SUCCESS}
              size={SIZE.MD}
              text={mutation.success}
              visible={!!mutation.success}
            />
          ) : null}
          {mutation.error ? (
            <Alert
              banner
              type="error"
              message={mutation.error}
              style={{ marginTop: 'var(--spacing-sm)' }}
            />
          ) : null}
        </Flex>
      </Form>
    </Flex>
  );
};

export default EFTAForm;
