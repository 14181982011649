import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { DIGITAL_WALLET_EVENTS_QUERY } from 'queries/wallet';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import CondensedTable from 'components/CondensedTable/CondensedTable';

const columns = Object.freeze([
  { title: 'Wallet Type', dataIndex: 'vendor' },
  { title: 'Date', dataIndex: 'date' },
  { title: 'Time', dataIndex: 'time' },
  { title: 'Token Number', dataIndex: 'tokenNumber' },
  { title: 'Token Requester ID', dataIndex: 'tokenRequesterId' },
  { title: 'Reason', dataIndex: 'reason' },
  { title: 'Indicator', dataIndex: 'indicator' },
  { title: 'DNITHE', dataIndex: 'dnitheId' },
]);

const DigitalWalletModal = ({ walletReferenceIds, open, ...modalProps }) => {
  const { accountId, customerId } = useParams();
  const [walletEvents, setWalletEvents] = useState([]);

  const [getDigitalWalletEvents, digitalWalletEventsQuery] = useLazyQuery(
    DIGITAL_WALLET_EVENTS_QUERY,
    {
      variables: { accountId, customerId },
      onCompleted: ({ account }) => {
        const walletEvents = (account?.digitalWallet?.events || []).map(
          (event, index) => {
            const key = `digital-wallet-event-${index}`;
            const dnitheId =
              walletReferenceIds?.find(
                (it) =>
                  it.tokenRequesterId === event?.tokenRequesterId &&
                  it.tokenNumber === event?.tokenNumber,
              )?.dnitheId || '';
            return {
              ...event,
              key,
              dnitheId,
            };
          },
        );
        setWalletEvents(walletEvents);
      },
    },
  );

  useEffect(() => {
    if (open) {
      getDigitalWalletEvents();
    }
  }, [accountId, open]);

  return (
    <Modal
      {...modalProps}
      centered
      width={1200}
      cancelButtonProps={{ hidden: true }}
      title="Provisioning History"
      open={open}
    >
      <QueryBoundary
        query={digitalWalletEventsQuery}
        mode={QueryBoundary.MODE.MESSAGE}
        loadingMessage="Loading provisioning history"
        errorMessage="Failed to load digital wallet provisioning history"
      >
        <CondensedTable
          data={walletEvents}
          columns={columns}
          bordered={true}
          length={10}
          size="medium"
        ></CondensedTable>
      </QueryBoundary>
    </Modal>
  );
};

export default DigitalWalletModal;
