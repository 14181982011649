import { useContext } from 'react';

import { CustomerInfoContext } from 'providers/CustomerInfoProvider';

const useCustomerInfo = () => {
  const customerInfo = useContext(CustomerInfoContext);
  return customerInfo;
};

export default useCustomerInfo;
