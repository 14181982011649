import { Col, DatePicker, Form, Input, Row } from 'antd';
import styled from '@emotion/styled';
import useAgent from 'hooks/useAgent';

const FormItems = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  margin-bottom: 1rem;
`;

const FormItem = ({ label, name, type }) => {
  return (
    <Form.Item label={label} name={name} className="form-item">
      {type === 'date' ? (
        <DatePicker
          placeholder="MM/DD/YYYY"
          format={'M/D/YYYY'}
          style={{ width: '100%' }}
        />
      ) : (
        <Input name={name} />
      )}
    </Form.Item>
  );
};

const CustomerSearchForm = () => {
  const agent = useAgent();

  return (
    <FormItems>
      <div>
        <FormItem label="First Name" name="firstName" type="text" />
        <FormItem label="Last Name" name="lastName" type="text" />
        <FormItem label="Last 4 SSN" name="lastFourSsn" type="text" />
        {agent?.isSuperUser && (
          <FormItem label="Full SSN" name="fullSsn" type="text" />
        )}
      </div>
      <div>
        <FormItem label="Phone" name="primaryPhone" type="text" />
        <FormItem label="Email" name="email" type="text" />
        <FormItem
          data-testid="birth-date-field"
          label="Date of Birth"
          name="birthDate"
          type="date"
        />
      </div>
      <div>
        <FormItem label="Street 1" name="street1" type="text" />
        <FormItem label="Street 2" name="street2" type="text" />
        <Row gutter={8}>
          <Col span={12}>
            <FormItem label="City" name="city" type="text" />
          </Col>
          <Col span={12}>
            <FormItem label="State" name="state" type="text" />
          </Col>
        </Row>
      </div>
      <div>
        <FormItem
          label="TSYS Account Id"
          name="externalAccountId"
          type="text"
        />
        <FormItem label="Customer ID" name="customerId" type="text" />
        <FormItem label="Account ID" name="accountId" type="text" />
        <FormItem label="Card Last 4" name="cardLast4" type="text" />
      </div>
    </FormItems>
  );
};

export default CustomerSearchForm;
