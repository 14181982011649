export const DOCUMENT_STATUS_OPTIONS = Object.freeze({
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  REQUESTED_UPDATES: 'REQUESTED_UPDATES',
});

export const statusOptions = [
  { value: DOCUMENT_STATUS_OPTIONS.ACCEPTED, label: 'Accept' },
  { value: DOCUMENT_STATUS_OPTIONS.REJECTED, label: 'Reject' },
  {
    value: DOCUMENT_STATUS_OPTIONS.REQUESTED_UPDATES,
    label: 'Request Changes',
  },
];

export const DOCUMENT_REJECTION_REASONS = Object.freeze({
  BUSINESS_DOCUMENTS_NOT_ACCEPTED: 'BUSINESS_DOCUMENTS_NOT_ACCEPTED',
  CAMSCANNER_DOCUMENTS_NOT_ACCEPTED: 'CAMSCANNER_DOCUMENTS_NOT_ACCEPTED',
  COMMISSIONS_NOT_ACCEPTED: 'COMMISSIONS_NOT_ACCEPTED',
  DEPOSITS_NOT_VERIFIED: 'DEPOSITS_NOT_VERIFIED',
  GENERIC_ADDRESS_NOT_MATCHING: 'GENERIC_ADDRESS_NOT_MATCHING',
  INCOME_NOT_MATCHING: 'INCOME_NOT_MATCHING',
  PAYSTUB_ADDR_NOT_MATCHING: 'PAYSTUB_ADDR_NOT_MATCHING',
  SCREENSHOTS_NOT_ACCEPTED: 'SCREENSHOTS_NOT_ACCEPTED',
  TAX_DOCUMENT_ADDR_NOT_MATCHING: 'TAX_DOCUMENT_ADDR_NOT_MATCHING',
  TENFORTY_TAX_DOCUMENTS_NOT_ACCEPTED: 'TENFORTY_TAX_DOCUMENTS_NOT_ACCEPTED',
});

export const rejectionReasonOptions = [
  {
    value: DOCUMENT_REJECTION_REASONS.BUSINESS_DOCUMENTS_NOT_ACCEPTED,
    label: 'Business Documents Not Accepted',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.CAMSCANNER_DOCUMENTS_NOT_ACCEPTED,
    label: 'CamScanner Documents Not Accepted',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.COMMISSIONS_NOT_ACCEPTED,
    label: 'Commissions Not Accepted',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.DEPOSITS_NOT_VERIFIED,
    label: 'Deposits Not Verified',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.GENERIC_ADDRESS_NOT_MATCHING,
    label: 'Generic Address Not Matching',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.INCOME_NOT_MATCHING,
    label: 'Income Not Matching',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.PAYSTUB_ADDR_NOT_MATCHING,
    label: 'Paystub Address Not Matching',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.SCREENSHOTS_NOT_ACCEPTED,
    label: 'Screenshots Not Accepted',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.TAX_DOCUMENT_ADDR_NOT_MATCHING,
    label: 'Tax Document Address Not Matching',
  },
  {
    value: DOCUMENT_REJECTION_REASONS.TENFORTY_TAX_DOCUMENTS_NOT_ACCEPTED,
    label: '1040 Tax Documents Not Accepted',
  },
];
