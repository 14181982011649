import { Alert, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const ManagedByThirdPartyAlert = ({ showThirdPartyEmailModal, width }) => {
  return (
    <Alert
      banner
      type="success"
      message="Managed by a third party"
      closeable
      style={{
        marginBottom: 'var(--spacing-md)',
        width,
      }}
      action={
        <Button size="small" type="link" onClick={showThirdPartyEmailModal}>
          <EditOutlined />
        </Button>
      }
    />
  );
};

export default ManagedByThirdPartyAlert;
