const typePolicies = {
  Account: {
    fields: {
      balanceInfo: { merge: true },
      paymentInfo: { merge: true },
      pricingDetails: { merge: true },
      payments: { merge: false },
    },
  },
  Customer: {
    fields: {
      contactInfo: {
        merge(existing, incoming) {
          return { ...existing, ...incoming };
        },
      },
    },
  },
  Query: {
    fields: {
      mcAgentCustomerActivity: {
        keyArgs: ['customerId'],
        merge(existing = {}, incoming = {}) {
          const { activity: incomingActivity, currentPage } = incoming;
          const { activity: existingActivity = [] } = existing;
          if (currentPage === 1) {
            return incoming;
          }
          return {
            ...incoming,
            activity: [...existingActivity, ...incomingActivity],
          };
        },
      },
    },
  },
};

export default typePolicies;
