import { gql } from '@apollo/client';

const EFA_CALL_VERIFICATION = gql`
  mutation EFA_Call_Verification(
    $applicantId: String!
    $applicationId: String!
    $isCallVerified: Boolean!
  ) {
    verifyEFACall(
      applicantId: $applicantId
      applicationId: $applicationId
      isCallVerified: $isCallVerified
    ) {
      applicationId
      callVerified
    }
  }
`;

export { EFA_CALL_VERIFICATION };
