import { useState } from 'react';
import { Link, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Button, Card, Space, Table, Tag } from 'antd';
import { useQuery } from '@apollo/client';

import FraudCaseDetails from './FraudCaseDetails';
import FraudNewCaseForm from './FraudNewCaseForm';
import QueryResult from 'components/QueryResult/QueryResult';

import { FRAUD_CASES } from 'queries/fraudCases';
import { FRAUD_STATUS } from 'utilities/constants';
import { formatDate } from 'utilities/datesAndTimes';
import { COLORS } from 'utilities/styles';

import { SentryRoute } from 'services/sentry';

const FraudStatusBadge = ({ status }) => {
  let color = '';
  let displayText = '';
  switch (status) {
    case FRAUD_STATUS.SETTING_AS_CLEARED:
    case FRAUD_STATUS.CLEARED:
      color = COLORS.green1;
      displayText = FRAUD_STATUS.CLEARED;
      break;
    case FRAUD_STATUS.SETTING_AS_CONFIRMED:
    case FRAUD_STATUS.CONFIRMED:
      color = COLORS.brick1;
      displayText = FRAUD_STATUS.CONFIRMED;
      break;
    case FRAUD_STATUS.SETTING_AS_UNDER_INVESTIGATION:
    case FRAUD_STATUS.UNDER_INVESTIGATION:
      color = COLORS.orange1;
      displayText = FRAUD_STATUS.UNDER_INVESTIGATION;
      break;
    case FRAUD_STATUS.SETTING_AS_RESTRICTED:
    case FRAUD_STATUS.RESTRICTED:
      color = COLORS.orange1;
      displayText = FRAUD_STATUS.RESTRICTED;
      break;
    case FRAUD_STATUS.SETTING_AS_RESTORED:
    case FRAUD_STATUS.RESTORED:
      color = COLORS.blue1;
      displayText = FRAUD_STATUS.RESTORED;
      break;
    case FRAUD_STATUS.SETTING_AS_REJECTED:
      color = COLORS.brick1;
      displayText = FRAUD_STATUS.REJECTED;
    default:
  }
  return <Tag color={color}>{displayText}</Tag>;
};

const FraudCasesPage = () => {
  const [addingNewCase, setAddingNewCase] = useState(false);

  const { customerId, applicationId } = useParams();

  // APPLICATIONS TODO: Need a way to load Fraud Cases with application ID
  const { loading, error, data } = useQuery(FRAUD_CASES, {
    variables: { customerId, applicationId },
  });

  const { path, url } = useRouteMatch();

  const columns = [
    {
      title: 'Case',
      dataIndex: 'type',
      key: 'type',
      render: (val, { caseHash }) => (
        <Link key={caseHash} to={`${url}/case/${caseHash}`}>
          {val}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <FraudStatusBadge status={status} />,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: formatDate,
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: formatDate,
    },
    {
      title: 'Action',
      dataIndex: 'details',
      key: 'action',
      render: (val) => <div>{val[0]?.action}</div>,
    },
    {
      title: 'Case #',
      dataIndex: 'caseHash',
    },
  ];

  return (
    <QueryResult loading={loading} error={error} data={data} called={true}>
      <Switch>
        <SentryRoute exact path={`${path}/case/:caseId`}>
          <FraudCaseDetails cases={data?.fraudCasesV2} />
        </SentryRoute>
        <SentryRoute>
          {addingNewCase ? (
            <FraudNewCaseForm setAddingNewCase={setAddingNewCase} />
          ) : (
            <Card bordered={false} title="Fraud Cases">
              <Space direction="vertical" style={{ width: '100%' }}>
                <Table
                  columns={columns}
                  dataSource={data?.fraudCasesV2}
                  bordered={true}
                  size="small"
                  pagination={false}
                  rowKey="caseHash"
                />
                <Button
                  data-testid="new-case-button"
                  onClick={() => setAddingNewCase(true)}
                >
                  New Case
                </Button>
              </Space>
            </Card>
          )}
        </SentryRoute>
      </Switch>
    </QueryResult>
  );
};

export default FraudCasesPage;
