import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ACCOUNT_PRICING_DETAILS } from 'queries/account';
import { useParams } from 'react-router-dom';
import { COLORS } from 'utilities/styles';
import { Pill } from './Pill';

export default function GracePeriodPill({ accountId }) {
  const { customerId } = useParams();
  const { enableGracePeriod } = useFlags();

  const { data: pricingInfo, error } = useQuery(ACCOUNT_PRICING_DETAILS, {
    variables: { customerId, accountId },
  });

  if (enableGracePeriod) {
    return (
      <>
        {error && (
          <Pill
            color={COLORS.grey2}
            name={'Grace Period - Unknown'}
            id={'grace-period-error'}
            icon={<ExclamationCircleOutlined />}
          />
        )}

        {pricingInfo &&
          !pricingInfo?.account?.pricingDetails?.hasLateFeeGrace && (
            <Pill
              name={'Grace Period Test'}
              id={'grace-period-indicator'}
              color={COLORS.blue1}
            />
          )}
      </>
    );
  }
  return null;
}
