import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Flex, Form, Radio, Space } from 'antd';

import StepContainer from '../StepContainer';
import {
  setNextStep,
  setCustomerHasCard,
  useSendCardState,
  resetSendCardState,
  SEND_CARD_STEPS,
} from './sendCardState';

import DisplayValue from 'components/DisplayValue/DisplayValue';
import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_EVENTS } from 'utilities/constants';
import { formatDate } from 'utilities/datesAndTimes';

const CardStatusStep = ({ account = {} }) => {
  const { accountId, customerId } = useParams();
  const { search } = useLocation();
  const [cardStatusForm] = Form.useForm();
  const { customerHasCard } = useSendCardState();
  const { trackEvent } = useAnalytics();

  const bypassFraud = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return queryParams?.get('bypassFraud') === 'true';
  }, [search]);

  const handleContinue = async () => {
    try {
      await cardStatusForm.validateFields();
    } catch {
      return;
    }

    trackEvent(ANALYTICS_EVENTS.SEND_CARD_CARD_STATUS, {
      customerId,
      accountId,
      customerHasCard,
    });

    if (customerHasCard || bypassFraud) {
      setNextStep(SEND_CARD_STEPS.ACCOUNT_STANDING);
    } else {
      setNextStep(SEND_CARD_STEPS.FRAUD_REVIEW);
    }
  };

  const handlePossessionSelection = (event) => {
    setCustomerHasCard(event.target.value);
  };

  return (
    <StepContainer
      title="Verify Card Status"
      onContinue={handleContinue}
      onExit={resetSendCardState}
    >
      <Flex gap="var(--spacing-lg)" style={{ margin: 'var(--spacing-lg) 0' }}>
        <DisplayValue label="Current Expiration Date">
          {formatDate(account?.cardDetails?.expirationDate)}
        </DisplayValue>
        <DisplayValue label="Last Reissue Date">
          {formatDate(account?.cardDetails?.lastCardRequestDate)}
        </DisplayValue>
      </Flex>
      <Form
        form={cardStatusForm}
        name="cardStatus"
        layout="vertical"
        initialValues={{ customerHasCard }}
      >
        <Form.Item
          label="Are you processing a lost/stolen or a replacement card for this cardholder?"
          name="customerHasCard"
          rules={[{ required: true, message: 'Please make a selection' }]}
        >
          <Radio.Group
            onChange={handlePossessionSelection}
            value={customerHasCard}
          >
            <Space direction="vertical">
              <Radio data-testid="customer-lost-card-radio" value={false}>
                Lost/stolen - Card not in possession or there is suspicious
                activity
              </Radio>
              <Radio data-testid="customer-has-card-radio" value={true}>
                Replacement - Card holder has possession of the card and no
                suspicious activity
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </StepContainer>
  );
};

export default CardStatusStep;
