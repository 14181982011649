import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';

const TimeframeField = ({ label = 'Timeframe', name }) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          type: 'array',
          required: true,
          message: `Please select a Start and Expiration Date`,
        },
      ]}
    >
      <DatePicker.RangePicker
        data-testid="end-date-input"
        format="M/D/YYYY"
        style={{ width: '100%' }}
        disabledDate={(current) => {
          return current < dayjs().startOf('day');
        }}
      />
    </Form.Item>
  );
};

export default TimeframeField;
