import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Tooltip, Typography } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';

import Access from 'components/Access/Access';
import QueryResult from 'components/QueryResult/QueryResult';

import useAgent from 'hooks/useAgent';
import { ADD_AGENT_AUDIT_LOG_FOR_BAN_MUTATION } from 'mutations/addAgentAuditLog';
import { GET_FUNDING_ACCOUNT_FULL_BAN } from 'queries/fullBan';

const { Text } = Typography;

const BankAccountNumberDisplay = ({ numberLast4, fundingAccountId }) => {
  const { customerId } = useParams();
  const agent = useAgent();

  const [showFullBan, setShowFullBan] = useState(false);

  const [getFullBan, fullBanQuery] = useLazyQuery(
    GET_FUNDING_ACCOUNT_FULL_BAN,
    {
      variables: { customerId, fundingAccountId },
    },
  );

  const [sendAudit] = useMutation(ADD_AGENT_AUDIT_LOG_FOR_BAN_MUTATION);

  const helpText = showFullBan
    ? 'Hide Full Bank Account Number'
    : 'Show Full Bank Account Number';

  const handleFullBanClick = async () => {
    if (!showFullBan) {
      try {
        await sendAudit({
          variables: {
            customerId,
            agentId: agent?.user?.requester_id,
            agentName: agent?.user?.name,
          },
        });
        getFullBan();
      } catch (err) {
        console.error('Caught error during audit of full BAN', err);
      }
    }
    setShowFullBan(!showFullBan);
  };

  return (
    <div>
      {showFullBan ? (
        <QueryResult {...fullBanQuery}>
          <Text>{fullBanQuery?.data?.fundingAccountFullBan?.fullBan}</Text>
        </QueryResult>
      ) : (
        numberLast4
      )}
      <Access roles={['command-manager', 'super-user']}>
        <Tooltip title={helpText}>
          <Button
            type="link"
            title={helpText}
            aria-label={helpText}
            icon={showFullBan ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            style={{ width: 24 }}
            data-testid="toggle-ban-vis-button"
            onClick={handleFullBanClick}
          />
        </Tooltip>
      </Access>
    </div>
  );
};

export default BankAccountNumberDisplay;
