import { gql } from '@apollo/client';

const UPDATE_INCOME_VERIFICATION_STATUS = gql`
  mutation UpdateIncomeVerificationStatus(
    $applicationId: String!
    $documentRequests: [IncomeVerifyDocumentUpdateInput!]!
    $verificationStatus: VerificationStatus!
    $customRejectionMessage: String
  ) {
    updateIncomeVerificationDocuments(
      input: {
        applicationId: $applicationId
        documentRequests: $documentRequests
        verificationStatus: $verificationStatus
        customRejectionMessage: $customRejectionMessage
      }
    ) {
      applicationId
    }
  }
`;

export { UPDATE_INCOME_VERIFICATION_STATUS };
