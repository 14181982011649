import useAnalytics from 'hooks/useAnalytics';

import SanitizeHTML from 'components/SanitizeHTML/SanitizeHTML';
import { ANALYTICS_EVENTS } from 'utilities/constants';
import {
  formatDateTime,
  getDateFromJavaInstant,
} from 'utilities/datesAndTimes';
import { constructTextHighlightAsString } from 'utilities/helpers';

const getMessage = (autoUpdateMessage, highlight) => {
  let message = autoUpdateMessage;

  // For address auto-updates, we want to massage the message a bit
  if (message?.toLowerCase().indexOf('new address') > -1) {
    // match the JSON string for a both new and old addresses
    const matches = message.match(/({[^{}]*})/g);

    // This is only a safeguard for if this format changes in the future before we get to update it
    if (matches) {
      // [ currentAddress, previousAddress ] = matches is the basic format, but we need to parse them as JSON
      let currentAddress = JSON.parse(matches[0]);
      let previousAddress = JSON.parse(matches[1]);

      // update New Address first (the result in our non-global match)
      message = message.replace(
        /{[^{}]*}/,
        `${currentAddress.street1}${currentAddress.street2 || ''},\n${
          currentAddress.city
        }, ${currentAddress.state} ${currentAddress.zip}`,
      );
      // then again with the Old Address (the only remaining result in our non-global match)
      message = message.replace(
        /{[^{}]*}/,
        `${previousAddress.street1}${previousAddress.street2 || ''},\n${
          previousAddress.city
        }, ${previousAddress.state} ${previousAddress.zip}`,
      );
    }
  }

  if (highlight) {
    message = constructTextHighlightAsString(message, highlight);
  }

  return message;
};

const AutomatedUpdateEvent = ({ automatedUpdate, highlight }) => {
  const { trackEvent } = useAnalytics();

  const date = formatDateTime(
    getDateFromJavaInstant(automatedUpdate.createdTz),
  );
  const message = getMessage(automatedUpdate.message, highlight);

  return (
    <div
      data-testid="automated-event-item"
      onClick={() => trackEvent(ANALYTICS_EVENTS.AUTO_EVENTS_MISCLICKED)}
    >
      <div>{date}</div>
      <SanitizeHTML html={message} data-testid="automated-update-message" />
    </div>
  );
};

export default AutomatedUpdateEvent;
