import { gql } from '@apollo/client';

const STATEMENT_SUMMARY = gql`
  query ($customerId: String!, $accountId: String!, $periodEndDate: String!) {
    statementSummary(
      customerId: $customerId
      accountId: $accountId
      periodEndDate: $periodEndDate
    ) {
      id
      accountId
      customerId
      balanceForward
      payments
      otherCredits
      otherDebits
      totalFinanceCharge
      totalNewBalance
      creditLimit
      periodEndDate
      minimumDue
      paymentDueDate
    }
  }
`;

export { STATEMENT_SUMMARY };
