import 'amazon-connect-streams'; // Docs: https://github.com/amazon-connect/amazon-connect-streams

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Button, Flex, Modal, Typography } from 'antd';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import config from 'config';

import { noteVar } from 'apollo/reactiveVars';
import useAnalytics from 'hooks/useAnalytics';
import useAWSConnectInfo from 'hooks/useAWSConnectInfo';
import { CUSTOMER_SEARCH } from 'queries/customerSearch';
import { ANALYTICS_EVENTS } from 'utilities/constants';

const SoftPhone = () => {
  const [initialized, setInitialized] = useState(false);
  const note = useReactiveVar(noteVar);
  const history = useHistory();
  const softPhoneRef = useRef();
  const { trackEvent } = useAnalytics();
  const {
    agentStatus,
    agentStatusColors,
    inboundCallInfo,
    setAgentStatus,
    setInboundCallInfo,
    clearInboundCallInfo,
  } = useAWSConnectInfo();

  const handleCustomerNotFound = () => {
    message.error('Could not find customer from Dialer!');
    addPhoneNumberToNote();
    history.push('/search');
  };

  const handleIncomingCall = (contact) => {
    setInboundCallInfo(contact);
    const attributeMap = contact?.getAttributes();
    const customerId = attributeMap?.screenPopValue?.value;

    if (customerId) {
      searchCustomer({
        variables: { customerSearchRequestBody: { customerId } },
      });
    } else if (contact?.isInbound()) {
      handleCustomerNotFound();
    }
  };

  const [searchCustomer] = useLazyQuery(CUSTOMER_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const customers = data?.searchCustomer;
      if (!customers || customers.length === 0) {
        return handleCustomerNotFound();
      }

      const customer = customers[0];
      const noteExists = note?.trim()?.length > 0;
      if (noteExists) {
        openNoteWarningModal(customer);
      } else {
        initializeNewCustomer(customer);
      }
    },
    onError: () => {
      handleCustomerNotFound();
    },
  });

  const openNoteWarningModal = (customer) => {
    Modal.confirm({
      title: 'Incoming call!',
      content: (
        <div>
          <p>Looks like there is an unsaved note from a previous customer.</p>
          <p>
            Would you like to continue editing the note or discard it and go to
            the new customer?
          </p>
        </div>
      ),
      okText: 'Go to new customer',
      onOk: () => {
        initializeNewCustomer(customer);
      },
      cancelText: 'Continue editing note',
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  // Open the notes box, set the note to the inbound phone number and route to dashboard
  const initializeNewCustomer = (customer) => {
    addPhoneNumberToNote();
    const id = customer.customerId;
    const route =
      customer.customerType === 'APPLICANT'
        ? `/applicants/${id}`
        : `/customers/${id}`;
    history.push(route);
  };

  const addPhoneNumberToNote = () => {
    const attributeMap = inboundCallInfo?.getAttributes();
    const phoneNumber = attributeMap?.customerPhoneNumber?.value;
    const noteExists = note?.trim()?.length > 0;
    if (!noteExists) {
      noteVar(
        phoneNumber
          ? `IB ${phoneNumber}\nLast 4 of Card Number:`
          : 'Last 4 of Card Number:',
      );
    }
  };

  const handleCallEnded = () => {
    clearInboundCallInfo();
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    return () => {
      window.connect?.core?.terminate();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // All of this should probably be done through Context but that's a pretty
  // decent overhall
  const initializeSoftPhone = () => {
    if (!window.connect) {
      return message.error(
        'There was a problem with AWS connect library, please refresh the page and try again.',
      );
    }
    setInitialized(true);

    window.connect.core?.initCCP(softPhoneRef.current, {
      ccpUrl: `https://${config.AWS_CONNECT_INSTANCE}.awsapps.com/connect/ccp-v2`,
      loginPopup: true,
      loginUrl: `https://${config.AWS_CONNECT_INSTANCE}.awsapps.com/connect/login`,
      softphone: {
        allowFramedSoftphone: true,
      },
      ccpAckTimeout: 5000,
      ccpSynTimeout: 3000,
    });

    window.connect.contact((contact) => {
      contact.onConnecting(handleIncomingCall);
      contact.onEnded(handleCallEnded);
    });

    window.connect.agent((agent) => {
      agent.onStateChange((e) => {
        setAgentStatus(e.newState);
      });
    });

    window.addEventListener('beforeunload', handleBeforeUnload);
    trackEvent(ANALYTICS_EVENTS.SOFTPHONE_INITIALIZED);
  };

  return (
    <>
      <Flex
        style={{
          padding: 'var(--spacing-xs)',
          backgroundColor: agentStatusColors.background,
        }}
        align="center"
        justify="center"
      >
        <Typography.Title
          level={5}
          style={{
            margin: 0,
            color: agentStatusColors.foreground,
            whiteSpace: 'nowrap',
          }}
        >
          {agentStatus || 'Not Connected'}
        </Typography.Title>
      </Flex>
      <div
        style={{
          height: '554px', //avoids any scrolling within the iframe
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          padding: 'var(--spacing-sm)',
        }}
        ref={softPhoneRef}
      >
        {!initialized && (
          <Button
            type="primary"
            onClick={initializeSoftPhone}
            style={{ margin: '1rem' }}
          >
            Connect to Dialer
          </Button>
        )}
      </div>
    </>
  );
};

export default SoftPhone;
