import { useEffect, useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Form, Radio, Select, Space } from 'antd';

import { COLORS } from 'utilities/styles';

const { Option } = Select;

export default function FraudNewCaseReasonFormRow({ onRemove, data, name }) {
  const [options, setOptions] = useState([]);
  const [category, selectedCategory] = useState('reports');

  useEffect(() => {
    const reasons = data?.actionOptionsV2?.reason[category];
    setOptions([...reasons].sort());
  }, [data, category]);

  return (
    <Space key={name} align="baseline" data-testid="reason-selection">
      <Form.Item
        name={[name, 'category']}
        initialValue="reports"
        data-testid="reason-type"
      >
        <Radio.Group onChange={(e) => selectedCategory(e.target.value)}>
          <Radio.Button value="reports">Reports</Radio.Button>
          <Radio.Button value="notifications">Notifications</Radio.Button>
          <Radio.Button value="other">Other</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={[name, 'name']}
        style={{ width: 325 }}
        data-testid="reason-subtype"
        required
        rules={[{ required: true, message: 'Please select a reason.' }]}
      >
        <Select placeholder="Select a reason">
          {options?.map((reason) => (
            <Option key={reason} value={reason}>
              {reason}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {onRemove && (
        <MinusCircleOutlined
          onClick={() => onRemove(name)}
          style={{ color: COLORS.blue1 }}
          data-testid="delete-reason"
        />
      )}
    </Space>
  );
}
