import { useMutation } from '@apollo/client';

import useAgent from 'hooks/useAgent';
import useCustomerInfo from 'hooks/useCustomerInfo';
import { ADD_APPLICATION_NOTE, ADD_CUSTOMER_NOTE } from 'mutations/notes';

const refetchQueries = ['NotesInteractionsZendeskRecords', 'GetAgentNotes'];

const useAddNote = () => {
  const agent = useAgent();
  const { applicationId, customerId } = useCustomerInfo();

  const [addApplicationNote, applicationNoteMutation] = useMutation(
    ADD_APPLICATION_NOTE,
    {
      refetchQueries,
    },
  );

  const [addCustomerNote, customerNoteMutation] = useMutation(
    ADD_CUSTOMER_NOTE,
    {
      refetchQueries,
    },
  );

  const addNote = async (message, isSticky = false) => {
    const variables = {
      message,
      product: 'CREDIT',
      agentId: agent?.user?.requester_id,
      agentName: agent?.user?.name,
      isSticky,
    };

    let submitNote;
    if (customerId) {
      submitNote = addCustomerNote;
      variables.customerId = customerId;
    } else if (applicationId) {
      submitNote = addApplicationNote;
      variables.applicationId = applicationId;
    }

    return await submitNote({
      variables,
    });
  };

  const addNoteMutation = applicationId
    ? applicationNoteMutation
    : customerNoteMutation;

  return [addNote, addNoteMutation];
};

export default useAddNote;
