import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';

import AgentScript from 'components/AgentScript/AgentScript';
import { CUSTOMER_OVERVIEW } from 'queries/customer';

import { formatDate, isAfterCutOffTime } from 'utilities/datesAndTimes';

const PaymentAuthorizationMessage = ({
  account = '--',
  amount,
  cardLast4 = '--',
  date,
  isScheduledPayment,
}) => {
  const [customerFullName, setCustomerFullName] = useState('');
  const [paymentDate, setPaymentDate] = useState(null);
  const { customerId } = useParams();
  const translateKey = isScheduledPayment
    ? 'scheduledPaymentScript'
    : 'oneTimePaymentScript';

  useQuery(CUSTOMER_OVERVIEW, {
    variables: { customerId: customerId },
    onCompleted: (resultData) => {
      const contactInfo = resultData.customer.contactInfo;
      const first = contactInfo.firstName ?? '';
      const last = contactInfo.lastName ?? '';
      setCustomerFullName(`${first} ${last}`);
    },
  });

  useEffect(() => {
    if (date) {
      let displayDate = dayjs(date);
      // If Immediate payment, check if past cutoff time to render the actual payment date
      if (!isScheduledPayment) {
        if (isAfterCutOffTime()) {
          displayDate = dayjs().add(1, 'day');
        } else {
          displayDate = dayjs();
        }
      }
      setPaymentDate(formatDate(displayDate));
    } else {
      setPaymentDate('--');
    }
  }, [date, isScheduledPayment]);

  return (
    <AgentScript
      title="Payment Authorization"
      translateKey={`payments.${translateKey}`}
      values={{
        account,
        amount: amount ? `$${amount}` : '--',
        cardLast4,
        customerFullName,
        paymentDate,
        todaysDate: formatDate(dayjs()),
      }}
    />
  );
};

export default PaymentAuthorizationMessage;
