import { ACCOUNT_STATUS_CODE } from 'utilities/constants';
import { CARD_WATCH_REASON, TIERED_WATCH_REASON } from './applyStatusUtilities';

const formatBlockingStatusMessage = (
  selectedStatus,
  activeStatuses,
  blockingStatuses,
) => {
  const activeBlockers = Object.entries(blockingStatuses).reduce(
    (blockers, [statusCode, reasonCodes]) => {
      const activeBlockers = activeStatuses.filter(
        (active) => active.statusCode === statusCode,
      );

      if (!activeBlockers.length) {
        return blockers;
      }

      if (reasonCodes.length) {
        activeBlockers
          .filter((activeBlocker) =>
            reasonCodes.includes(activeBlocker.reasonCode),
          )
          .forEach((activeBlocker) => {
            blockers.push(
              `${activeBlocker.statusCode}-${activeBlocker.reasonCode}`,
            );
          });
      } else {
        blockers.push(activeBlockers[0].statusCode);
      }

      return blockers;
    },
    [],
  );

  if (activeBlockers.length) {
    return `Applying a ${selectedStatus} status is blocked by the following statuses that are already on the account: ${activeBlockers.join(
      ', ',
    )}`;
  }
};

export const getBlockingStatusMessage = (
  selectedStatus,
  selectedReasonCode,
  activeStatuses,
) => {
  let blockingStatusMessage;
  switch (selectedStatus) {
    case ACCOUNT_STATUS_CODE.CLOSED:
      blockingStatusMessage = formatBlockingStatusMessage(
        ACCOUNT_STATUS_CODE.CLOSED,
        activeStatuses,
        {
          CL: [],
          CO: [],
          CC: [],
          BK: [],
          SF: [],
          FR: [],
        },
      );
      break;
    case ACCOUNT_STATUS_CODE.TIERED_WATCH:
      const TWReasonCodes = Object.values(TIERED_WATCH_REASON);
      if (selectedReasonCode === TIERED_WATCH_REASON.ADS_II_BYPASS) {
        blockingStatusMessage = formatBlockingStatusMessage(
          ACCOUNT_STATUS_CODE.TIERED_WATCH,
          activeStatuses,
          {
            TW: TWReasonCodes,
            CW: [CARD_WATCH_REASON.FRAUD_ALERT_BYPASS],
          },
        );
      } else if (selectedReasonCode === TIERED_WATCH_REASON.SECONDARY_BYPASS) {
        blockingStatusMessage = formatBlockingStatusMessage(
          ACCOUNT_STATUS_CODE.TIERED_WATCH,
          activeStatuses,
          {
            TW: TWReasonCodes.slice(0, 4),
          },
        );
      }
      break;
    default:
      break;
  }

  return blockingStatusMessage;
};

const useBlockingStatusMessage = (
  selectedStatusCode,
  selectedReasonCode,
  activeStatuses,
) => {
  const blockingStatusMessage = () => {
    return getBlockingStatusMessage(
      selectedStatusCode,
      selectedReasonCode,
      activeStatuses,
    );
  };

  return blockingStatusMessage();
};

export default useBlockingStatusMessage;
