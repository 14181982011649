import { gql } from '@apollo/client';

const ACCOUNT_CARD_ALERT = gql`
  mutation AccountCardAlert(
    $customerId: String!
    $accountId: String!
    $dateTimeDiscovered: String!
    $dateTimeReported: String!
    $fraudOnAccount: Boolean!
    $isCardLost: Boolean!
    $isPinLost: Boolean!
    $lostStolenCircumstances: String!
    $reason: AlertReason!
    $reporter: String!
    $willAddOverride: Boolean!
  ) {
    accountCardAlert(
      customerId: $customerId
      accountId: $accountId
      accountCardAlertInput: {
        alertReason: $reason
        dateTimeDiscovered: $dateTimeDiscovered
        dateTimeReported: $dateTimeReported
        fraudOnAccount: $fraudOnAccount
        isCardLost: $isCardLost
        isPinLost: $isPinLost
        lostStolenCircumstance: $lostStolenCircumstances
        reporter: $reporter
        willAddOverride: $willAddOverride
      }
    ) {
      success
    }
  }
`;

const REPLACE_ACCOUNT_CARD = gql`
  mutation ReplaceAccountCard(
    $customerId: String!
    $accountId: String!
    $changeExpirationDate: Boolean
    $deliveryMethod: AccountCardReplacementDeliveryType!
    $expeditedFee: Boolean!
    $numberOfCards: Int!
    $overrideAddressValidation: Boolean
    $requestReason: AccountCardReplacementRequestReason!
  ) {
    replaceAccountCard(
      customerId: $customerId
      accountId: $accountId
      accountCardReplacementInput: {
        changeExpirationDate: $changeExpirationDate
        deliveryMethod: $deliveryMethod
        expeditedFee: $expeditedFee
        numberOfCards: $numberOfCards
        overrideAddressValidation: $overrideAddressValidation
        requestReason: $requestReason
      }
    ) {
      success
    }
  }
`;

const TRANSFER_ACCOUNT_CARD = gql`
  mutation TransferAccountCard(
    $customerId: String!
    $accountId: String!
    $tokenizedCardNumber: String!
    $fraudStatusCode: AccountTransferFraudStatusType!
    $isFraudulent: Boolean!
    $requestedCard: Boolean!
    $statusType: AccountTransferStatusType!
  ) {
    transferAccountCard(
      customerId: $customerId
      accountId: $accountId
      accountCardTransferInput: {
        cardNumber: $tokenizedCardNumber
        fraudStatusCode: $fraudStatusCode
        isFraudulent: $isFraudulent
        requestedCard: $requestedCard
        statusType: $statusType
      }
    ) {
      success
    }
  }
`;

export { ACCOUNT_CARD_ALERT, REPLACE_ACCOUNT_CARD, TRANSFER_ACCOUNT_CARD };
