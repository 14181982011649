import { message } from 'antd';
import { useEffect } from 'react';

import Loading from 'components/Loading/Loading';

const formatQueryError = (queryError) => {
  if (queryError?.graphQLErrors) {
    const errors = queryError.graphQLErrors.map((error) => {
      const location = error?.path ? error.path.join('/') : '';
      return `${error.message} [${location}]`;
    });
    return (
      <>
        <br />
        {errors.map((errorMessage) => (
          <>
            {errorMessage}
            <br />
          </>
        ))}
      </>
    );
  }

  return queryError.message;
};

const QueryResult = ({
  loading,
  error,
  data,
  called,
  renderChildrenWithNullData = false,
  children,
}) => {
  useEffect(() => {
    if (error) {
      message.config({ maxCount: 1 });
      message.error(formatQueryError(error));
      console.error(error);
    }
  }, [error]);

  if (loading || !called) {
    return <Loading />;
  }

  if (data || renderChildrenWithNullData) {
    return children;
  }

  return null;
};

export default QueryResult;
