import { useEffect, useState, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Breadcrumb, Button, Divider, Flex, List, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { LeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import OfferEligibilityModal from '../OfferEligibilityModal';
import S3FraudApproval from './S3FraudApproval';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import useModalControls from 'hooks/useModalControls';
import { CREDIT_BALANCE_REFUND_ELIGIBILITY } from 'queries/creditBalanceRefund';
import { STATUS } from 'utilities/constants';

import {
  useCreditBalanceRefundState,
  goToPreviousStep,
  resetCreditBalanceRefundState,
  CREDIT_BALANCE_REFUND_STEPS_LIST,
} from './creditBalanceRefundState';

const { Title } = Typography;

const CreditBalanceGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 3fr 0fr 9fr;
  grid-column-gap: var(--spacing-md);
`;

const containerStyles = { minHeight: '300px' };

const CreditBalanceRefundSection = () => {
  const { accountId, customerId } = useParams();
  const { isS3FraudAgent } = useFlags();

  const {
    currentStep,
    previousStep,
    submissionCompleted,
    submissionInitiated,
    steps,
  } = useCreditBalanceRefundState();

  const eligibilityModal = useModalControls();

  const [eligibility, setEligibility] = useState(undefined);

  const cbrEligibilityQuery = useQuery(CREDIT_BALANCE_REFUND_ELIGIBILITY, {
    variables: { accountId, customerId },
    onCompleted: (data) => {
      const cbrChecks = data?.account?.creditBalanceRefundEligibility;
      if (cbrChecks) {
        setEligibility({
          eligible: cbrChecks.isEligible,
          checks: [
            {
              name: 'No Pending CBR Request',
              pass: cbrChecks.doesNotHaveAPendingCbrRequest,
            },
            {
              name: 'No Active Disputes',
              pass: cbrChecks.doesNotHaveActiveDisputes,
            },
            {
              name: 'No Payments in the Last 7 Days',
              pass: cbrChecks.doesNotHavePaymentInLast7Days,
            },
            {
              name: 'Has Negative Balance',
              pass: cbrChecks.hasNegativeBalance,
            },
          ],
        });
      }
    },
  });

  const renderStep = () => {
    const StepComponent = currentStep.component;
    return (
      <Suspense fallback={<div></div>}>
        <StepComponent containerStyles={containerStyles}></StepComponent>
      </Suspense>
    );
  };

  const renderCompleted = () => {
    return (
      <SectionMessage
        status={STATUS.SUCCESS}
        text="Credit balance refund request submitted."
      />
    );
  };

  const renderIneligible = () => {
    return (
      <>
        <OfferEligibilityModal
          {...eligibilityModal}
          offer="Credit Balance Refund"
          eligible={eligibility?.eligible}
          eligibilityChecks={eligibility?.checks}
        />
        <SectionMessage
          status={STATUS.WARNING}
          text="This account is not eligible for a Credit Balance Refund."
          buttons={[
            {
              text: 'View Eligibility Criteria',
              onClick: eligibilityModal.show,
            },
          ]}
        />
      </>
    );
  };

  const renderFlow = () => {
    return (
      <>
        {isS3FraudAgent ? (
          <S3FraudApproval containerStyles={containerStyles}></S3FraudApproval>
        ) : (
          <>
            <Breadcrumb
              style={{ marginBottom: 'var(--spacing-lg)' }}
              items={[
                { title: 'Credit Balance Refund' },
                { title: currentStep.title },
              ]}
            />
            <CreditBalanceGrid>
              <Flex vertical={true} justify="space-between">
                <div>
                  <Title level={5}>Refund Progress</Title>
                  <List
                    dataSource={CREDIT_BALANCE_REFUND_STEPS_LIST}
                    renderItem={(step, index) => {
                      return (
                        <List.Item>
                          {index >= steps.length - 1
                            ? step.renderPreprocess()
                            : step.renderPostprocess()}
                        </List.Item>
                      );
                    }}
                  ></List>
                </div>
                {previousStep ? (
                  <>
                    <div style={{ paddingTop: 'var(--spacing-md)' }}>
                      <Button
                        type="link"
                        icon={<LeftOutlined />}
                        onClick={goToPreviousStep}
                        disabled={submissionInitiated}
                      >
                        {previousStep.title}
                      </Button>
                    </div>
                  </>
                ) : null}
              </Flex>
              <Divider
                type="vertical"
                style={{ height: '100%', margin: '0 var(--spacing-md)' }}
              />
              <div>{renderStep()}</div>
            </CreditBalanceGrid>
          </>
        )}
      </>
    );
  };

  const renderContent = () => {
    if (!eligibility?.eligible) {
      return renderIneligible();
    }
    if (submissionCompleted) {
      return renderCompleted();
    }
    return renderFlow();
  };

  useEffect(() => {
    resetCreditBalanceRefundState();
  }, [accountId, customerId]);

  return (
    <QueryBoundary
      query={cbrEligibilityQuery}
      mode={QueryBoundary.MODE.MESSAGE}
      loadingMessage={'Loading Eligibility Details'}
      errorMessage="Failed To Load Eligibility Details"
    >
      {renderContent()}
    </QueryBoundary>
  );
};

export default CreditBalanceRefundSection;
