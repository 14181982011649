import { Card, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { COLORS } from 'utilities/styles';

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (email, customer) => {
      const route = customerRoute(customer);
      return <Link to={route}>{email}</Link>;
    },
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Last Four SSN',
    dataIndex: 'last4Ssn',
    key: 'last4Ssn',
    align: 'right',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Local Time',
    dataIndex: 'localTime',
    key: 'localTime',
  },
  {
    title: 'Customer Type',
    dataIndex: 'type',
    key: 'type',
    render: () => {
      return <Tag color={COLORS.green1}>Customer</Tag>;
    },
  },
];

let locale = {
  emptyText: 'User Not Found',
};

const CustomerSearchResults = ({ data, title }) => {
  return (
    <Card
      bordered={false}
      title={title ?? 'Search Result'}
      style={{ marginTop: 5 }}
    >
      <Table locale={locale} columns={columns} dataSource={data} />
    </Card>
  );
};

const customerRoute = ({ customerId }) => {
  return `/customers/${customerId}`;
};

export default CustomerSearchResults;
