import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  Radio,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';

import DisplayValue from 'components/DisplayValue/DisplayValue';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useAgent from 'hooks/useAgent';
import useCeaseAndDesistStatus from 'hooks/useCeaseAndDesistStatus';
import useModalControls from 'hooks/useModalControls';
import {
  UPDATE_CUSTOMER_PREFERENCES,
  REMOVE_CEASE_AND_DESIST,
} from 'mutations/customerMeta';
import { CUSTOMER_OVERVIEW, CUSTOMER_PREFERENCES } from 'queries/customer';

import { formatDate, formatDateToSubmit } from 'utilities/datesAndTimes';

const { Text, Title } = Typography;
const { Item } = Form;

const LOADING_SKELETON = {
  title: false,
  paragraph: { rows: 2, width: ['120px', '140px'] },
};

const CeaseAndDesist = () => {
  const agent = useAgent();
  const [form] = Form.useForm();
  const { customerId } = useParams();
  const { ceaseAndDesistEnabled } = useCeaseAndDesistStatus();
  const editCeaseAndDesistModal = useModalControls();
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [failureMessage, setFailureMessage] = useState('');

  const { data: customerData } = useQuery(CUSTOMER_OVERVIEW, {
    variables: { customerId },
  });

  const customerPreferencesQuery = useQuery(CUSTOMER_PREFERENCES, {
    variables: { customerId },
  });

  const mutationOptions = {
    refetchQueries: ['GetCustomerCommPreferences'],
    onCompleted: editCeaseAndDesistModal.hide,
  };

  const [addCeaseAndDesistStatus, addCeaseAndDesistMutation] = useMutation(
    UPDATE_CUSTOMER_PREFERENCES,
    {
      ...mutationOptions,
      onError: () => {
        setFailureMessage('Failed to add cease and desist status.');
      },
    },
  );

  const [removeCeaseAndDesistStatus, removeCeaseAndDesistMutation] =
    useMutation(REMOVE_CEASE_AND_DESIST, {
      ...mutationOptions,
      onError: () => {
        setFailureMessage('Failed to remove cease and desist status.');
      },
    });

  const first = customerData?.customer?.contactInfo?.firstName;
  const last = customerData?.customer?.contactInfo?.lastName;
  const cardHolder = first && last ? `${first} ${last}` : 'Cardholder';

  const REQUESTER_TYPES = {
    CARDHOLDER: cardHolder,
    BANKRUPTCY: 'Bankruptcy',
    ATTORNEY: 'Attorney',
    THIRD_PARTY: '3rd party',
    OTHER: 'Other',
  };

  const handleSubmit = () => {
    setFailureMessage('');
    if (ceaseAndDesistEnabled) {
      removeCeaseAndDesistStatus({ variables: { customerId } });
    } else {
      form
        .validateFields()
        .then(() => {
          const selectedRadio = form.getFieldValue('requester-radio');
          let communicationFrom = selectedRadio;
          switch (selectedRadio) {
            case REQUESTER_TYPES.OTHER:
              communicationFrom = form.getFieldValue('requester-input');
              break;
            case REQUESTER_TYPES.CARDHOLDER:
              communicationFrom = cardHolder;
              break;
          }

          const floridaIndicator =
            customerData?.customer?.contactInfo?.address?.state === 'FL';

          addCeaseAndDesistStatus({
            variables: {
              customerId,
              communicationRequest: {
                ceaseDesist: {
                  communicationFrom,
                  floridaIndicator,
                  notificationDate: formatDateToSubmit(Date.now()),
                },
              },
            },
          });
        })
        .catch(() => {});
    }
  };

  const ceaseAndDesist =
    customerPreferencesQuery?.data?.customer?.communicationPreferences
      ?.ceaseDesist;

  return (
    <div>
      <Flex justify="space-between">
        <Title level={5}> Cease and Desist</Title>
        {agent.isSuperUser && (
          <Button
            data-testid="change-button"
            type="link"
            icon={<EditOutlined style={{ fontSize: '1.5rem' }} />}
            onClick={editCeaseAndDesistModal.show}
            disabled={customerPreferencesQuery?.loading}
            size="small"
          ></Button>
        )}
      </Flex>
      <Divider style={{ margin: '0 0 var(--spacing-sm)' }} />
      <QueryBoundary
        query={customerPreferencesQuery}
        skeleton={LOADING_SKELETON}
      >
        {ceaseAndDesist && (
          <Alert
            banner
            type="info"
            message={`Requested by ${
              ceaseAndDesist?.communicationFrom
            } on ${formatDate(ceaseAndDesist?.notificationDate)}`}
            style={{ marginBottom: 'var(--spacing-sm)' }}
          />
        )}
        <Row gutter={['var(--spacing-md)', 'var(--spacing-lg)']}>
          <Col span={24}>
            <DisplayValue label="Current Status:">
              <span data-testid="cease-desist-span">
                {ceaseAndDesistEnabled ? 'ON' : 'OFF'}
              </span>
            </DisplayValue>
          </Col>

          <Modal
            {...editCeaseAndDesistModal}
            title="Change Cease & Desist status"
            onOk={handleSubmit}
            okText="Confirm"
            cancelText="Close"
            confirmLoading={
              addCeaseAndDesistMutation.loading ||
              removeCeaseAndDesistMutation.loading
            }
          >
            {ceaseAndDesistEnabled ? (
              <Text strong>Turn Cease & Desist status OFF</Text>
            ) : (
              <Form form={form}>
                <Text>Who is requesting this change?</Text>
                <Item
                  name="requester-radio"
                  style={{ marginTop: '.5rem' }}
                  rules={[
                    {
                      required: true,
                      message: 'Requester is required',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) =>
                      setShowOtherInput(
                        e.target.value === REQUESTER_TYPES.OTHER,
                      )
                    }
                  >
                    <Space direction="vertical">
                      {Object.keys(REQUESTER_TYPES).map((key) => {
                        return (
                          <Radio
                            value={REQUESTER_TYPES[key]}
                            key={key}
                            data-testid={`radio-${REQUESTER_TYPES[key]}`.toLowerCase()}
                          >
                            {REQUESTER_TYPES[key]}
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                </Item>
                {showOtherInput && (
                  <Item
                    name="requester-input"
                    label="Name"
                    style={{ marginTop: '.5rem' }}
                    rules={[
                      {
                        required: true,
                        message: 'Name is required',
                      },
                    ]}
                  >
                    <Input />
                  </Item>
                )}
                {failureMessage ? (
                  <Alert type="error" message={failureMessage} />
                ) : null}
              </Form>
            )}
          </Modal>
        </Row>
      </QueryBoundary>
    </div>
  );
};

export default CeaseAndDesist;
