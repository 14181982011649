import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Modal, Tooltip } from 'antd';
import {
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';

import ErrorTooltip from 'components/ErrorTooltip/ErrorTooltip';
import SectionMessage from 'components/SectionMessage/SectionMessage';

import { CUSTOMER_OVERVIEW } from 'queries/customer';
import { ACTIVATE_CARD } from 'mutations/activateCard';

import useAnalytics from 'hooks/useAnalytics';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { ANALYTICS_EVENTS, STATUS, SIZE } from 'utilities/constants';

const { confirm } = Modal;

const ActivateCardButton = ({
  cardLast4,
  eligibleForActivation,
  activationDisabledTooltipMessage,
}) => {
  const { accountId, customerId } = useParams();
  const { trackEvent } = useAnalytics();

  const [dateOfBirth, setDateOfBirth] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [ssnLast4, setSsnLast4] = useState('');

  const [activationSuccess, setActivationSuccess] = useTimeoutValue(
    false,
    2500,
  );
  const [showActivationError, setShowActivationError] = useTimeoutValue(
    false,
    5000,
  );

  useQuery(CUSTOMER_OVERVIEW, {
    variables: { customerId },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ customer }) => {
      setSsnLast4(customer.lastFourSsn);
      setDateOfBirth(customer.birthDate);
      const fiveDigitZipCode =
        customer.contactInfo.address.postalCode.substring(0, 5);
      setZipCode(fiveDigitZipCode);
    },
  });

  const customerVerificationDetailsLoaded =
    ssnLast4 && cardLast4 && dateOfBirth && zipCode;

  const [
    submitActivateCardRequest,
    { loading: submittingActivateCardRequest, error: submitActivateCardError },
  ] = useMutation(ACTIVATE_CARD, {
    refetchQueries: ['GetCustomerWithFullAccounts'],
    onCompleted: () => {
      setActivationSuccess(true);
    },
    onError: () => {
      setShowActivationError(true);
    },
  });

  const handleActivateCardClick = async () => {
    await submitActivateCardRequest({
      variables: {
        customerId,
        accountId,
        ssnLast4,
        cardLast4,
        dateOfBirth,
        zipCode,
      },
    });

    trackEvent(ANALYTICS_EVENTS.ACTIVATE_CARD_SUBMIT);
  };

  const confirmCardActivation = () => {
    confirm({
      title: 'Are you sure you want to activate this card?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      onOk() {
        return handleActivateCardClick();
      },
      onCancel() {},
    });
  };

  return (
    <>
      <ErrorTooltip
        placement="topLeft"
        open={showActivationError}
        zIndex={4}
        title={
          submitActivateCardError &&
          `Unable to activate card due to: ${submitActivateCardError.message}. Please escalate to TSYS team via slack`
        }
      >
        <div>
          <Tooltip
            title={
              !eligibleForActivation ? activationDisabledTooltipMessage : ''
            }
          >
            <Button
              danger
              icon={<ExclamationCircleOutlined />}
              loading={submittingActivateCardRequest}
              onClick={confirmCardActivation}
              disabled={
                !eligibleForActivation || !customerVerificationDetailsLoaded
              }
            >
              Activate Card
            </Button>
          </Tooltip>
        </div>
      </ErrorTooltip>
      <SectionMessage
        data-testid="activate-card-success-message"
        status={STATUS.SUCCESS}
        size={SIZE.XS}
        text="Card Successfully Activated"
        cover={true}
        visible={activationSuccess}
      />
    </>
  );
};

export default ActivateCardButton;
