import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { ClockCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Input, Timeline, Tooltip } from 'antd';
import debounce from 'lodash.debounce';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useAnalytics from 'hooks/useAnalytics';
import useScrollLimit from 'hooks/useScrollLimit';
import {
  AUTOMATED_UPDATES_APPLICATION,
  AUTOMATED_UPDATES_CUSTOMER,
} from 'queries/automatedUpdates';
import { ANALYTICS_EVENTS } from 'utilities/constants';
import { getDateFromJavaInstant } from 'utilities/datesAndTimes';
import autoEventsWorker from 'workers/autoEvents';

import AutomatedUpdateEvent from './AutomatedUpdateEvent';
import CommunicationEvent from './CommunicationEvent';

const ExtraTabContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const SCROLL_INTERVAL = 300;
const RECORD_TYPE_COMMS = 'CommunicationsRecordGraphql';
const RECORD_TYPE_AUTOMATED_UPDATE = 'AgentAutomatedUpdate';

const webWorker = new Worker(autoEventsWorker);

const postToWorker = debounce((filter, records) => {
  webWorker.postMessage({
    type: 'autoevents',
    payload: JSON.stringify(records),
    filter,
  });
}, 500);

const formatAutomatedRecords = (records, searchTerm) => {
  const items = [];

  records.forEach((record) => {
    let children;
    const { __typename: type } = record;
    switch (type) {
      case RECORD_TYPE_COMMS:
        children = (
          <CommunicationEvent
            comm={record}
            highlight={searchTerm}
          ></CommunicationEvent>
        );
        break;
      case RECORD_TYPE_AUTOMATED_UPDATE:
        children = (
          <AutomatedUpdateEvent
            automatedUpdate={record}
            highlight={searchTerm}
          ></AutomatedUpdateEvent>
        );
        break;
      default:
        children = null;
    }
    items.push({
      dot: <ClockCircleOutlined />,
      children,
    });
  });
  return items;
};

const AutoEvents = () => {
  const bodyHeight = '440px';

  const { customerId, applicationId } = useParams();
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const { trackEvent } = useAnalytics();
  const [searchFilter, setSearchFilter] = useState('');
  const [processingRecords, setProcessingRecords] = useState(false);

  webWorker.onmessage = useCallback((message) => {
    let workerResp = JSON.parse(message.data);
    const { payload: records, filter } = workerResp;
    const items = formatAutomatedRecords(records, filter);
    setProcessingRecords(false);
    setFilteredRecords(items);
  }, []);

  const trackScrollEvent = (scrollLimit) =>
    trackEvent(ANALYTICS_EVENTS.AUTO_EVENTS_SCROLL_LIMIT_HIT, {
      limit: Math.floor(scrollLimit / SCROLL_INTERVAL),
    });
  const { onScroll } = useScrollLimit(SCROLL_INTERVAL, trackScrollEvent);

  const processRecords = (filter, records) => {
    setProcessingRecords(true);
    postToWorker(filter, records);
  };

  const parseRecordsAndProcess = (data = {}) => {
    const auto = data.auto || [];
    const comms = data.comms?.content || [];

    // add localizedDate & localizedText attributes for webworker to use.
    const consolidatedRecords = [...comms, ...auto].reduce((acc, record) => {
      let addlAttributes;
      const { __typename: type } = record;

      if (type === RECORD_TYPE_COMMS) {
        addlAttributes = {
          localizedDate: dayjs(record.sentDate),
          localizedText: record.title,
        };
      }

      if (type === RECORD_TYPE_AUTOMATED_UPDATE) {
        addlAttributes = {
          localizedDate: getDateFromJavaInstant(record.createdTz),
          localizedText: record.message,
        };
      }

      acc.push({ ...record, ...addlAttributes });
      return acc;
    }, []);
    setRecords(consolidatedRecords);
    processRecords(null, consolidatedRecords);
  };

  const [getUpdatesForApplication, autoUpdatesApplicationQuery] = useLazyQuery(
    AUTOMATED_UPDATES_APPLICATION,
    {
      variables: {
        applicationId,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        parseRecordsAndProcess(data);
      },
      onError: () => {
        setProcessingRecords(false);
      },
    },
  );

  const [getUpdatesForCustomer, autoUpdatesCustomerQuery] = useLazyQuery(
    AUTOMATED_UPDATES_CUSTOMER,
    {
      variables: {
        customerId,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        parseRecordsAndProcess(data);
      },
      onError: () => {
        setProcessingRecords(false);
      },
    },
  );

  const reloadAutoNotes = () => {
    setProcessingRecords(true);
    if (applicationId) {
      autoUpdatesApplicationQuery.refetch();
    } else {
      autoUpdatesCustomerQuery.refetch();
    }
  };

  useEffect(() => {
    setSearchFilter('');
    if (applicationId) {
      getUpdatesForApplication({ variables: { applicationId } });
    } else if (customerId) {
      getUpdatesForCustomer({ variables: { customerId } });
    }
  }, [applicationId, customerId]);

  const activeQuery = applicationId
    ? autoUpdatesApplicationQuery
    : autoUpdatesCustomerQuery;

  return (
    <Card
      style={{ minHeight: '500px', paddingBottom: '1rem' }}
      styles={{
        body: {
          overflow: 'auto',
          height: bodyHeight, //specific height to sit inline with Interactions
          padding: '1rem',
        },
      }}
      bordered={false}
      size="small"
      title="Auto"
      extra={
        <ExtraTabContainer>
          <Input
            placeholder="Search.."
            allowClear
            data-testid="search-auto-events"
            type="text"
            value={searchFilter}
            onChange={(e) => {
              const filterValue = e.target.value;
              setSearchFilter(filterValue);
              processRecords(filterValue, records);
              // postToWorker(e.target.value, records);
            }}
          />

          <Tooltip
            title={
              searchFilter.length > 0 ? 'Clear filter to refresh' : 'Refresh'
            }
          >
            <Button
              data-testid="reload-auto-notes"
              type="link"
              aria-label="Refresh"
              icon={<ReloadOutlined />}
              onClick={reloadAutoNotes}
              disabled={searchFilter.length > 0}
            />
          </Tooltip>
        </ExtraTabContainer>
      }
    >
      <QueryBoundary
        messageHeight={bodyHeight}
        errorMessage="Failed to load Auto Events."
        query={activeQuery}
        loadingOverride={activeQuery.loading || processingRecords}
        skeleton={{
          title: false,
          paragraph: { rows: 13 },
        }}
      >
        <Timeline
          mode="left"
          data-testid="auto-notes-timeline"
          onScroll={onScroll}
          items={filteredRecords}
        />
      </QueryBoundary>
    </Card>
  );
};

export default AutoEvents;
