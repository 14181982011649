import { Alert, Descriptions } from 'antd';

import { displayValueOrBlank, formatDollarsFromCents } from 'utilities/helpers';
import { formatDateTimeDescriptive } from 'utilities/datesAndTimes';

const CountAndAmount = ({ item = {} }) => {
  const count = item?.count || 0;

  const parsedTotal = parseInt(item?.totalAmount);
  const totalAmount = formatDollarsFromCents(parsedTotal);

  return (
    <>
      Count: {displayValueOrBlank(count)}
      <br />
      Amount: {displayValueOrBlank(totalAmount)}
    </>
  );
};

/**
 *
 * DISPLAY FLAG IF .fraud === true on FRAUD AUTHORIZATIONS
 */

const AuthorizationDetail = ({
  detail = {},
  accountDetails = {},
  pricingDetails = {},
}) => {
  const cardInfo = detail.transactionDetail || {}; //only auth
  const merchantInfo = detail.merchant || detail.merchantInformation || {};

  const getDateTime = () => {
    let dateTime;
    if (detail.isFraudAuthorization) {
      dateTime = formatDateTimeDescriptive(detail.dateTimeAuthorization);
    } else {
      dateTime = formatDateTimeDescriptive(detail.submittedAt, true);
    }
    return displayValueOrBlank(dateTime);
  };

  const getRequestType = () => {
    let requestType;
    if (detail.isFraudAuthorization) {
      requestType = detail.authorizationRequestMessage; //parse from authorizationRequestMessage?
    } else {
      requestType = detail.accountingFunction;
    }
    return displayValueOrBlank(requestType);
  };

  const getResponse = () => {
    let response;
    if (detail.isFraudAuthorization) {
      response = detail.responseInformation?.code;
    } else {
      response = detail.status;
    }
    return displayValueOrBlank(response);
  };

  const getResponseReason = () => {
    let reason = '';
    if (detail.isFraudAuthorization) {
      reason = `${detail.responseInformation?.declineReason} - ${detail.responseInformation?.declineReasonDescription}`;
    } else {
      reason = `${cardInfo.declineReason} - ${detail.declineReasonDescription}`;
    }
    return displayValueOrBlank(reason);
  };

  const getPOSEntryMode = () => {
    let pos;
    if (detail.isFraudAuthorization) {
      pos = detail.pointOfSaleInformation;
    } else {
      pos = cardInfo.posEntry;
    }
    return displayValueOrBlank(pos);
  };

  const getApprovalCode = () => {
    let approvalCode;
    if (detail.isFraudAuthorization) {
      approvalCode = detail.responseInformation?.approvalCode;
    } else {
      approvalCode = detail.approvalCode;
    }
    return displayValueOrBlank(approvalCode);
  };

  const getCurrentCreditLimit = () => {
    if (detail.isFraudAuthorization) {
      return;
    }

    const creditAmountBefore = cardInfo.creditAmountBefore;
    const currentBalanceAmount = cardInfo.activityToday?.currentBalanceAmount;
    if (
      Number.isInteger(creditAmountBefore) &&
      Number.isInteger(currentBalanceAmount)
    ) {
      const currentCreditLimit =
        parseInt(creditAmountBefore) + parseInt(currentBalanceAmount);
      return formatDollarsFromCents(currentCreditLimit);
    }
  };

  const getEnteredExpirationDate = () => {
    let exp = cardInfo.expires || detail.cardExpiry;
    if (exp) {
      exp = `${exp.substring(0, 2)}/${exp.substring(2)}`;
    }
    return displayValueOrBlank(exp);
  };

  return (
    <>
      {detail.fraud ? (
        <Alert
          banner
          type="warning"
          message="This Authorization has been CONFIRMED FRAUDULENT"
          style={{ marginBottom: 'var(--spacing-md)' }}
        />
      ) : null}
      <Descriptions
        title="Authorization Information"
        colon={false}
        layout="vertical"
        size="small"
        bordered
        style={{ marginBottom: 'var(--spacing-lg)' }}
      >
        <Descriptions.Item label="Date/Time">{getDateTime()}</Descriptions.Item>

        <Descriptions.Item label="Authorization Amount">
          {formatDollarsFromCents(detail.amount)}
        </Descriptions.Item>

        <Descriptions.Item label="Authorization Request Type">
          {getRequestType()}
        </Descriptions.Item>

        <Descriptions.Item label="Response">{getResponse()}</Descriptions.Item>

        <Descriptions.Item label="Response Reason">
          {getResponseReason()}
        </Descriptions.Item>

        <Descriptions.Item label="Recurring">
          {/* only auth */}
          {displayValueOrBlank(detail.recurring)}
        </Descriptions.Item>

        <Descriptions.Item label="Card Expiration Date">
          {displayValueOrBlank(accountDetails.cardDetails?.expirationDate)}
        </Descriptions.Item>

        <Descriptions.Item label="Entered Expiration Date">
          {/* only auth */}
          {getEnteredExpirationDate()}
        </Descriptions.Item>

        <Descriptions.Item label="CVI2 Response">
          {/* only auth */}
          {displayValueOrBlank(cardInfo.cardVerificationIndicator2Response)}
        </Descriptions.Item>

        <Descriptions.Item label="Transaction ID">
          {displayValueOrBlank(detail.transactionId)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="Merchant Information"
        colon={false}
        layout="vertical"
        size="small"
        bordered
        column={12}
        style={{ marginBottom: 'var(--spacing-lg)' }}
      >
        <Descriptions.Item label="Merchant Name" span={4}>
          {displayValueOrBlank(merchantInfo.name)}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Phone Number" span={4}>
          {displayValueOrBlank(merchantInfo.phoneNumber)}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Category Description" span={4}>
          {merchantInfo.categoryCode ? `${merchantInfo.categoryCode} - ` : ''}
          {displayValueOrBlank(merchantInfo.categoryDescription)}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Address" span={12}>
          City: {displayValueOrBlank(merchantInfo.address?.city)}
          <br />
          Postal Code: {displayValueOrBlank(merchantInfo.address?.postalCode)}
          <br />
          State/Province:{' '}
          {displayValueOrBlank(merchantInfo.address?.stateProvince)}
          <br />
          Street1: {displayValueOrBlank(merchantInfo.address?.street1)}
          <br />
          Street2: {displayValueOrBlank(merchantInfo.address?.street2)}
          <br />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="Card Information"
        colon={false}
        layout="vertical"
        size="small"
        column={12}
        bordered
      >
        <Descriptions.Item label="Card Last 4" span={4}>
          {/* only auth */}
          {displayValueOrBlank(detail.cardLast4)}
        </Descriptions.Item>

        <Descriptions.Item label="Balance at Authorization" span={4}>
          {/* only auth */}
          {displayValueOrBlank(
            formatDollarsFromCents(
              cardInfo.activityToday?.currentBalanceAmount,
            ),
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Current Available" span={4}>
          {/* only auth */}
          {displayValueOrBlank(
            formatDollarsFromCents(cardInfo.creditAmountBefore),
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Cash Limit" span={4}>
          {/* only auth */}
          {displayValueOrBlank(
            formatDollarsFromCents(pricingDetails?.cashAdvanceCreditLimit),
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Current Credit Limit" span={4}>
          {/* only auth */}
          {displayValueOrBlank(getCurrentCreditLimit())}
        </Descriptions.Item>

        <Descriptions.Item label="New Available" span={4}>
          {/* only auth */}
          {displayValueOrBlank(
            formatDollarsFromCents(
              cardInfo.activityToday?.availableAfterAuthorization,
            ),
          )}
        </Descriptions.Item>

        <Descriptions.Item label="POS Entry Mode" span={4}>
          {getPOSEntryMode()}
        </Descriptions.Item>

        <Descriptions.Item label="Approval Code" span={4}>
          {displayValueOrBlank(detail.approvalCode)}
          {getApprovalCode()}
        </Descriptions.Item>

        <Descriptions.Item label="Bad PIN Attempts" span={4}>
          {/* only auth */}
          {displayValueOrBlank(cardInfo.activityToday?.badPinAttemptCount || 0)}
        </Descriptions.Item>

        <Descriptions.Item label="Approved" span={4}>
          {/* only auth */}
          <CountAndAmount item={cardInfo.activityToday?.approved} />
        </Descriptions.Item>

        <Descriptions.Item label="Declined" span={4}>
          {/* only auth */}
          <CountAndAmount item={cardInfo.activityToday?.declined} />
        </Descriptions.Item>

        <Descriptions.Item label="Purchased" span={4}>
          {/* only auth */}
          <CountAndAmount item={cardInfo.activityToday?.purchases} />
        </Descriptions.Item>

        <Descriptions.Item label="Cash Advances" span={4}>
          {/* only auth */}
          <CountAndAmount item={cardInfo.activityToday?.cashAdvances} />
        </Descriptions.Item>

        <Descriptions.Item label="ATM Cash" span={4}>
          {/* only auth */}
          <CountAndAmount item={cardInfo.activityToday?.atmCash} />
        </Descriptions.Item>

        <Descriptions.Item label="Mail or Telephone Order" span={4}>
          {/* only auth */}
          <CountAndAmount item={cardInfo.activityToday?.mailOrTelephoneOrder} />
        </Descriptions.Item>

        <Descriptions.Item label="Referred" span={4}>
          {/* only auth */}
          <CountAndAmount item={cardInfo.activityToday?.referred} />
        </Descriptions.Item>

        <Descriptions.Item label="Account Part of Mass Compromise" span={8}>
          {/* same for both */}
          {displayValueOrBlank(detail.massCompromise)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AuthorizationDetail;
