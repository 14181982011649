import { gql } from '@apollo/client';

const CREATE_OR_UPDATE_THIRD_PARTY_INFO = gql`
  mutation CreateOrUpdateThirdPartyInfo(
    $accountId: String!
    $customerId: String!
    $thirdPartyName: String!
    $thirdPartyEmail: String!
    $customerOptIn: Boolean!
  ) {
    createOrUpdateAccountPreferences(
      accountId: $accountId
      accountPreferences: {
        accountId: $accountId
        customerId: $customerId
        thirdPartyName: $thirdPartyName
        thirdPartyEmail: $thirdPartyEmail
        customerOptIn: $customerOptIn
      }
    ) {
      id
    }
  }
`;

const REMOVE_THIRD_PARTY = gql`
  mutation RemoveThirdParty($accountId: String!) {
    removeAccountPreferences(accountId: $accountId) {
      id
    }
  }
`;

export { CREATE_OR_UPDATE_THIRD_PARTY_INFO, REMOVE_THIRD_PARTY };
