import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Flex, Form } from 'antd';

import AddFundingAccountModal from 'components/AddFundingAccountModal/AddFundingAccountModal';
import useModalControls from 'hooks/useModalControls';
import { CUSTOMER_FUNDING_ACCOUNTS } from 'queries/fundingAccounts';
import { getFundingAccountName } from 'utilities/helpers';

import StepContainer from './StepContainer';
import SelectFundingAccount from './SelectFundingAccount';

const FundingAccountStep = ({
  fundingAccount,
  setFundingAccount,
  canAddFundingAccount = true,
  onContinue = () => {},
}) => {
  const { customerId, accountId } = useParams();
  const [form] = Form.useForm();
  const addFundingAccountModal = useModalControls();

  const [fundingAccounts, setFundingAccounts] = useState([]);

  useQuery(CUSTOMER_FUNDING_ACCOUNTS, {
    variables: { customerId, accountId },
    onCompleted: (data) => {
      setFundingAccounts(data?.fundingAccounts);
    },
  });

  const onSelectFundingAccount = (bankAccountId) => {
    const displayName = getFundingAccountName(fundingAccounts, bankAccountId);
    setFundingAccount(bankAccountId, displayName);
  };

  const onFundingAccountConfirmation = async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }
    onContinue();
  };

  return (
    <>
      <AddFundingAccountModal
        {...addFundingAccountModal}
        refetchQueries={['MakePaymentQuery']}
      />
      <StepContainer
        title="Select a Funding Account"
        information="Customers can edit their bank account at any time during the life of their plan"
        onContinue={onFundingAccountConfirmation}
        buttonText="Confirm Funding Account"
      >
        <Form
          form={form}
          name="fundingAccount"
          layout="vertical"
          initialValues={{
            fundingAccount: fundingAccount?.id || null,
          }}
        >
          <SelectFundingAccount
            label="Bank Account"
            name="fundingAccount"
            fundingAccounts={fundingAccounts}
            onChange={onSelectFundingAccount}
          />
          <Flex justify="flex-end" gap="var(--spacing-sm)">
            {canAddFundingAccount ? (
              <Button onClick={addFundingAccountModal.show}>
                Add New Funding Account
              </Button>
            ) : null}
          </Flex>
        </Form>
      </StepContainer>
    </>
  );
};

export default FundingAccountStep;
