import { useEffect } from 'react';
import { Button, Card, Flex, Form, Input, Layout } from 'antd';
import { makeVar, useLazyQuery, useReactiveVar } from '@apollo/client';

import FraudFilesSearchResults from './FraudFilesSearchResults';

import QueryResult from 'components/QueryResult/QueryResult';

import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_EVENTS } from 'utilities/constants';
import { FRAUD_FILE_SEARCH } from 'queries/fraudFileSearch';

const searchResultsVar = makeVar(null);

const FormItem = ({ label, name, rules }) => {
  return (
    <Form.Item label={label} name={name} className="form-item" rules={rules}>
      <Input name={name} style={{ width: 400 }} />
    </Form.Item>
  );
};

const FraudFilesSearchLayout = () => {
  const searchResults = useReactiveVar(searchResultsVar);
  const { trackEvent } = useAnalytics();

  const [form] = Form.useForm();

  const [searchFraudFiles, searchFraudFileQuery] = useLazyQuery(
    FRAUD_FILE_SEARCH,
    {
      onCompleted: ({ retrieveFraudDocs }) => {
        let index = 0;
        searchResultsVar(
          retrieveFraudDocs.reduce(
            (results, fraudFile) => {
              let result = convertFraudFilesToSearchResult(fraudFile, index);
              results.fraudFile.push(result);
              index = index + 1;
              return results;
            },
            {
              fraudFile: [],
            },
          ),
        );
      },
    },
  );

  useEffect(() => {
    document.title = 'Mission Control Search';
  }, []);

  const handleFormSubmit = async (formValues) => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    trackEvent(ANALYTICS_EVENTS.FDR_FILE_SEARCH_CLICKED);

    const searchValues = Object.fromEntries(
      Object.entries(formValues).filter(([_, value]) => !!value),
    );

    searchFraudFiles({ variables: { searchRequest: searchValues } });
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Layout.Content>
      <Card bordered={false} title="Search for Fraud Files">
        <Form
          form={form}
          layout="vertical"
          size="middle"
          onFinish={handleFormSubmit}
        >
          <FormItem
            label="First Name"
            name="firstName"
            type="text"
            rules={[{ required: true, message: 'First Name is required' }]}
          />
          <FormItem
            label="Last Name"
            name="lastName"
            type="text"
            rules={[{ required: true, message: 'Last Name is required' }]}
          />
          <FormItem label="Account ID" name="accountId" type="text" />
          <FormItem label="Card Last 4" name="panLast4" type="text" />
          <Form.Item>
            <Flex
              gap="var(--spacing-sm)"
              style={{ marginTop: 'var(--spacing-md)' }}
            >
              <Button danger onClick={onReset} data-testid="resetButton">
                Clear Form
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="searchButton"
              >
                Search
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Card>

      <QueryResult
        {...searchFraudFileQuery}
        renderChildrenWithNullData={true}
        called
      >
        {searchResults ? (
          <>
            <FraudFilesSearchResults
              title="Fraud File Results"
              data={searchResults.fraudFile}
            />
          </>
        ) : null}
      </QueryResult>
    </Layout.Content>
  );
};

export const convertFraudFilesToSearchResult = (fraudFile, index) => {
  const fraudFileResult = {
    key: `${fraudFile.parentCaseId}_${fraudFile.caseId}_${index}`,
    customerName: fraudFile.customerName,
    parentCaseId: fraudFile.parentCaseId,
    caseId: fraudFile.caseId,
    signUrl: fraudFile.signUrl,
    documentName: fraudFile.documentName,
    fileType: fraudFile.fileType,
    maskedPanLast4: fraudFile.maskedPanLast4.slice(-4),
    accountId: fraudFile.accountId,
    fraudIndicator: fraudFile.fraudIndicator,
    clientId: fraudFile.clientId,
    vaultToken: fraudFile.vaultToken,
    statusWork: fraudFile.statusWork,
  };
  return fraudFileResult;
};

export default FraudFilesSearchLayout;
