import { gql } from '@apollo/client';

const ADD_AGENT_AUDIT_LOG_FOR_BAN_MUTATION = gql`
  mutation AddAgentAuditLogForBan(
    $customerId: String!
    $agentId: String!
    $agentName: String!
  ) {
    addAuditLogForCustomerV3(
      customerId: $customerId
      agentId: $agentId
      agentName: $agentName
      fieldName: "ban"
    ) {
      id
    }
  }
`;

const ADD_AGENT_AUDIT_LOG_FOR_APPLICATION_SSN_MUTATION = gql`
  mutation AddAgentAuditLogForApplicationSSN(
    $applicationId: String!
    $agentId: String!
    $agentName: String!
  ) {
    addAuditLogForApplicationV3(
      applicationId: $applicationId
      agentId: $agentId
      agentName: $agentName
      fieldName: "ssn"
    ) {
      id
    }
  }
`;

const ADD_AGENT_AUDIT_LOG_FOR_CUSTOMER_SSN_MUTATION = gql`
  mutation AddAgentAuditLogForCustomerSSN(
    $customerId: String!
    $agentId: String!
    $agentName: String!
  ) {
    addAuditLogForCustomerV3(
      customerId: $customerId
      agentId: $agentId
      agentName: $agentName
      fieldName: "ssn"
    ) {
      id
    }
  }
`;

export {
  ADD_AGENT_AUDIT_LOG_FOR_BAN_MUTATION,
  ADD_AGENT_AUDIT_LOG_FOR_APPLICATION_SSN_MUTATION,
  ADD_AGENT_AUDIT_LOG_FOR_CUSTOMER_SSN_MUTATION,
};
