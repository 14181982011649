import { gql } from '@apollo/client';

const CREATE_PAYMENT_PLAN_SETTLEMENT_OFFER = gql`
  mutation CreateSettlementOffer(
    $accountId: String!
    $customerId: String!
    $durationOverrideInMonths: Int
    $efta: String!
    $fundingAccountId: Long!
    $paymentPlanCadence: String!
    $paymentPlanStartDate: String!
    $selectedOfferId: String!
  ) {
    createSettlementOffer(
      channel: AGENT_TOOL
      createSettlementOfferRequest: {
        accountId: $accountId
        customerId: $customerId
        durationOverrideInMonths: $durationOverrideInMonths
        efta: $efta
        fundingAccountId: $fundingAccountId
        paymentPlanCadence: $paymentPlanCadence
        paymentPlanStartDate: $paymentPlanStartDate
        selectedOfferId: $selectedOfferId
      }
    ) {
      accountId
      offerAggregate {
        createdAt
        data {
          acceptanceExpiration
          acceptedAt
          atpUnderSIF
          canceledReason
          expiration
          fundingAccountId
          offerId
          originalOutstandingBalance
          originalSettlementBalance
          pastPaymentPlanIds
          paymentPlan {
            authorizationText
            customerId
            id
            source
            transactions {
              amount
              date
            }
          }
          payments {
            amount
            date
            txId
            type
          }
          remainingBalance
          state
          subType
          type
        }
        offerId
        offerType
        updatedAt
      }
    }
  }
`;

const CREATE_DEBIT_SETTLEMENT_OFFER = gql`
  mutation CreateDebitSettlementoffer(
    $accountId: String!
    $selectedOfferId: String!
  ) {
    createDebitSettlementOffer(
      accountId: $accountId
      channel: AGENT_TOOL
      selectedOfferId: $selectedOfferId
    ) {
      createdAt
      data {
        acceptanceExpiration
        acceptedAt
        accountId
        atpUnderSIF
        canceledReason
        duration {
          unit
          value
        }
        expiration
        fundingAccountId
        offerId
        originalOutstandingBalance
        originalSettlementBalance
        pastPaymentPlanIds
        paymentPlan {
          authorizationText
          customerId
          id
          source
          transactions {
            amount
            date
          }
        }
        payments {
          amount
          date
          txId
          type
        }
        remainingBalance
        state
        subType
        type
      }
      offerId
      offerType
      updatedAt
    }
  }
`;

const UPDATE_SETTLEMENT_PAYMENT_PLAN = gql`
  mutation UpdateSettlementPaymentPlan(
    $authorizationText: String!
    $customerId: String!
    $transactions: [TransactionDetailsInput!]!
    $offerId: UUID!
  ) {
    updateSettlementOfferPaymentPlan(
      paymentPlan: {
        authorizationText: $authorizationText
        customerId: $customerId
        source: AGENT_TOOL
        transactions: $transactions
      }
      offerId: $offerId
    ) {
      acceptanceExpiration
      acceptedAt
      atpUnderSIF
      canceledReason
      expiration
      fundingAccountId
      offerId
      originalOutstandingBalance
      originalSettlementBalance
      pastPaymentPlanIds
      paymentPlan {
        authorizationText
        customerId
        id
        source
        transactions {
          amount
          date
        }
      }
      payments {
        amount
        date
        txId
        type
      }
      remainingBalance
      state
      subType
      type
    }
  }
`;

const UPDATE_SETTLEMENT_FUNDING_ACCOUNT = gql`
  mutation UpdateSettlementFundingAccount(
    $authorizationText: String
    $customerId: Long!
    $fundingAccountId: Long!
    $offerId: UUID!
  ) {
    updateSettlementOfferFundingAccount(
      settlementFundingAccount: {
        authorizationText: $authorizationText
        customerId: $customerId
        fundingAccountId: $fundingAccountId
      }
      offerId: $offerId
    ) {
      acceptanceExpiration
      acceptedAt
      atpUnderSIF
      canceledReason
      expiration
      fundingAccountId
      offerId
      originalOutstandingBalance
      originalSettlementBalance
      pastPaymentPlanIds
      paymentPlan {
        authorizationText
        customerId
        id
        source
        transactions {
          amount
          date
        }
      }
      payments {
        amount
        date
        txId
        type
      }
      remainingBalance
      state
      subType
      type
    }
  }
`;

const CANCEL_SETTLEMENT_OFFER = gql`
  mutation CancelSettlementOffer($offerId: UUID!) {
    cancelSettlementOffer(offerId: $offerId) {
      acceptanceExpiration
      acceptedAt
      atpUnderSIF
      canceledReason
      expiration
      fundingAccountId
      offerId
      originalOutstandingBalance
      originalSettlementBalance
      pastPaymentPlanIds
      paymentPlan {
        authorizationText
        customerId
        id
        source
        transactions {
          amount
          date
        }
      }
      payments {
        amount
        date
        txId
        type
      }
      remainingBalance
      state
      subType
      type
    }
  }
`;

const SEND_PRE_OFFER_EMAIL = gql`
  mutation SendPreOfferEmail(
    $availableOfferId: String!
    $accountId: String!
    $customerId: String!
  ) {
    sendPreOfferCommunication(
      availableOfferId: $availableOfferId
      accountId: $accountId
      customerId: $customerId
    ) {
      amount
      durationMonths
      offerId
      offerName
    }
  }
`;

export {
  CANCEL_SETTLEMENT_OFFER,
  CREATE_PAYMENT_PLAN_SETTLEMENT_OFFER,
  CREATE_DEBIT_SETTLEMENT_OFFER,
  UPDATE_SETTLEMENT_FUNDING_ACCOUNT,
  SEND_PRE_OFFER_EMAIL,
  UPDATE_SETTLEMENT_PAYMENT_PLAN,
};
