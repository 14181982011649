// The identify call lets you tie a user to their actions and record traits about them.
// It includes a unique User ID and any optional traits you know about the user, like their email, name, etc.
const identifyUser = (agent, labels) =>
  window.analytics?.identify(agent, labels);

// The track call is how you record any actions your users perform, along with any properties that describe the action
const trackEvent = (event, properties) =>
  window.analytics?.track(event, properties);

// The page call lets you record whenever a user sees a page of your website, along with any optional properties about the page.
const trackPage = (category, name, properties) =>
  window.analytics?.page(category, name, properties);

// Calling reset resets the id, including anonymousId, and clears traits for the currently identified user and group.
const resetUser = () => window.analytics?.reset();

const useAnalytics = () => {
  return { identifyUser, trackEvent, trackPage, resetUser };
};

export default useAnalytics;
