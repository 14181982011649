import { useEffect } from 'react';
import { Form } from 'antd';
import dayjs from 'dayjs';

import {
  ACCOUNT_STATUS_CODE,
  INDEFINITE_EXPIRATION_DATE,
} from 'utilities/constants';

import { FORM_FIELD, TIERED_WATCH_REASON } from './applyStatusUtilities';
import TimeframeField from './TimeframeField';

const FraudStatusFields = ({ form }) => {
  const selectedStatusCode = Form.useWatch(FORM_FIELD.STATUS_CODE, form);
  const selectedReasonCode = Form.useWatch(FORM_FIELD.REASON_CODE, form);

  useEffect(() => {
    if (
      selectedStatusCode === ACCOUNT_STATUS_CODE.CARD_WATCH ||
      selectedStatusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH
    ) {
      const expiration =
        selectedReasonCode === TIERED_WATCH_REASON.ADS_II_BYPASS ||
        selectedReasonCode === TIERED_WATCH_REASON.SECONDARY_BYPASS
          ? dayjs().add(48, 'hour')
          : dayjs(INDEFINITE_EXPIRATION_DATE);
      form.setFieldValue(FORM_FIELD.FRAUD_TIMEFRAME, [dayjs(), expiration]);
    } else {
      form.setFieldValue(FORM_FIELD.FRAUD_TIMEFRAME, []);
    }
  }, [selectedStatusCode, selectedReasonCode]);

  return (
    <TimeframeField
      label="Timeframe for fraud status"
      name={FORM_FIELD.FRAUD_TIMEFRAME}
    ></TimeframeField>
  );
};

export default FraudStatusFields;
