import { gql } from '@apollo/client';

const APPLICANT_META = gql`
  query GetApplicantByApplicationId($applicationId: String!) {
    applicationV2(applicationId: $applicationId) {
      submission {
        applicants {
          applicantId
          contactDetails {
            address {
              address1
              address2
              city
              state
              zip
            }
            emailAddress
            phoneNumber
          }
          dateOfBirth
          firstName
          middleName
          lastName
          last4Ssn
          financialDetails {
            income
            monthlyHousingAmount
          }
        }
      }
    }
  }
`;

/**
 * This should work as long as applications continue to return an array including just one applicant.
 * If at some point we start to receive multiple applicants we will likely need to create an
 * endpoint specific to an applicant ID to retrieve only a single SSN
 */
const APPLICANT_FULL_SSN = gql`
  query GetApplicantFullSSN($applicationId: String!) {
    applicationV2(applicationId: $applicationId) {
      submission {
        applicants {
          applicantId
          fullSsn
        }
      }
    }
  }
`;

export { APPLICANT_META, APPLICANT_FULL_SSN };
