import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  List,
  Typography,
} from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useTimeoutValue from 'hooks/useTimeoutValue';
import { UPDATE_CUSTOMER_PREFERENCES } from 'mutations/customerMeta';
import { CUSTOMER_PREFERENCES } from 'queries/customer';
import { STATUS } from 'utilities/constants';

const SUBSCRIBED = 'SUBSCRIBED';
const NOT_SUBSCRIBED = 'NOT_SUBSCRIBED';
const ALL_MESSAGES = 'allMessages';

const COMMUNICATION_CHANNELS = Object.freeze({
  [ALL_MESSAGES]: {
    name: ALL_MESSAGES,
    label: 'Do not contact in any way',
  },
  campaignEmails: {
    name: 'campaignEmails',
    label: 'Do not send marketing emails',
  },
  campaignSms: {
    name: 'campaignSms',
    label: 'Do not send marketing text',
  },
  homeCall: {
    name: 'homeCall',
    label: 'Do not call home',
  },
  transactionalEmails: {
    name: 'transactionalEmails',
    label: 'Do not send transactional email',
  },
  transactionalSms: {
    name: 'transactionalSms',
    label: 'Do not send transactional text',
  },
  workCall: {
    name: 'workCall',
    label: 'Do not call work',
  },
});

const SubscribeCheckBox = ({ value, onChange, disabled, children }) => {
  const onChkChange = ({ target }) => {
    const newValue = target.checked ? NOT_SUBSCRIBED : SUBSCRIBED;
    onChange(newValue);
  };
  return (
    <Checkbox
      checked={value === NOT_SUBSCRIBED}
      disabled={disabled}
      onChange={onChkChange}
    >
      {children}
    </Checkbox>
  );
};

const CurrentCommunicationPreferences = ({
  preferences,
  electronicCommsConsent,
}) => {
  const commsSelections =
    preferences.allMessages === NOT_SUBSCRIBED
      ? [COMMUNICATION_CHANNELS.allMessages.label]
      : Object.keys(preferences)
          .filter((key) => preferences[key] === NOT_SUBSCRIBED)
          .map((key) => COMMUNICATION_CHANNELS[key].label);

  // add e-consent on top of the list
  commsSelections.unshift(
    `E-Consent: ${electronicCommsConsent ? 'Yes' : 'No'}`,
  );

  return (
    <div data-testid="current-comms-preferences">
      <List
        dataSource={commsSelections}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </div>
  );
};

const PreferencesSection = ({ children, title }) => {
  return (
    <>
      <Divider
        orientation="left"
        style={{
          fontSize: 'var(--font-size-sm)',
          marginBottom: 'var(--spacing-sm)',
          minWidth: 'unset',
          maxWidth: '300px',
        }}
      >
        {title}
      </Divider>
      <div style={{ margin: '0 var(--spacing-md)' }}>{children}</div>
    </>
  );
};

const PreferencesPage = () => {
  const { customerId } = useParams();
  const [form] = Form.useForm();
  const [showSuccessMessage, setShowSuccessMessage] = useTimeoutValue(false);
  const [shoeFailureMessage, setShowFailureMessage] = useState(false);
  const [customerHomePhone, setCustomerHomePhone] = useState('');
  const [customerLanguage, setCustomerLanguage] = useState('EN_US');
  const [commsChannelPreferences, setCommsChannelPreferences] = useState();
  const [electronicCommsConsent, setElectronicCommsConsent] = useState();

  const preferencesQuery = useQuery(CUSTOMER_PREFERENCES, {
    variables: { customerId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const customer = data?.customer || {};
      if (customer?.communicationPreferences) {
        setCustomerLanguage(customer.communicationPreferences.language);

        const channel = customer.communicationPreferences.channel;
        form.setFieldsValue(channel);
        setCommsChannelPreferences(channel);
      }
      if (customer.contactInfo?.phoneNumber) {
        setCustomerHomePhone(
          customer.contactInfo.phoneNumber.replace(/\s/g, ''),
        );
      }
      if (customer.electronicCommunicationsConsent) {
        setElectronicCommsConsent(
          customer.electronicCommunicationsConsent.hasElectronicConsent,
        );
      }
    },
  });

  const [execUpdate, updateMutation] = useMutation(
    UPDATE_CUSTOMER_PREFERENCES,
    {
      refetchQueries: ['GetCustomerCommPreferences'],
    },
  );

  const handleDisableCommsChange = (value) => {
    const disableComms = value === NOT_SUBSCRIBED;

    if (disableComms) {
      const fieldValues = Object.assign({}, form.getFieldsValue());
      delete fieldValues.allMessages;
      Object.keys(fieldValues).forEach((key) => {
        fieldValues[key] = NOT_SUBSCRIBED;
      });
      form.setFieldsValue(fieldValues);
    }
  };

  const getAllNotSubscribedState = (values) => {
    const valuesDup = Object.assign({}, values);
    delete valuesDup[ALL_MESSAGES];

    const allNotSubscribed = Object.values(valuesDup).every(
      (value) => value === NOT_SUBSCRIBED,
    );

    return allNotSubscribed ? NOT_SUBSCRIBED : SUBSCRIBED;
  };

  const onValuesChange = async (changedValue, allValues) => {
    if (changedValue[ALL_MESSAGES]) {
      handleDisableCommsChange(changedValue[ALL_MESSAGES]);
      return;
    }

    form.setFieldValue(ALL_MESSAGES, getAllNotSubscribedState(allValues));
  };

  const onFormComplete = async (values) => {
    setShowFailureMessage(false);
    try {
      await execUpdate({
        variables: {
          customerId,
          communicationRequest: {
            channel: {
              ...values,
              [ALL_MESSAGES]: getAllNotSubscribedState(values),
            },
            language: customerLanguage,
          },
        },
        refetchQueries: ['GetCustomerCommPreferences'],
      });
      setShowSuccessMessage(true);
    } catch (error) {
      setShowFailureMessage(true);
    }
  };

  const disabled = updateMutation.loading || preferencesQuery.loading;

  return (
    <Card bordered={false}>
      <Typography.Title level={5} style={{ marginBottom: 'var(--spacing-md)' }}>
        Communication Preferences
      </Typography.Title>

      <QueryBoundary
        query={preferencesQuery}
        skeleton={{
          title: false,
          paragraph: { rows: 3, width: ['240px', '240px', '240px'] },
        }}
      >
        {commsChannelPreferences && (
          <CurrentCommunicationPreferences
            preferences={commsChannelPreferences}
            electronicCommsConsent={electronicCommsConsent}
          />
        )}
      </QueryBoundary>

      <Divider></Divider>

      <Typography.Title level={5} style={{ marginBottom: 'var(--spacing-md)' }}>
        Edit Communication Preferences
      </Typography.Title>
      <Form
        form={form}
        disabled={disabled}
        onFinish={onFormComplete}
        onValuesChange={onValuesChange}
        size="small"
        style={{ position: 'relative' }}
      >
        <SectionMessage
          status={STATUS.SUCCESS}
          cover={true}
          text="Communication Preferences Updated"
          visible={showSuccessMessage}
        ></SectionMessage>
        <Form.Item
          name={COMMUNICATION_CHANNELS.allMessages.name}
          disabled={disabled}
        >
          <SubscribeCheckBox>
            {COMMUNICATION_CHANNELS.allMessages.label}
          </SubscribeCheckBox>
        </Form.Item>
        <PreferencesSection title="Phone">
          <Form.Item name={COMMUNICATION_CHANNELS.homeCall.name}>
            <SubscribeCheckBox>
              {`${COMMUNICATION_CHANNELS.homeCall.label} ${customerHomePhone}`}
            </SubscribeCheckBox>
          </Form.Item>
          <Form.Item name={COMMUNICATION_CHANNELS.workCall.name}>
            <SubscribeCheckBox>
              {COMMUNICATION_CHANNELS.workCall.label}
            </SubscribeCheckBox>
          </Form.Item>
          <Form.Item name={COMMUNICATION_CHANNELS.campaignSms.name}>
            <SubscribeCheckBox>
              {COMMUNICATION_CHANNELS.campaignSms.label}
            </SubscribeCheckBox>
          </Form.Item>
          <Form.Item name={COMMUNICATION_CHANNELS.transactionalSms.name}>
            <SubscribeCheckBox>
              {COMMUNICATION_CHANNELS.transactionalSms.label}
            </SubscribeCheckBox>
          </Form.Item>
        </PreferencesSection>

        <PreferencesSection title="Email">
          <Form.Item name={COMMUNICATION_CHANNELS.campaignEmails.name}>
            <SubscribeCheckBox>
              {COMMUNICATION_CHANNELS.campaignEmails.label}
            </SubscribeCheckBox>
          </Form.Item>
          <Form.Item name={COMMUNICATION_CHANNELS.transactionalEmails.name}>
            <SubscribeCheckBox>
              {COMMUNICATION_CHANNELS.transactionalEmails.label}
            </SubscribeCheckBox>
          </Form.Item>
        </PreferencesSection>

        {shoeFailureMessage ? (
          <Alert
            data-testid="preferences-failure-message"
            type="error"
            message="Failed to update communication preferences. Please try again."
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
        ) : null}
        <Button
          key="submit"
          type="primary"
          disabled={disabled}
          loading={updateMutation.loading}
          onClick={form.submit}
          size="medium"
        >
          Save
        </Button>
      </Form>
    </Card>
  );
};

export default PreferencesPage;
