import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import client from 'apollo/client';
import {
  Alert,
  Checkbox,
  Flex,
  Form,
  List,
  Modal as AntModal,
  Select,
  Typography,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useAccountQuery from 'hooks/useAccountQuery';
import useAgent from 'hooks/useAgent';
import useTimeoutValue from 'hooks/useTimeoutValue';

import { ACCOUNT_STATUSES } from 'queries/account';
import {
  SET_ACCOUNT_ATTRIBUTES,
  UPDATE_ACCOUNT_STATUS,
} from 'mutations/account';

import { getCardDisplayName } from 'utilities/helpers';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import {
  ACCOUNT_ATTRIBUTE,
  ACCOUNT_STATUS_CODE,
  ACCOUNT_STATUS_OPERATION,
  BREAKPOINT,
  SIZE,
  STATUS,
} from 'utilities/constants';

import {
  CDF10_REASONS,
  MULTI_ACCOUNT_REASONS,
  DEFAULT_STATUS_FORM_VALUES,
  STATUS_OPTIONS,
  FORM_FIELD,
  FRAUDLENT_CLOSED_REASONS,
  TIERED_WATCH_REASON,
  CARD_WATCH_REASON,
} from './applyStatusUtilities';
import useBlockingStatusMessage from './useBlockingStatusMessage';
import FraudStatusFields from './FraudStatusFields';
import ClosedStatusFields from './ClosedStatusFields';

const Modal = styled(AntModal)`
  min-width: 100%;
  top: var(--spacing-lg);

  @media (min-width: ${BREAKPOINT.MD_PX}) {
    min-width: 740px;
  }
`;

const StatusDetailsGrid = styled.div(() => {
  return `
    display: grid;
    grid-gap: 0 var(--spacing-md);
    grid-template-columns: 1fr;

    @media (min-width: ${BREAKPOINT.LG_PX}) {
      grid-template-columns: 1fr 1fr;
    }
  `;
});

const ApplyStatusModal = ({ hide, ...modalProps }) => {
  const { accountId, customerId } = useParams();
  const agent = useAgent();
  const [form] = Form.useForm();
  const {
    enableClosedStatus,
    isFraudOps,
    isFdr,
    isFdi,
    isSah,
    isS3FraudAgent,
  } = useFlags();
  const canApplyTW15orCW20 = agent?.isSuperUser || isFdi || isFdr;
  const canApplyFraudClosedStatus = isFraudOps || isSah || isS3FraudAgent;

  const [operationsLoading, setOperationsLoading] = useState(false);
  const [activeStatuses, setActiveStatuses] = useState([]);
  const [statusReasons, setReasonOptions] = useState([]);

  const selectedStatusCode = Form.useWatch(FORM_FIELD.STATUS_CODE, form);
  const selectedReasonCode = Form.useWatch(FORM_FIELD.REASON_CODE, form);
  const applyToAllAccounts = Form.useWatch(
    FORM_FIELD.APPLY_TO_ALL_ACCOUNTS,
    form,
  );

  const blockingStatusMessage = useBlockingStatusMessage(
    selectedStatusCode,
    selectedReasonCode,
    activeStatuses,
  );

  const filteredStatusOptions = useMemo(() => {
    const options = [...STATUS_OPTIONS];
    if (!enableClosedStatus) {
      return options.filter(
        (option) => option.value !== ACCOUNT_STATUS_CODE.CLOSED,
      );
    }
    return options;
  }, [enableClosedStatus]);

  const { applyCDF10, isMultiAccount } = useMemo(() => {
    return {
      applyCDF10: CDF10_REASONS.includes(selectedReasonCode),
      isMultiAccount: MULTI_ACCOUNT_REASONS.includes(selectedReasonCode),
    };
  }, [selectedReasonCode]);

  const bypassStatusesToBeReplaced =
    selectedStatusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH &&
    selectedReasonCode === TIERED_WATCH_REASON.SECONDARY_BYPASS
      ? activeStatuses.filter(
          (status) =>
            (status.statusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH &&
              status.reasonCode === TIERED_WATCH_REASON.ADS_II_BYPASS) ||
            (status.statusCode === ACCOUNT_STATUS_CODE.CARD_WATCH &&
              status.reasonCode === CARD_WATCH_REASON.FRAUD_ALERT_BYPASS),
        )
      : [];

  const [highlightBlockingStatusMessage, setHighlightBlockingStatusMessage] =
    useState(false);
  const [showAttributeError, setShowAttributeError] = useState(false);
  const [showStatusError, setShowStatusError] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useTimeoutValue(
    false,
    3000,
  );
  const [statusOverrideWarning, setStatusOverrideWarning] = useState('');
  const [removingBypassStatus, setRemovingBypassStatus] = useState('');

  const { account: currentViewedAccount, accounts } = useAccountQuery(
    customerId,
    accountId,
  );

  useQuery(ACCOUNT_STATUSES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      customerId,
      accountIds: [accountId],
    },
    onCompleted: (data) => {
      const accountStatusData = (data?.accountFraudStatuses || []).find(
        (statusObj) => statusObj.accountId === accountId,
      );
      const accountStatuses = accountStatusData?.accountStatuses || [];
      setActiveStatuses(accountStatuses);
    },
  });

  const [setAccountAttributes] = useMutation(SET_ACCOUNT_ATTRIBUTES, {
    onError: () => setShowAttributeError(true),
  });

  const [setAccountFraudStatus] = useMutation(UPDATE_ACCOUNT_STATUS);

  useEffect(() => {
    if (selectedStatusCode) {
      // initialize reason options
      const statusOption = STATUS_OPTIONS.find(
        (option) => option.value === selectedStatusCode,
      );
      const reasonOptions = statusOption?.reasonCodes || [];
      setReasonOptions(reasonOptions);
      form.setFieldsValue({
        [FORM_FIELD.REASON_CODE]: reasonOptions[0].value,
      });
    }
  }, [selectedStatusCode]);

  useEffect(() => {
    setRemovingBypassStatus('');

    const handleStatusOverrideWarningChange = (statusCode) => {
      if (bypassStatusesToBeReplaced?.length) {
        const fullStatusCode = bypassStatusesToBeReplaced
          .map((status) => `${status.statusCode}-${status.reasonCode}`)
          .join(bypassStatusesToBeReplaced.length > 1 ? ', ' : '');

        setRemovingBypassStatus(fullStatusCode);
        return setStatusOverrideWarning(
          `Applying this status will replace ${fullStatusCode}. Are you sure you want to proceed?`,
        );
      }

      //warn about status replacement
      const existingStatusCode = activeStatuses.find((status) => {
        return status.statusCode === statusCode;
      });

      if (existingStatusCode) {
        return setStatusOverrideWarning(
          `Applying this status will replace ${existingStatusCode.description}. Are you sure you want to proceed?`,
        );
      } else {
        setStatusOverrideWarning('');
      }
    };

    form.validateFields();
    handleStatusOverrideWarningChange(selectedStatusCode, selectedReasonCode);
  }, [selectedStatusCode, selectedReasonCode, activeStatuses]);

  const closeModal = () => {
    setStatusOverrideWarning('');
    setHighlightBlockingStatusMessage(false);
    form.resetFields();
    hide();
  };

  const handleSubmit = async () => {
    let fieldValues;
    try {
      fieldValues = await form.validateFields();
    } catch (error) {
      return;
    }

    setShowAttributeError(false);
    setShowStatusError(false);

    if (blockingStatusMessage) {
      setHighlightBlockingStatusMessage(true);
      return;
    }

    setHighlightBlockingStatusMessage(false);

    try {
      const accountIds =
        isMultiAccount && applyToAllAccounts
          ? accounts.map((account) => account.id)
          : [currentViewedAccount.id];

      const { statusCode, reasonCode } = fieldValues;
      const activeStatus = activeStatuses.find(
        (status) => ACCOUNT_STATUS_CODE[status.statusCode] === statusCode,
      );

      let startDate, stopDate;
      const fraudTimeframe = fieldValues[FORM_FIELD.FRAUD_TIMEFRAME];
      if (fraudTimeframe && fraudTimeframe.length) {
        startDate = formatDateToSubmit(fraudTimeframe[0]);
        stopDate = formatDateToSubmit(fraudTimeframe[1]);
      }

      setOperationsLoading(true);

      const hasCW20 = bypassStatusesToBeReplaced?.find(
        (status) => status.statusCode === ACCOUNT_STATUS_CODE.CARD_WATCH,
      );

      const hasTW15 = bypassStatusesToBeReplaced?.find(
        (status) => status.statusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH,
      );

      // if applying TW19, remove existing bypass statuses (TW15, CW20) first
      // ignore if account only has TW15
      let bypassStatusRemovalResponse;
      if (bypassStatusesToBeReplaced?.length > 1 || hasCW20) {
        bypassStatusRemovalResponse = await Promise.all(
          bypassStatusesToBeReplaced.map((status) => {
            const fullStatusCode = `${status.statusCode}_${status.reasonCode}`;

            return setAccountFraudStatus({
              variables: {
                customerId,
                accountIds,
                agentName: agent?.user?.name || 'anonymous',
                operation: ACCOUNT_STATUS_OPERATION.REMOVED,
                reasonCode: fullStatusCode,
                statusCode: status.statusCode,
              },
            });
          }),
        );
      }

      let statusResponse;
      if (
        hasTW15 ||
        !bypassStatusesToBeReplaced?.length ||
        !bypassStatusRemovalResponse.errors
      ) {
        statusResponse = await setAccountFraudStatus({
          variables: {
            customerId,
            accountIds,
            agentName: agent?.user?.name || 'anonymous',
            operation: activeStatus
              ? ACCOUNT_STATUS_OPERATION.UPDATED
              : ACCOUNT_STATUS_OPERATION.APPLIED,
            reasonCode: `${statusCode}_${reasonCode}`,
            statusCode: statusCode,
            startDate,
            stopDate,
          },
        });
      }

      if (!statusResponse.errors && applyCDF10) {
        await setAccountAttributes({
          variables: {
            customerId,
            accountIds,
            attributes: [
              {
                attributeId: ACCOUNT_ATTRIBUTE.CDF10,
              },
            ],
          },
        });
      }

      await client.refetchQueries({
        include: [
          'GetAccountStatusesRaw',
          'GetCustomerWithFullAccounts',
          'GetAccountStatementPreferences',
        ],
      });

      setSubmissionSuccess(true);
      setOperationsLoading(false);
      setStatusOverrideWarning('');
      setRemovingBypassStatus('');
      hide();
    } catch (error) {
      setShowStatusError(true);
    }
  };

  const renderStatusFields = () => {
    if (blockingStatusMessage) {
      return (
        <Alert
          banner
          type={highlightBlockingStatusMessage ? 'error' : 'warning'}
          message={blockingStatusMessage}
          style={{ marginBottom: 'var(--spacing-md)', gridColumn: '1/-1' }}
        />
      );
    }

    const statusCode = form.getFieldValue(FORM_FIELD.STATUS_CODE);

    if (
      statusCode === ACCOUNT_STATUS_CODE.CARD_WATCH ||
      statusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH
    ) {
      return <FraudStatusFields account={currentViewedAccount} form={form} />;
    }

    if (statusCode === ACCOUNT_STATUS_CODE.CLOSED) {
      return <ClosedStatusFields account={currentViewedAccount} form={form} />;
    }
  };

  const validateTWandCWReason = (_, value) => {
    const statusCode = form.getFieldValue(FORM_FIELD.STATUS_CODE);
    const isTieredWatch = statusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH;
    const isCardWatch = statusCode === ACCOUNT_STATUS_CODE.CARD_WATCH;

    const bypassReasons = [
      TIERED_WATCH_REASON.ADS_II_BYPASS,
      CARD_WATCH_REASON.FRAUD_ALERT_BYPASS,
    ];

    return new Promise((resolve, reject) => {
      if (!isTieredWatch && !isCardWatch) {
        return resolve();
      }
      if (bypassReasons.includes(value) && !canApplyTW15orCW20) {
        return reject(`Requires Super User, FDI or FDR privilege`);
      }
      if (!isCardWatch && !agent?.isSuperUser) {
        return reject(`Requires Super User privilege`);
      }
      resolve();
    });
  };

  const validateFraudulentClosedReason = (_, value) => {
    return new Promise((resolve, reject) => {
      if (
        FRAUDLENT_CLOSED_REASONS.includes(value) &&
        !canApplyFraudClosedStatus
      ) {
        return reject(`Requires Fraud Ops, SAH, or S3 privilege`);
      }
      resolve();
    });
  };

  useEffect(() => {
    form.setFieldValue(FORM_FIELD.APPLY_TO_ALL_ACCOUNTS, isMultiAccount);
  }, [isMultiAccount]);

  return (
    <Modal
      {...modalProps}
      title="Apply Account Fraud Status"
      okText="Apply Account Status"
      onOk={handleSubmit}
      onCancel={closeModal}
      confirmLoading={operationsLoading}
      okButtonProps={{
        disabled: submissionSuccess || blockingStatusMessage,
      }}
    >
      <div style={{ position: 'relative' }}>
        <SectionMessage
          data-testid="status-submission-success-message"
          status={STATUS.SUCCESS}
          size={SIZE.SM}
          text="Account Status Set"
          cover={true}
          visible={submissionSuccess}
        ></SectionMessage>
        <Flex vertical={true} gap="var(--spacing-lg)">
          <Form
            form={form}
            name="applyFraudStatusForm"
            layout="vertical"
            initialValues={DEFAULT_STATUS_FORM_VALUES}
            disabled={operationsLoading}
          >
            <StatusDetailsGrid>
              <Form.Item
                label="Status"
                name={FORM_FIELD.STATUS_CODE}
                rules={[
                  {
                    required: true,
                    message: `Please select a category.`,
                  },
                ]}
              >
                <Select
                  data-testid="status-select"
                  placeholder={`Select a Status`}
                  options={filteredStatusOptions}
                ></Select>
              </Form.Item>

              <Form.Item
                label="Reason"
                name={FORM_FIELD.REASON_CODE}
                rules={[
                  {
                    required: true,
                    message: `Please select a reason.`,
                  },
                  {
                    validator: validateTWandCWReason,
                  },
                  {
                    validator: validateFraudulentClosedReason,
                  },
                ]}
              >
                <Select
                  data-testid="reason-select"
                  placeholder={`Select a Reason`}
                  options={statusReasons}
                ></Select>
              </Form.Item>

              {open && renderStatusFields()}

              {isMultiAccount ? (
                <Form.Item
                  name={FORM_FIELD.APPLY_TO_ALL_ACCOUNTS}
                  valuePropName="checked"
                  style={{
                    gridColumn: '1/-1',
                    marginBottom: 0,
                  }}
                >
                  <Checkbox data-testid="apply-to-all-accounts-checkbox">
                    Apply status to all accounts.
                  </Checkbox>
                </Form.Item>
              ) : null}

              <List
                header={
                  <Typography.Text strong>Applying status to:</Typography.Text>
                }
                bordered
                size="small"
                dataSource={
                  isMultiAccount && applyToAllAccounts
                    ? accounts
                    : [currentViewedAccount]
                }
                style={{
                  gridColumn: '1/-1',
                  marginBottom: 'var(--spacing-md)',
                }}
                renderItem={(account) => {
                  return (
                    <List.Item>
                      <Typography.Text>
                        {getCardDisplayName(account)}
                      </Typography.Text>
                    </List.Item>
                  );
                }}
              />
            </StatusDetailsGrid>
            {applyCDF10 ? (
              <Alert
                banner
                type="info"
                message={
                  <span>
                    <strong>CDF10</strong> will automatically be applied to the
                    above listed accounts when the selected status is applied.
                  </span>
                }
              />
            ) : null}

            {removingBypassStatus ? (
              <Alert
                banner
                type="info"
                message={
                  <span>
                    <strong>{removingBypassStatus}</strong> will automatically
                    be removed from the above listed accounts when the selected
                    status is applied.
                  </span>
                }
              />
            ) : null}

            {showStatusError ? (
              <Alert
                banner
                type="error"
                message={`Failed to set account status. Please try submitting again.`}
              ></Alert>
            ) : null}

            {showAttributeError ? (
              <Alert
                banner
                type="error"
                message={`Failed to set ${ACCOUNT_ATTRIBUTE.CDF10} attribute. Please try submitting again.`}
              ></Alert>
            ) : null}

            {statusOverrideWarning && !blockingStatusMessage ? (
              <Form.Item
                name={FORM_FIELD.OVERWRITE_STATUS}
                valuePropName="checked"
                style={{ gridColumn: '1/-1' }}
                rules={[
                  {
                    message: 'Please acknowledge status replacement',
                    validator: (_, checked) => {
                      return new Promise((resolve, reject) => {
                        if (!checked) {
                          reject();
                        }
                        resolve();
                      });
                    },
                  },
                ]}
              >
                <Checkbox data-testid="overwrite-warning-acknowledgement">
                  {statusOverrideWarning}
                </Checkbox>
              </Form.Item>
            ) : null}
          </Form>
        </Flex>
      </div>
    </Modal>
  );
};

export default ApplyStatusModal;
