import { gql } from '@apollo/client';

const FRAUD_CASES = gql`
  query FraudCases($customerId: String, $applicationId: String) {
    fraudCasesV2(customerId: $customerId, applicationId: $applicationId) {
      caseHash
      created
      details {
        action
        actor
        evidence {
          category
          evidenceType
        }
        reasons {
          category
          name
        }
        timestamp
      }
      status
      type
      updated
    }
  }
`;

const FRAUD_CASES_SIMPLE = gql`
  query FraudCases($customerId: String, $applicationId: String) {
    fraudCasesV2(customerId: $customerId, applicationId: $applicationId) {
      caseHash
      status
      type
    }
  }
`;

const CASE_OPTIONS = gql`
  query FraudCaseOptions {
    caseOptionsV2 {
      fraudType
      product
      state
      reason {
        notifications
        other
        reports
      }
      evidence {
        any
        ato
        behaviors
        caseStatus
        coreIdentity
        external
        linkingSigns
        miscellaneous
        payments
      }
    }
  }
`;

const ACTION_OPTIONS = gql`
  query FraudActionOptions {
    actionOptionsV2 {
      type {
        caseActions
        caseStatus
      }
      reason {
        notifications
        other
        reports
      }
      evidence {
        any
        ato
        behaviors
        caseStatus
        coreIdentity
        external
        linkingSigns
        miscellaneous
        payments
      }
    }
  }
`;

export { FRAUD_CASES, FRAUD_CASES_SIMPLE, CASE_OPTIONS, ACTION_OPTIONS };
