import { Flex, Skeleton } from 'antd';

import LocationContainer from 'components/LocationContainer/LocationContainer';

const ClientMetaSkeleton = ({ children, active, loading }) => {
  return loading ? (
    <Flex
      data-testid="client-meta-skeleton"
      gap="var(--spacing-lg)"
      vertical={true}
    >
      <Skeleton
        active={active}
        title={{ width: '80%', style: { marginBottom: 0 } }}
        paragraph={false}
      ></Skeleton>
      <LocationContainer>
        <Skeleton
          active={true}
          title={false}
          paragraph={{ rows: 2, style: { marginBottom: 0 } }}
        ></Skeleton>
      </LocationContainer>
      <Skeleton active={true} title={false} paragraph={{ rows: 6 }}></Skeleton>
    </Flex>
  ) : (
    children
  );
};

export default ClientMetaSkeleton;
