import { Typography } from 'antd';
import {
  RightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { makeVar, useReactiveVar } from '@apollo/client';

import { COLORS } from 'utilities/styles';

const { Text } = Typography;

export const createReactiveState = (
  initialializeState = () => {},
  config = {},
) => {
  const stateVar = makeVar(initialializeState());

  const resetState = () => {
    const freshState = initialializeState();
    const { onReset } = config;
    if (onReset) {
      onReset(stateVar(), freshState);
    }
    stateVar(freshState);
  };

  const setPropertyValue = (key, value) => {
    const currentState = stateVar();
    stateVar({
      ...currentState,
      [key]: value,
    });
  };

  const setPropertyValues = (map) => {
    const currentState = stateVar();
    stateVar({
      ...currentState,
      ...map,
    });
  };

  const createPropertySetter = (key) => {
    return (value) => {
      setPropertyValue(key, value);
    };
  };

  const useState = () => {
    const state = useReactiveVar(stateVar);
    return {
      ...state,
    };
  };

  return {
    createPropertySetter,
    resetState,
    stateVar,
    setPropertyValue,
    setPropertyValues,
    useState,
  };
};

/**
 * Step helpers
 */
export const getStepProperties = (initialStep) => {
  return {
    currentStep: initialStep,
    previousStep: undefined,
    steps: [initialStep],
    stepIndex: 0,
  };
};

export const createStepHandlers = (reactiveState, availableSteps = []) => {
  const goToPreviousStep = () => {
    const currentState = reactiveState.stateVar();
    const { steps } = currentState;
    if (steps.length > 1) {
      const stepsUpdate = [...steps];
      stepsUpdate.pop();
      const stepIndex = stepsUpdate.length - 1;

      reactiveState.setPropertyValues({
        steps: stepsUpdate,
        currentStep: stepsUpdate[stepIndex],
        previousStep: stepsUpdate[stepIndex - 1],
        stepIndex,
      });
    }
  };

  const setNextStep = (step) => {
    if (step && availableSteps.includes(step)) {
      const currentState = reactiveState.stateVar();
      const stepsUpdate = [...currentState.steps, step];
      const stepIndex = stepsUpdate.length - 1;

      reactiveState.setPropertyValues({
        ...currentState,
        steps: stepsUpdate,
        stepIndex,
        currentStep: stepsUpdate[stepIndex],
        previousStep: stepsUpdate[stepIndex - 1],
      });
    }
  };

  return {
    goToPreviousStep,
    setNextStep,
  };
};

/**
 *
 * @returns Submission helpers
 */

export const getSubmissionProperties = () => {
  return {
    submissionCompleted: false,
    submissionInitiated: false,
  };
};

export const createSubmissionHandlers = (reactiveState) => {
  const setSubmissionInitiated = reactiveState.createPropertySetter(
    'submissionInitiated',
  );
  const setSubmissionCompleted = reactiveState.createPropertySetter(
    'submissionCompleted',
  );

  return { setSubmissionInitiated, setSubmissionCompleted };
};

/**
 * Address helpers
 */
export const getAddressReviewProperties = () => {
  return {
    addressIsCurrent: undefined,
    addressUpdated: false,
    initialAddressInfo: undefined,
  };
};

export const createAddressReviewHandlers = (reactiveState) => {
  const setAddressIsCurrent =
    reactiveState.createPropertySetter('addressIsCurrent');
  const setAddressUpdated =
    reactiveState.createPropertySetter('addressUpdated');
  const setInitialAddressInfo = (value) => {
    const currentState = reactiveState.stateVar();
    if (!currentState.initialAddressInfo) {
      reactiveState.setPropertyValues({
        initialAddressInfo: value,
      });
    }
  };

  return { setAddressIsCurrent, setAddressUpdated, setInitialAddressInfo };
};

/**
 * Funding Account
 */
export const getFundingAccountProperties = () => {
  return {
    fundingAccount: {
      id: undefined,
      name: undefined,
    },
  };
};

export const createFundingAccountHandlers = (reactiveState = {}) => {
  const { setPropertyValue } = reactiveState;

  const setFundingAccount = (id, name) => {
    setPropertyValue('fundingAccount', { id, name });
  };

  return { setFundingAccount };
};

/**
 * Step Labels
 */
const renderStep = (icon, title, secondary) => {
  return (
    <Text>
      {icon}
      {` ${title}`}
      {secondary ? (
        <>
          <br />
          <Text type="secondary" italic>
            {secondary}
          </Text>
        </>
      ) : undefined}
    </Text>
  );
};

export const renderPendingStep = (title) => {
  return (
    <Text type="secondary">
      <RightOutlined /> {title}
    </Text>
  );
};

export const renderPositiveStep = (title, secondary) => {
  return renderStep(
    <CheckCircleOutlined style={{ color: COLORS.green1 }} />,
    title,
    secondary,
  );
};

export const renderWarningStep = (title, secondary) => {
  return renderStep(
    <ExclamationCircleOutlined style={{ color: COLORS.mustard1 }} />,
    title,
    secondary,
  );
};

export const renderUnableToProceed = (title, secondary) => {
  return renderStep(
    <CloseCircleOutlined style={{ color: COLORS.mustard1 }} />,
    title,
    secondary,
  );
};
