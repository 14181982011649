import { useEffect, useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Form, Select, Space } from 'antd';

import { COLORS } from 'utilities/styles';

const { Option } = Select;

export default function FraudNewCaseEvidenceFormRow({ name, data, onRemove }) {
  const [options, setOptions] = useState([]);
  const [category, selectedCategory] = useState('coreIdentity');

  useEffect(() => {
    const evidences = data?.actionOptionsV2?.evidence[category];
    setOptions([...evidences]?.sort());
  }, [data, category]);

  return (
    <Space key={name} align="baseline">
      <Form.Item
        name={[name, 'category']}
        style={{ width: 255 }}
        data-testid="evidence-type"
        rules={[{ required: true, message: 'Please select category.' }]}
      >
        <Select
          onChange={(value) => {
            selectedCategory(value);
          }}
        >
          <Option value="coreIdentity">Core Identity</Option>
          <Option value="caseStatus">Case Status</Option>
          <Option value="any">Any</Option>
          <Option value="payments">Payments</Option>
          <Option value="behaviors">Behaviors</Option>
          <Option value="miscellaneous">Miscellaneous</Option>
          <Option value="linkingSigns">Linking Signs</Option>
          <Option value="ato">ATO</Option>
          <Option value="external">External</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[name, 'evidenceType']}
        data-testid="evidence-subtype"
        style={{ width: 325 }}
        rules={[{ required: true, message: 'Please select an evidence.' }]}
      >
        <Select placeholder="Select an evidence">
          {options?.map((evidence) => (
            <Option key={evidence} value={evidence}>
              {evidence}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <MinusCircleOutlined
        style={{ color: COLORS.blue1 }}
        onClick={() => onRemove(name)}
        data-testid="deleteEvidence"
      />
    </Space>
  );
}
