import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Checkbox,
  Flex,
  Form,
  List,
  Modal,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { CANCEL_SETTLEMENT_OFFER } from 'mutations/settlements';
import { SIZE, STATUS } from 'utilities/constants';

import { resetPaymentPlanState, usePaymentPlanState } from './paymentPlanState';

const { Text, Paragraph } = Typography;
const { Item } = Form;

const CancelPlanModal = ({ hide, ...modalProps }) => {
  const { offer } = usePaymentPlanState();
  const [cancelPlanForm] = Form.useForm();

  const [cancelPlanSuccess, setCancelPlanSucess] = useTimeoutValue(false, 3000);
  const [cancelPlanErrorMsg, setCancelPlanErrorMsg] = useState('');

  const [cancelSettlementOffer, cancelSettlementOfferMutation] = useMutation(
    CANCEL_SETTLEMENT_OFFER,
    {
      onCompleted: () => {
        setCancelPlanSucess(true);
        setTimeout(closeModal, 2500);
      },
      onError: () => {
        setCancelPlanErrorMsg('Failed to cancel program. Please try again.');
      },
    },
  );

  const closeModal = () => {
    resetPaymentPlanState();
    setCancelPlanSucess(false);
    setCancelPlanErrorMsg('');
    hide();
  };

  const onConfirm = async () => {
    await cancelPlanForm.validateFields();

    cancelSettlementOffer({ variables: { offerId: offer.offerId } });
  };

  const disclosures = [
    'Any scheduled payments associated with this plan will be canceled.',
    'The progress made towards settling the account for this offer amount will be lost, but previous payments are still deducted from the account’s outstanding balance.',
    'Another offer can be claimed at a future date once any pending payments have cleared, and the offer will be based off the new outstanding balance.',
  ];

  return (
    <Modal
      {...modalProps}
      title="Cancel Plan Confirmation"
      width={600}
      footer={[]}
      onCancel={() => {
        cancelPlanForm.resetFields();
        hide();
      }}
      destroyOnClose={true}
    >
      <Flex vertical={true}>
        <Form
          name="cancelPlanDisclosures"
          form={cancelPlanForm}
          layout="vertical"
          onFinish={onConfirm}
        >
          <Paragraph>
            <Text type="secondary">
              Please confirm that the customer understands the following:
            </Text>
          </Paragraph>
          <List
            itemLayout="horizontal"
            data-testid="disclosures-list"
            size="small"
            dataSource={disclosures}
            renderItem={(item) => (
              <List.Item
                style={{
                  borderBottomColor: 'transparent',
                  borderBottomWidth: 0,
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                }}
              >
                <List.Item.Meta avatar={<InfoCircleOutlined />} title={item} />
              </List.Item>
            )}
          />
          <Item
            name="cancelDisclosures"
            valuePropName="checked"
            rules={[
              {
                /* eslint-disable no-unused-vars */
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (!value) {
                      reject();
                    }
                    resolve();
                  });
                },
                message:
                  'Customer must understand and accept the terms to cancel their offer.',
              },
            ]}
            style={{ marginBottom: '0px' }}
          >
            <Checkbox style={{ marginTop: 'var(--spacing-sm)' }}>
              I have confirmed that the customer understands the terms to cancel
              the offer.
            </Checkbox>
          </Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: 'right', marginTop: 'var(--spacing-sm)' }}
            loading={cancelSettlementOfferMutation.loading}
          >
            Confirm
          </Button>
        </Form>
        <SectionMessage
          data-testid="cancel-plan-success"
          status={STATUS.SUCCESS}
          size={SIZE.SM}
          text={'Program successfully canceled'}
          cover={true}
          visible={cancelPlanSuccess}
        />
        {cancelPlanErrorMsg && (
          <Alert
            banner
            type="error"
            message={cancelPlanErrorMsg}
            style={{ marginTop: 'var(--spacing-sm)' }}
          />
        )}
      </Flex>
    </Modal>
  );
};

export default CancelPlanModal;
