import FundingAccountStep from '../FundingAccountStep';
import {
  RECOVERIES_STEPS,
  setFundingAccount,
  setNextStep,
  useRecoveriesState,
} from './recoveriesState';

const FundingAccountStepWrapper = () => {
  const { fundingAccount } = useRecoveriesState();

  const handleContinue = async () => {
    setNextStep(RECOVERIES_STEPS.PAYMENT_PLAN_DETAILS);
  };

  return (
    <FundingAccountStep
      fundingAccount={fundingAccount}
      setFundingAccount={setFundingAccount}
      onContinue={handleContinue}
    />
  );
};

export default FundingAccountStepWrapper;
