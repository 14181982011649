export const topicCategories = [
  { label: 'Information', value: 'Information' },
  { label: 'Updates', value: 'Updates' },
  { label: 'Push Calls', value: 'PushCalls' },
  { label: 'Fraud', value: 'Fraud' },
  { label: 'Payments', value: 'Payments' },
  { label: 'FDI', value: 'FDI' },
  { label: 'Pre-Issuance', value: 'PreIssuance' },
  { label: 'Support Queue', value: 'SupportQueue' },
  { label: 'Tech Issues', value: 'TechIssues' },
  { label: 'Other', value: 'Other' },
];

export const topics = {
  Fraud: [
    'Claim Inquiry',
    'Digital Wallet',
    'Dispute Charge',
    'Extended Fraud Alert',
    'Inquire About Declined Transaction',
    'Inquire About Application Fraud Block',
    'Inquire About Falcon Block',
    'Inquire About Fraud Block - Other',
    'Lock/Unlock Card',
    'Report Application Fraud',
    'Report Lost or Stolen Card',
    'Report Unauthorized Transaction',
    'Other',
  ],
  Information: [
    'Balance or Credit Inquiry',
    'Card Arrival Time',
    'Cash Advance Policy',
    'Change in Terms',
    'CLIP Inquiry',
    'Credit Balance Refund',
    'Credit Reporting',
    'Navigate Site',
    'Privacy Policy',
    'Rates and Terms',
    'Reissue',
    'Rewards',
    'Other',
  ],
  Payments: [
    'Adding/Removing Bank Account',
    'Cancel/Refund Payment',
    'Inquire About Due',
    'Inquire About Payment Process',
    'Make a Payment',
    'Manage Recurrent Payments',
    'Payment Investigation',
    'Understand OTB Hold',
    'Understand Returned Payments',
    'Other',
  ],
  PreIssuance: [
    'Navigate Site and/or Application',
    'Provide Proof of Income',
    'Status of Application',
    'Understand Declined Application',
  ],
  PushCalls: ['Push Collections / Recoveries Calls'],
  SupportQueue: [
    'Admin Reset',
    'CLIP Inquiry',
    'Expedited Card',
    'Fee Waiver',
    'Fraud Account Assistance',
    'Full SSN Search',
    'Hardship',
    'Okta Inquiry',
    'OTB Release',
    'Payment issues/inquiry',
    'Reassurance',
    'Statement/Transaction Inquiries',
    'Stripe Inquiry',
    'System Advice (ZD, Tableau, MC, TCS)',
    'System Assistance (Idology, TCS, MC)',
    'Talk-off Assistance',
    'Wiki Assistance',
    'Zendesk Submission',
    'Other',
  ],
  TechIssues: ['App', 'Web'],
  Updates: [
    'Activate Card',
    'Add Authorized User',
    'Bankruptcy',
    'Billing Preferences (Paper / Paperless)',
    'Close Account',
    'Debt Consolidation',
    'Deceased',
    'Due Date Changes',
    'Fee Waiver',
    'Hardship',
    'Password Troubleshoot/Reset',
    'PIN Update',
    'Replacement Card',
    'Request Better APR/AMF/Rewards',
    'Travel Notice',
    'Update Personal/Contact Information',
    'Other',
  ],
  FDI: [
    'Falcon Block-Valid Trans',
    'Falcon Block-Decline Fraud',
    'Report L/S - No Fraud',
    'Declined Trans-No Fraud',
    'Activate Card',
    'Lock/Unlock Card-NOT Falcon Block',
    'No Claim-Acct Update/Info',
    'No Claim-Transferred',
    'No Claim-Other',
    'New Claim-Unauthorized Charge',
    'New Claim-Dispute Charge',
    'No Claim-App Fraud/ATO',
    'Existing-Add Transaction',
    'Existing-Remove Transaction',
    'Existing-Withdraw Claim',
    'Existing-Other',
    'Existing-Docs requested',
    'Existing-Status',
    'Dispute-Pending Trans',
    'Replacement Card',
    'Digital Wallet',
  ],
  Other: [
    'Callback 3rd Party Answer',
    'Callback No Answer',
    'Caller Disconnected',
    'Collections/Recoveries Transfer',
    'Credit Protection',
    'Dead Air',
    'Kudos (Escalations Only)',
    'Onshore Agent Transfer',
    'Spanish Transfer',
    'Other',
  ],
};
