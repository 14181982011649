import { gql } from '@apollo/client';

const GET_THIRD_PARTY_INFO = gql`
  query GetThirdPartyInfo($accountId: String!) {
    getAccountPreferences(accountId: $accountId) {
      id
      accountId
      customerId
      thirdPartyName
      thirdPartyEmail
      customerOptIn
    }
  }
`;

export { GET_THIRD_PARTY_INFO };
