import { gql } from '@apollo/client';

/** @deprecated marked to be removed once we rollout SLS-1790 */
const CLIP_HISTORY = gql`
  query ClipResultsQuery($customerId: String!, $accountId: Long!) {
    clipResults(customerId: $customerId, accountId: $accountId) {
      policyName
      outcome
      dateEvaluated
      dateExecuted
      creditLineBeforeClip
      creditLineAfterClip
      isInGoodStanding
      hasPassedNoDelinquenciesCheck
      hasPassedNotOverlimitCheck
      hasPassedAtpCheck
      evaluationStatementNumber
    }
  }
`;

const CLIP_ELIGIBILITY = gql`
  query ClipEligibilityCriteria($customerId: String!, $accountId: Long!) {
    clipEligibilityCriteria(accountId: $accountId, customerId: $customerId) {
      estimatedNextEvaluationDate
      clipExperienceEnrollmentType
      clipExperienceEligible
      clipExperienceEnrolled
      currentStatementNumber
      spendBonusEnrollment
      evaluationResults {
        creditLineAfterClip
        creditLineBeforeClip
        dateEvaluated
        dateExecuted
        delinquencyDate
        evaluationStatementNumber
        hasPassedActiveLast6MonthsCheck
        hasPassedDelinquenciesCheck
        hasPassedNeverActiveCheck
        hasPassedOverlimitCheck
        isInGoodStanding
        outcome
        policyName
        ineligibilityReason
      }
    }
  }
`;

export { CLIP_ELIGIBILITY, CLIP_HISTORY };
