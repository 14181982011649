import { makeVar, useReactiveVar } from '@apollo/client';

const isSidebarOpenVar = makeVar(false);

const toggleSidebarOpen = () => {
  isSidebarOpenVar(!isSidebarOpenVar());
};

const useSidebar = () => {
  const sidebarOpen = useReactiveVar(isSidebarOpenVar);
  return { sidebarOpen, toggleSidebarOpen };
};

export default useSidebar;
