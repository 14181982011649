import dayjs from 'dayjs';
import { Card, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import { getDefaultPayments } from 'utilities/helpers';
import { formatDate } from 'utilities/datesAndTimes';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';

import { useRecoveriesState } from './recoveriesState';

const { Text, Paragraph } = Typography;

const EstimatedPayments = ({ loadingQuery }) => {
  const { acceptedOffer, paymentCadence, paymentDates, pifPlanDuration } =
    useRecoveriesState();

  const paymentPlan = paymentCadence?.paymentPlanSchedule;
  const expirationDate = paymentPlan?.[paymentPlan?.length - 1]?.date || '';

  const isPifOffer = acceptedOffer?.offerType === SETTLEMENT_OFFER_TYPES.PIF;
  const balanceText = isPifOffer ? 'Balance' : 'Settlement Balance';

  const allPaymentPlanDetailsFilled = isPifOffer
    ? !!(paymentCadence?.cadence && paymentDates?.startDate && pifPlanDuration)
    : !!(paymentCadence?.cadence && paymentDates?.startDate);

  const durationMonths = pifPlanDuration
    ? pifPlanDuration
    : acceptedOffer?.durationMonths;

  const estimatedPayments = getDefaultPayments(
    acceptedOffer.balance,
    dayjs(),
    dayjs().add(durationMonths, 'month'),
    durationMonths,
  );

  const startDateText = (
    <>
      <Paragraph>
        {balanceText}: ${acceptedOffer?.balance}
        <br />
        <strong>
          Plan Due Date: {formatDate(dayjs(expirationDate).add(1, 'day'))}
        </strong>
        <br />
        --
      </Paragraph>
      <Paragraph>
        <CalendarOutlined /> <strong>With a start date of today</strong>
        <br />
        {paymentCadence.paymentPlanSchedule?.length
          ? ''
          : ' the customer would owe about:'}
      </Paragraph>
    </>
  );

  const estimatedPaymentsText = (
    <Text>
      {startDateText}
      <strong>${estimatedPayments.monthly} | monthly</strong>, over{' '}
      {durationMonths} months
      <br />
      <strong>${estimatedPayments.bimonthly} | every 2 weeks</strong>, over{' '}
      {durationMonths} months
      <br />
      <strong>${estimatedPayments.weekly} | weekly</strong>, over{' '}
      {durationMonths} months
    </Text>
  );

  const calculatedPaymentsText = (
    <Text>
      {startDateText}
      <QueryBoundary
        query={{ loading: loadingQuery, called: true, data: {} }}
        skeleton={{
          title: false,
          paragraph: { rows: 1 },
        }}
      >
        {paymentCadence?.cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME ? (
          <strong>
            Offer consists of one payment of{' '}
            {paymentCadence?.paymentPlanSchedule?.[0]?.amount ?? '--'}
          </strong>
        ) : (
          <>
            <strong>
              Offer consists of {paymentCadence?.paymentPlanSchedule?.length}{' '}
              payments of about:
            </strong>
            <br />
            {paymentCadence?.paymentPlanSchedule?.[0]?.amount ?? '--'} | over{' '}
            {durationMonths} months
          </>
        )}
      </QueryBoundary>
    </Text>
  );

  return (
    <Card type="inner" title="Estimated Payments Calculator">
      {!allPaymentPlanDetailsFilled
        ? estimatedPaymentsText
        : calculatedPaymentsText}
    </Card>
  );
};

export default EstimatedPayments;
