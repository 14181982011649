/*
    Latest color reference
    https://www.figma.com/proto/wVz0YgLZIVsh9WJAFZSlZf/Compass-%7C-Design-System?page-id=2584%3A5701&node-id=2586%3A6847&viewport=314%2C48%2C0.15&scaling=scale-down-width&starting-point-node-id=2255%3A4885&show-proto-sidebar=1
*/
export const COLORS = {
  black: '#000000',

  blue1: '#005CC4',
  blue2: '#65A8FF',
  blue3: '#B8D3F4',
  blue4: '#DBF2FF',

  brick1: '#C44A17',
  brick2: '#FF9987',
  brick3: '#FCD4C2',
  brick4: '#FFF8F4',

  green1: '#007459',
  green2: '#60CDA9',
  green3: '#B5E5DA',
  green4: '#D9F2E8',

  grey1: '#535353',
  grey2: '#898989',
  grey3: '#D6DAD7',
  grey4: '#ECECEC',

  haze1: '#E8DFDA',
  haze2: '#F5F2EB',
  haze3: '#F9F8F7',
  haze4: '#FFFFFF', // white

  indigo1: '#032C68',
  indigo2: '#5B82B8',
  indigo3: '#A5CAFF',
  indigo4: '#E4EFFF',

  ink1: '#122C26',
  ink2: '#677370',
  ink3: '#CBD8D6',
  ink4: '#EEF4F2',

  mustard1: '#8F5907',
  mustard2: '#CAS852',
  mustard3: '#E8DAB5',
  mustard4: '#FEF1C7',

  orange1: '#F98638',
  orange2: '#FFA86E',
  orange3: '#FADEC9',
  orange4: '#FEF3EB',

  pine1: '#072D26',
  pine2: '#64958B',
  pine3: '#0BAD2CD',
  pine4: '#C5E7E0',

  purple1: '#6B297D',
  purple2: '#A778B1',
  purple3: '#EEC7F8',
  purple4: '#6EDD6F2',

  red1: '#b50b13',
  red2: '#CB545A',
  red3: '#E9B6B8',
  red4: '#F8E7E7',

  teal1: '#025C62',
  teal2: '#59BAC0',
  teal3: '#BFFBFF',
  teal4: '#F3FAFA',

  yellow1: '#F8BA35',
  yellow2: '#F7D479',
  yellow3: '#F7E8C1',
  yellow4: '#FEF9EC',
};

export const COLORS_CALABRIO = {
  blue: '#1d91ff',
  gray: '#c0c0c0',
  green: '#31c100',
  orange: '#fe8080',
  peach: '#ffc17f',
  pink: '#fa81ff',
  purple: '#daaae6',
  red: '#ff0000',
  teal: '#008080',
  yellow: '#ffd600',
};
