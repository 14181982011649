import { InfoOutlined } from '@ant-design/icons';
import { Modal, Table, Tooltip, Typography } from 'antd';

import QueryResult from 'components/QueryResult/QueryResult';
import useClipEligibility from 'hooks/useClipEligibility';

const overview = [
  {
    dataIndex: 'category',
    key: 'category',
  },
  {
    dataIndex: 'value',
    key: 'value',
  },
];

const { Paragraph } = Typography;

const getSummaryInfo = (data = {}) => {
  const { clipResults, clipEligibilityCriteria = {} } = data;

  const {
    estimatedNextEvaluationDate,
    clipExperienceEnrollmentType,
    clipExperienceEligible,
    evaluationResults,
    currentStatementNumber,
    spendBonusEnrollment,
  } = clipEligibilityCriteria;

  return [
    {
      // SLS-1793
      key: 'next-evaluation-date',
      category: 'Next Evaluation Date',
      value: `${estimatedNextEvaluationDate}`,
    },
    {
      key: 'current-statement-number',
      category: 'Current Statement Number',
      value: `${currentStatementNumber}`,
    },
    {
      key: 'has-account-been-evaluated',
      category: 'Has Account Been Evaluated for a CLIP?',
      value: `${evaluationResults?.length > 0 || clipResults?.length > 0}`,
    },
    {
      // SLS-1859
      key: 'can-see-s7-progress-tracker',
      category: 'S7 Experience Type',
      value: `${clipExperienceEnrollmentType}`,
    },
    {
      key: 'enrolled-in-spend-bonus',
      category: 'Enrolled in Spend Bonus?',
      value: `${spendBonusEnrollment}`,
    },
  ];
};

const columns = [
  {
    dataIndex: 'category',
    key: 'category',
  },
  {
    dataIndex: 'value',
    key: 'value',
  },
];

const getResultsInfo = (data = {}, showTalkoff, setShowTalkoff) => {
  const {
    hasPassedActiveLast6MonthsCheck,
    hasPassedNoDelinquenciesCheck,
    hasPassedDelinquenciesCheck,
    hasPassedNotOverlimitCheck,
    hasPassedNeverActiveCheck,
    evaluationStatementNumber,
    hasPassedOverlimitCheck,
    creditLineBeforeClip,
    creditLineAfterClip,
    isInGoodStanding,
    delinquencyDate,
    dateEvaluated,
    dateExecuted,
    policyName,
    outcome,
    ineligibilityReason,
  } = data;

  return [
    {
      key: 'policy-name',
      category: 'Policy Name',
      value: `${policyName}`,
    },
    {
      key: 'outcome',
      category: 'Outcome',
      value: `${outcome}`,
    },
    {
      key: 'date-evaluated',
      category: 'Date Evaluated',
      value: `${dateEvaluated}`,
    },
    {
      key: 'date-executed',
      category: 'Date Executed',
      value: `${dateExecuted}`,
    },
    {
      key: 'credit-line-before-clip',
      category: 'Credit Line Before Clip',
      value: `${creditLineBeforeClip}`,
    },
    {
      key: 'credit-line-after-clip',
      category: 'Credit Line After Clip',
      value: `${creditLineAfterClip}`,
    },
    {
      key: 'in-good-standing',
      category: 'In good standing (not BK/Fraud/Closed/Deceased)',
      value: `${isInGoodStanding}`,
    },
    {
      // SLS-1932
      key: 'passed-never-active-check',
      category: 'Passed Never Active Check',
      value: `${hasPassedNeverActiveCheck}`,
    },
    {
      // SLS-1933
      key: 'passed-inactive-last-6-months-check',
      category: 'Passed Inactive Last 6 Months Check',
      value: `${hasPassedActiveLast6MonthsCheck}`,
    },
    {
      // SLS-1861
      key: 'passed-no-late-payments-check',
      category: 'Passed No Late Payments Check',
      value: (
        <div>
          <span>
            {`${hasPassedNoDelinquenciesCheck ?? hasPassedDelinquenciesCheck}` +
              `${delinquencyDate ? ` (${delinquencyDate})` : ''}`}
          </span>
          {(!hasPassedNoDelinquenciesCheck || !hasPassedDelinquenciesCheck) &&
            delinquencyDate && (
              <Tooltip title="Past due date may not be up-to-date in backdated payment scenarios">
                <InfoOutlined
                  data-testid="no-late-payments-backdate-warning-tooltip"
                  style={{
                    color: '#120887',
                    border: '1px solid #120887',
                    fontSize: 11,
                    borderRadius: 50,
                    padding: 2,
                    marginLeft: 6,
                  }}
                />
              </Tooltip>
            )}
        </div>
      ),
    },
    {
      key: 'passed-not-overlimit-check',
      category: 'Passed Not Overlimit Check',
      value: `${hasPassedNotOverlimitCheck ?? hasPassedOverlimitCheck}`,
    },
    {
      key: 'statement-number-at-evaluation',
      category: 'Statement Number at Evaluation',
      value: `${evaluationStatementNumber}`,
    },
    {
      key: 'ineligibility-reason',
      category: 'Ineligibility Talkoff',
      value: ineligibilityReason ? (
        <span>
          <Paragraph
            style={{ width: 400, margin: 0 }}
            ellipsis={{
              rows: 2,
              expandable: 'collapsible',
            }}
          >
            {ineligibilityReason}
          </Paragraph>
        </span>
      ) : undefined,
    },
  ];
};

const sortByDateEvaluated = (a, b) =>
  new Date(b?.dateEvaluated) - new Date(a?.dateEvaluated);

const filterUndefinedItems = (item) =>
  item?.value !== 'undefined' && item?.value !== undefined;

const mapNullItems = (item) => {
  return {
    ...item,
    value: item?.value === 'null' ? '-' : item?.value,
  };
};

const ClipEligibilityModal = ({ ...modalProps }) => {
  const { loading, called, error, data, clipResults } = useClipEligibility();

  return (
    <Modal
      {...modalProps}
      centered
      width={800}
      cancelButtonProps={{ hidden: true }}
      title="CLIP Eligibility Criteria"
    >
      <QueryResult loading={loading} error={error} data={data} called={called}>
        <Table
          columns={overview}
          dataSource={getSummaryInfo(data)
            .filter(filterUndefinedItems)
            .map(mapNullItems)}
          bordered={true}
          size="small"
          pagination={false}
          showHeader={false}
        />

        {clipResults.length >= 1 && (
          <>
            <br />
            <Typography.Title level={3}>Clip Results</Typography.Title>
            {[...clipResults].sort(sortByDateEvaluated).map((result, index) => (
              <div key={`clip-result-table-${index}`}>
                <Table
                  columns={columns}
                  dataSource={getResultsInfo(result)
                    .filter(filterUndefinedItems)
                    .map(mapNullItems)}
                  bordered={true}
                  size="small"
                  pagination={false}
                  showHeader={false}
                />
                <br />
                <br />
              </div>
            ))}
          </>
        )}
      </QueryResult>
    </Modal>
  );
};

export default ClipEligibilityModal;
