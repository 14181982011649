import { gql } from '@apollo/client';

const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress(
    $customerId: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $postalCode: String
    $overrideAddressValidation: Boolean
  ) {
    updateCustomer(
      customerId: $customerId
      overrideAddressValidation: $overrideAddressValidation
      updateCustomerRequest: {
        addresses: {
          primaryAddress: {
            street1: $street1
            street2: $street2
            city: $city
            state: $state
            postalCode: $postalCode
          }
        }
      }
    ) {
      id
    }
  }
`;

const UPDATE_CUSTOMER_INFORMATION = gql`
  mutation UpdateCustomerInformation(
    $customerId: String!
    $firstName: String!
    $middleName: String
    $lastName: String!
    $birthDate: String!
    $email: String!
    $phone: String!
    $ssn: String
  ) {
    updateCustomer(
      customerId: $customerId
      updateCustomerRequest: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        birthDate: $birthDate
        contact: { email: $email, primaryPhone: $phone }
        ssn: $ssn
      }
    ) {
      id
    }
  }
`;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation ResetPassword($customerId: String!) {
    resetPassword(customerId: $customerId) {
      passwordResetUrl
    }
  }
`;

const CUSTOMER_PUBLISH_COMMS = gql`
  mutation PublishComms(
    $customerId: String!
    $template: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $accountId: String
    $cardLast4: String
    $productName: String
  ) {
    publishCommsMessageV2(
      customerId: $customerId
      template: $template
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      accountId: $accountId
      cardLast4: $cardLast4
      productName: $productName
    ) {
      success
    }
  }
`;

const UPDATE_CUSTOMER_PREFERENCES = gql`
  mutation UpdateCommunicationPreferences(
    $customerId: String
    $communicationRequest: CommunicationPreferenceInput!
  ) {
    updateCommunicationPreferences(
      customerId: $customerId
      updateCommunicationPreferencesRequest: $communicationRequest
    ) {
      id
    }
  }
`;

const REMOVE_CEASE_AND_DESIST = gql`
  mutation RemoveCeaseAndDesist($customerId: String) {
    deleteCeaseDesistStatus(customerId: $customerId) {
      id
    }
  }
`;

export {
  CUSTOMER_PUBLISH_COMMS,
  REMOVE_CEASE_AND_DESIST,
  SEND_PASSWORD_RESET_EMAIL,
  UPDATE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_INFORMATION,
  UPDATE_CUSTOMER_PREFERENCES,
};
