const prodConfig = {
  AWS_CONNECT_INSTANCE: 'missionlane-connect',
  CI_TOOL_URL: 'https://ci-tool.external.gcp.missionlane.com/cs',
  ENVIRONMENT: 'production',
  FILEPICKER_API_KEY: 'A8uwROM6BQ4iNPL2K7Fv4z',
  GQL_HOST: 'https://cc-api-orc.agent.external.gcp.missionlane.com/graphql',
  GQL_COOKIE_DOMAIN: '.missionlane.com',
  LAUNCH_DARKLY_CLIENT_ID: '61faaa9f62f81c18129471c1',
  OKTA_CLIENT_ID: '0oaat9dvfcBi47l8i357',
  OKTA_REDIRECT_URI: 'https://mission-control.missionlane.com/login/callback',
  SEGMENT_KEY: '10Q7EfqKJyLGZqBOXI44VMlsGk5aD2wi',
  TCS_SSO_URL:
    'https://missionlane.okta.com/home/missionlane_tsystcs_2/0oaaylej6rOaPTfpT357/alnaylp7wpMNkPaQ9357',
  VGS_VAULT_ID: 'tntrscbp87u',
  VGS_ENV: 'live',
  VGS_CNAME: 'mc-vgs.missionlane.com',
  ONBASE_FILE_VAULT_URL: 'https://tntrscbp87u.live.verygoodproxy.com',
};

export default prodConfig;
