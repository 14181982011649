import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ApolloProvider } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';

import client from 'apollo/client';

const GQLApolloProvider = ({ children }) => {
  const { authState } = useOktaAuth();

  if (!authState) {
    return (
      <Spin
        data-testid="loading-spinner"
        indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
        style={{ display: 'block' }}
      />
    );
  } else {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }
};

export default GQLApolloProvider;
