import { gql } from '@apollo/client';

const CREATE_ZENDESK_CASE = gql`
  mutation CreateZendeskCase(
    $email: String!
    $name: String!
    $note: String!
    $caseType: String!
    $cycleDate: String
    $requestedDueDate: String
  ) {
    caseWorkCreateCase(
      email: $email
      name: $name
      message: $note
      caseType: $caseType
      cycleDate: $cycleDate
      requestedDueDate: $requestedDueDate
    ) {
      caseType
      createdTz
      subject
      url
    }
  }
`;

export { CREATE_ZENDESK_CASE };
