import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useQuery } from '@apollo/client';

import { CUSTOMER_OVERVIEW } from 'queries/customer';

const baseUrl = `https://app.fullstory.com/ui/M812D/segments/everyone/people`;

const ButtonLink = ({ email }) => {
  const url = `${baseUrl}:search:(:((UserEmail:==:%22${email}%22)):():():():)/0`;
  return (
    <a href={url} target="fullstory">
      Fullstory
    </a>
  );
};

const FullStoryButton = () => {
  const { customerId } = useParams();
  const {
    loading,
    data,
    error = null,
  } = useQuery(CUSTOMER_OVERVIEW, {
    variables: { customerId },
  });
  const email = data?.customer?.contactInfo?.email || null;
  const enabled = email !== null && error === null;

  return (
    <Button
      data-testid="full-story-button"
      disabled={!enabled}
      loading={loading}
    >
      <ButtonLink email={email} />
    </Button>
  );
};
export default FullStoryButton;
