import { useEffect, useState } from 'react';

//Simplify toggling of temporary values
/**
 *
 * @param {*} defaultValue - The value toggled back to after timeout
 * @param {Number} duration - How long the temporary value sent to "setValue" should be available
 */
const useTimeoutValue = (defaultValue, duration = 3000) => {
  const [tempValue, setTempValue] = useState(null);

  // Only reset vlaue if parent component is still mounted
  useEffect(() => {
    let isMounted = true;
    if (tempValue) {
      setTimeout(() => {
        isMounted && setTempValue(null);
      }, duration);
    }

    return () => (isMounted = false);
  }, [duration, tempValue]);

  return [
    tempValue || defaultValue,
    (temporaryValue) => {
      setTempValue(temporaryValue);
    },
  ];
};

export default useTimeoutValue;
