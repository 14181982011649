import dayjs from 'dayjs';
import { Typography } from 'antd';

import { formatDate } from 'utilities/datesAndTimes';

const { Text } = Typography;

const IntroAndFutureApr = ({ account, introAprDetails, pricingDetails }) => {
  const introAprExpirationDate = introAprDetails?.futureAprStartDate
    ? dayjs(introAprDetails?.futureAprStartDate).subtract(1, 'day').format()
    : null;

  const introAprExpirationText = introAprExpirationDate
    ? `Through ${formatDate(introAprExpirationDate)}`
    : null;
  const introAprExpirationLengthInMonths =
    introAprDetails?.introAprLengthInMonths
      ? `For ${introAprDetails?.introAprLengthInMonths} Months`
      : 'Through --/--/----';

  const introAprStartDateText = introAprDetails?.futureAprStartDate
    ? `Starting ${formatDate(introAprDetails?.futureAprStartDate)}`
    : null;
  const introAprStartLengthInMonths = introAprDetails?.introAprLengthInMonths
    ? `Starting in ${introAprDetails?.introAprLengthInMonths} Months`
    : 'Starting --/--/----';

  const futurePurchaseAprText = introAprDetails?.futureApr ? (
    `${introAprDetails?.futureApr}%`
  ) : (
    <Text type="secondary" italic>
      Pending
    </Text>
  );

  return (
    <div data-testid="intro-apr">
      Introductory Rate - {pricingDetails?.purchaseApr}%{' '}
      {pricingDetails?.purchaseAprType?.toLowerCase()}
      <br />
      <Text type="secondary">
        {introAprExpirationText || introAprExpirationLengthInMonths}
      </Text>
      <br />
      <br />
      Purchase APR - {futurePurchaseAprText}
      <br />
      <Text type="secondary">
        {introAprStartDateText || introAprStartLengthInMonths}
      </Text>
    </div>
  );
};

export default IntroAndFutureApr;
