import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Tooltip, Typography } from 'antd';

import DisplayValue from 'components/DisplayValue/DisplayValue';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useModalControls from 'hooks/useModalControls';
import { CUSTOMER_WITH_FULL_ACCOUNTS } from 'queries/customer';

import { DECEASED_STATUS } from 'utilities/constants';
import EditDeceasedStatusModal from './EditDeceasedStatusModal';

const { Title } = Typography;

const LOADING_SKELETON = {
  title: false,
  paragraph: { rows: 2, width: ['120px', '140px'] },
};

const DeceasedStatus = () => {
  const { customerId } = useParams();
  const editDeceasedStatusModal = useModalControls();
  const [accounts, setAccounts] = useState(false);
  const [deceasedStatus, setDeceasedStatus] = useState(
    DECEASED_STATUS.UNAVAILABLE,
  );

  const customerQuery = useQuery(CUSTOMER_WITH_FULL_ACCOUNTS, {
    variables: { customerId },
    onCompleted: (data) => {
      const creditAccounts = data?.customer?.creditAccounts || [];
      setAccounts(creditAccounts);

      if (!creditAccounts.length) {
        return DECEASED_STATUS.UNAVAILABLE;
      }

      const statusObjects = creditAccounts.map((account) => account.statuses);

      const isDeceased = !!statusObjects.find((statuses) => {
        return statuses.isDeceased;
      });

      let deceasedStatus = DECEASED_STATUS.NOT_DECEASED;
      if (isDeceased) {
        deceasedStatus = DECEASED_STATUS.DECEASED;
      } else {
        const hasPendingDeceasedStatus = statusObjects.find((statuses) => {
          return statuses.raw.find(({ statusCode, reasonCode }) => {
            return statusCode === 'WA' && reasonCode === 'W1';
          });
        });
        if (hasPendingDeceasedStatus) {
          deceasedStatus = DECEASED_STATUS.PENDING_DECEASED;
        }
      }

      return setDeceasedStatus(deceasedStatus);
    },
  });

  const confirmedDeceased = deceasedStatus === DECEASED_STATUS.DECEASED;

  return (
    <div>
      <EditDeceasedStatusModal
        {...editDeceasedStatusModal}
        accounts={accounts}
        deceasedStatus={deceasedStatus}
      />
      <Flex justify="space-between">
        <Title level={5}>Deceased</Title>
        <Tooltip
          title={
            confirmedDeceased
              ? 'Cardholder is confirmed deceased. This status cannot be reversed within Mission Control.'
              : undefined
          }
          placement="topRight"
        >
          <Button
            data-testid="edit-deceased-status-button"
            type="link"
            icon={<EditOutlined style={{ fontSize: '1.5rem' }} />}
            onClick={editDeceasedStatusModal.show}
            disabled={confirmedDeceased}
            size="small"
          ></Button>
        </Tooltip>
      </Flex>
      <Divider style={{ margin: '0 0 var(--spacing-sm)' }} />
      <QueryBoundary query={customerQuery} skeleton={LOADING_SKELETON}>
        <DisplayValue label="Current Status:">
          <span data-testid="deceased-status">
            {deceasedStatus?.replace('_', ' ')}
          </span>
        </DisplayValue>
      </QueryBoundary>
    </div>
  );
};

export default DeceasedStatus;
