import { useMemo, useState } from 'react';
import { Alert, Button, Card, Form, Radio, Space } from 'antd';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAgent from 'hooks/useAgent';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useApplicationQuery from 'hooks/useApplicationQuery';

import { EFA_CALL_VERIFICATION } from 'mutations/efa';
import { EFA_PHONE_VERIFICATION, SIZE, STATUS } from 'utilities/constants';

const EFACallVerificationPage = () => {
  const agent = useAgent();
  const [form] = Form.useForm();
  const { applicationId } = useParams();
  const { customerInfo } = useCustomerInfo();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { application, applicationQuery } = useApplicationQuery(applicationId);

  const requiresVerification = useMemo(() => {
    return application && application.statusReason === EFA_PHONE_VERIFICATION;
  }, [application]);

  const [submitCallVerification, callVerificationMutation] = useMutation(
    EFA_CALL_VERIFICATION,
  );

  const handleSubmit = async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch {
      return;
    }

    setShowError(false);

    try {
      await submitCallVerification({
        variables: {
          applicantId: customerInfo.id,
          applicationId,
          isCallVerified: values.verified,
        },
      });
      setShowSuccess(true);
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <>
      <Card title="EFA Call Verification">
        <Form form={form} initialValues={{}} layout="vertical">
          <SectionMessage
            data-testid="call-verification-success-message"
            status={STATUS.SUCCESS}
            size={SIZE.SM}
            text="EFA Call Verification Submitted"
            visible={showSuccess}
            cover
          ></SectionMessage>

          <QueryBoundary
            mode={QueryBoundary.MODE.MESSAGE}
            loadingMessage="Loading Application Information"
            loadingOverride={applicationQuery.loading || !agent.loaded}
            query={applicationQuery}
          >
            {agent.isSuperUser ? (
              <>
                <div style={{ paddingBottom: 'var(--spacing-md)' }}>
                  <Form.Item
                    data-testid="verification-field"
                    name="verified"
                    label="What was the outcome of the EFA phone verification of the applicant?"
                    style={{ marginBottom: 'var(--spacing-md)' }}
                    rules={[
                      {
                        message: 'Please make a selection',
                        required: true,
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio data-testid="pass-verification" value={true}>
                          Pass: True-name party verified the application as
                          legitimate
                        </Radio>
                        <Radio data-testid="fail-verification" value={false}>
                          Fail: True-name party identified application as fraud
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  {!requiresVerification ? (
                    <Alert
                      banner
                      data-testid="no-verification-message"
                      type="warning"
                      style={{ maxWidth: 'fit-content' }}
                      message="This application is not in need of call verification."
                    />
                  ) : null}

                  {showError ? (
                    <Alert
                      banner
                      type="error"
                      style={{ maxWidth: 'fit-content' }}
                      message="Submission failed Please try again."
                    />
                  ) : null}
                </div>

                <Button
                  data-testid="execute-verification-button"
                  type="primary"
                  disabled={
                    !requiresVerification || callVerificationMutation.data
                  }
                  loading={callVerificationMutation.loading}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </>
            ) : (
              <SectionMessage
                data-testid="no-efa-access-message"
                status={STATUS.ERROR}
                size={SIZE.SM}
                text="Sorry, you lack the permissions to execute an EFA Contact Pull"
              ></SectionMessage>
            )}
          </QueryBoundary>
        </Form>
      </Card>
    </>
  );
};
export default EFACallVerificationPage;
