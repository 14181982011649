import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Card, Form, Select, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/client';

import ReasonsEvidenceFormList from './ReasonsEvidenceFormList';
import QueryResult from 'components/QueryResult/QueryResult';
import useAgent from 'hooks/useAgent';
import { NEW_CASE_ACTION } from 'mutations/newCaseAction';
import { ACTION_OPTIONS, FRAUD_CASES } from 'queries/fraudCases';

import { formatDateToSubmitIsoUtcOffset } from 'utilities/datesAndTimes';

const { Option, OptGroup } = Select;

const FraudNewActionForm = ({ setAddingNewAction }) => {
  const agent = useAgent();
  const { customerId, caseId, applicationId } = useParams();
  const [showSubmissionFailure, setShowSubmissionFailure] = useState(false);

  const [newActionForm] = Form.useForm();
  const {
    loading: loadingOpt,
    errors: errorOpt,
    data: dataOpt,
  } = useQuery(ACTION_OPTIONS);
  const [submitNewAction, { loading }] = useMutation(NEW_CASE_ACTION, {
    refetchQueries: [
      {
        query: FRAUD_CASES,
        variables: {
          customerId: customerId,
          applicationId: applicationId,
        },
      },
    ],
  });

  const handleNewActionCancel = () => {
    setAddingNewAction(false);
    newActionForm.resetFields();
  };

  const handleNewActionSubmit = async () => {
    let values;
    try {
      values = await newActionForm.validateFields();
    } catch (error) {
      return;
    }

    const variables = {
      ...values,
      ...{
        reasons: JSON.stringify({ reasons: values.reasons }),
        evidence: JSON.stringify({ evidence: values.evidence }),
        fraudCaseId: caseId,
        actor: agent?.user?.name || 'anonymous',
        initiatedAt: formatDateToSubmitIsoUtcOffset(Date.now()),
      },
    };

    try {
      setShowSubmissionFailure(false);
      await submitNewAction({
        variables,
      });
      setAddingNewAction(false);
      newActionForm.resetFields();
    } catch (error) {
      setShowSubmissionFailure(true);
    }
  };

  return (
    <QueryResult
      loading={loadingOpt}
      error={errorOpt}
      data={dataOpt}
      called={true}
    >
      <Card bordered={false} title="Add New Action">
        <Form
          title="New Action"
          form={newActionForm}
          style={{ width: '50%', minWidth: '700px' }}
        >
          <Form.Item
            label={<Typography.Text strong>Type</Typography.Text>}
            name="actionType"
            data-testid="action-type"
            rules={[
              {
                required: true,
                message: 'Please select an action type!',
              },
            ]}
          >
            <Select placeholder="Select a type">
              <OptGroup label="Case Actions">
                {dataOpt?.actionOptionsV2?.type?.caseActions?.map((option) => {
                  return (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  );
                })}
              </OptGroup>
              <OptGroup label="Case Status">
                {dataOpt?.actionOptionsV2?.type?.caseStatus?.map((option) => {
                  return (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  );
                })}
              </OptGroup>
            </Select>
          </Form.Item>

          <ReasonsEvidenceFormList />
          {showSubmissionFailure ? (
            <Alert
              type="error"
              message="Failed to create new fraud action. Please try again."
              style={{ marginBottom: 'var(--spacing-md)' }}
            />
          ) : null}
        </Form>
        <Button onClick={handleNewActionCancel}>Cancel</Button>
        <Button
          loading={loading}
          style={{ marginLeft: 5 }}
          type="primary"
          onClick={handleNewActionSubmit}
        >
          Save
        </Button>
      </Card>
    </QueryResult>
  );
};

export default FraudNewActionForm;
