import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

import SelectFundingAccount from './SelectFundingAccount';
import SelectPaymentAmount from './SelectPaymentAmount';
import PaymentAuthorizationMessage from './PaymentAuthorizationMessage';

import AddFundingAccountModal from 'components/AddFundingAccountModal/AddFundingAccountModal';
import QueryResult from 'components/QueryResult/QueryResult';

import useModalControls from 'hooks/useModalControls';
import { ONE_TIME_PAYMENT } from 'queries/payments';
import { RE_AGE_ELIGIBILITY } from 'queries/reage';
import { MAKE_PAYMENT, SCHEDULE_PAYMENT } from 'mutations/payments';

import {
  formatDollarsFromCents,
  getAccountLast4,
  transformPaymentData,
} from 'utilities/helpers';
import {
  dateIsWithin90Days,
  formatDate,
  formatDateToSubmit,
} from 'utilities/datesAndTimes';

const { Text } = Typography;

const MakePaymentModal = ({ hide, ...modalProps }) => {
  const [paymentRequestForm] = Form.useForm();
  const addFundingAccountModal = useModalControls();

  const [submittingPayment, setSubmittingPayment] = useState(false);
  const [isScheduledPayment, setIsScheduledPayment] = useState(false);
  const [reAgePaymentAmount, setReAgePaymentAmount] = useState(null);
  const [reAgeFailureMessage, setReAgeFailureMessage] = useState('');

  const [scriptAmount, setScriptAmount] = useState(null);
  const [scriptAccount, setScriptAccount] = useState(null);
  const [scriptDate, setScriptDate] = useState(dayjs());

  const { accountId, customerId } = useParams();
  const [getPaymentInfo, paymentQuery] = useLazyQuery(ONE_TIME_PAYMENT);
  const data = transformPaymentData(paymentQuery?.data);

  useEffect(() => {
    if (customerId && accountId && open) {
      getPaymentInfo({
        variables: { accountId, customerId },
      });
    }
  }, [customerId, accountId, open, getPaymentInfo]);

  useEffect(() => {
    const defaultFundingAccount = data?.defaultFundingAccount;
    if (!scriptAccount && defaultFundingAccount) {
      paymentRequestForm.setFieldsValue({
        fundingAccount: defaultFundingAccount.id,
      });
      setScriptAccount(defaultFundingAccount.id);
    }
  }, [data, scriptAccount, scriptAmount, paymentRequestForm]);

  useQuery(RE_AGE_ELIGIBILITY, {
    variables: { customerId, accountId },
    onCompleted: (data) => {
      const reAgeData = data?.account?.offers?.eligibility?.find(
        (offer) => offer.offerType === 'REAGE',
      );
      if (reAgeData.eligible) {
        setReAgePaymentAmount(reAgeData.data.amountToPay);
      }
    },
    onError: () =>
      setReAgeFailureMessage(
        'Re-age eligibility check failed. Refresh to check for re-age eligibility payment amount.',
      ),
  });

  const [submitImmediatePayment, { error: submitImmediateError }] = useMutation(
    MAKE_PAYMENT,
    {
      refetchQueries: ['PaymentsQuery', 'GetSettlementOffers'],
    },
  );

  const [submitSchedulePayment, { error: submitScheduleError }] = useMutation(
    SCHEDULE_PAYMENT,
    {
      refetchQueries: ['PaymentsQuery', 'GetSettlementOffers'],
    },
  );

  const handleScheduledPayment = (e) => {
    const tomorrow = dayjs().startOf('day').add(1, 'day');
    paymentRequestForm.setFieldsValue({
      paymentDate: tomorrow,
    });
    setScriptDate(tomorrow);
    setIsScheduledPayment(e.target.value);
  };

  const closeModal = () => {
    hide();
    paymentRequestForm.resetFields();
    setScriptAmount('25.00');
    setIsScheduledPayment(false);
    setReAgeFailureMessage('');
  };

  const submitPayment = (values) => {
    const variables = {
      customerId,
      accountId,
      amount: Math.round(values.paymentAmount * 100),
      fundingAccountId: values.fundingAccount,
    };
    if (values.scheduledPayment) {
      variables.paymentDate = formatDateToSubmit(values.paymentDate);
      return submitSchedulePayment({
        variables,
      });
    } else {
      variables.idempotencyKey = uuidv4();
      variables.forcePayment = values.forcePayment;
      return submitImmediatePayment({
        variables,
      });
    }
  };

  const handleSubmit = async () => {
    setSubmittingPayment(true);
    try {
      const values = await paymentRequestForm.validateFields();
      await submitPayment(values);
      setSubmittingPayment(false);
      closeModal();
    } catch (error) {
      setSubmittingPayment(false);
    }
  };

  return (
    <Modal
      {...modalProps}
      title="Make Payment"
      centered
      width={1000}
      onCancel={closeModal}
      onOk={handleSubmit}
      okText="Authorize and Submit Payment"
      confirmLoading={submittingPayment}
    >
      <QueryResult {...paymentQuery}>
        <h4>Statement Due Date: {formatDate(data.statementDueDate)}</h4>
        <h4>
          Total amount of scheduled payments:{' '}
          {`${formatDollarsFromCents(data.totalScheduledPayments)}`}
        </h4>

        <Divider />

        <Form
          form={paymentRequestForm}
          name="paymentRequest"
          layout="vertical"
          initialValues={{
            fundingAccount: data.defaultFundingAccount?.id,
            forcePayment: false,
            scheduledPayment: false,
          }}
        >
          <Row>
            <Col span={12}>
              <SelectPaymentAmount
                label="Payment Amount"
                name="paymentAmount"
                paymentOptions={data.paymentOptions}
                setAmount={setScriptAmount}
                reAgePaymentAmount={reAgePaymentAmount}
                reAgeFailureMessage={reAgeFailureMessage}
                form={paymentRequestForm}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                label="Immediate or Future Payment?"
                name="scheduledPayment"
              >
                <Radio.Group onChange={handleScheduledPayment}>
                  <Space direction="vertical">
                    <Radio value={false}>Immediate Payment</Radio>
                    <Radio value={true} disabled={!data.canSchedulePayment}>
                      Scheduled Payment{' '}
                      {!data.canSchedulePayment && (
                        <em>(can only schedule up to 3 payments)</em>
                      )}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                hidden={!isScheduledPayment}
                label="Select Date"
                name="paymentDate"
              >
                <DatePicker
                  format="MM/DD/YYYY"
                  onChange={setScriptDate}
                  disabledDate={(d) => !d || !dateIsWithin90Days(d)}
                />
              </Form.Item>
              {isScheduledPayment && (
                <Text italic>
                  Cut off time is 5:59pm EST for same day; 11:59pm EST for
                  scheduled
                </Text>
              )}
            </Col>
          </Row>
          <Row gutter={[10, 0]} align="middle">
            <Col flex="auto">
              <SelectFundingAccount
                label="Bank Account"
                name="fundingAccount"
                fundingAccounts={data?.fundingAccounts}
                onChange={setScriptAccount}
              />
            </Col>
            <Col
              style={{
                paddingTop: 4,
              }}
            >
              <Button size="small" onClick={addFundingAccountModal.show}>
                Add Funding Account
              </Button>
              <AddFundingAccountModal
                {...addFundingAccountModal}
                refetchQueries={['MakePaymentQuery']}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <PaymentAuthorizationMessage
                cardLast4={data.cardLast4}
                amount={scriptAmount}
                account={getAccountLast4(data?.fundingAccounts, scriptAccount)}
                date={scriptDate}
                isScheduledPayment={isScheduledPayment}
              />
              <Form.Item
                name="authorizePayment"
                initialValue={false}
                valuePropName="checked"
                noStyle
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'Must acknowledge customer authorization to submit payment',
                            ),
                          ),
                  },
                ]}
              >
                <Checkbox data-testid="ack-checkbox">
                  Acknowledge customer authorization
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="forcePayment"
                hidden={isScheduledPayment}
                valuePropName="checked"
              >
                <Checkbox disabled={isScheduledPayment}>Force Payment</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {submitImmediateError && (
          <Alert
            type="error"
            message={`Error submitting payment: ${submitImmediateError.message}`}
          />
        )}

        {submitScheduleError && (
          <Alert
            type="error"
            message={`Error scheduling payment: ${submitScheduleError.message}`}
          />
        )}
      </QueryResult>
    </Modal>
  );
};

export default MakePaymentModal;
