import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal as AntModal } from 'antd';
import { useLazyQuery } from '@apollo/client';
import styled from '@emotion/styled';
import _capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useAccountQuery from 'hooks/useAccountQuery';
import useAnalytics from 'hooks/useAnalytics';
import { ACCOUNT_PRICING_DETAILS } from 'queries/account';
import { TRANSACTION_DETAIL } from 'queries/transactions';
import { ANALYTICS_EVENTS, TRANSACTION_TYPE } from 'utilities/constants';
import { formatDollarsFromCents } from 'utilities/helpers';
import { formatDateToSubmit } from 'utilities/datesAndTimes';

import AuthorizationDetail from './AuthorizationDetail';
import TransactionDetail from './TransactionDetail';

const Modal = styled(AntModal)`
  top: var(--spacing-lg);
`;

const TransactionDetailModal = ({ transaction, hide, ...modalProps }) => {
  const { trackEvent } = useAnalytics();
  const { accountId, customerId } = useParams();
  const [pricingDetails, setPricingDetails] = useState(null);
  const [transactionDetail, setTransactionDetail] = useState(null);
  const { account } = useAccountQuery(customerId, accountId);

  const [getTransactionDetail, transactionDetailQuery] = useLazyQuery(
    TRANSACTION_DETAIL,
    {
      onCompleted: (res) => {
        setTransactionDetail(res?.getTransactionById);
      },
    },
  );

  const [getPricingDetails] = useLazyQuery(ACCOUNT_PRICING_DETAILS, {
    onCompleted: (res) => {
      setPricingDetails(res?.getTransactionById);
    },
  });

  const closeModal = () => {
    hide();
    setTransactionDetail(null);
    trackEvent(ANALYTICS_EVENTS.TRANSACTION_DETAIL_CLOSED);
  };

  const getTitle = () => {
    const detailType = transaction?.isAuthorization
      ? TRANSACTION_TYPE.AUTHORIZATION
      : TRANSACTION_TYPE.TRANSACTION;
    let title = `${_capitalize(detailType)}`;
    const merchantName = transactionDetail?.merchant?.name;
    const amount = transactionDetail?.amount;
    if (merchantName) {
      title = `${title} - ${merchantName}`;
    }
    if (amount) {
      title = `${title} - ${formatDollarsFromCents(amount)}`;
    }
    return title;
  };

  const renderDetail = () => {
    const detail = transaction?.isAuthorization ? (
      <AuthorizationDetail
        detail={transactionDetail}
        accountDetails={account}
        pricingDetails={pricingDetails}
      ></AuthorizationDetail>
    ) : (
      <TransactionDetail
        detail={transactionDetail}
        accountDetails={account}
      ></TransactionDetail>
    );

    return (
      <>
        <h2>{getTitle()}</h2>
        {detail}
      </>
    );
  };

  useEffect(() => {
    if (open && transaction) {
      if (transaction.isFraudAuthorization) {
        setTransactionDetail(transaction);
        getPricingDetails({ variables: { accountId, customerId } });
      } else {
        const detailDateRange = transaction.detailDateRange || {};
        const fromDate = detailDateRange.from || dayjs();
        const toDate = detailDateRange.to || dayjs();

        getTransactionDetail({
          variables: {
            accountId,
            customerId,
            fromDate: formatDateToSubmit(fromDate),
            toDate: formatDateToSubmit(toDate),
            transactionId: transaction.id,
          },
        });
      }
    }
  }, [open, transaction]);

  return (
    <Modal
      {...modalProps}
      width={800}
      onOk={closeModal}
      onCancel={closeModal}
      destroyOnClose={true}
      data-testid="transactionDetailModal"
      cancelButtonProps={{ hidden: true }}
    >
      {/* When viewing authorization history, the transaction already contains all neccessary information. No need to load a detail. */}
      {transaction?.isFraudAuthorization ? (
        renderDetail()
      ) : (
        <QueryBoundary
          query={transactionDetailQuery}
          mode={QueryBoundary.MODE.MESSAGE}
          loadingMessage="Loading Transaction Detail"
          errorMessage="Failed to load transaction detail"
        >
          {transactionDetail ? renderDetail() : null}
        </QueryBoundary>
      )}
    </Modal>
  );
};

export default TransactionDetailModal;
