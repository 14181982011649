import {
  createReactiveState,
  createFundingAccountHandlers,
  createStepHandlers,
  getFundingAccountProperties,
  getStepProperties,
  renderPendingStep,
  renderPositiveStep,
} from 'utilities/stateHelpers';

import {
  SETTLEMENT_OFFER_TYPES,
  SETTLEMENTS_CADENCE_VALUES,
} from 'utilities/constants';

import ChooseOfferStep from './ChooseOfferStep';
import FundingAccountStepWrapper from './FundingAccountStepWrapper';
import PaymentPlanDetailsStep from './PaymentPlanDetailsStep';
import DisclosureStep from './DisclosureStep';
import EFTAStep from './EFTAStep';

const CHOOSE_OFFER_STEP = {
  title: 'Choose an Offer',
  component: ChooseOfferStep,
  renderPreprocess: () => {
    return;
  },
  renderPostprocess: () => {
    const currentState = recoveriesStateVar();
    const { offerType, durationMonths, settlementPercentage } =
      currentState.acceptedOffer;
    const pifPlanDuration = currentState?.pifPlanDuration;
    const selectedOfferIsPif = offerType.includes(SETTLEMENT_OFFER_TYPES.PIF);
    const offerTypeText = selectedOfferIsPif ? 'Paid in Full' : 'Settlement';
    const balancePercentage = `${settlementPercentage}`;
    const offerDuration =
      selectedOfferIsPif && pifPlanDuration ? pifPlanDuration : durationMonths;
    return renderPositiveStep(
      `Offer selected:`,
      `${offerTypeText} | ${balancePercentage} over ${offerDuration} month(s)`,
    );
  },
};

const ADD_FUNDING_ACCOUNT_STEP = {
  title: 'Select a Funding Account',
  component: FundingAccountStepWrapper,
  renderPreprocess: () => {
    return renderPendingStep(ADD_FUNDING_ACCOUNT_STEP.title);
  },
  renderPostprocess: () => {
    const currentState = recoveriesStateVar();
    const { name } = currentState.fundingAccount;
    return renderPositiveStep('Funding Account:', name);
  },
};

const PAYMENT_PLAN_DETAILS_STEP = {
  title: 'Set Payment Plan Details',
  component: PaymentPlanDetailsStep,
  renderPreprocess: () => {
    return renderPendingStep(PAYMENT_PLAN_DETAILS_STEP.title);
  },
  renderPostprocess: () => {
    const currentState = recoveriesStateVar();
    const firstPaymentDate = currentState?.paymentDates?.startDate;
    const { cadence, paymentPlanSchedule } = currentState.paymentCadence;

    const paymentCadenceText =
      cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME
        ? '1 payment of'
        : `${paymentPlanSchedule?.length} ${cadence} payments of`;

    return renderPositiveStep(
      `Payment Plan Details:`,
      `${paymentCadenceText} ${paymentPlanSchedule?.[0]?.amount} 
      starting ${firstPaymentDate}`,
    );
  },
};

const DISCLOSURE_STEP = {
  title: 'Read Disclosures',
  component: DisclosureStep,
  renderPreprocess: () => {
    return renderPendingStep(DISCLOSURE_STEP.title);
  },
  renderPostprocess: () => {
    return renderPositiveStep(`Disclosures accepted`);
  },
};

const EFTA_STEP = {
  title: 'Read EFTA',
  component: EFTAStep,
  renderPreprocess: () => {
    return renderPendingStep(EFTA_STEP.title);
  },
  renderPostprocess: () => {
    return;
  },
};

export const RECOVERIES_STEPS = Object.freeze({
  CHOOSE_OFFER: CHOOSE_OFFER_STEP,
  ADD_FUNDING_ACCOUNT: ADD_FUNDING_ACCOUNT_STEP,
  PAYMENT_PLAN_DETAILS: PAYMENT_PLAN_DETAILS_STEP,
  DISCLOSURE: DISCLOSURE_STEP,
  EFTA: EFTA_STEP,
});

export const PAYMENT_PLAN_PROGRESS_STEPS = [
  RECOVERIES_STEPS.CHOOSE_OFFER,
  RECOVERIES_STEPS.ADD_FUNDING_ACCOUNT,
  RECOVERIES_STEPS.PAYMENT_PLAN_DETAILS,
  RECOVERIES_STEPS.DISCLOSURE,
  RECOVERIES_STEPS.EFTA,
];

export const ONE_TIME_DEBIT_PROGRESS_STEPS = [
  RECOVERIES_STEPS.CHOOSE_OFFER,
  RECOVERIES_STEPS.DISCLOSURE,
  RECOVERIES_STEPS.EFTA,
];

const initializeState = () => {
  return {
    ...getStepProperties(RECOVERIES_STEPS.CHOOSE_OFFER),
    ...getFundingAccountProperties(),
    acceptedOffer: {
      balance: null,
      durationMonths: null,
      settlementPercentage: null,
      offerId: null,
      offerName: null,
      offerType: null,
    },
    pifPlanDuration: null,
    fundingAccount: {
      id: null,
      name: null,
    },
    paymentDates: {
      startDate: null,
      endDate: null,
    },
    paymentCadence: {
      cadence: null,
      totalNumberOfPayments: null,
      amountPerPaymentPeriod: null,
      paymentPlanSchedule: null,
    },
  };
};

const recoveriesState = createReactiveState(initializeState);

const { createPropertySetter, setPropertyValue } = recoveriesState;
export const {
  resetState: resetRecoveriesState,
  stateVar: recoveriesStateVar,
  useState: useRecoveriesState,
} = recoveriesState;

const stepHandlers = createStepHandlers(
  recoveriesState,
  Object.values(RECOVERIES_STEPS),
);
export const { setNextStep } = stepHandlers;

export const goToPreviousStep = () => {
  const currentState = recoveriesStateVar();
  if (currentState?.steps.length === 2) {
    resetRecoveriesState();
  } else {
    stepHandlers.goToPreviousStep();
  }
};

export const { setFundingAccount } =
  createFundingAccountHandlers(recoveriesState);

export const setPifPlanDuration = createPropertySetter('pifPlanDuration');

export const setPaymentDates = (startDate, endDate) => {
  setPropertyValue('paymentDates', { startDate, endDate });
};

export const setPaymentCadence = (cadence, paymentPlanSchedule) => {
  setPropertyValue('paymentCadence', { cadence, paymentPlanSchedule });
};

export const setAcceptedOffer = (
  balance,
  durationMonths,
  offerId,
  offerName,
  offerType,
  settlementPercentage,
) => {
  setPropertyValue('acceptedOffer', {
    balance,
    durationMonths,
    settlementPercentage: settlementPercentage ?? '100%',
    offerId,
    offerName,
    offerType,
  });
};
