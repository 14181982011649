import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Form, InputNumber, Alert } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { UPDATE_CUSTOMER_FINANCIAL_INFO } from 'mutations/financialInfo';
import { SIZE, STATUS } from 'utilities/constants';
import { parseCurrency } from 'utilities/helpers';

const COPY = {
  HIGH_HOUSING_WARNING:
    'The input Monthly Rent/mortgage seems high. Please confirm the amount with the customer.',
  HIGH_INCOME_WARNING:
    'The input Total Annual Income seems high. Please confirm the amount with the customer.',
};

const warningStyle = {
  marginBottom: '.75rem',
  alignItems: 'center',
  marginTop: '-.75rem',
};

const EditIncomeResidenceModal = ({
  hide,
  customerId,
  monthlyLiving,
  annualIncome,
  ...modalProps
}) => {
  const [showHousingWarning, setShowHousingWarning] = useState(false);
  const [showIncomeWarning, setShowIncomeWarning] = useState(false);
  const formName = 'incomeResidence';
  const [form] = Form.useForm();

  const [fieldsChanged, setFieldsChanged] = useState({});
  const [failureMessage, setFailureMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useTimeoutValue('', 3000);

  let successMessage;
  if (Object.entries(fieldsChanged).length > 1) {
    successMessage = 'Income and residence info successfully updated';
  } else if (fieldsChanged.annualIncome) {
    successMessage = 'Income successfully updated';
  } else {
    successMessage = 'Monthly rent/mortgage successfully updated';
  }

  const [updateFinancialInfo, { loading: updatingFinancialInfo }] = useMutation(
    UPDATE_CUSTOMER_FINANCIAL_INFO,
    {
      onCompleted: () => {
        setShowSuccessMessage(successMessage);
        setTimeout(() => {
          closeModal();
        }, 2500);
      },
      onError: (mutationError) => {
        setFailureMessage(mutationError.message);
      },
    },
  );

  const onSubmit = async (event) => {
    await updateFinancialInfo({
      variables: {
        customerId: customerId,
        annualIncome: Math.floor(event.annualIncome),
        monthlyLiving: Math.floor(event.monthlyLiving),
      },
      refetchQueries: ['GetCustomerFinancialInfo'],
    });
  };

  const updateFieldStatus = (event) => {
    const fieldId = event.target.getAttribute('id');
    const fieldName = fieldId.replace(`${formName}_`, '');
    const currentValue = parseCurrency(event.target.value)?.toString();

    let initialValue;
    if (fieldName === 'monthlyLiving') {
      initialValue = monthlyLiving;
      setShowHousingWarning(currentValue > 100000);
    }

    if (fieldName === 'annualIncome') {
      initialValue = annualIncome;
      setShowIncomeWarning(currentValue > 500000);
    }

    const valueHasChanged = initialValue !== currentValue;
    setFieldsChanged((previousState) => {
      let fieldStatus = 'success';
      if (valueHasChanged) {
        fieldStatus = 'warning';
      }
      if (!currentValue) {
        fieldStatus = 'error';
      }
      return {
        ...previousState,
        [fieldName]: fieldStatus,
      };
    });
  };

  const isSubmitDisabled = () => {
    return Object.values(fieldsChanged).some(
      (fieldStatus) => fieldStatus === 'error',
    );
  };

  const closeModal = () => {
    setFieldsChanged({});
    setFailureMessage('');
    form.resetFields();
    hide();
  };

  return (
    <Modal
      {...modalProps}
      id="incomeResidenceModal"
      title="Edit Income & Residence Information"
      okText="Update"
      onOk={() => form.submit()}
      onCancel={closeModal}
      confirmLoading={updatingFinancialInfo}
      okButtonProps={{ disabled: isSubmitDisabled() }}
    >
      <Form
        form={form}
        name={formName}
        initialValues={{ monthlyLiving, annualIncome }}
        layout="vertical"
        onChange={updateFieldStatus}
        onFinish={onSubmit}
      >
        <SectionMessage
          data-testid="success-message"
          status={STATUS.SUCCESS}
          size={SIZE.SM}
          text={successMessage}
          cover={true}
          visible={showSuccessMessage}
          SectionMessage
        />

        <Form.Item
          name="monthlyLiving"
          label="Monthly Rent/Mortgage:"
          rules={[
            { required: true, message: 'Monthly rent/mortgage is required.' },
          ]}
          validateStatus={fieldsChanged['monthlyLiving']}
        >
          <InputNumber
            data-testid="monthlyLiving-input"
            prefix="$"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        {showHousingWarning && (
          <Alert
            data-testid="monthlyLiving-warning"
            description={COPY.HIGH_HOUSING_WARNING}
            style={warningStyle}
            type="warning"
            showIcon
          />
        )}
        <Form.Item
          name="annualIncome"
          label="Total Annual Income:"
          rules={[{ required: true, message: 'Annual income is required.' }]}
          validateStatus={fieldsChanged['annualIncome']}
        >
          <InputNumber
            data-testid="annualIncome-input"
            prefix="$"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        {showIncomeWarning && (
          <Alert
            data-testid="annualIncome-warning"
            description={COPY.HIGH_INCOME_WARNING}
            style={warningStyle}
            type="warning"
            showIcon
          />
        )}
        {failureMessage ? (
          <Alert
            data-testid="failure-message"
            type="error"
            message={failureMessage}
          ></Alert>
        ) : null}
      </Form>
    </Modal>
  );
};

export default EditIncomeResidenceModal;
