import { gql } from '@apollo/client';

export const UPDATE_STATEMENT_PREFERENCES = gql`
  mutation UpdateStatementPreferences(
    $customerId: String!
    $accountId: String!
    $statementPreferencesUpdateInput: StatementPreferencesUpdateInput!
  ) {
    updateStatementPreferences(
      accountId: $accountId
      customerId: $customerId
      statementPreferencesUpdateInput: $statementPreferencesUpdateInput
    ) {
      success
    }
  }
`;
