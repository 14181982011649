import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Alert } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { GET_ACCOUNT_CARD_LAST_4 } from 'queries/account';
import {
  CREATE_DEBIT_SETTLEMENT_OFFER,
  CREATE_PAYMENT_PLAN_SETTLEMENT_OFFER,
} from 'mutations/settlements';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { getEftaText } from 'utilities/helpers';
import { SETTLEMENTS_CADENCE_VALUES, SIZE, STATUS } from 'utilities/constants';

import { resetRecoveriesState, useRecoveriesState } from './recoveriesState';
import EFTAForm from './EFTAForm';

const EFTAStep = ({ settlementsQuery, thirdParty }) => {
  const { customerId, accountId } = useParams();

  const { customerInfo } = useCustomerInfo();
  const customerName = customerInfo.fullName;
  const email = customerInfo.email;

  const {
    acceptedOffer,
    fundingAccount,
    paymentCadence,
    paymentDates,
    pifPlanDuration,
  } = useRecoveriesState();

  const { name: thirdPartyName, email: thirdPartyEmail } = thirdParty;

  const fundingAccountLast4 = fundingAccount?.name?.substring(
    fundingAccount.name.length - 4,
    fundingAccount.name.length,
  );

  const [cardLast4, setCardLast4] = useState('');

  const [createOfferSuccess, setCreateOfferSuccess] = useTimeoutValue(
    false,
    3000,
  );
  const [createOfferError, setCreateOfferError] = useState(false);

  const getCardLast4Query = useQuery(GET_ACCOUNT_CARD_LAST_4, {
    variables: { customerId, accountId },
    onCompleted: ({ account }) => {
      setCardLast4(account.cardDetails.last4);
    },
  });

  const onCreateOfferCompletion = () => {
    setCreateOfferSuccess(true);
    setCreateOfferError(false);
    setTimeout(() => {
      resetRecoveriesState();
      settlementsQuery.refetch();
    }, 2500);
  };

  const [
    createPaymentPlanSettlementOffer,
    { loading: createPaymentPlanOfferLoading },
  ] = useMutation(CREATE_PAYMENT_PLAN_SETTLEMENT_OFFER, {
    onCompleted: () => onCreateOfferCompletion(),
    onError: () => setCreateOfferError(true),
  });

  const [createDebitSettlementOffer, { loading: createDebitOfferLoading }] =
    useMutation(CREATE_DEBIT_SETTLEMENT_OFFER, {
      onCompleted: () => onCreateOfferCompletion(),
      onError: () => setCreateOfferError(true),
    });

  const onConfirm = async () => {
    const eftaText = getEftaText({
      eftaDetails: {
        cardLast4,
        customerName: thirdPartyName ?? customerName,
        fundingAccountLast4,
        email: thirdPartyEmail ?? email,
      },
      todaysDate: dayjs().format('L'),
      paymentPlanSchedule: paymentCadence?.paymentPlanSchedule,
      cadence: paymentCadence?.cadence,
    });

    if (paymentCadence.cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT) {
      await createDebitSettlementOffer({
        variables: { accountId, selectedOfferId: acceptedOffer.offerId },
      });
    } else {
      const variables = {
        accountId,
        customerId,
        // if SIF offer, pifPlanDuration will be null
        durationOverrideInMonths: pifPlanDuration,
        efta: eftaText,
        fundingAccountId: fundingAccount.id,
        paymentPlanCadence: paymentCadence.cadence,
        paymentPlanStartDate: formatDateToSubmit(paymentDates.startDate),
        selectedOfferId: acceptedOffer.offerId,
      };

      await createPaymentPlanSettlementOffer({ variables });
    }
  };

  return (
    <>
      <QueryBoundary query={getCardLast4Query}>
        <EFTAForm
          eftaDetails={{
            cardLast4,
            customerName: thirdPartyName ?? customerName,
            email: thirdPartyEmail ?? email,
            fundingAccountLast4,
          }}
          onFinish={onConfirm}
          mutation={{
            loading: createPaymentPlanOfferLoading || createDebitOfferLoading,
          }}
        />
      </QueryBoundary>

      {createOfferError && (
        <Alert
          banner
          type="error"
          message={'Error creating program. Please try again.'}
          style={{ marginTop: 'var(--spacing-sm)' }}
        />
      )}
      {createOfferSuccess ? (
        <SectionMessage
          data-testid="settlements-success-message"
          cover={true}
          status={STATUS.SUCCESS}
          size={SIZE.MD}
          text="Program successfully created!"
          visible={createOfferSuccess}
        />
      ) : null}
    </>
  );
};

export default EFTAStep;
