import * as Sentry from '@sentry/react';
import { Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import config from 'config';

export const SentryRoute = Sentry.withSentryRouting(Route);
export const sentryBrowserHistory = createBrowserHistory();

export const setSentryAgentInfo = (agent = {}) => {
  Sentry.setUser({ email: agent.email, name: agent.name, roles: agent.roles });
};

Sentry.init({
  dsn: 'https://1ba6ed3a5680838e1c8381d6aa5e97c3@o543490.ingest.us.sentry.io/4508286874812416',
  environment: config.ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history: sentryBrowserHistory,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,
});

Sentry.setTags({
  'commit-id': process.env.REACT_APP_GIT_COMMIT ?? 'development',
});
