import { gql } from '@apollo/client';

const GET_SETTLEMENT_OFFERS = gql`
  query GetSettlementOffers($customerId: String!, $accountId: String!) {
    account(customerId: $customerId, accountId: $accountId) {
      offers {
        eligibility(offerType: SETTLEMENT) {
          checks {
            name
            pass
          }
          eligible
          offerType
        }
        offers(offerType: SETTLEMENT) {
          createdAt
          data {
            acceptanceExpiration
            acceptedAt
            atpUnderSIF
            canceledReason
            duration {
              value
              unit
            }
            expiration
            fundingAccountId
            isDebitCardOffer
            offerId
            originalOutstandingBalance
            originalSettlementBalance
            pastPaymentPlanIds
            paymentPlan {
              authorizationText
              customerId
              id
              source
              transactions {
                amount
                date
              }
            }
            payments {
              amount
              date
              txId
              type
            }
            remainingBalance
            state
            subType
            type
          }
        }
        settlement {
          available {
            amount
            durationMonths
            offerId
            offerName
            outstandingBalance
          }
        }
      }
    }
  }
`;

const GET_SETTLEMENT_PAYMENT_PLAN = gql`
  query GetSettlementPaymentPlan(
    $amount: Long!
    $startDate: String!
    $duration: Long!
    $cadence: String!
  ) {
    paymentPlanSchedule(
      amount: $amount
      startDate: $startDate
      duration: $duration
      cadence: $cadence
    ) {
      amount
      date
    }
  }
`;

export { GET_SETTLEMENT_OFFERS, GET_SETTLEMENT_PAYMENT_PLAN };
