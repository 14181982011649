import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Alert, Form, InputNumber, Radio, Space } from 'antd';

import { formatDollarsFromCents } from 'utilities/helpers';

const SelectPaymentAmount = ({
  label,
  name,
  paymentOptions,
  initialAmount,
  setAmount,
  reAgePaymentAmount,
  reAgeFailureMessage,
  form,
}) => {
  const defaultCustomAmount = '25.00';
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [customPaymentAmount, setCustomPaymentAmount] = useState(
    initialAmount || defaultCustomAmount,
  );

  const { showReAgePaymentOption } = useFlags();

  const customAmountOptionValue = reAgePaymentAmount
    ? paymentOptions.length + 1
    : paymentOptions.length;

  useEffect(() => {
    setSelectedPaymentType(null);
    setAmount(defaultCustomAmount);
    setCustomPaymentAmount(defaultCustomAmount);
  }, []);

  useEffect(() => {
    if (initialAmount) {
      setCustomPaymentAmount(initialAmount);
    }
  }, [initialAmount]);

  const handleCustomAmount = (amount) => {
    const dollarAmount = (Math.round(amount * 100) / 100).toFixed(2);
    setCustomPaymentAmount(dollarAmount);
    setAmount(dollarAmount);
    form.setFieldsValue({ [name]: dollarAmount });
    setSelectedPaymentType(customAmountOptionValue);
  };

  const handlePaymentType = (event) => {
    setSelectedPaymentType(event.target.value);
    setAmount(event.target['data-amount']);
  };

  return (
    <>
      <Form.Item
        label={label}
        name={name}
        valuePropName="data-amount"
        rules={[
          {
            required: true,
            message: 'Please select a payment amount!',
          },
        ]}
      >
        <Radio.Group onChange={handlePaymentType} value={selectedPaymentType}>
          <Space direction="vertical">
            {paymentOptions.map(({ label: optionLabel, amount }, index) => (
              <Radio
                value={index}
                key={index}
                data-amount={(amount / 100).toFixed(2)}
                disabled={amount === 0}
              >
                <strong>{optionLabel}:</strong>{' '}
                {`${formatDollarsFromCents(amount)}`}
              </Radio>
            ))}
            {showReAgePaymentOption && reAgePaymentAmount && (
              <Radio
                value={paymentOptions.length}
                data-amount={(reAgePaymentAmount / 100).toFixed(2)}
              >
                <strong>Re-Age Eligible Amount:</strong>{' '}
                {`${formatDollarsFromCents(reAgePaymentAmount)}`}
              </Radio>
            )}
            <Radio
              value={customAmountOptionValue}
              data-amount={customPaymentAmount}
            >
              <Space>
                <strong>Custom Amount: $</strong>
                <InputNumber
                  min="0.00"
                  step="0.01"
                  size="small"
                  defaultValue={initialAmount || defaultCustomAmount}
                  onChange={handleCustomAmount}
                />
              </Space>
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {reAgeFailureMessage && (
        <Alert
          type="error"
          message={reAgeFailureMessage}
          style={{ marginTop: 'var(--spacing-xs)' }}
        />
      )}
    </>
  );
};

export default SelectPaymentAmount;
