import { Alert, Skeleton as AntSkeleton } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import { SIZE, STATUS } from 'utilities/constants';

const SkeletonWrapper = ({ children, skeleton, ...rest }) => {
  // A weak check to see if skeleton is a custom skeleton component or params for ant skeleton
  let skeletonIsComp = typeof skeleton === 'function';
  let Skeleton = skeletonIsComp ? skeleton : AntSkeleton;
  let layout = skeletonIsComp ? {} : skeleton;
  return (
    <Skeleton {...layout} {...rest}>
      {children}
    </Skeleton>
  );
};

const MODE = Object.freeze({
  MESSAGE: 'message',
  SKELETON: 'skeleton',
});

const QueryBoundary = ({
  children,
  canRetry = true,
  disabled = false,
  errorMessage = 'Failed to load data',
  handlePartialSuccess = true,
  loadingOverride,
  loadingMessage = 'Loading data',
  messageHeight,
  messageSize = SIZE.SM,
  mode = MODE.SKELETON,
  partialSuccessMessage = 'Partial Success: Only a portion of the information was retrieved.',
  query = { loading: false, called: true, error: false, data: {} },
  skeleton = {},
}) => {
  const { called, data, error, loading, refetch, variables } = query;

  const retry = (event) => {
    event.preventDefault();
    refetch && refetch(variables);
  };

  const messageButtons = canRetry ? [{ onClick: retry, text: 'Retry' }] : [];

  const renderWithSpinner = () => {
    return loading || loadingOverride ? (
      <SectionMessage
        height={messageHeight}
        size={messageSize}
        status={STATUS.LOADING}
        text={loadingMessage}
      />
    ) : (
      renderContent()
    );
  };

  const renderWithSkeleton = () => {
    return (
      <SkeletonWrapper
        active={!called || loading || loadingOverride}
        loading={!called || loading || loadingOverride}
        skeleton={skeleton}
      >
        {renderContent()}
      </SkeletonWrapper>
    );
  };

  const renderError = () => {
    return (
      <SectionMessage
        height={messageHeight}
        size={messageSize}
        status={STATUS.ERROR}
        text={errorMessage}
        buttons={messageButtons}
      />
    );
  };

  let renderPartialSuccess = () => {
    return (
      <>
        <Alert
          showIcon={true}
          type="warning"
          closable={true}
          style={{ marginBottom: 'var(--spacing-sm)' }}
          message={partialSuccessMessage}
        />
        {children}
      </>
    );
  };

  const renderContent = () => {
    if (error) {
      if (handlePartialSuccess && data) {
        return renderPartialSuccess();
      }
      return renderError();
    }

    return data ? children : null;
  };

  const render = () => {
    if (disabled) {
      return children;
    }

    let renderMethod = () => null;

    switch (mode) {
      case MODE.MESSAGE:
        renderMethod = renderWithSpinner;
        break;
      case MODE.SKELETON:
        renderMethod = renderWithSkeleton;
        break;
      default:
    }

    const result = renderMethod();

    return result;
  };

  return render();
};

QueryBoundary.MODE = MODE;

export default QueryBoundary;
