import { gql } from '@apollo/client';

const RE_AGE_ELIGIBILITY = gql`
  query ReAgeEligibility($customerId: String, $accountId: String!) {
    account(customerId: $customerId, accountId: $accountId) {
      offers {
        eligibility(offerType: REAGE) {
          checks {
            name
            pass
          }
          data {
            ... on ReageEligibilityData {
              amountToPay
            }
          }
          eligible
          offerType
        }
      }
    }
  }
`;

export { RE_AGE_ELIGIBILITY };
