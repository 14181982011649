import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'antd';
import { useQuery } from '@apollo/client';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import FundingAccountTable from 'components/FundingAccountTable/FundingAccountTable';
import { CUSTOMER_FUNDING_ACCOUNTS } from 'queries/fundingAccounts';

const FundingAccountsPage = () => {
  const { customerId } = useParams();
  const [fundingAccounts, setFundingAccounts] = useState([]);

  const accountsQuery = useQuery(CUSTOMER_FUNDING_ACCOUNTS, {
    notifyOnNetworkStatusChange: true,
    variables: { customerId },
    onCompleted: (data) => {
      setFundingAccounts(data?.fundingAccounts || []);
    },
  });

  return (
    <Card bordered={false} title="Funding Accounts">
      <QueryBoundary
        query={accountsQuery}
        mode={QueryBoundary.MODE.MESSAGE}
        loadingMessage="Retrieving Funding Accounts"
      >
        <FundingAccountTable
          fundingAccounts={fundingAccounts}
          canAddFundingAccount={true}
          loading={accountsQuery.loading}
        />
      </QueryBoundary>
    </Card>
  );
};

export default FundingAccountsPage;
