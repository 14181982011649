import { gql } from '@apollo/client';

const ACTIVATE_CARD = gql`
  mutation ActivateCard(
    $customerId: String!
    $accountId: String!
    $ssnLast4: String!
    $cardLast4: String!
    $dateOfBirth: String!
    $zipCode: String!
  ) {
    activateCardV2(
      customerId: $customerId
      accountId: $accountId
      last4OfSSN: $ssnLast4
      last4OfCard: $cardLast4
      dateOfBirth: $dateOfBirth
      zipCode: $zipCode
    ) {
      activationStatus
    }
  }
`;

const RESET_ACTIVATION_ATTEMPTS = gql`
  mutation ResetActivationAttempts($customerId: String!, $accountId: String!) {
    resetActivationAttempts(customerId: $customerId, accountId: $accountId)
  }
`;
export { ACTIVATE_CARD, RESET_ACTIVATION_ATTEMPTS };
