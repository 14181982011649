import { Empty, Space, Statistic as AntStatistic, Tag } from 'antd';
import styled from '@emotion/styled';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AccountTitle from 'components/AccountTitle/AccountTitle';
import AssetPill from 'components/Pill/AssetPill';
import GracePeriodPill from 'components/Pill/GracePeriodPill';
import LinkCard from 'components/LinkCard/LinkCard';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import { COLORS } from 'utilities/styles';

const OVERVIEW_BODY_HEIGHT = '195px';

const OverviewBody = styled.div`
  min-height: ${OVERVIEW_BODY_HEIGHT};
`;

const Statistic = styled(AntStatistic)(({ placeholder }) => {
  return placeholder
    ? `
      .ant-statistic-title, .ant-statistic-content {
        color: ${COLORS.grey3};
      }
    `
    : null;
});

const AccountOverview = ({
  accountLabel = 'Account',
  accountId,
  cardLast4,
  children,
  color = COLORS.grey1,
  cardIsActivated,
  cardIsPreExpired,
  enableNavigation = false,
  navigateTo = '',
  onNavigate = () => {},
  placeholder = false,
  stat = '--',
  statPrefix,
  statValue = '--',
  subHeading = null,
  query,
  creditorValue,
}) => {
  const { showActivateCard, enableAssetSale } = useFlags();
  color = placeholder ? COLORS.grey3 : color;

  return (
    <LinkCard
      enabled={enableNavigation}
      onNavigate={onNavigate}
      to={navigateTo}
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <AccountTitle
          color={color}
          level={5}
          loading={query?.loading}
          cardLast4={cardLast4}
        >
          {accountLabel}
        </AccountTitle>
        <AccountOverviewStatusPane
          accountId={accountId}
          showActivateCard={showActivateCard}
          cardIsActivated={cardIsActivated}
          cardIsPreExpired={cardIsPreExpired}
          query={query}
          creditorValue={creditorValue}
          enableAssetSale={enableAssetSale}
        />

        {subHeading}
        <Statistic
          title={stat}
          prefix={statPrefix}
          value={statValue}
          placeholder={placeholder}
        />
        <OverviewBody>
          <QueryBoundary
            messageHeight={OVERVIEW_BODY_HEIGHT}
            errorMessage={'Failed to load Account Data.'}
            query={query}
            skeleton={{
              title: false,
              paragraph: { rows: 6, width: '100%' },
            }}
          >
            {children ? (
              children
            ) : !placeholder ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            ) : null}
          </QueryBoundary>
        </OverviewBody>
      </Space>
    </LinkCard>
  );
};

export default AccountOverview;

function AccountOverviewStatusPane({
  accountId,
  query,
  showActivateCard,
  cardIsActivated,
  cardIsPreExpired,
  creditorValue,
  enableAssetSale,
}) {
  const statusPillProps =
    cardIsActivated || cardIsPreExpired
      ? {
          color: COLORS.green1,
          icon: <CheckCircleOutlined />,
          label: 'Activated',
        }
      : {
          color: COLORS.orange1,
          icon: <ExclamationCircleOutlined />,
          label: 'Not Activated',
        };
  return (
    <Space>
      {!query && (
        <>
          {showActivateCard && (
            <Tag
              color={statusPillProps.color}
              icon={statusPillProps.icon}
              style={{ margin: 0, color: COLORS.haze4 }}
            >
              {statusPillProps.label}
            </Tag>
          )}
          {enableAssetSale && creditorValue && (
            <AssetPill id="account-creditor" name={creditorValue} />
          )}
          <GracePeriodPill accountId={accountId} />
        </>
      )}
    </Space>
  );
}
