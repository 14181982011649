import { gql } from '@apollo/client';

const ADD_APPLICATION_INTERACTION = gql`
  mutation AddApplicationInteraction(
    $agentId: String!
    $applicationId: String!
    $agentName: String!
    $inBound: Boolean!
    $resolution: String!
    $channel: String!
    $nextTouchDateTime: String!
    $collections: Boolean!
    $calledFrom: String!
    $calledTo: String!
    $product: String!
    $topicCategory: String!
    $topic: String!
    $callTags: String!
    $contactEventId: String
  ) {
    addInteractionForApplicationV3(
      agentId: $agentId
      applicationId: $applicationId
      agentName: $agentName
      inBound: $inBound
      resolution: $resolution
      channel: $channel
      nextTouchDateTime: $nextTouchDateTime
      collections: $collections
      calledFrom: $calledFrom
      calledTo: $calledTo
      product: $product
      topicCategory: $topicCategory
      topic: $topic
      callTags: $callTags
      contactEventId: $contactEventId
    ) {
      id
      applicationId
      agentId
      agentName
      inBound
      resolution
      channel
      nextTouchDateTime
      collections
      calledFrom
      calledTo
      product
      topicCategory
      topic
      callTags
      contactEventId
      createdTz
      updatedTz
    }
  }
`;

const ADD_CUSTOMER_INTERACTION = gql`
  mutation AddCustomerInteraction(
    $agentId: String!
    $customerId: String!
    $agentName: String!
    $inBound: Boolean!
    $resolution: String!
    $channel: String!
    $nextTouchDateTime: String!
    $collections: Boolean!
    $calledFrom: String!
    $calledTo: String!
    $product: String!
    $topicCategory: String!
    $topic: String!
    $callTags: String!
    $contactEventId: String
  ) {
    addInteractionForCustomerV3(
      agentId: $agentId
      customerId: $customerId
      agentName: $agentName
      inBound: $inBound
      resolution: $resolution
      channel: $channel
      nextTouchDateTime: $nextTouchDateTime
      collections: $collections
      calledFrom: $calledFrom
      calledTo: $calledTo
      product: $product
      topicCategory: $topicCategory
      topic: $topic
      callTags: $callTags
      contactEventId: $contactEventId
    ) {
      id
      customerId
      agentId
      agentName
      inBound
      resolution
      channel
      nextTouchDateTime
      collections
      calledFrom
      calledTo
      product
      topicCategory
      topic
      callTags
      contactEventId
      createdTz
      updatedTz
    }
  }
`;

const ADD_APPLICATION_NOTE = gql`
  mutation AddNewNote(
    $agentId: String!
    $applicationId: String!
    $agentName: String!
    $isSticky: Boolean!
    $message: String!
    $product: String!
  ) {
    addNoteForApplicationV3(
      agentId: $agentId
      applicationId: $applicationId
      agentName: $agentName
      isSticky: $isSticky
      message: $message
      product: $product
    ) {
      id
      applicationId
      agentId
      agentName
      message
      isSticky
      product
      createdTz
      updatedTz
    }
  }
`;

const ADD_CUSTOMER_NOTE = gql`
  mutation AddNewNote(
    $agentId: String!
    $customerId: String!
    $agentName: String!
    $isSticky: Boolean!
    $message: String!
    $product: String!
  ) {
    addNoteForCustomerV3(
      agentId: $agentId
      customerId: $customerId
      agentName: $agentName
      isSticky: $isSticky
      message: $message
      product: $product
    ) {
      id
      customerId
      agentId
      agentName
      message
      isSticky
      product
      createdTz
      updatedTz
    }
  }
`;

const ADD_APPLICATION_VIEW = gql`
  mutation AddApplicationView(
    $applicationId: String!
    $agentId: String!
    $agentName: String!
  ) {
    addViewForApplicationV3(
      applicationId: $applicationId
      agentId: $agentId
      agentName: $agentName
    ) {
      id
      applicationId
      agentId
      agentName
      createdTz
      updatedTz
    }
  }
`;

const ADD_CUSTOMER_VIEW = gql`
  mutation AddCustomerView(
    $customerId: String!
    $agentId: String!
    $agentName: String!
  ) {
    addViewForCustomerV3(
      customerId: $customerId
      agentId: $agentId
      agentName: $agentName
    ) {
      id
      customerId
      agentId
      agentName
      createdTz
      updatedTz
    }
  }
`;

// Bulk notes for customers
const ADD_NOTES_MUTATION = gql`
  mutation AddBulkNotes(
    $customerIds: [String!]!
    $applicationIds: [String!]!
    $agentId: String!
    $agentName: String!
    $message: String!
    $isSticky: Boolean!
    $product: String!
  ) {
    addBulkNotesV3(
      agentId: $agentId
      customerIds: $customerIds
      applicationIds: $applicationIds
      agentName: $agentName
      isSticky: $isSticky
      message: $message
      product: $product
    ) {
      id
    }
  }
`;

const UPDATE_STICKY_NOTE = gql`
  mutation UpdateStickyNote(
    $noteId: String!
    $agentName: String!
    $agentId: String!
  ) {
    updateStickyV3(noteId: $noteId, agentName: $agentName, agentId: $agentId)
  }
`;

const ADD_COMMENT_MUTATION = gql`
  mutation addComment(
    $noteId: String!
    $agentId: String!
    $agentName: String!
    $message: String!
  ) {
    addNoteCommentV3(
      noteId: $noteId
      agentId: $agentId
      agentName: $agentName
      message: $message
    ) {
      agentId
      agentName
      createdTz
      id
      message
      updatedTz
    }
  }
`;

const UPDATE_COMMENT_MUTATION = gql`
  mutation updateComment(
    $noteId: String!
    $commentId: String!
    $agentId: String!
    $agentName: String!
    $message: String!
  ) {
    updateNoteCommentV3(
      noteId: $noteId
      commentId: $commentId
      agentId: $agentId
      agentName: $agentName
      message: $message
    ) {
      agentId
      agentName
      createdTz
      id
      message
      updatedTz
    }
  }
`;

export {
  ADD_APPLICATION_INTERACTION,
  ADD_CUSTOMER_INTERACTION,
  ADD_APPLICATION_NOTE,
  ADD_CUSTOMER_NOTE,
  ADD_APPLICATION_VIEW,
  ADD_CUSTOMER_VIEW,
  ADD_NOTES_MUTATION,
  ADD_COMMENT_MUTATION,
  UPDATE_COMMENT_MUTATION,
  UPDATE_STICKY_NOTE,
};
