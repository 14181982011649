import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import Loading from 'components/Loading/Loading';
import { CUSTOMER_SEARCH } from 'queries/customerSearch';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const CustomerDirectLinkSearch = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  const toCustomerSearch = () => {
    history.push('/search');
  };

  if (validateEmail(email)) {
    useQuery(CUSTOMER_SEARCH, {
      variables: {
        customerSearchRequestBody: { email: email },
      },
      onCompleted: (response) => {
        const customer = response?.searchCustomer?.[0];
        if (customer?.customerType === 'CUSTOMER') {
          history.push(`/customers/${customer.customerId}`);
        } else if (customer?.customerType === 'APPLICANT') {
          history.push(`/applicants/${customer.customerId}`);
        } else {
          toCustomerSearch();
        }
      },
      onError: () => {
        toCustomerSearch();
      },
    });
  } else {
    toCustomerSearch();
  }

  return <Loading />;
};

export default CustomerDirectLinkSearch;
