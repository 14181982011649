import { Button, Form, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';

import FraudNewCaseEvidenceFormRow from './FraudNewCaseEvidenceFormRow';
import FraudNewCaseReasonFormRow from './FraudNewCaseReasonFormRow';

import QueryResult from 'components/QueryResult/QueryResult';
import { ACTION_OPTIONS } from 'queries/fraudCases';

const ReasonsEvidenceFormList = () => {
  const { loading, error, data } = useQuery(ACTION_OPTIONS);

  return (
    <QueryResult loading={loading} error={error} data={data} called={true}>
      <Form.List
        name="reasons"
        rules={[
          {
            required: true,
            message: 'Please select a report!',
          },
        ]}
        initialValue={[
          {
            /* Intentionally empty object. This will render one <ReasonFormRow /> with default values. */
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name }) => (
              <FraudNewCaseReasonFormRow
                key={name}
                name={name}
                onRemove={
                  fields.length > 1 ? (name) => remove(name) : undefined
                }
                data={data}
              />
            ))}
            <Form.Item>
              <Button onClick={() => add()} block icon={<PlusOutlined />}>
                Add Reason
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item>
        <Typography.Text strong>Evidence: </Typography.Text>
      </Form.Item>

      <Form.List name="evidence">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name }) => (
              <FraudNewCaseEvidenceFormRow
                name={name}
                key={name}
                data={data}
                onRemove={(name) => remove(name)}
              />
            ))}
            <Form.Item>
              <Button onClick={() => add()} block icon={<PlusOutlined />}>
                Add Evidence
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </QueryResult>
  );
};

export default ReasonsEvidenceFormList;
