import { useState } from 'react';
import { Modal } from 'antd';
import { useLazyQuery } from '@apollo/client';

import QueryResult from 'components/QueryResult/QueryResult';
import SanitizeHTML from 'components/SanitizeHTML/SanitizeHTML';
import useModalControls from 'hooks/useModalControls';
import { CUSTOMER_RECORD_DOCUMENT } from 'queries/comms';
import { formatDateTime } from 'utilities/datesAndTimes';
import { constructTextHighlightAsString } from 'utilities/helpers';

const CommunicationEvent = ({ comm, highlight }) => {
  const eventDetailModal = useModalControls();
  const [documentText, setDocumentText] = useState('');
  const [getDocument, documentQuery] = useLazyQuery(CUSTOMER_RECORD_DOCUMENT, {
    onCompleted: (data) => setDocumentText(data?.commCustomerIoDocument?.body),
  });

  const showModal = () => {
    getDocument({
      variables: { documentId: comm.documentId },
    });
    eventDetailModal.show();
  };

  const date = formatDateTime(comm.sentDate);
  let message = `${comm.communicationType}: ${comm.actionName} - ${comm.title}`;
  if (highlight) {
    message = constructTextHighlightAsString(message, highlight);
  }

  return (
    <>
      <a
        role="button"
        onClick={showModal}
        data-testid="communication-event-item"
      >
        <div>{date}</div>
        <SanitizeHTML html={message} />
      </a>
      <Modal
        {...eventDetailModal}
        cancelButtonProps={{ hidden: true }}
        title={`${comm.communicationType}: ${date}`}
        width={650}
      >
        <QueryResult {...documentQuery}>
          <div dangerouslySetInnerHTML={{ __html: documentText }} />
        </QueryResult>
      </Modal>
    </>
  );
};

export default CommunicationEvent;
