import { gql } from '@apollo/client';

const GET_CAG_BY_ACCOUNT_ID = gql`
  query GetCagByAccountId($accountId: String!) {
    bookedAccountsLatestCag(accountId: $accountId) {
      documentLink
    }
  }
`;

export { GET_CAG_BY_ACCOUNT_ID };
