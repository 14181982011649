import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Modal, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useModalControls from 'hooks/useModalControls';
import { GET_ACCOUNT_CARD_LAST_4 } from 'queries/account';
import { ONE_TIME_PAYMENT } from 'queries/payments';
import {
  getFundingAccountName,
  formatDollarsFromCents,
} from 'utilities/helpers';
import {
  SETTLEMENT_OFFER_TYPES,
  SETTLEMENT_OFFER_STATES,
} from 'utilities/constants';

import ChangeFundingAccount from './ChangeFundingAccount';
import {
  setPaymentFundingAccount,
  usePaymentPlanState,
  setEftaDetails,
} from './paymentPlanState';

const { Text, Paragraph } = Typography;

const ActiveOfferCard = ({ thirdParty }) => {
  const { customerId, accountId } = useParams();
  const { customerInfo } = useCustomerInfo();

  const { balance, fundingAccount, offer } = usePaymentPlanState();

  const [settlementPercentage, setSettlementPercentage] = useState('');
  const [cardLast4, setCardLast4] = useState('');
  const fundingAccountModal = useModalControls();

  const isPendingCloseOut =
    offer?.state === SETTLEMENT_OFFER_STATES.PENDING_CLOSE_OUT;

  const isPifOffer = offer?.offerType === SETTLEMENT_OFFER_TYPES.PIF;

  useEffect(() => {
    const settlementBalancePercentage =
      ((balance.originalSettlementBalance / 100).toFixed(2) /
        (balance.originalOutstandingBalance / 100).toFixed(2)) *
      100;

    setSettlementPercentage(`${Math.round(settlementBalancePercentage)}%`);
  }, [balance]);

  const title = isPifOffer ? (
    <>
      Paid in Full |{' '}
      <Text type="secondary">
        100%
        {offer?.duration?.unit === 'MONTHS'
          ? `, ${offer?.duration.value} ${offer?.duration.unit.toLowerCase()}`
          : ' of outstanding balance'}
      </Text>
    </>
  ) : (
    <>
      Settlement |{' '}
      <Text type="secondary">
        {settlementPercentage}
        {offer?.duration?.unit === 'MONTHS'
          ? `, ${
              offer?.duration?.value
            } ${offer?.duration?.unit?.toLowerCase()}`
          : ' of outstanding balance'}
      </Text>
    </>
  );

  const balanceText = isPifOffer ? 'Program Balance' : 'Settlement Balance';

  const fundingAccountsQuery = useQuery(ONE_TIME_PAYMENT, {
    variables: { customerId, accountId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const fundingAccountName = getFundingAccountName(
        data?.fundingAccounts,
        fundingAccount.id,
      );
      setPaymentFundingAccount(fundingAccount.id, fundingAccountName);
    },
  });

  const [getCardLast4Query] = useLazyQuery(GET_ACCOUNT_CARD_LAST_4, {
    onCompleted: ({ account }) => {
      setCardLast4(account.cardDetails.last4);
    },
  });

  useEffect(() => {
    getCardLast4Query({ variables: { customerId, accountId } });

    const customerName = customerInfo.fullName;
    const email = customerInfo.email;

    const fundingAccountLast4 = fundingAccount?.name?.substring(
      fundingAccount.name.length - 4,
      fundingAccount.name.length,
    );

    if (cardLast4 && fundingAccountLast4) {
      setEftaDetails(customerName, email, cardLast4, fundingAccountLast4);
    }
  }, [customerId, fundingAccount, cardLast4]);

  return (
    <>
      <Card type="inner" title={title}>
        <Paragraph>
          {balanceText}:{' '}
          <Text strong>
            {formatDollarsFromCents(balance.originalSettlementBalance)}
          </Text>
          <br />
          Remaining Balance:{' '}
          {isPendingCloseOut ? (
            <Text strong mark>
              {formatDollarsFromCents(balance.remainingBalance)}
            </Text>
          ) : (
            <Text strong>
              {formatDollarsFromCents(balance.remainingBalance)}
            </Text>
          )}
        </Paragraph>
        <Paragraph>
          <Text strong>
            Plan Due Date: {dayjs(offer.expiration).format('LL')}
          </Text>
        </Paragraph>
        {fundingAccount.id ? (
          <>
            <Text strong>Funding Account</Text>
            {!isPendingCloseOut && (
              <Button
                icon={<EditOutlined />}
                onClick={fundingAccountModal.show}
                type="text"
              />
            )}
            <br />
            <QueryBoundary
              query={fundingAccountsQuery}
              skeleton={{
                title: false,
                paragraph: { rows: 1 },
              }}
            >
              <Text type="secondary">{fundingAccount?.name ?? ''}</Text>
            </QueryBoundary>
          </>
        ) : (
          <Paragraph>
            <Text strong> Payment Method: Debit Card</Text>
          </Paragraph>
        )}
      </Card>
      <Modal {...fundingAccountModal} footer={[]}>
        <ChangeFundingAccount
          onSuccess={fundingAccountModal.hide}
          thirdParty={thirdParty}
        />
      </Modal>
    </>
  );
};

export default ActiveOfferCard;
