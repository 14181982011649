import { gql } from '@apollo/client';

const AUTOMATED_UPDATES_APPLICATION = gql`
  query AutomatedUpdates($applicationId: String!) {
    auto: automatedUpdatesV3(applicationId: $applicationId) {
      id
      customerId
      agentId
      agentName
      message
      product
      createdTz
      updatedTz
      __typename
    }
  }
`;
const AUTOMATED_UPDATES_CUSTOMER = gql`
  query AutomatedUpdates($customerId: String!) {
    auto: automatedUpdatesV3(customerId: $customerId) {
      id
      customerId
      agentId
      agentName
      message
      product
      createdTz
      updatedTz
      __typename
    }
    comms: commCustomerRecords(customerId: $customerId, page: 0, size: 500) {
      content {
        actionName
        communicationType
        documentId
        provider
        sentDate
        title
        __typename
      }
      __typename
    }
  }
`;

export { AUTOMATED_UPDATES_APPLICATION, AUTOMATED_UPDATES_CUSTOMER };
