import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Checkbox,
  Flex,
  List,
  Modal as AntModal,
  Typography,
} from 'antd';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';

import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAccountQuery from 'hooks/useAccountQuery';
import useAgent from 'hooks/useAgent';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { UPDATE_ACCOUNT_STATUS } from 'mutations/account';
import { getCardDisplayName } from 'utilities/helpers';
import {
  ACCOUNT_STATUS_CODE,
  ACCOUNT_STATUS_OPERATION,
  BREAKPOINT,
  SIZE,
  STATUS,
} from 'utilities/constants';

const Modal = styled(AntModal)`
  min-width: 100%;
  top: var(--spacing-lg);

  @media (min-width: ${BREAKPOINT.MD_PX}) {
    min-width: 740px;
  }
`;

const IS_MULTI_ACCOUNT = Object.freeze([ACCOUNT_STATUS_CODE.TIERED_WATCH]);

const REQUIRES_SUPER_USER_PRIVILEGE = Object.freeze([
  ACCOUNT_STATUS_CODE.TIERED_WATCH,
]);

const RemoveStatusModal = ({ statusToRemove, hide, ...modalProps }) => {
  const { accountId, customerId } = useParams();
  const agent = useAgent();

  const [applyToAllAccounts, setApplyToAllAccounts] = useState(true);
  const [showRemovalError, setShowRemovalError] = useState(false);
  const [showCantRemoveError, setShowCantRemoveError] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useTimeoutValue(
    false,
    3000,
  );

  const { isMultiAccount, fullStatusCode, requiresSuperUserPrivilege } =
    useMemo(() => {
      let isMultiAccount = false;
      let requiresSuperUserPrivilege = false;
      let fullStatusCode = '';
      if (statusToRemove) {
        isMultiAccount = IS_MULTI_ACCOUNT.includes(statusToRemove?.statusCode);
        requiresSuperUserPrivilege = REQUIRES_SUPER_USER_PRIVILEGE.includes(
          statusToRemove?.statusCode,
        );
        fullStatusCode = `${statusToRemove.statusCode}_${statusToRemove.reasonCode}`;
      }
      return { isMultiAccount, fullStatusCode, requiresSuperUserPrivilege };
    }, [statusToRemove]);

  const canRemoveStatus = useMemo(() => {
    return requiresSuperUserPrivilege ? agent.isSuperUser : true;
  }, [agent, requiresSuperUserPrivilege]);

  const { account: currentViewedAccount, accounts } = useAccountQuery(
    customerId,
    accountId,
  );

  const [updateAccountStatus, removeMutation] = useMutation(
    UPDATE_ACCOUNT_STATUS,
    {
      onCompleted: () => {
        setSubmissionSuccess(true);
        setTimeout(hide, 3000);
      },
      onError: () => {
        setShowRemovalError(true);
      },
      refetchQueries: [
        'GetAccountStatuses',
        'GetAccountStatusesRaw',
        'GetCustomerWithFullAccounts',
        'GetAccountStatementPreferences',
      ],
    },
  );

  const closeModal = () => {
    setShowCantRemoveError(false);
    setShowRemovalError(false);
    hide();
  };

  const handleSubmit = async () => {
    if (canRemoveStatus) {
      setShowRemovalError(false);
      setShowCantRemoveError(false);

      const { statusCode, reasonCode } = statusToRemove;
      await updateAccountStatus({
        variables: {
          customerId,
          accountIds: [accountId],
          agentName: agent?.user?.name,
          operation: ACCOUNT_STATUS_OPERATION.REMOVED,
          statusCode,
          reasonCode: `${statusCode}_${reasonCode}`,
        },
      });
    } else {
      setShowCantRemoveError(true);
    }
  };

  return (
    <Modal
      {...modalProps}
      title="Remove Account Status"
      okText="Remove Account Status"
      onOk={handleSubmit}
      onCancel={closeModal}
      confirmLoading={removeMutation.loading}
      okButtonProps={{
        disabled: submissionSuccess,
      }}
    >
      <div style={{ position: 'relative' }}>
        <SectionMessage
          data-testid="status-removal-success-message"
          status={STATUS.SUCCESS}
          size={SIZE.SM}
          text="Account Status Removed"
          cover={true}
          visible={submissionSuccess}
        ></SectionMessage>

        <Flex vertical={true} gap="var(--spacing-lg)">
          {!canRemoveStatus ? (
            <Alert
              type="warning"
              message={`Removing the following status requires Super User privileges.`}
            ></Alert>
          ) : (
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              Are you sure you would like to remove the following status?
            </Typography.Paragraph>
          )}

          <div>
            {isMultiAccount ? (
              <Checkbox
                data-testid="apply-to-all-accounts-checkbox"
                checked={applyToAllAccounts}
                onChange={(event) => {
                  setApplyToAllAccounts(event.target.checked);
                }}
                style={{
                  marginBottom: 'var(--spacing-xs)',
                }}
              >
                Remove status from all accounts.
              </Checkbox>
            ) : null}

            <List
              header={
                <Typography.Text strong>
                  Removing {fullStatusCode} from:
                </Typography.Text>
              }
              bordered
              size="small"
              dataSource={
                isMultiAccount && applyToAllAccounts
                  ? accounts
                  : [currentViewedAccount]
              }
              renderItem={(account) => {
                return (
                  <List.Item>
                    <Typography.Text>
                      {getCardDisplayName(account)}
                    </Typography.Text>
                  </List.Item>
                );
              }}
            ></List>
          </div>

          {showRemovalError ? (
            <Alert
              type="error"
              message={`Failed to remove account status. Please try submitting again.`}
            ></Alert>
          ) : null}

          {showCantRemoveError ? (
            <Alert
              data-testid="not-super-user-error-message"
              type="error"
              message={`You do not have permission to remove this status.`}
            ></Alert>
          ) : null}
        </Flex>
      </div>
    </Modal>
  );
};

export default RemoveStatusModal;
