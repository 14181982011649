import localConfig from './env/local';
import stagingConfig from './env/staging';
import prodConfig from './env/prod';

let config = localConfig;
const { host } = window.location;

if (
  host.indexOf('mission-control.missionlane-staging.com') > -1 ||
  host.indexOf('mission-control-staging') > -1
) {
  config = stagingConfig;
} else if (host.indexOf('missionlane.com') > -1) {
  config = prodConfig;
}

// inject a static header to the configured headers.
if (!config.GQL_HEADERS) {
  config.GQL_HEADERS = {};
}

// only inject the header if there's not one provided by the config.
if (!config.GQL_HEADERS['x-lendup-card-channel']) {
  config.GQL_HEADERS['x-lendup-card-channel'] = 'ci tool';
}

export default config;
