import { gql } from '@apollo/client';

const ATTACHMENTS = gql`
  query GetAttachments($customerId: String) {
    attachments(customerId: $customerId) {
      date
      name
      url
    }
  }
`;

const ZD_ATTACHMENTS = gql`
  query GetZendeskAttachmentsV3(
    $email: String!
    $customerId: String
    $applicationId: String
  ) {
    zd_attachments: commZendeskAttachmentsByEmailV3(
      emailAddress: $email
      page: 0
      size: 100
      sort: "insertTz"
      direction: DESC
    ) {
      content {
        commentId
        contentUrl
        insertTz
        name
      }
    }
    attachments: attachmentsV3(
      customerId: $customerId
      applicationId: $applicationId
    ) {
      date
      name
      url
    }
  }
`;

export { ATTACHMENTS, ZD_ATTACHMENTS };
