import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Flex, Skeleton, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import useAccountQuery from 'hooks/useAccountQuery';
import useAnalytics from 'hooks/useAnalytics';
import { TRANSACTIONS } from 'queries/transactions';
import { REQUEST_CREDIT_BALANCE_REFUND } from 'mutations/creditBalanceRefund';
import { ANALYTICS_EVENTS } from 'utilities/constants';
import {
  formatDollarsFromCents,
  formatTransactionData,
} from 'utilities/helpers';
import { formatDateToSubmit } from 'utilities/datesAndTimes';

import StepContainer from '../StepContainer';
import {
  resetCreditBalanceRefundState,
  setSubmissionInitiated,
  setSubmissionCompleted,
} from './creditBalanceRefundState';

const { Paragraph, Text } = Typography;

const CountSkeleton = styled(Skeleton)`
  min-width: 1ch;
  max-width: 2ch;
  ul {
    margin-bottom: 0;
  }
`;

const SubmissionStep = ({ containerStyles }) => {
  const { accountId, customerId } = useParams();
  const { account } = useAccountQuery(customerId, accountId);
  const { trackEvent } = useAnalytics();

  const [authorizationCount, setAuthorizationCount] = useState('-');
  const [errorMessage, setErrorMessage] = useState();

  const refundAmount = useMemo(() => {
    let refundAmount = 0;

    if (account) {
      refundAmount = Math.abs(account.balanceInfo?.currentBalance || 0);
    }

    return refundAmount;
  }, [account]);

  const transactionsQuery = useQuery(TRANSACTIONS, {
    variables: {
      accountId,
      customerId,
      fromDate: formatDateToSubmit(dayjs().subtract(90, 'day')),
      toDate: formatDateToSubmit(dayjs()),
    },
    onCompleted: (data) => {
      const transactions = formatTransactionData(
        data?.account?.activities || [],
      );
      setAuthorizationCount(
        transactions.reduce((total, transaction) => {
          if (transaction.isAuthorization) {
            total++;
          }
          return total;
        }, 0),
      );
    },
  });

  const [requestCreditBalanceRefund, { loading: processingRefund }] =
    useMutation(REQUEST_CREDIT_BALANCE_REFUND, {
      variables: { accountId },
    });

  const handleSubmit = async () => {
    setSubmissionInitiated(true);
    setErrorMessage(undefined);

    try {
      await requestCreditBalanceRefund();
    } catch (error) {
      setErrorMessage(
        'Failed to submit credit balance refund request. Please try again.',
      );
      trackEvent(ANALYTICS_EVENTS.CBR_SUBMISSION_FAILURE, {
        customerId,
        accountId,
      });
      return;
    }

    setSubmissionCompleted(true);
    trackEvent(ANALYTICS_EVENTS.CBR_SUBMISSION_SUCCESS, {
      customerId,
      accountId,
    });
  };

  return (
    <StepContainer
      title="Approve Credit Balance Refund Request"
      onContinue={handleSubmit}
      onExit={resetCreditBalanceRefundState}
      buttonText="Approve"
      buttonProps={{
        loading: processingRefund,
        disabled: processingRefund,
      }}
      errorMessage={errorMessage}
      style={{
        ...containerStyles,
        maxWidth: '650px',
      }}
    >
      <Alert
        banner
        type="warning"
        style={{ marginBottom: 'var(--spacing-md)' }}
        message={
          <Text>
            To deny this request, exit process and send appropriate written
            exception.
          </Text>
        }
      />

      <Flex
        gap="var(--spacing-sm)"
        style={{ marginBottom: 'var(--spacing-md)' }}
      >
        <span>Outstanding Authorizations:</span>
        {transactionsQuery.loading ? (
          <CountSkeleton
            active={true}
            title={false}
            paragraph={{ rows: [{ width: '1rem' }] }}
          />
        ) : (
          authorizationCount
        )}
      </Flex>

      <Paragraph data-testid="no-fraud-message" style={{ marginBottom: 0 }}>
        This account has a credit balance of{' '}
        <strong>{formatDollarsFromCents(refundAmount)}</strong>. Upon clicking
        "Approve", the credit balance of{' '}
        <strong>{formatDollarsFromCents(refundAmount)}</strong> will be sent to
        TSYS and processed. The customer should receive their refund in 3 to 7
        business days.
      </Paragraph>
    </StepContainer>
  );
};

export default SubmissionStep;
