import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useAgent from './useAgent';

const useCanAccessCaseCreationForm = () => {
  const agent = useAgent();
  const flags = useFlags();

  return useMemo(() => {
    const showCaseCreationFormV2 = flags.showCaseCreationFormV2 || {};
    const released = showCaseCreationFormV2.released || false;
    const users = showCaseCreationFormV2.users || [];
    return (
      released ||
      !!users.find(
        (email) => email.toLowerCase() === agent?.user?.email?.toLowerCase(),
      )
    );
  }, [agent, flags]);
};

export default useCanAccessCaseCreationForm;
