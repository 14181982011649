import { useEffect, useState } from 'react';
import { useTranslation as i18nUseTranslation } from 'react-i18next';
import i18next from 'i18next';

/**
 *
 * @param {*} namespace - The namespace to load translations from (locales/{{lng}}/{{namespace}}.json). Can be a string or an array of strings
 */
const useTranslation = (namespace) => {
  const { t } = i18nUseTranslation(namespace);
  const [languageLoading, setLanguageLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18next?.language);

  useEffect(() => {
    const languageChangeListener = (newLanguage) =>
      setCurrentLanguage(newLanguage);
    i18next.on('languageChanged', languageChangeListener);

    return () => {
      i18next.off('languageChanged', languageChangeListener);
    };
  }, []);

  const changeLanguage = async (language = 'en', callback) => {
    setLanguageLoading(true);
    i18next.changeLanguage(language, () => {
      setLanguageLoading(false);
      callback?.();
    });
  };

  return { translateKey: t, changeLanguage, currentLanguage, languageLoading };
};

export default useTranslation;
