import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Docs: https://www.i18next.com/overview/configuration-options
const TranslationProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    i18n
      .use(Backend)
      .use(initReactI18next)
      .init(
        {
          lng: 'en',
          fallbackLng: 'en',
          defaultNS: 'common',
          ns: 'common',
          // debug: true, // Turn on for console logging
          interpolation: {
            escapeValue: false,
          },
        },
        () => setLoading(false),
      );
  }, []);

  if (loading) {
    return null;
  }

  return <>{children}</>;
};

export default TranslationProvider;
