import { gql } from '@apollo/client';

const ACCOUNT_FIELDS_FRAGMENT = gql`
  fragment AccountFields on Account {
    id
    activationEligibilityV2 {
      eligible
      reasons {
        description
        reasonCode
      }
    }
    accountDetails {
      creditorDisplay
      accountTag
    }
    statuses {
      isAccountOnWatch
      isBankrupt
      isChargedOff
      isCreditRevoked
      isCardOnWatch
      isClosed
      isDeceased
      isDelinquent
      isInLitigation
      isMilitaryLending
      isOverlimit
      isPastDue
      isScra
      daysPastDue
      hasAppFraud
      pastDueStatus
      raw {
        description
        reasonCode
        statusCode
        startDate
        stopDate
        date
      }
    }
    paymentInfo {
      minimumDue
      nextStatementCloseDate
      lastStatementCloseDate
      printDueDate
    }
    balanceInfo {
      availableCredit
      cashAdvanceAvailableCreditLimit
      currentBalance
      pastDueBalance
      statementBalance
      remainingStatementBalance
    }
    cardDetails {
      expirationDate
      last4
      lastCardRequestDate
      name
      issuanceState
      cardReceiptVerificationDate
      productDisplayName {
        productCode
        productName
      }
    }
    autopay {
      id
      enrolledDate
      scheduleStatus
    }
    pastDue {
      consecutiveDaysPastDue
      daysPastDue
      pastDueAmountTotal
      pastDueTotal
      pastDues {
        interval
        times
        total
      }
    }
    securedCardInfo {
      currentAmount
      status
      refundedDate
      isRefunded
      originalAmount
    }
    insuranceStatusInfo {
      insuranceType
      status
    }
    insuranceEligibilityInfo {
      insuranceType
      eligible
    }
  }
`;

const GET_ACCOUNT_CARD_NUMBER = gql`
  query GetAccountCardNumber(
    $accountId: String!
    $customerId: String!
    $phone: String!
  ) {
    account(accountId: $accountId, customerId: $customerId) {
      tokenizedCardNumber(phone: $phone)
    }
  }
`;

const GET_ACCOUNT_REWARDS = gql`
  query GetAccountRewards($accountId: String!, $customerId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      rewards {
        categoryBonus
        esbAmount
        esbRewardDate
        esbNetSpend
        rewardsRate
        categoryCode
        categoryName
        categoryRewardsRate
      }
    }
  }
`;

const ACCOUNT_STATUSES = gql`
  query GetAccountStatuses($customerId: String!, $accountIds: [String!]!) {
    accountFraudStatuses(customerId: $customerId, accountIds: $accountIds) {
      accountId
      accountStatuses {
        description
        reasonCode
        statusCode
      }
    }
  }
`;

const ACCOUNT_STATUS_BALANCE = gql`
  ${ACCOUNT_FIELDS_FRAGMENT}
  query GetAccountStatus($accountId: String!, $customerId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      ...AccountFields
    }
  }
`;

const ACCOUNT_PRICING_DETAILS = gql`
  query GetAccountPricingDetails($accountId: String!, $customerId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      pricingDetails {
        annualFee
        purchaseApr
        creditLimit
        cashAdvanceApr
        cashAdvanceCreditLimit
        isRewardsCard
        rewardsRate
        futureApr
        futureAprStartDate
        introAprLengthInMonths
        nextMembershipFeeDate
        purchaseAprType
        cashAprType
        hasLateFeeGrace
      }
    }
  }
`;

const ACCOUNT_REFERRALS = gql`
  query GetAccountReferrals($customerId: String!, $accountId: String!) {
    account(customerId: $customerId, accountId: $accountId) {
      offers {
        referral {
          data {
            referenceId
            offerId
            offerState
            type
            referenceId
            expirationDate
            invitationCode
            maximumStatementCredit
            statementCreditPerReferral
            referredCustomers {
              amountStatementCredit
            }
          }
        }
      }
    }
  }
`;

const GET_UPCOMING_PAYMENT_ACTIVITY = gql`
  query GetUpcomingPaymentActivity($accountId: String!, $customerId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      upcomingPayments {
        id
        state
      }
      autopay {
        id
        enrolledDate
        scheduleStatus
      }
    }
  }
`;

const GET_ACCOUNT_CARD_LAST_4 = gql`
  query GetAccountCardLast4($accountId: String!, $customerId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      cardDetails {
        last4
      }
    }
  }
`;

const GET_ACCOUNT_STATEMENT_PREFERENCES = gql`
  query GetAccountStatementPreferences(
    $accountId: String!
    $customerId: String!
  ) {
    account(accountId: $accountId, customerId: $customerId) {
      statementPreferences {
        canUpdateStatementPreference
        isPaperlessStatement
      }
    }
  }
`;

const GET_ACCOUNT_TRANSFER_HISTORY = gql`
  query GetAccountTransferHistory($accountId: String!, $previousMonths: Int!) {
    accountTransferHistory(
      accountId: $accountId
      previousMonths: $previousMonths
    ) {
      transfers {
        date
        fromCardNumberRedacted
        eventType
        statusReason
        toCardNumberRedacted
      }
    }
  }
`;

const ACCOUNT_STATUSES_RAW = gql`
  query GetAccountStatusesRaw($customerId: String!, $accountId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      statusesRaw {
        statuses {
          date
          description
          reasonCode
          startDate
          statusCode
          stopDate
        }
      }
    }
  }
`;

export {
  ACCOUNT_FIELDS_FRAGMENT,
  ACCOUNT_PRICING_DETAILS,
  ACCOUNT_REFERRALS,
  ACCOUNT_STATUS_BALANCE,
  ACCOUNT_STATUSES,
  ACCOUNT_STATUSES_RAW,
  GET_ACCOUNT_CARD_LAST_4,
  GET_ACCOUNT_CARD_NUMBER,
  GET_ACCOUNT_REWARDS,
  GET_UPCOMING_PAYMENT_ACTIVITY,
  GET_ACCOUNT_STATEMENT_PREFERENCES,
  GET_ACCOUNT_TRANSFER_HISTORY,
};
