import dayjs from 'dayjs';
import { Typography } from 'antd';

import { wholeDollarCurrencyFormatter } from 'utilities/helpers';
import { formatDate } from 'utilities/datesAndTimes';

const { Text } = Typography;

const EarlySpendBonus = ({ rewards }) => {
  const esbNetSpend = wholeDollarCurrencyFormatter(rewards?.esbNetSpend);

  const esbAmount = wholeDollarCurrencyFormatter(rewards?.esbAmount);

  const earlySpendBonusExpDate = rewards?.esbRewardDate
    ? formatDate(rewards?.esbRewardDate)
    : null;

  const earlySpendBonusIsExpired = dayjs().isAfter(
    dayjs(earlySpendBonusExpDate),
  );

  if (!esbNetSpend || !esbAmount || !earlySpendBonusExpDate) {
    return (
      <Text type="secondary" italic>
        Pending
      </Text>
    );
  }

  if (earlySpendBonusIsExpired) {
    return (
      <div data-testid="expired-early-spend-bonus">
        <Text type="danger" italic>
          Offer Expired
        </Text>
        <br />
        <Text type="secondary">
          Spend {esbNetSpend} by {earlySpendBonusExpDate}
          <br />
          for a {esbAmount} statement credit
        </Text>
      </div>
    );
  }

  return (
    <div data-testid="early-spend-bonus">
      Spend {esbNetSpend} by {earlySpendBonusExpDate}
      <br />
      for a {esbAmount} statement credit
    </div>
  );
};

export default EarlySpendBonus;
