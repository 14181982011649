import dayjs from 'dayjs';

import {
  ACCOUNT_STATUS_CODE,
  AGENT_ACTIVITY_TYPE,
  FRAUD_STATUS,
  PAST_DUE_INTERVAL,
  SETTLEMENTS_CADENCE_VALUES,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from './constants';
import { COLORS } from './styles';
import {
  dateIsWithin90Days,
  formatDateDescriptive,
  formatDateTimeIso,
} from './datesAndTimes';

/**
 * ACCOUNT HELPERS
 */
export const isTieredWatchStatus = (statusCode) => {
  return ACCOUNT_STATUS_CODE[statusCode] === ACCOUNT_STATUS_CODE.TIERED_WATCH;
};

export const getAccountLast4 = (fundingAccounts, id) => {
  return fundingAccounts?.find((account) => account.id === id)?.numberLast4;
};

export const getAccountBankName = (fundingAccounts, id) => {
  return fundingAccounts?.find((account) => account.id === id)?.bankName;
};

const PAYMENT_BUCKET_ORDER = Object.keys(PAST_DUE_INTERVAL).reduce(
  (map, bucket, index) => {
    map[bucket] = index;
    return map;
  },
  {},
);

export const transformPaymentData = (data) => {
  const account = data?.account || {};
  const balanceInfo = account.balanceInfo || {};
  const paymentInfo = account.paymentInfo || {};

  let statementMinimum = 0;
  if (account.pastDue) {
    const sortedBuckets = account.pastDue.pastDues
      .filter((bucket) => bucket.total > 0)
      .sort(
        (b1, b2) =>
          PAYMENT_BUCKET_ORDER[b2.interval] - PAYMENT_BUCKET_ORDER[b1.interval],
      );
    if (sortedBuckets.length) {
      statementMinimum = sortedBuckets[0].total;
    }
  }

  const paymentOptions = [
    {
      label: 'Minimum Due',
      amount: paymentInfo.minimumDue,
    },
    {
      label: 'Statement Minimum',
      amount: statementMinimum,
    },
    {
      label: 'Current Balance',
      amount: balanceInfo.currentBalance,
    },
    {
      label: 'Past Due Amount',
      amount: balanceInfo.pastDueBalance,
    },
  ];

  const statementBalance = balanceInfo.statementBalance;

  const remainingStatementBalance = balanceInfo.remainingStatementBalance;

  if (remainingStatementBalance !== statementBalance) {
    paymentOptions.push({
      label: 'Remaining Statement Balance',
      amount: remainingStatementBalance,
    });
  } else {
    paymentOptions.push({
      label: 'Statement Balance',
      amount: statementBalance,
    });
  }

  const fundingAccounts = data?.fundingAccounts || [];
  const defaultFundingAccount = fundingAccounts.find(
    (account) => account.isDefault,
  );

  const upcomingPayments = account.upcomingPayments || [];

  const scheduledPayments = upcomingPayments.filter(
    (payment) => payment.state === 'SCHEDULED',
  );

  const totalScheduledPayments = scheduledPayments.reduce(
    (result, payment) => result + Math.abs(payment.amount),
    0,
  );

  const canSchedulePayment =
    scheduledPayments.filter((payment) =>
      dateIsWithin90Days(dayjs(payment.date)),
    ).length < 3;

  return {
    totalScheduledPayments,
    canSchedulePayment,
    paymentOptions,
    fundingAccounts,
    defaultFundingAccount,
    cardLast4: data?.account?.cardDetails?.last4,
    statementDueDate: dayjs(data?.account?.paymentInfo?.printDueDate),
  };
};

/**
 * AGENT ACTIVITY HELPERS
 */
export const isAgentNote = (interaction) => {
  return (
    interaction?.activityType === AGENT_ACTIVITY_TYPE.AGENT_NOTE ||
    interaction?.activityType === AGENT_ACTIVITY_TYPE.AGENT_HISTORICAL_NOTE ||
    interaction?.activityType === AGENT_ACTIVITY_TYPE.AGENT_INTERACTION
  );
};

export const isZendeskInteraction = (interaction) => {
  return interaction?.activityType === AGENT_ACTIVITY_TYPE.ZENDESK_EVENT;
};

/**
 * CREDIT HELPERS
 */
export const getCardDisplayName = (account) => {
  const { productDisplayName, last4: cardLastFour } = {
    ...account?.cardDetails,
  };
  const { productName } = { ...productDisplayName };
  const cardLast4Suffix = `(...${cardLastFour})`;
  return `${productName ?? 'Visa'} ${cardLast4Suffix}`;
};

export const minDueWarningHelper = (minDue, accountBalance) => {
  const shouldHighlightMinDue = minDue > accountBalance;
  const minDueStyle = shouldHighlightMinDue ? { color: COLORS.red1 } : {};
  const minDueFormatted = formatDollarsFromCents(minDue);
  return {
    shouldHighlightMinDue,
    minDueStyle,
    minDueFormatted,
    highMinDueWarningMessage: 'Current balance less than minimum due',
  };
};

/**
 * CURRENCY HELPERS
 */
export const parseCurrency = (value) => {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return value.replace(/\$\s?|(,*)/g, '');
};

const defaultCurrencyFormatter = new Intl.NumberFormat('en-US', {
  //format
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Examples:
 * 100.00 -> $100
 * 100.1  -> $100.10
 * 100.50 -> $100.50
 */
export const dollarCurrencyFormatter = new Intl.NumberFormat('en-US', {
  //format
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  trailingZeroDisplay: 'stripIfInteger',
});

export const currencyFormatter = (value) => {
  //format
  if (typeof value === 'string') {
    return defaultCurrencyFormatter.format(
      Number(value.replace(/[^\d.]/g, '')),
    ); //remove all non-numeric
  }

  if (typeof value === 'number') {
    return defaultCurrencyFormatter.format(value);
  }

  return value;
};

export const wholeDollarCurrencyFormatter = (value) => {
  //format
  if (typeof value === 'string') {
    return dollarCurrencyFormatter
      .format(Number(value.replace(/[^\d.]/g, '')))
      .replace(/\.00$/, ''); //remove all non-numeric
  }

  if (typeof value === 'number') {
    return dollarCurrencyFormatter.format(value).replace(/\.00$/, '');
  }

  return value;
};

export const formatDollarsFromCents = (amount) => {
  if (isNaN(amount)) {
    return;
  }
  return currencyFormatter(amount / 100);
};

/**
 * FORMATTING HELPERS
 */
export const formatFileName = (fileName) => {
  //format
  if (!fileName) {
    return fileName;
  }
  const firstUnderscore = fileName.indexOf('_');
  return fileName.substring(firstUnderscore + 1, fileName.length);
};

export const formatFundingAccount = (account) => {
  //format
  return account
    ? `${account.bankName} - ${account.numberLast4} - ${account.id}`
    : '---';
};

export const formatPathForAnalytics = (path) => {
  //format
  if (!path || path.length < 1) {
    return path;
  }
  return path.split('/').reduce((previousPath, currentSegment, i, fullPath) => {
    if (fullPath[i - 1] === 'customers' || currentSegment.length <= 1) {
      return previousPath;
    }
    return `${previousPath}${currentSegment}/`;
  }, '/');
};

/**
 * FRAUD HELPERS
 */
export const getFraudStatus = (value) => {
  let status = '';
  switch (value) {
    case FRAUD_STATUS.SETTING_AS_CLEARED:
    case FRAUD_STATUS.CLEARED:
      status = FRAUD_STATUS.CLEARED;
      break;
    case FRAUD_STATUS.SETTING_AS_CONFIRMED:
    case FRAUD_STATUS.CONFIRMED:
      status = FRAUD_STATUS.CONFIRMED;
      break;
    case FRAUD_STATUS.SETTING_AS_UNDER_INVESTIGATION:
    case FRAUD_STATUS.UNDER_INVESTIGATION:
      status = FRAUD_STATUS.UNDER_INVESTIGATION;
      break;
    case FRAUD_STATUS.SETTING_AS_RESTRICTED:
    case FRAUD_STATUS.RESTRICTED:
      status = FRAUD_STATUS.RESTRICTED;
      break;
    case FRAUD_STATUS.SETTING_AS_RESTORED:
    case FRAUD_STATUS.RESTORED:
      status = FRAUD_STATUS.RESTORED;
      break;
    case FRAUD_STATUS.SETTING_AS_REJECTED:
      status = FRAUD_STATUS.REJECTED;
      break;
    default:
  }
  return status;
};

export const formatTransactionData = (
  transactions = [],
  requestFromDate,
  requestToDate,
) => {
  return transactions.map((transaction, index) => {
    const formatted = {
      ...transaction,
      key: `transaction-${index}-${transaction.transactionId}`,
      id: transaction.transactionId,
      detailDateRange: {
        from: requestFromDate,
        to: requestToDate,
      },
    };

    const isAuthorization = formatted.sourceActivityType === 'AUTH';
    const isFraudAuthorization =
      formatted.__typename === 'FraudAuthorizationActivity';

    formatted.isAuthorization = isAuthorization || isFraudAuthorization;
    formatted.isFraudAuthorization = isFraudAuthorization;

    if (formatted.date) {
      formatted.dateTimestamp = formatDateTimeIso(
        formatted.date,
        !isFraudAuthorization,
      );
      formatted.date = dayjs(formatted.date);
    }

    if (formatted.dateTimeAuthorization) {
      formatted.dateTimestamp = formatDateTimeIso(
        formatted.dateTimeAuthorization,
        !isFraudAuthorization,
      );
      formatted.dateTimeAuthorization = dayjs(formatted.dateTimeAuthorization);
    }

    if (
      formatted.postedAt &&
      formatted.postedAt !== 'null' /* This is unusual. Please see below */
    ) {
      formatted.postedAtTimestamp = formatted.postedAt;
      formatted.postedAt = dayjs(formatted.postedAt);
    }

    if (isAuthorization || isFraudAuthorization) {
      formatted.type = TRANSACTION_TYPE.AUTHORIZATION;
      if (!isFraudAuthorization) {
        // In short, pendingAuthorization = (sourceType = AUTH) and ( (status = pending) or (date is null) and status = approved )
        formatted.isPendingAuthorization =
          (!formatted.postedAt &&
            formatted.amount > 0 &&
            formatted.activityStatus === TRANSACTION_STATUS.APPROVED) ||
          formatted.activityStatus === TRANSACTION_STATUS.PENDING;
      }
    }

    if (isFraudAuthorization) {
      const declineReason = formatted.responseInformation.declineReason;
      formatted.isDeclined = declineReason !== '0000';
    } else {
      formatted.isDeclined = formatted.state === TRANSACTION_STATUS.DECLINED;
    }

    if (isFraudAuthorization) {
      formatted.amount = formatted.transactionAmount;
      formatted.date = formatted.dateTimeAuthorization; //makes listing/sorting in transaction table easier
      formatted.merchantChannel = formatted.merchantInformation?.name;

      let state = formatted.responseInformation?.codeDescription;
      if (state === 'Unknown Desc') {
        state = formatted.responseInformation?.code;
      }
      formatted.state = state;
    } else {
      formatted.merchantChannel = formatted.description;
    }

    if (formatted.amount === null) {
      formatted.amount = 0;
    }
    return formatted;
  });
};

export const formatTransactionDescription = (transaction) => {
  if (!transaction) {
    return '-';
  }
  return [
    formatDateDescriptive(transaction.date),
    transaction.description,
    formatDollarsFromCents(transaction.amount),
  ].join(' - ');
};

export const displayValueOrBlank = (value) => {
  if (typeof value === 'number') {
    return value;
  }
  if (value === 'null') {
    return '-';
  }
  return value ? value : '-';
};

/**
 * Process an attachment url in order to allow it to open in new tab without downloading.
 * The reasoning for this is, for urls where we don't have access to setting the headers.
 * Manually Opens Attachments in new tab
 * @param {*} url
 */
export const sanitizeAndView = async (url) => {
  const blob = await fetch(url).then((r) => r.blob());
  const newUrl = window.URL.createObjectURL(blob);
  const newWindow = window.open(newUrl, '_blank');

  // It seems popup blockers are causing null windows
  if (newWindow) {
    newWindow.onload = () => {
      newWindow.focus();
      window.URL.revokeObjectURL(newUrl);
    };
  } else {
    window.URL.revokeObjectURL(newUrl);
  }
};

/**
 * RECOVERIES HELPERS
 */

// calculate default amount per payment period, no date calculations
export const getDefaultPayments = (
  balance,
  startDate,
  endDate,
  durationMonths,
) => {
  const weeklyDiff = Math.floor(
    dayjs(endDate).diff(dayjs(startDate), 'week', true),
  );

  const monthly = (balance / durationMonths).toFixed(2);
  const bimonthly = (balance / Math.floor(weeklyDiff / 2)).toFixed(2);
  const weekly = (balance / weeklyDiff).toFixed(2);

  const paymentObj = {
    monthly,
    bimonthly,
    weekly,
  };

  return paymentObj;
};

export const getFundingAccountName = (allFundingAccounts, accountId) => {
  const accountLast4 = getAccountLast4(allFundingAccounts, accountId);
  const bankName = getAccountBankName(allFundingAccounts, accountId);

  const displayName = `${bankName} ...${accountLast4}`;
  return displayName;
};

export const getEftaText = ({
  eftaDetails,
  todaysDate,
  paymentPlanSchedule,
  cadence,
}) => {
  const { cardLast4, customerName, fundingAccountLast4, email } = eftaDetails;
  const oneTimeDebit = cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT;
  const lumpSumPayment =
    cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME ||
    paymentPlanSchedule.length === 1;

  const achOrDebitText = oneTimeDebit
    ? 'make a one time electronic debit from a debit card for the following amount:'
    : `make ${
        lumpSumPayment ? 'an' : 'a recurring'
      } electronic debit from the bank account ending in ${fundingAccountLast4} for the following ${lumpSumPayment ? 'date and amount' : 'dates and amounts'}:`;

  return [
    `To make payments towards the balance on your Mission Lane Visa, ending in ${cardLast4}, you ${customerName}, today ${todaysDate}, authorize Mission Lane to ${achOrDebitText}`,
    `${JSON.stringify(paymentPlanSchedule)}`,
    'To cancel or change payments, call us at least one business day before the payment date at (888) 695-8536 from 8:30 AM to 8 PM Eastern Time, Monday through Friday.',
    'Do you authorize [this payment/these payments]? [Wait for response]',
    `Thank you, you've successfully authorized payments. We'll send a confirmation email to ${email}.`,
  ].join();
};

export const constructTextHighlightAsString = (inputStr, match) => {
  if (match) {
    inputStr = inputStr.replace(
      new RegExp(match, 'ig'),
      (hit) =>
        `<span style="background-color: ${COLORS.yellow1}">${hit}</span>`,
    );
  }
  return inputStr;
};

export const isReverseAuthAllowed = (account) => {
  const rawStatuses = account.statuses?.raw || [];
  const disallowedStatuses = [
    ACCOUNT_STATUS_CODE.CHARGED_OFF,
    ACCOUNT_STATUS_CODE.CREDIT_REVOKED,
    ACCOUNT_STATUS_CODE.PAST_DUE,
    ACCOUNT_STATUS_CODE.BANKTRUPTCY,
    ACCOUNT_STATUS_CODE.CREDIT_COUNSELING,
    ACCOUNT_STATUS_CODE.CLOSED,
    ACCOUNT_STATUS_CODE.ASSOCIATION_EXCEPTION_FILE,
    ACCOUNT_STATUS_CODE.FORECLOSURE,
    ACCOUNT_STATUS_CODE.CONFIRMED_FRAUD,
    ACCOUNT_STATUS_CODE.TRANSFERRED_TO_LENDER,
    ACCOUNT_STATUS_CODE.MISC_TRANSFER,
    ACCOUNT_STATUS_CODE.POTENTIAL_PURGE,
    ACCOUNT_STATUS_CODE.REISSUE_DECLINE,
    ACCOUNT_STATUS_CODE.REFUSED_TO_PAY,
    ACCOUNT_STATUS_CODE.DELINQUENT,
    ACCOUNT_STATUS_CODE.SECURITY_FRAUD,
    ACCOUNT_STATUS_CODE.SKIP_TRACE,
    ACCOUNT_STATUS_CODE.WARNING_BULLETIN,
    ACCOUNT_STATUS_CODE.TRANSFER,
  ];
  // in one loop to reduce O(n). Break on first false.
  for (const status of rawStatuses) {
    if (disallowedStatuses.includes(status.statusCode)) {
      return false;
    } else if (
      status.statusCode === ACCOUNT_STATUS_CODE.CARD_WATCH &&
      ['07', '08', '09', '10'].includes(status.reasonCode)
    ) {
      return false;
    } else if (
      status.statusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH &&
      ['L8', 'L9'].includes(status.reasonCode)
    ) {
      return false;
    }
  }
  return true;
};
