import { Modal as AntModal } from 'antd';
import styled from '@emotion/styled';

import {
  setTransactionSelected,
  setTransactionsSelected,
  useTransactionState,
} from './transactionState';
import TransactionTable from '../TransactionTable/TransactionTable';

import { BREAKPOINT } from 'utilities/constants';

const Modal = styled(AntModal)`
  min-width: 100%;
  top: var(--spacing-lg);

  @media (min-width: ${BREAKPOINT.MD_PX}) {
    min-width: 80%;
  }
`;

const ViewSelectedTransactionsModal = ({ ...modalProps }) => {
  const { selectedTransactionKeys, selectedTransactions } =
    useTransactionState();

  return (
    <Modal
      {...modalProps}
      centered
      cancelButtonProps={{ hidden: true }}
      title="Selected Transactions"
    >
      <TransactionTable
        transactions={selectedTransactions}
        rowSelection={{
          onSelect: setTransactionSelected,
          onSelectAll: setTransactionsSelected,
          selectedRowKeys: selectedTransactionKeys,
        }}
        showSelectionActions={true}
      />
    </Modal>
  );
};

export default ViewSelectedTransactionsModal;
