import AccountOverview from './AccountOverview';
import { PRODUCT_COLOR } from 'utilities/constants';

const DebitOverview = () => {
  const getAccountOverviewParams = () => {
    return {
      color: PRODUCT_COLOR.DEBIT,
      accountLabel: 'Money',
      tags: [],
      enabledNavigation: false,
    };
  };

  return <AccountOverview {...getAccountOverviewParams()}></AccountOverview>;
};

export default DebitOverview;
