import { COLORS } from './styles';

export const ACCOUNT_ATTRIBUTE = Object.freeze({
  CDF10: 'CDF10',
});

export const ACCOUNT_STATUS_OPERATION = Object.freeze({
  APPLIED: 'APPLIED',
  REMOVED: 'REMOVED',
  UPDATED: 'UPDATED',
});

export const ACCOUNT_STATUS_CODE = Object.freeze({
  BANKTRUPTCY: 'BK',
  CHARGED_OFF: 'CO',
  CW: 'CW',
  CARD_WATCH: 'CW',
  CL: 'CL',
  CLOSED: 'CL',
  CREDIT_COUNSELING: 'CC',
  CONFIRED_FRAUD: 'FR',
  CR: 'CR',
  CREDIT_REVOKED: 'CR',
  DECEASED: 'DC',
  ASSOCIATION_EXCEPTION_FILE: 'EX',
  FORECLOSURE: 'FO',
  MISC_TRANSFER: 'MX',
  TRANSFERRED_TO_LENDER: 'LT',
  PAST_DUE: 'PD',
  POTENTIAL_PURGE: 'PP',
  REISSUE_DECLINE: 'RD',
  REFUSED_TO_PAY: 'RP',
  RM: 'RM',
  RETURNED_MAIL: 'RM',
  DELINQUENT: 'RQ',
  SECURITY_FRAUD: 'SF',
  SKIP_TRACE: 'ST',
  TW: 'TW',
  TIERED_WATCH: 'TW',
  TRANSFER: 'XF',
  WA: 'WA',
  WATCH_ACCOUNT: 'WA',
  WARNING_BULLETIN: 'WB',
});

export const ACCOUNT_STATUS = Object.freeze({
  ENABLED: 'ENABLED',
  DEFAULT: 'DEFAULT',
  NON_OPERATIONAL: 'NON_OPERATIONAL',
  DISABLED: 'DISABLED',
});

export const ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE = Object.freeze({
  CARD_NOT_RECEIVED: 'NR',
  CARD_LOST: 'LS',
  CARD_STOLEN: 'ST',
});

export const AGENT_ACTIVITY_TYPE = Object.freeze({
  AGENT_NOTE: 'AGENT_NOTE',
  AGENT_VIEW: 'AGENT_VIEW',
  AGENT_HISTORICAL_NOTE: 'AGENT_HISTORICAL_NOTE',
  AGENT_INTERACTION: 'AGENT_INTERACTION',
  ZENDESK_EVENT: 'ZENDESK_EVENT',
});

export const AGENT_ROLES = Object.freeze({
  SUPER_USER: 'super-user',
  COMMAND_MANAGER: 'command-manager',
  AGENT: 'agent',
});

export const ANALYTICS_EVENTS = {
  // Customer Overview
  ADDRESS_EDIT_SAVED: 'Address edit submitted',
  ADDRESS_EDIT_FORCED: 'Address edit forced',
  AUTO_EVENTS_SCROLL_LIMIT_HIT: 'Auto events scrolled',
  AUTO_EVENTS_MISCLICKED: 'Auto event click attempted',
  AUTOMATED_EMAIL_CLOSED: 'Email edit cancelled',
  AUTOMATED_EMAIL_OPENED: 'Email send opened',
  AUTOMATED_EMAIL_SEND: 'Email send submitted',
  CBR_ADDRESS_REVIEW: 'CBR - Address Review',
  CBR_MANAGE_FUNDING_ACCOUNTS: 'CBR - Manage Funding Accounts',
  CBR_BAD_CHECKS_INQUIRY: 'CBR - Bad Checks Inquiry',
  CBR_SUBMISSION_FAILURE: 'CBR - Submission Failure',
  CBR_SUBMISSION_SUCCESS: 'CBR - Submission Success',
  EDIT_CUSTOMER_INFO_OPENED: 'Edit customer info opened',
  EDIT_CUSTOMER_INFO_CLOSED: 'Edit customer info closed',
  FRAUD_OPENED: 'Fraud pill selected',
  FULL_SSN_REQUESTED: 'Full SSN requested',
  GENERAL_INFO_EDIT_SAVED: 'Customer general info edit submitted',
  INTERACTIONS_SCROLL_LIMIT_HIT: 'Interactions scrolled',
  INTERACTIONS_MISCLICKED: 'Interaction click attempted',
  NOTE_BOX_COLLAPSED: 'Note box collapsed',
  NOTE_BOX_EXPANDED: 'Note box expanded',
  NOTE_BOX_TAG_CALL_CLICKED: 'Tag call clicked',
  NOTE_BOX_TAG_CALL_RESOLVED: 'Tag call resolved',
  NOTE_BOX_TAG_CALL_CANCELLED: 'Tag call cancelled',
  NOTE_BOX_TEXTAREA_RESIZED: 'Note text expanded',
  NOTE_BOX_SAVE_CLICKED: 'Note submitted',
  NOTE_BOX_SUPER_STICKY_CHECKED: 'Note super sticky checked',
  NOTE_BOX_SUPER_STICKY_UNCHECKED: 'Note super sticky unchecked',
  PAYMENT_EDIT_CLOSED: 'Scheduled payment edit cancelled',
  PAYMENT_EDIT_OPENED: 'Scheduled payment edit opened',
  PAYMENT_EDIT_SAVED: 'Scheduled payment edit submitted',
  SEND_CARD_CARD_STATUS: 'Send Card - Card Status',
  SEND_CARD_ACCOUNT_STANDING: 'Send Card - Account Standing',
  SEND_CARD_FRAUD_REVIEW: 'Send Card - Fraud Review',
  SEND_CARD_CARD_LOSS_DETAILS: 'Send Card - Card Loss Details',
  SEND_CARD_ADDRESS_REVIEW: 'Send Card - Address Review',
  // Submission Step requests
  SEND_CARD_REQUEST_START: 'Send Card - Request Start',
  SEND_CARD_REQUEST_END: 'Send Card - Request End',
  SEND_CARD_REPLACEMENT: 'Send Card - Replace Card',
  SEND_CARD_GET_CARD_NUMBER: 'Send Card - Get Card Number',
  SEND_CARD_SECURITY_REPORT: 'Send Card - Security Report',
  SEND_CARD_ACCOUNT_TRANSFER: 'Send Card - Account Transfer',
  SEND_CARD_COMPARE_LAST_4: 'Send Card - Compare Last 4',
  SNAPSHOT_CREDIT_OPENED: 'Snapshot credit selected',
  SNAPSHOT_DEBIT_OPENED: 'Snapshot debit selected',
  SNAPSHOT_EARN_OPENED: 'Snapshot earn selected',
  SNAPSHOT_CREDIT_BUILDER_LOANS_OPENED:
    'Snapshot credit builder loans selected',
  SNAPSHOT_CASH_ADVANCE_OPENED: 'Snapshot cash advance selected',
  SUPER_STICKY_REMOVED: 'Note super sticky deleted',
  TRANSACTION_DETAIL_OPENED: 'Transaction detail modal opened',
  TRANSACTION_DETAIL_CLOSED: 'Transaction detail modal closed',
  ZENDESK_OPENED: 'Interactions Zendesk selected',
  // Search
  CUSTOMER_SEARCH_CLICKED: 'Customer searched',
  APPLICATION_SEARCH_CLICKED: 'Application searched',
  // Misc
  GRAPHQL_ERROR: 'GraphQL Error',
  SOFTPHONE_INITIALIZED: 'Softphone initialized',
  ACTIVATE_CARD_SUBMIT: 'Activate card request submitted',
  //Fraud Files Search
  FDR_FILE_SEARCH_CLICKED: 'Fraud files searched',
};

// https://ant.design/components/layout/#breakpoint-width
export const BREAKPOINT = Object.freeze({
  SM: 576,
  SM_PX: '576px',
  MD: 768,
  MD_PX: '768px',
  LG: 992,
  LG_PX: '992px',
  XL: 1200,
  XL_PX: '1200px',
  XXL: 1600,
  XXL_PX: '1600px',
});

export const CARD_ISSUANCE_STATE_STATUS = Object.freeze({
  ACTIVATED: 'ACTIVATED',
  CANCELED: 'CANCELED',
  DELAYED: 'DELAYED',
  MAILED: 'MAILED',
  PRE_EXPIRED: 'PRE_EXPIRED',
  PRE_MAILED: 'PRE_MAILED',
  RETURNED: 'RETURNED',
});

export const CHANNEL = Object.freeze({
  AGENT: 'AGENT',
});

export const CREDIT_PRODUCT_CODES = Object.freeze([
  'VCR',
  'VCL',
  'VCS',
  'CreditCard',
]);

export const CUSTOMER_TYPE = Object.freeze({
  CUSTOMER: 'CUSTOMER',
  APPLICANT: 'APPLICANT',
  APPLICATION: 'APPLICATION',
  PREQUAL: 'PREQUAL',
});

export const DECEASED_STATUS = Object.freeze({
  DECEASED: 'DECEASED',
  NOT_DECEASED: 'NOT_DECEASED',
  // only for use in mc front end
  PENDING_DECEASED: 'PENDING_DECEASED',
  UNAVAILABLE: 'UNAVAILABLE',
});

export const INDEFINITE_EXPIRATION_DATE = '01-01-2099';

export const EFA_PHONE_VERIFICATION = 'EFA_PHONE_VERIFICATION';

export const ERROR_CODES = {
  INVALID_ADDR_NO_MATCHES: 'INVALID_ADDR_NO_MATCHES',
  INVALID_ADDR_SPELLING: 'INVALID_ADDR_SPELLING',
};

export const FRAUD_STATUS = Object.freeze({
  CLEARED: 'CLEARED',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  RESTORED: 'RESTORED',
  RESTRICTED: 'RESTRICTED',
  UNDER_INVESTIGATION: 'UNDER INVESTIGATION',
  SETTING_AS_CLEARED: 'Setting as Cleared',
  SETTING_AS_CONFIRMED: 'Setting as Confirmed',
  SETTING_AS_REJECTED: 'Setting as Rejected',
  SETTING_AS_RESTORED: 'Setting as Restored',
  SETTING_AS_RESTRICTED: 'Setting as Under Investigation Restricted',
  SETTING_AS_UNDER_INVESTIGATION: 'Setting as Under Investigation',
});

export const PAST_DUE_INTERVAL = Object.freeze({
  FROM_001_TO_30: '1 to 30 days',
  FROM_031_TO_060: '31 to 60 days',
  FROM_061_TO_90: '61 to 90 days',
  FROM_091_TO_120: '91 to 120 days',
  FROM_121_TO_150: '121 to 150 days',
  FROM_151_TO_180: '151 to 180 days',
  FROM_181_TO_210: '181 to 210 days',
  FROM_211_TO_240: '211 to 230 days',
  FROM_241_TO_270: '241 to 270 days',
  FROM_271_TO_300: '271 to 300 days',
  FROM_301_TO_330: '301 to 330 days',
  FROM_331: '331 or more days',
});

export const PAYMENT_PLAN = Object.freeze({
  MONTHLY_FIXED: 'MONTHLY_FIXED',
  MONTHLY_MINIMUM: 'MONTHLY_MINIMUM',
  MONTHLY_STATEMENT_BALANCE: 'MONTHLY_STATEMENT_BALANCE',
  MONTHLY_CURRENT_BALANCE: 'MONTHLY_CURRENT_BALANCE',
});

export const PAYMENT_PLAN_DISPLAY = Object.freeze({
  [PAYMENT_PLAN.MONTHLY_FIXED]: 'Fixed Amount',
  [PAYMENT_PLAN.MONTHLY_MINIMUM]: 'Minimum Payment',
  [PAYMENT_PLAN.MONTHLY_STATEMENT_BALANCE]: 'Statement Balance',
  [PAYMENT_PLAN.MONTHLY_CURRENT_BALANCE]: 'Current Balance',
});

export const PRODUCT = Object.freeze({
  CREDIT_CARD: 'CreditCard',
  DEBIT: 'MONEY', // Not 100% sure how  Money and Gigs show up in the DB
  PAY_YOUR_WAY: 'PayYourWay',
});

export const PRODUCT_COLOR = Object.freeze({
  CASH_ADVANCE: COLORS.indigo1,
  CREDIT_CARD: COLORS.pine1,
  CREDIT_BUILDER_LOANS: COLORS.pine1,
  DEBIT: COLORS.indigo1,
  EARN: COLORS.brick1,
});

export const REQUEST_REASON = Object.freeze({
  SPECIAL: 'SPECIAL',
  ACE: 'ACE',
  NEW: 'NEW',
  REISSUE: 'REISSUE',
  TRANSFER: 'TRANSFER',
});

export const SECURED_CARD_STATUS = Object.freeze({
  CLEARED_SEC_DEP: 'Cleared',
  RETURNED_SEC_DEP: 'Returned',
  FRAUD_SECURITY_DEP: 'Fraud',
  DISPUTED_SECURITY_DEP: 'Disputed',
  ERROR_IN_SEC_DEP: 'Error',
  REFUNDED_SEC_DEP: 'Refunded',
  PENDING_SEC_DEPOSIT: 'Pending',
});

/**
 * Current list of Segment event types that can be returned and displayed in the Auto Notes component.
 * This list should be updated as needed.
 */
export const SEGMENT_EVENT_RETURN_TYPES = [
  'Call Activation Support',
  'Call Support',
  'Okta Login Failure',
  'Okta Login Success',
  'Okta OTP Enter Failure',
  'Okta OTP Enter Submit',
  'Okta OTP Enter Success',
  'Okta OTP Request Failure',
  'Okta OTP Request Submit',
  'Okta OTP Request Success',
  'Okta OTP Required',
  'Risk Auth Login Success',
  'RiskAuth Login Success',
  'Risk Auth OTP Enter Failure',
  'Risk Auth OTP Enter Submit',
  'Risk Auth OTP Enter Success',
  'Risk Auth OTP Request Failure',
  'Risk Auth OTP Request Submit',
  'Risk Auth OTP Request Success',
  'Risk Auth OTP Required',
  'Unauthorized Request',
  'User Authentication Complete',
];

export const SIZE = Object.freeze({
  XXS: 'extra-extra-small',
  XS: 'extra-small',
  SM: 'small',
  MD: 'medium',
  LG: 'large',
  XL: 'extra-large',
});

export const STATUS = Object.freeze({
  ERROR: 'error',
  INVALID: 'invalid',
  INFORMATION: 'information',
  LOADING: 'loading',
  SUCCESS: 'success',
  WARNING: 'warning',
});

export const STATES_ABBR = Object.freeze({
  AL: { name: 'Alabama' },
  AK: { name: 'Alaska' },
  AZ: { name: 'Arizona' },
  AR: { name: 'Arkansas' },
  AA: { name: 'Armed Forces Americas' },
  AE: { name: 'Armed Forces Europe' },
  AP: { name: 'Armed Forces Pacific' },
  CA: { name: 'California' },
  CO: { name: 'Colorado' },
  CT: { name: 'Connecticut' },
  DE: { name: 'Delaware' },
  DC: { name: 'District Of Columbia' },
  FL: { name: 'Florida' },
  GA: { name: 'Georgia' },
  HI: { name: 'Hawaii' },
  ID: { name: 'Idaho' },
  IL: { name: 'Illinois' },
  IN: { name: 'Indiana' },
  IA: { name: 'Iowa' },
  KS: { name: 'Kansas' },
  KY: { name: 'Kentucky' },
  LA: { name: 'Louisiana' },
  ME: { name: 'Maine' },
  MD: { name: 'Maryland' },
  MA: { name: 'Massachusetts' },
  MI: { name: 'Michigan' },
  MN: { name: 'Minnesota' },
  MS: { name: 'Mississippi' },
  MO: { name: 'Missouri' },
  MT: { name: 'Montana' },
  NE: { name: 'Nebraska' },
  NV: { name: 'Nevada' },
  NH: { name: 'New Hampshire' },
  NJ: { name: 'New Jersey' },
  NM: { name: 'New Mexico' },
  NY: { name: 'New York' },
  NC: { name: 'North Carolina' },
  ND: { name: 'North Dakota' },
  OH: { name: 'Ohio' },
  OK: { name: 'Oklahoma' },
  OR: { name: 'Oregon' },
  PA: { name: 'Pennsylvania' },
  RI: { name: 'Rhode Island' },
  SC: { name: 'South Carolina' },
  SD: { name: 'South Dakota' },
  TN: { name: 'Tennessee' },
  TX: { name: 'Texas' },
  UT: { name: 'Utah' },
  VT: { name: 'Vermont' },
  VA: { name: 'Virginia' },
  WA: { name: 'Washington' },
  WV: { name: 'West Virginia' },
  WI: { name: 'Wisconsin' },
  WY: { name: 'Wyoming' },
});

export const TRANSACTION_STATUS = Object.freeze({
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  DO_NOT_HONOR: 'DO NOT HONOR',
  PENDING: 'PENDING',
  POSTED: 'POSTED',
  REVERSED: 'REVERSED',
  SCHEDULED: 'SCHEDULED',
});

export const TRANSACTION_TYPE = Object.freeze({
  AUTHORIZATION: 'AUTHORIZATION',
  TRANSACTION: 'TRANSACTION',
  CREDIT: 'CREDIT',
  FEE: 'FEE',
  PAYMENT: 'PAYMENT',
  PURCHASE: 'PURCHASE',
  REWARD: 'REWARD',
});

export const ZENDESK_MACROS = Object.freeze([
  {
    id: '229167888',
    title: 'Address update <90 days docs needed',
  },
  {
    id: '6296936928403',
    title: 'SpringFour Info',
  },
  {
    id: '360107255793',
    title: 'Name change docs needed',
  },
  {
    id: '360107735614',
    title: 'OTB expedite',
  },
  {
    id: '360107799434',
    title: 'Pin update',
  },
  {
    id: '1900010659065',
    title: 'Collections/Recoveries Initiated escalation',
  },
  {
    id: '360082813373',
    title: 'Follow up with cardholder (3rd Party Representation)',
  },
  {
    id: '1500009609222',
    title: 'Paperless Enrollment Request',
  },
  {
    id: '1500009338741',
    title: 'Paperless Enrollment Fulfilled',
  },
  {
    id: '19759232862227',
    title: 'Dispute Initiated Docs Needed',
  },
]);

export const SETTLEMENTS_CADENCE_VALUES = Object.freeze({
  MONTHLY: 'MONTHLY',
  BIWEEKLY: 'BIWEEKLY',
  WEEKLY: 'WEEKLY',
  ONE_TIME: 'ONE_TIME',
  ONE_TIME_DEBIT: 'ONE_TIME_DEBIT',
});

export const SETTLEMENT_OFFER_STATES = Object.freeze({
  EMPTY: 'EMPTY',
  NOT_READY: 'NOT_READY',
  READY: 'READY',
  IN_PROGRESS: 'IN_PROGRESS',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
  PENDING_CLOSE_OUT: 'PENDING_CLOSE_OUT',
  FULFILLED: 'FULFILLED',
});

export const SETTLEMENT_OFFER_TYPES = Object.freeze({
  PIF: 'PIF',
  SIF: 'SIF',
});

export const PAYMENT_STATES = Object.freeze({
  PENDING: 'PENDING',
  POSTED: 'POSTED',
  SCHEDULED: 'SCHEDULED',
  CANCELLED: 'CANCELLED',
  RETURNED: 'RETURNED',
  DECLINED: 'DECLINED',
  REVERSED: 'REVERSED',
  REVERSAL: 'REVERSAL',
});

export const ASSET_SALE_TAGS = Object.freeze({
  ACM001: { value: 'ACM001', label: 'MMM Receivables LLC' },
  ACM002: { value: 'ACM002', label: 'MMM Receivables LLC' },
});

export const DEFAULT_ASSET_SALE = 'Mission Lane';
