import { useMemo } from 'react';
import { Space, Tag } from 'antd';
import dayjs from 'dayjs';

import { COLORS } from 'utilities/styles';

const STATUS_REGEX = /([A-Z][a-z]+)/g;

export const humanizeStatusText = (status) => {
  if (!status) {
    return '';
  }
  const matches = status.match(STATUS_REGEX);
  return matches.join(' ');
};

const getPastDueMessage = (pastDueStatus) => {
  if (!pastDueStatus || Number.isInteger(pastDueStatus)) {
    return null;
  }

  let bucketNumber;

  switch (pastDueStatus) {
    case 'PAST_DUE_1_30_DAYS':
      bucketNumber = 1;
      break;
    case 'PAST_DUE_31_60_DAYS':
      bucketNumber = 2;
      break;
    case 'PAST_DUE_61_90_DAYS':
      bucketNumber = 3;
      break;
    case 'PAST_DUE_91_120_DAYS':
      bucketNumber = 4;
      break;
    case 'PAST_DUE_121_150_DAYS':
      bucketNumber = 5;
      break;
    case 'PAST_DUE_151_180_DAYS':
      bucketNumber = 6;
      break;
    case 'PAST_DUE_181_210_DAYS':
    case 'PAST_DUE_211_240_DAYS':
    case 'PAST_DUE_241_270_DAYS':
    case 'PAST_DUE_271_300_DAYS':
    case 'PAST_DUE_301_330_DAYS':
    case 'PAST_DUE_331_PLUS_DAYS':
      bucketNumber = 8;
      break;
  }

  return `Past Due: Bucket ${bucketNumber}`;
};

/*
  We generally need to think of a better way to handle statuses
  both at the account and the customer level.
*/
const CustomerAccountStatus = ({ accountStatus, createdAt }) => {
  let statusTags = useMemo(() => {
    let tags = [];

    if (accountStatus) {
      Object.entries(accountStatus).forEach(([statusName, statusValue]) => {
        if (statusValue === true) {
          if (statusName === 'isPastDue') {
            if (accountStatus.isChargedOff) {
              return;
            }
            tags.push({
              title: getPastDueMessage(accountStatus.pastDueStatus),
            });
            return;
          } else if (statusName === 'isBankrupt') {
            tags.push({
              title: humanizeStatusText(statusName),
              color: COLORS.red1,
            });
            return;
          } else if (statusName === `isInLitigation`) {
            tags.push({
              title: 'Litigation Placement',
              color: COLORS.red1,
            });
            return;
          }
          tags.push({ title: humanizeStatusText(statusName) });
        }
      });
    }

    // if account is less than 90 days old
    if (createdAt) {
      let ninetyDaysAgo = dayjs().subtract(90, 'day');
      let openedDate = dayjs(createdAt);
      if (ninetyDaysAgo.isBefore(openedDate)) {
        tags.push({
          title: 'Less Than 90 Days Old',
          color: COLORS.blue1,
        });
      }
    }

    return tags;
  }, [accountStatus, createdAt]);

  return statusTags.length ? (
    <Space
      size={[8, 8]}
      wrap={true}
      style={{ fontSize: 'var(--font-size-sm)' }}
    >
      {statusTags.map((tag, i) => (
        <Tag
          key={i}
          color={tag.color || COLORS.yellow1}
          style={{
            margin: 0,
            color: tag.color ? COLORS.haze4 : COLORS.black,
          }}
        >
          {tag.title}
        </Tag>
      ))}
    </Space>
  ) : null;
};

export default CustomerAccountStatus;
