import { Trans } from 'react-i18next';

import Loading from 'components/Loading/Loading';
import useTranslation from 'hooks/useTranslation';

const Translation = ({ translateKey = '', ...rest }) => {
  const { languageLoading } = useTranslation();

  if (languageLoading) {
    return <Loading />;
  }

  return <Trans i18nKey={translateKey} {...rest} />;
};

export default Translation;
