import { gql } from '@apollo/client';

const STATEMENT_LINKS = gql`
  query StatementLinks(
    $accountId: String!
    $customerId: String!
    $to: String
    $from: String
  ) {
    statementLinks(
      accountId: $accountId
      customerId: $customerId
      to: $to
      from: $from
    ) {
      statements {
        date
        documentUrl
        id
      }
    }
  }
`;

export { STATEMENT_LINKS };
