import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Alert, Form, Typography, Modal, Radio, Space } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useAgent from 'hooks/useAgent';

import { UPDATE_ACCOUNT_STATUS } from 'mutations/account';
import { UPDATE_DECEASED_STATUS } from 'mutations/accountStatus';

import {
  ACCOUNT_STATUS_CODE,
  ACCOUNT_STATUS_OPERATION,
  DECEASED_STATUS,
  STATUS,
  SIZE,
} from 'utilities/constants';

const STATUS_ACTION = {
  APPLY_DECEASED: 'APPLY_DECEASED',
  APPLY_PENDING: 'APPLY_PENDING',
  REMOVE_PENDING: 'REMOVE_PENDING',
};

const EditDeceasedStatusModal = ({
  accounts = [],
  deceasedStatus,
  hide,
  ...modalProps
}) => {
  const { customerId } = useParams();
  const agent = useAgent();
  const [partialErrorMessage, setPartialErrorMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [partialSuccessMessage, setPartialSuccessMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const [deceasedStatusForm] = Form.useForm();
  const statusAction = Form.useWatch('statusAction', deceasedStatusForm);
  const [hasActiveStatus, isDeceased, isPendingDeceased] = useMemo(() => {
    const deceasedCheck = deceasedStatus === DECEASED_STATUS.DECEASED;
    const pendingDeceasedCheck =
      deceasedStatus === DECEASED_STATUS.PENDING_DECEASED;
    return [
      deceasedCheck || pendingDeceasedCheck,
      deceasedCheck,
      pendingDeceasedCheck,
    ];
  }, [deceasedStatus]);

  const refetchQueries = ['GetAccountStatuses', 'GetCustomerWithFullAccounts'];
  const [updateAccountStatus, accountStatusUpdate] = useMutation(
    UPDATE_ACCOUNT_STATUS,
    { refetchQueries },
  );

  const [updateDeceasedStatus, deceasedStatusUpdate] = useMutation(
    UPDATE_DECEASED_STATUS,
    { refetchQueries },
  );

  const closeModal = () => {
    hide();
    deceasedStatusForm.resetFields();
  };

  const updatePendingStatus = async (operation) => {
    const accountIds = accounts.map((account) => account.id);
    try {
      await updateAccountStatus({
        variables: {
          customerId,
          accountIds,
          agentName: agent?.user?.name,
          operation,
          statusCode: ACCOUNT_STATUS_CODE.WA,
          reasonCode: `${ACCOUNT_STATUS_CODE.WA}_W1`,
        },
      });

      setSuccessMessage(
        `Account status WA_W1 has been ${operation.toLowerCase()}.`,
      );
    } catch (error) {
      const op =
        operation === ACCOUNT_STATUS_OPERATION.APPLIED ? 'apply' : 'remove';
      setErrorMessage(`Failed to ${op} status WA-W1. Please try again.`);
    }
  };

  const setDeceased = async () => {
    const accountIds = accounts.map((account) => account.id);
    try {
      const deceasedResponse = await updateDeceasedStatus({
        variables: {
          accountIds,
          customerId,
        },
      });

      const deceasedStatusResponse =
        deceasedResponse?.data?.updateDeceasedFlagAndStatuses;

      if (deceasedStatusResponse) {
        const successfulUpdates = deceasedStatusResponse.filter(
          ({ hasDeceasedFlag, isClosedDeceased, isCreditRevokedDeceased }) => {
            return (
              hasDeceasedFlag && isClosedDeceased && isCreditRevokedDeceased
            );
          },
        );

        const failedUpdates = deceasedStatusResponse.filter(
          ({ hasDeceasedFlag, isClosedDeceased, isCreditRevokedDeceased }) => {
            return !(
              hasDeceasedFlag &&
              isClosedDeceased &&
              isCreditRevokedDeceased
            );
          },
        );

        if (!failedUpdates.length) {
          return setSuccessMessage(
            `Cardholder has been flagged as deceased and account status CL_DC and CR_DC have been applied.`,
          );
        } else if (!successfulUpdates.length) {
          return setErrorMessage(
            `Failed to apply deceased status to all accounts. Please try again.`,
          );
        } else {
          const errorMessage = failedUpdates.reduce(
            (
              message,
              { accountId, isClosedDeceased, isCreditRevokedDeceased },
            ) => {
              let errorMessage = '';

              if (!isClosedDeceased && !isCreditRevokedDeceased) {
                errorMessage +=
                  'Account statuses CL_DC and CR_DC failed to apply. Escalate to R2 to apply the missing statuses.';
              } else if (!isClosedDeceased) {
                errorMessage +=
                  'Account status CL_DC failed to apply. Escalate to R2 to apply the missing status.';
              } else if (!isCreditRevokedDeceased) {
                errorMessage +=
                  'Account status CR_DC failed to apply. Escalate to R2 to apply the missing status.';
              }

              return (
                <Typography.Paragraph>
                  {message}
                  <b>Account {accountId}:</b> {errorMessage}
                </Typography.Paragraph>
              );
            },
            '',
          );

          const partialSuccessMessage = successfulUpdates.reduce(
            (message, { accountId }) => {
              return (
                <Typography.Paragraph key={accountId}>
                  {message}
                  <b>Account {accountId}</b> has been flagged as deceased and
                  account status CL_DC and CR_DC have been applied.
                </Typography.Paragraph>
              );
            },
            '',
          );

          setPartialErrorMessage(errorMessage);
          setPartialSuccessMessage(partialSuccessMessage);
        }
      }
    } catch (error) {
      setErrorMessage(
        `Failed to apply deceased status to all accounts. Please try again.`,
      );
    }
  };

  const handleSubmit = async () => {
    let formValues;
    try {
      formValues = await deceasedStatusForm.validateFields();
    } catch (error) {
      return;
    }

    setErrorMessage(null);
    setPartialErrorMessage(null);
    setPartialSuccessMessage(null);
    switch (formValues.statusAction) {
      case STATUS_ACTION.APPLY_PENDING:
        updatePendingStatus(ACCOUNT_STATUS_OPERATION.APPLIED);
        break;
      case STATUS_ACTION.REMOVE_PENDING:
        updatePendingStatus(ACCOUNT_STATUS_OPERATION.REMOVED);
        break;
      case STATUS_ACTION.APPLY_DECEASED:
        setDeceased();
        break;
    }
  };

  const renderSubmissionAlert = () => {
    if (statusAction === undefined) {
      return;
    }

    let message;
    switch (statusAction) {
      case STATUS_ACTION.APPLY_PENDING:
        message = (
          <span>
            In your call note, please make sure to record what partial
            information the caller provided.
            <br />
            Status <strong>WA-W1</strong> will be applied to all accounts.
          </span>
        );
        break;
      case STATUS_ACTION.REMOVE_PENDING:
        message = (
          <span>
            Status <strong>WA-W1</strong> will be removed to from accounts.
          </span>
        );
        break;

      case STATUS_ACTION.APPLY_DECEASED:
        message = (
          <span>
            In your call note, please make sure to note whether a death
            certificate was supplied as well as the date of the cardholder's
            death, caller's name and relationship to the cardholder where
            applicable.
            <br />
            Statuses <strong>CR-DC</strong> and <strong>CL-DC</strong> will be
            applied to all accounts.
          </span>
        );
        break;
    }

    return (
      <Alert
        banner
        type="warning"
        message={message}
        style={{ marginBottom: 'var(--spacing-md)' }}
      />
    );
  };

  return (
    <Modal
      {...modalProps}
      title="Edit Cardholder Deceased Status"
      okText="Update Deceased Status"
      width="600px"
      onOk={handleSubmit}
      okButtonProps={{
        'data-testid': 'submit-deceased-status',
        disabled:
          successMessage || partialSuccessMessage || partialErrorMessage,
      }}
      onCancel={closeModal}
      confirmLoading={
        accountStatusUpdate.loading || deceasedStatusUpdate.loading
      }
    >
      <div style={{ position: 'relative' }}>
        {successMessage ? (
          <SectionMessage
            data-testid="success-message"
            status={STATUS.SUCCESS}
            size={SIZE.SM}
            cover={true}
            text={successMessage}
          />
        ) : null}
        <Form form={deceasedStatusForm} layout="vertical">
          {hasActiveStatus && isDeceased ? (
            <SectionMessage
              data-testid="deceased-message"
              status={STATUS.WARNING}
              size={SIZE.SM}
              text={
                <>
                  The cardholder has been marked as deceased.
                  <br />
                  This status cannot be reversed within Mission Coontrol.
                </>
              }
            />
          ) : (
            <>
              <Alert
                banner
                type="info"
                showIcon={false}
                style={{ marginBottom: 'var(--spacing-md)' }}
                message={
                  <>
                    <Typography.Paragraph>
                      To apply a deceased status to this cardholder's accounts
                      you need to fulfill either of the following criteria:
                    </Typography.Paragraph>
                    <ul>
                      <li>
                        Date of death, caller's name, and caller's relationship
                        to the deceased
                      </li>
                      <li>Death Certificate</li>
                    </ul>
                  </>
                }
              />
              <Form.Item
                data-testid="proof-radio"
                name="statusAction"
                label="Do you have acceptable proof of the cardholder's passing?"
                style={{ marginBottom: 'var(--spacing-md)' }}
                rules={[
                  {
                    message: 'Please make a selection',
                    required: true,
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {isPendingDeceased ? (
                      <Radio
                        data-testid="option-remove-pending"
                        value={STATUS_ACTION.REMOVE_PENDING}
                      >
                        No, I have found the cardholder is NOT DECEASED. I wish
                        to remove the PENDING DECEASED status.
                      </Radio>
                    ) : (
                      <Radio
                        data-testid="option-apply-pending"
                        value={STATUS_ACTION.APPLY_PENDING}
                      >
                        No, I have only collected partial information and wish
                        to apply a PENDING DECEASED status on all accounts.
                      </Radio>
                    )}
                    <Radio
                      data-testid="option-apply-deceased"
                      value={STATUS_ACTION.APPLY_DECEASED}
                    >
                      Yes, I have collected acceptable proof (death certificate
                      or caller's name, relationship to cardholder, and date of
                      death) and I wish to confirm the cardholder as DECEASED.
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              {renderSubmissionAlert()}
            </>
          )}
        </Form>
        {partialSuccessMessage ? (
          <Alert
            banner
            type="success"
            message={partialSuccessMessage}
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
        ) : null}
        {errorMessage || partialErrorMessage ? (
          <Alert
            banner
            type="error"
            message={errorMessage || partialErrorMessage}
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default EditDeceasedStatusModal;
