import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Flex, Input, Tabs, Timeline, Tooltip } from 'antd';
import { ClockCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useCustomerInfo from 'hooks/useCustomerInfo';
import useAccountQuery from 'hooks/useAccountQuery';
import useApplicationQuery from 'hooks/useApplicationQuery';
import useTimeoutValue from 'hooks/useTimeoutValue';

import { INTERACTIONS } from 'queries/interactions';

import { SIZE, STATUS } from 'utilities/constants';
import { formatDate, formatDateToSubmit } from 'utilities/datesAndTimes';
import { isAgentNote, isZendeskInteraction } from 'utilities/helpers';

import AgentNote from './AgentNote';
import ZendeskInteraction from './ZendeskInteraction';

const TAB_ITEMS = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'notes',
    label: 'Notes',
  },
  {
    key: 'zendesk',
    label: 'Zendesk',
  },
];

// This should work for now but this probably needs yet another
// refactor to split functionality for customer/application.
// Theres potential for things to just get messier having to switch between
// customer and application logic
const NotesAndZendesk = () => {
  const { customerId, applicationId } = useParams();
  const { customerInfo, customerInfoQuery } = useCustomerInfo();
  const { enableTruncatedNotes } = useFlags();

  const { simpleAccounts } = useAccountQuery(customerId);
  const { application } = useApplicationQuery(applicationId);

  const [customerActivities, setCustomerActivities] = useState([]);
  const [pagination, setPagination] = useState(1);
  const rootRef = useRef(null);
  const [tabKey, setTabkey] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showLoadMoreFailure, setShowLoadMoreFailure] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);

  // simple state to set the active filter value
  const [searchFilter, setSearchFilter] = useState('');
  // debounced state to call filteredInteractions
  const [debouncedSearchFilter, setDebouncedSearchFilter] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [commentAddOrUpdateSuccess, setCommentAddOrUpdateSuccess] =
    useTimeoutValue(false, 3000);

  const filteredInteractions = useMemo(() => {
    if (customerActivities) {
      const isAgentOrZenDesktype = (interaction) =>
        isZendeskInteraction(interaction) || isAgentNote(interaction);

      // filters are subject to performance implications but alleviated with pagination support.
      let activities = customerActivities.filter(
        (interaction) =>
          interaction?.message && isAgentOrZenDesktype(interaction),
      );
      // filter by type
      if (tabKey !== 'all') {
        activities = activities.filter((activity) =>
          tabKey === 'zendesk'
            ? isZendeskInteraction(activity)
            : isAgentNote(activity),
        );
      }

      // filter by search term
      if (searchFilter && searchFilter.trim().length > 0) {
        activities = activities.filter((interaction) =>
          interaction?.message
            .toLowerCase()
            .includes(searchFilter.toLowerCase()),
        );
      }

      return activities?.map((interaction) => {
        return {
          dot: <ClockCircleOutlined />,
          children: (
            <div
              key={`interaction-itm-${interaction.id}`}
              data-testid="interaction-item"
            >
              {isZendeskInteraction(interaction) ? (
                <ZendeskInteraction
                  interaction={interaction}
                  enableTruncatedNotes={enableTruncatedNotes}
                  highlight={searchFilter}
                />
              ) : (
                <AgentNote
                  interaction={interaction}
                  onCommentAddOrUpdateSuccess={(message) => {
                    setSuccessMessage(message);
                    setCommentAddOrUpdateSuccess(true);
                  }}
                  rootRef={rootRef}
                  enableTruncatedNotes={enableTruncatedNotes}
                  highlight={searchFilter}
                />
              )}
            </div>
          ),
        };
      });
    }
  }, [customerActivities, tabKey, debouncedSearchFilter]);

  const originationDate = useMemo(() => {
    if (applicationId && application) {
      return dayjs(application.submittedAt).toISOString();
    }

    if (customerId && simpleAccounts && simpleAccounts.length > 0) {
      const accountCreationDates = simpleAccounts.map(
        (account) => account.createdAt && dayjs(account.createdAt),
      );
      return dayjs.min(accountCreationDates).toISOString();
    }
  }, [customerId, simpleAccounts, applicationId, application]);

  const getDateRange = (index = 1) => {
    return {
      startDate: dayjs().subtract(90 * index, 'days'),
      endDate: dayjs().add(1, 'days'), // tomorrow  -i.e. include all events disregard of server timezone offset.
    };
  };

  const [getInteractions, interactionsQuery] = useLazyQuery(INTERACTIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setCustomerActivities(data?.notesInteractionsZendeskRecords);
    },
  });

  const loadInteractions = async (reset) => {
    let page = pagination + 1;
    if (reset) {
      page = 1;
      setPagination(1);
      setCustomerActivities([]);
      setInitialLoad(true);
      setCanLoadMore(true);
    } else {
      // reset filter on Load More.
      // This is because active filter will hide the new records which inturn gives false impression.
      setSearchFilter('');
      setDebouncedSearchFilter('');
    }

    const { startDate, endDate } = getDateRange(page);

    setShowLoadMoreFailure(false);
    setIsLoading(true);

    try {
      const result = await getInteractions({
        variables: {
          customerId,
          applicationId,
          email: customerInfo?.email?.toLowerCase(),
          startDate: formatDateToSubmit(startDate),
          endDate: formatDateToSubmit(endDate),
        },
      });

      if (result.error) {
        throw result.error;
      }

      const beforeAccountCreation =
        originationDate && startDate.isBefore(originationDate);

      let messageUpdate = `Displaying notes from ${formatDate(
        startDate,
      )} to today. Click 'Load More' to view older entries.`;

      if (beforeAccountCreation) {
        messageUpdate = 'Displaying notes back to account creation.';
      }

      setInitialLoad(false);
      setPagination(page);
      setResultsMessage(messageUpdate);
      setCanLoadMore(!beforeAccountCreation);
    } catch (error) {
      if (!reset) {
        setShowLoadMoreFailure(true);
      }
    }

    setIsLoading(false);
  };

  const onTabChange = useCallback((key) => {
    setSearchFilter('');
    setDebouncedSearchFilter('');
    setTabkey(key);
  }, []);

  const debouncedSetSearchFilter = debounce(setDebouncedSearchFilter, 500);

  const handleSearchFilterChange = useCallback(
    (value) => {
      debouncedSetSearchFilter(value);
    },
    [customerActivities],
  );

  useEffect(() => {
    if (!customerInfoQuery.loading && customerInfo.initialized) {
      loadInteractions(true);
    }
  }, [customerInfoQuery.loading, customerInfo]);

  return (
    <div style={{ overflow: 'auto' }}>
      <Tabs
        size="small"
        items={TAB_ITEMS}
        onTabClick={onTabChange}
        tabBarExtraContent={
          <Flex>
            <Input
              data-testid="notes-search"
              placeholder="Search.."
              allowClear
              type="text"
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
                handleSearchFilterChange(e.target.value);
              }}
            />

            <Tooltip
              title={
                searchFilter?.trim().length > 0
                  ? 'Clear filter to refresh'
                  : 'Refresh'
              }
            >
              <Button
                type="link"
                icon={<ReloadOutlined />}
                disabled={searchFilter?.trim().length > 0}
                onClick={() => {
                  loadInteractions(true);
                }}
              />
            </Tooltip>
          </Flex>
        }
        style={{
          position: 'sticky',
          top: '0px',
          backgroundColor: '#fff',
          zIndex: 1,
        }}
      ></Tabs>

      <QueryBoundary
        disabled={!initialLoad}
        messageHeight={'400px'}
        errorMessage="Failed to load Interactions. Please try the refresh button."
        query={interactionsQuery}
        skeleton={{
          title: false,
          paragraph: { rows: 12 },
        }}
        loadingOverride={customerInfoQuery.loading || isLoading}
      >
        <Timeline
          data-testid="notes-timeline"
          mode="left"
          items={filteredInteractions}
          style={{ paddingLeft: '2px', paddingTop: '2px' }}
        />

        {searchFilter && !filteredInteractions.length ? (
          <SectionMessage
            status={STATUS.WARNING}
            size={SIZE.XS}
            text={`No results for: '${debouncedSearchFilter}'`}
          ></SectionMessage>
        ) : null}

        {resultsMessage && (
          <Alert
            data-testid="warning-message"
            type="warning"
            message={resultsMessage}
            style={{ marginBottom: 'var(--spacing-xs)' }}
          ></Alert>
        )}

        {showLoadMoreFailure && (
          <Alert
            data-testid="failure-message"
            type="error"
            message="Failed to load more interactions. Please try again."
            style={{ marginBottom: 'var(--spacing-xs)' }}
          ></Alert>
        )}

        <Button
          data-testid="load-more-interactions"
          type="primary"
          loading={isLoading}
          onClick={() => loadInteractions()}
          disabled={isLoading || !canLoadMore}
        >
          {canLoadMore ? 'Load More' : 'No more interactions'}
        </Button>
      </QueryBoundary>
      <SectionMessage
        status={STATUS.SUCCESS}
        size={SIZE.SM}
        text={successMessage}
        cover={true}
        visible={commentAddOrUpdateSuccess}
      ></SectionMessage>
    </div>
  );
};

export default NotesAndZendesk;
