import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Space, Table, Typography } from 'antd';
import { useLazyQuery } from '@apollo/client';

import Loading from 'components/Loading/Loading';
import useCustomerInfo from 'hooks/useCustomerInfo';
import { ZD_ATTACHMENTS } from 'queries/attachments';
import { formatDateTimeWithWeekDay } from 'utilities/datesAndTimes';
import { formatFileName, sanitizeAndView } from 'utilities/helpers';

const AttachmentsPage = () => {
  const { customerInfo } = useCustomerInfo();
  const { customerId, applicationId } = useParams();
  const [attachments, setAttachments] = useState([]);

  // APPLICATIONS TODO: Need to be able to query without customer ID
  const [getAttachments, { loading }] = useLazyQuery(ZD_ATTACHMENTS, {
    onCompleted: (results) => {
      let legacyAttachments = results?.attachments || [];
      let zdAttachments = (results?.zd_attachments?.content || []).map((a) => {
        return {
          url: a.contentUrl,
          date: a.insertTz,
          name: a.name,
        };
      });

      let atts = [...zdAttachments, ...legacyAttachments].sort(
        (a, b) => -a.date?.localeCompare(b.date),
      );

      setAttachments(atts);
    },
  });

  useEffect(() => {
    if (customerInfo?.email) {
      getAttachments({
        variables: { email: customerInfo?.email, customerId, applicationId },
      });
    }
  }, [customerInfo, customerId, applicationId]);

  const columns = [
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: (_, record) => (
        <Typography.Text>
          <a
            data-testid="filename-text"
            onClick={() => sanitizeAndView(record.url)}
          >
            {formatFileName(record.name)}
          </a>
        </Typography.Text>
      ),
    },
    {
      title: 'Last Modified',
      dataIndex: 'date',
      key: 'date',
      render: (date) => (
        <Typography.Text>
          {date && formatDateTimeWithWeekDay(date)}
        </Typography.Text>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <Card bordered={false} id="attachments-view" title="Attachments">
      <Space direction="vertical" style={{ width: '100%' }}>
        {attachments?.length > 0 ? (
          <Table
            size="small"
            dataSource={attachments}
            columns={columns}
            pagination={false}
            rowKey={(record) => `key-${record.date}-${record.name}`}
            style={{ width: '100%' }}
          />
        ) : (
          'No attachments found...'
        )}
      </Space>
    </Card>
  );
};

export default AttachmentsPage;
