import { Tag } from 'antd';

import { COLORS } from 'utilities/styles';

export function Pill({ name, id, color, icon }) {
  if (name) {
    return (
      <Tag
        data-testid={id}
        color={color}
        icon={icon}
        style={{ margin: 0, color: COLORS.haze4 }}
      >
        {name}
      </Tag>
    );
  }
  return null;
}
