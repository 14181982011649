import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Form, Radio, Space, Typography } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';

import StepContainer from './StepContainer';
import RemoveStatusModal from './AccountStatusSection/RemoveStatusModal';

import EditCustomerAddressForm from 'components/EditCustomerAddressForm/EditCustomerAddressForm';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import useModalControls from 'hooks/useModalControls';
import { ACCOUNT_STATUSES } from 'queries/account';
import { CUSTOMER_META } from 'queries/customer';

const { Title, Paragraph } = Typography;

const AddressReviewStep = ({
  containerStyles,
  checkReturnMailStatus = false,
  addressIsCurrent,
  addressUpdated,
  initialAddressInfo,
  setAddressIsCurrent,
  setAddressUpdated,
  setInitialAddressInfo,
  onContinue = () => {},
  onExit = () => {},
}) => {
  const { accountId, customerId } = useParams();
  const [addressReviewForm] = Form.useForm();
  const removeStatusModal = useModalControls();
  const [returnMailStatus, setReturnMailStatus] = useState(undefined);
  const [showReturnMailError, setShowReturnMailError] = useState(false);

  const initialAddress = useMemo(() => {
    if (!initialAddressInfo) {
      return <div></div>;
    }

    const street2 = initialAddressInfo.street2
      ? ` ${initialAddressInfo.street2}`
      : '';
    const streetAddress = `${initialAddressInfo.street1}${street2}`;
    const cityStateZip = `${initialAddressInfo.city}, ${initialAddressInfo.state} ${initialAddressInfo.postalCode}`;

    return (
      <div style={{ marginLeft: 'var(--spacing-md)' }}>
        <Paragraph italic>
          {streetAddress}
          <br></br>
          {cityStateZip}
        </Paragraph>
      </div>
    );
  }, [initialAddressInfo]);

  const customerMetaQuery = useQuery(CUSTOMER_META, {
    variables: { customerId },
    notifyOnNetworkStatusChange: true,
  });

  const [getAccountStatuses, accountStatusQuery] = useLazyQuery(
    ACCOUNT_STATUSES,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        customerId,
        accountIds: [accountId],
      },
      onCompleted: (data) => {
        const accountStatusData = (data?.accountFraudStatuses || []).find(
          (statusObj) => statusObj.accountId === accountId,
        );
        const accountStatuses = accountStatusData?.accountStatuses || [];
        const returnMail = accountStatuses.find((status) => {
          return status.statusCode === 'RM';
        });

        if (returnMail) {
          setReturnMailStatus(returnMail);
        } else {
          setReturnMailStatus(undefined);
          handleContinue();
        }
      },
    },
  );

  const handleContinue = async () => {
    if (returnMailStatus) {
      setShowReturnMailError(true);
      return;
    } else {
      setShowReturnMailError(false);
    }

    try {
      addressReviewForm.validateFields();
    } catch (error) {
      return;
    }

    if (checkReturnMailStatus && addressIsCurrent && !accountStatusQuery.data) {
      await getAccountStatuses();
      return;
    }

    if (!addressIsCurrent && !addressUpdated) {
      return;
    }
    onContinue();
  };

  const handleSelection = (event) => {
    setAddressIsCurrent(event.target.value);
  };

  const renderAddressUpdate = () => {
    return (
      <>
        <Divider />
        <Title level={5}>Update Customers Address</Title>
        <EditCustomerAddressForm
          onSuccess={() => setAddressUpdated(true)}
          submitProps={{ type: 'default' }}
        />
        <Divider style={{ marginBottom: '0px' }} />
      </>
    );
  };

  const renderReturnMailStatusCheck = () => {
    return returnMailStatus ? (
      <div style={{ marginBottom: 'var(--spacing-md)' }}>
        <Divider />
        <Title level={5}>Return Mail Status</Title>
        <Paragraph>Remove "Return Mail" status before proceeding</Paragraph>
        <Button
          data-testid="remove-rm-status-button"
          onClick={removeStatusModal.show}
        >
          Remove Return Mail Status
        </Button>
        {showReturnMailError ? (
          <Typography.Paragraph
            type="danger"
            style={{ marginTop: 'var(--spacing-xs)' }}
          >
            You must remove Return Mail status before proceeding
          </Typography.Paragraph>
        ) : undefined}
      </div>
    ) : null;
  };

  const renderSelectionResult = () => {
    if (addressIsCurrent === undefined) {
      return;
    }

    return addressIsCurrent
      ? renderReturnMailStatusCheck()
      : renderAddressUpdate();
  };

  useEffect(() => {
    // Had to move this out of the onComplete of customerMetaQuery because
    // it was regularly throwing an error due to render conflicts with parent component SendCardSection.
    // This is wonky but makes sure there are no stateupdate/render conflicts.
    if (customerMetaQuery.data && !initialAddressInfo) {
      setInitialAddressInfo(
        customerMetaQuery.data.customer.contactInfo.address,
      );
    }
  }, [customerMetaQuery.data]);

  return (
    <>
      <RemoveStatusModal
        {...removeStatusModal}
        statusToRemove={returnMailStatus}
      ></RemoveStatusModal>
      <StepContainer
        title="Verify Card Holder Address"
        onContinue={handleContinue}
        onExit={onExit}
        buttonProps={{
          disabled: accountStatusQuery.loading,
          loading: accountStatusQuery.loading,
        }}
        style={containerStyles}
      >
        <QueryBoundary
          query={customerMetaQuery}
          mode={QueryBoundary.MODE.MESSAGE}
          loadingMessage="Loading Card Holder Address"
        >
          {initialAddress}
          <Form
            form={addressReviewForm}
            name="addressReview"
            layout="vertical"
            initialValues={{ addressIsCurrent }}
            disabled={addressUpdated}
          >
            <Form.Item
              name="addressIsCurrent"
              rules={[
                { required: true, message: 'Address status is required' },
              ]}
              label="Confirm with the customer that the above address is
              accurate"
            >
              <Radio.Group onChange={handleSelection} disabled={addressUpdated}>
                <Space direction="vertical">
                  <Radio data-testid="address-is-accurate-radio" value={true}>
                    Yes, address is accurate
                  </Radio>
                  <Radio data-testid="address-needs-update-radio" value={false}>
                    No, address requires update
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
          {renderSelectionResult()}
        </QueryBoundary>
      </StepContainer>
    </>
  );
};

export default AddressReviewStep;
