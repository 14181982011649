import { useState } from 'react';
import { Modal } from 'antd';
import {
  Input,
  Checkbox,
  Button,
  Select,
  message as messageApi,
  Form,
} from 'antd';
import { useMutation } from '@apollo/client';

import useAgent from 'hooks/useAgent';
import { ADD_NOTES_MUTATION } from 'mutations/notes';

const { TextArea } = Input;
const { Option } = Select;
const { Item } = Form;

const defaultProduct = 'CREDIT';

const AddNotes = ({ hide, ...modalProps }) => {
  const agent = useAgent();

  const [message, setMessage] = useState('');
  const [customerIdsText, setCustomerIdsText] = useState('');
  const [product, setProduct] = useState(defaultProduct);
  const [isSticky, setIsSticky] = useState(false);
  const [saved, setSaved] = useState(false);

  const [addNotesMutation] = useMutation(ADD_NOTES_MUTATION, {
    onCompleted: () => {
      setSaved(true);
      clearFields();
    },
    onError: (error) => {
      messageApi.error(error.message);
    },
  });

  const saveNote = () => {
    const customerIds = customerIdsText?.split(',').map((id) => {
      return id?.replace(/\D/g, ''); // remove all non-digits
    });

    addNotesMutation({
      variables: {
        applicationIds: [], //temporary. Need to look into whether we need to add applicationIds to this form
        customerIds,
        message,
        isSticky,
        agentId: agent?.user?.requester_id,
        agentName: agent?.user?.name,
        product,
      },
    });
  };

  const handleOnCancel = () => {
    clearFields();
    hide();
  };

  const clearFields = () => {
    setMessage('');
    setCustomerIdsText('');
    setIsSticky(false);
    setProduct(defaultProduct);
  };

  const handleCreateAnotherNote = () => {
    clearFields();
    setSaved(false);
  };

  return (
    <Modal
      {...modalProps}
      title="Add bulk note to multiple customer profiles"
      footer={null}
      width={650}
    >
      {!saved ? (
        <Form layout={'vertical'} size={'middle'}>
          <Item label="Note">
            <TextArea
              data-testid="note-text-area"
              type="reset"
              autoSize={{ minRows: 3, maxRows: 10 }}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
          </Item>
          <Item label="Customer Ids">
            <TextArea
              data-testid="customer-ids-text-area"
              type="reset"
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(e) => setCustomerIdsText(e.target.value)}
              value={customerIdsText}
              placeholder="123456789, 456782489, 8735137892, 873254264"
            />
          </Item>
          <Item label="Product">
            <Select
              data-testid="product-select"
              defaultValue={defaultProduct}
              onChange={(value) => setProduct(value)}
            >
              <Option value="CREDIT">Credit</Option>
              <Option value="DEBIT">Money</Option>
              <Option value="GIGS">Earn</Option>
            </Select>
          </Item>
          <Item>
            <Checkbox
              data-testid="is-sticky-check-box"
              onChange={(e) => setIsSticky(e.target.checked)}
              checked={isSticky}
            >
              Super Sticky
            </Checkbox>
          </Item>
          <Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
              }}
            >
              <Button onClick={handleOnCancel}>Cancel</Button>
              <Button
                data-testid="save-note-button"
                type="primary"
                onClick={saveNote}
                disabled={!message || !customerIdsText}
              >
                Save
              </Button>
            </div>
          </Item>
        </Form>
      ) : (
        <div style={{ display: 'grid' }}>
          <span style={{ fontSize: '16px' }}>Notes Saved Successfully!</span>
          <div
            style={{
              display: 'flex',
              justifySelf: 'end',
              gap: '0.5rem',
              marginTop: '2rem',
            }}
          >
            <Button onClick={handleOnCancel}>Close</Button>
            <Button
              data-testid="save-note-button"
              type="primary"
              onClick={handleCreateAnotherNote}
            >
              Create another note
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddNotes;
