import { Card, Flex } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import CeaseAndDesist from './CeaseAndDesist';
import DeceasedStatus from './DeceasedStatus';
import IncomeResidence from './IncomeResidence';

const CustomerDetailsPage = () => {
  const { showDeceasedStatus } = useFlags();
  return (
    <Card style={{ paddingBottom: 'var(--spacing-md)' }}>
      <Flex vertical={true} gap="var(--spacing-xl)">
        <IncomeResidence />
        <CeaseAndDesist />
        {showDeceasedStatus ? <DeceasedStatus /> : null}
      </Flex>
    </Card>
  );
};

export default CustomerDetailsPage;
