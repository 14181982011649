import axios from 'axios';
import _get from 'lodash/get';
import config from 'config';
import { Card, Table, message } from 'antd';
import { getColumnSearchProps } from 'utilities/tableHelpers';

const FILE_TYPE = Object.freeze({
  ON_BASE_FILE: 'ON_BASE_FILE',
  TCS_LETTER: 'TCS_LETTER',
});

const downloadOnbaseFiles = async (vaultToken, documentName) => {
  try {
    const response = await axios.post(
      `${config.ONBASE_FILE_VAULT_URL}/reveal-file`,
      vaultToken,
      {
        responseType: 'blob',
      },
    );

    const href = URL.createObjectURL(response.data);
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.download = documentName;
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (e) {
    message.error('Failed to download file');
  }
};

const DownloadLink = ({ vaultToken, documentName }) => (
  <a onClick={() => downloadOnbaseFiles(vaultToken, documentName)}>
    {documentName}
  </a>
);

const searchFilter = (dataIndex, value, record) => {
  const indexValue = _get(record, dataIndex, '');
  return indexValue?.toString().includes(value);
};

const columns = [
  {
    title: 'Customer Name',
    dataIndex: 'customerName',
    key: 'customerName',
    ...getColumnSearchProps('maskedPanLast4', searchFilter),
  },
  {
    title: 'Parent Case ID',
    dataIndex: 'parentCaseId',
    key: 'parentCaseId',
    ...getColumnSearchProps('parentCaseId', searchFilter),
  },
  {
    title: 'Transaction ID',
    dataIndex: 'caseId',
    key: 'caseId',
    ...getColumnSearchProps('caseId', searchFilter),
  },
  {
    title: 'Card Last 4',
    dataIndex: 'maskedPanLast4',
    key: 'maskedPanLast4',
    ...getColumnSearchProps('maskedPanLast4', searchFilter),
  },
  {
    title: 'Status Work',
    dataIndex: 'statusWork',
    key: 'statusWork',
    ...getColumnSearchProps('statusWork', searchFilter),
  },
  {
    title: 'Account ID',
    dataIndex: 'accountId',
    key: 'accountId',
    ...getColumnSearchProps('accountId', searchFilter),
  },
  {
    title: 'File Type',
    dataIndex: 'fileType',
    key: 'fileType',
    ...getColumnSearchProps('fileType', searchFilter),
  },
  {
    title: 'Download',
    dataIndex: 'documentName',
    key: 'documentName',
    render: (documentName, record) => {
      if (record.fileType && record.fileType === FILE_TYPE.ON_BASE_FILE) {
        return (
          <DownloadLink
            vaultToken={record.vaultToken}
            documentName={record.documentName}
          />
        );
      } else {
        return !record.signUrl ? null : (
          <a rel="noopener noreferrer" href={record.signUrl} target="_blank">
            {documentName}
          </a>
        );
      }
    },
  },
];

let locale = {
  emptyText: 'Fraud Files Not Found',
};

const FraudFilesSearchResults = ({ data, title }) => {
  return (
    <Card
      bordered={false}
      title={title ?? 'Search Result'}
      style={{ marginTop: 5 }}
    >
      <Table locale={locale} columns={columns} dataSource={data} />
    </Card>
  );
};

export default FraudFilesSearchResults;
