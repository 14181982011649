import { useState } from 'react';

const useModalControls = (modalProps = {}) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    modalProps.onHide && modalProps.onHide();
    setOpen(false);
  };

  const show = () => {
    modalProps.onShow && modalProps.onShow();
    setOpen(true);
  };

  const onCancel = () => {
    modalProps.onCancel && modalProps.onCancel();
    hide();
  };

  const onOk = () => {
    modalProps.onOk && modalProps.onOk();
    hide();
  };

  return {
    hide,
    show,
    onCancel,
    onOk,
    open,
    ...modalProps,
  };
};

export default useModalControls;
