import { useState, useRef } from 'react';
import { Card } from 'antd';

import NotesAndZendesk from './NotesAndZendesk';
import ProfileViews from './ProfileViews';

const Interactions = () => {
  const [activeTabKey, setActiveTabKey] = useState('notesAndZendesk');
  const rootRef = useRef(null);

  const topNavTabItems = [
    {
      key: 'notesAndZendesk',
      tab: 'Notes & Zendesk',
    },
    {
      key: 'profileViews',
      tab: 'Profile Views',
    },
  ];

  const contentList = {
    notesAndZendesk: <NotesAndZendesk />,
    profileViews: <ProfileViews />,
  };

  const onTopNavTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <div ref={rootRef}>
      <Card
        style={{ height: '500px' }}
        styles={{
          body: {
            display: 'flex',
            paddingTop: 'var(--spacing-xs)',
            flexDirection: 'column',
            height: 'calc(100% - 46px) ', // 100% minus the height of the top nav
          },
        }}
        bordered={false}
        id="interactions-card"
        size="small"
        tabList={topNavTabItems}
        activeTabKey={activeTabKey}
        defaultActiveTabKey="notesAndZendesk"
        tabProps={{
          size: 'medium',
        }}
        data-testid="interactions-card"
        onTabChange={onTopNavTabChange}
      >
        {contentList[activeTabKey]}
      </Card>
    </div>
  );
};

export default Interactions;
