import { Button, Flex, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import { COLORS } from 'utilities/styles';

export const getColumnSearchProps = (dataIndex, customFilter) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 'var(--spacing-xs)',
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={'Search'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{
            marginBottom: 'var(--spacing-xs)',
            display: 'block',
          }}
        />
        <Flex gap="var(--spacing-xs)">
          <Button
            style={{ width: '100%' }}
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
          >
            Clear
          </Button>
          <Button
            style={{ width: '100% ' }}
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
          >
            Filter
          </Button>
        </Flex>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? `${COLORS.blue1}` : undefined,
        }}
      />
    ),

    onFilter: (value, record) => customFilter(dataIndex, value, record),
  };
};
