import { Alert, Button } from 'antd';

const ThirdPartyInfoPromptAlert = ({ showThirdPartyEmailModal }) => {
  return (
    <Alert
      banner
      message="Is this payment plan going to be managed by a third party? If so, enter their details first."
      type="info"
      showIcon
      closeable
      action={
        <Button size="small" type="primary" onClick={showThirdPartyEmailModal}>
          Enter Details
        </Button>
      }
      style={{ marginBottom: 'var(--spacing-md)', width: 725 }}
    />
  );
};

export default ThirdPartyInfoPromptAlert;
