import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Divider, Flex, Typography, Row, Col, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';

import EditIncomeResidenceModal from './EditIncomeResidenceModal';

import DisplayValue from 'components/DisplayValue/DisplayValue';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import { CUSTOMER_FINANCIAL_INFO } from 'queries/customer';

import useModalControls from 'hooks/useModalControls';
import { formatDate } from 'utilities/datesAndTimes';
import { parseCurrency, currencyFormatter } from 'utilities/helpers';
import { COLORS } from 'utilities/styles';

const { Title } = Typography;

const LOADING_SKELETON = {
  title: false,
  paragraph: { rows: 4, width: ['120px', '140px', '120px', '140px'] },
};

const IncomeResidence = () => {
  const { customerId } = useParams();

  const [timestamp, setTimestamp] = useState('');
  const [monthlyLiving, setmonthlyLiving] = useState('');
  const [annualIncome, setAnnualIncome] = useState('');
  const [shouldGetCLIPTout, setShouldGetCLIPTout] = useState(null);

  const editIncomeAndResidenceModal = useModalControls();

  const financialInfoQuery = useQuery(CUSTOMER_FINANCIAL_INFO, {
    variables: { customerId },
    onCompleted: (response) => {
      const financialInfo = response?.customer?.financialInfo;
      setAnnualIncome(parseCurrency(financialInfo?.annualIncome?.toString()));
      setmonthlyLiving(parseCurrency(financialInfo?.monthlyLiving?.toString()));
      setTimestamp(formatDate(financialInfo?.lastUpdated));
      setShouldGetCLIPTout(financialInfo?.shouldGetCLIPTout);
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div>
      <EditIncomeResidenceModal
        {...editIncomeAndResidenceModal}
        customerId={customerId}
        monthlyLiving={monthlyLiving}
        annualIncome={annualIncome}
        EditIncomeResidenceModal
      />
      <Flex justify="space-between">
        <Title level={5}>
          Income & Residence{' '}
          {shouldGetCLIPTout && (
            <Tooltip title="Please confirm or update the customer's current information.">
              {' '}
              <WarningFilled
                data-testid="clip-tout-warning"
                style={{ color: COLORS.yellow1 }}
              />{' '}
            </Tooltip>
          )}
        </Title>
        <Button
          data-testid="edit-button"
          type="link"
          icon={<EditOutlined style={{ fontSize: '1.5rem' }} />}
          onClick={editIncomeAndResidenceModal.show}
          size="small"
          Button
        />
      </Flex>
      <Divider style={{ margin: '0 0 var(--spacing-sm)' }} />
      <QueryBoundary query={financialInfoQuery} skeleton={LOADING_SKELETON}>
        <Row gutter={['var(--spacing-md)', 'var(--spacing-lg)']}>
          <Col span={24}>
            <DisplayValue label="Last Updated:">
              <span data-testid="last-updated">{timestamp}</span>
            </DisplayValue>
          </Col>
          <Col span={8}>
            <DisplayValue label="Monthly Rent/Mortgage:">
              {currencyFormatter(monthlyLiving) || '-'}
            </DisplayValue>
          </Col>
          <Col span={8}>
            <DisplayValue label="Total Annual Income:">
              {currencyFormatter(annualIncome) || '-'}
            </DisplayValue>
          </Col>
        </Row>
      </QueryBoundary>
    </div>
  );
};

export default IncomeResidence;
