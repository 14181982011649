import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Descriptions, Modal } from 'antd';
import { useMutation } from '@apollo/client';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { CANCEL_PAYMENT } from 'mutations/payments';
import {
  formatDollarsFromCents,
  formatFundingAccount,
} from 'utilities/helpers';
import { STATUS, SIZE } from 'utilities/constants';

const { Item } = Descriptions;

const CancelPaymentModal = ({ hide, payment, ...modalProps }) => {
  const [showCancelFailure, setShowCancelFailure] = useState(false);
  const [showCancelSuccess, setShowCancelSuccess] = useTimeoutValue(
    false,
    3000,
  );

  const { customerId } = useParams();

  const [cancelPayment, cancelMutation] = useMutation(CANCEL_PAYMENT, {
    refetchQueries: ['PaymentsQuery', 'GetSettlementOffers'],
    onCompleted: () => {
      setShowCancelSuccess(true);
      setTimeout(closeModal, 2500);
    },
    onError: () => setShowCancelFailure(true),
  });

  const submitCancelPayment = async () => {
    setShowCancelFailure('');
    await cancelPayment({
      variables: {
        customerId: customerId,
        scheduleId: payment.id,
      },
    });
  };

  const closeModal = () => {
    setShowCancelFailure(false);
    hide();
  };

  return (
    <Modal
      {...modalProps}
      title="Confirm payment cancellation"
      onOk={submitCancelPayment}
      onCancel={closeModal}
      okText="Cancel payment"
      confirmLoading={cancelMutation.loading}
      style={{ position: 'relative' }}
    >
      <SectionMessage
        data-testid="send-success-message"
        status={STATUS.SUCCESS}
        size={SIZE.SM}
        cover={true}
        visible={showCancelSuccess}
        text={'Payment Cancelled'}
      />
      <Descriptions bordered={true} size="small">
        <Item span={3} label="Amount">
          {formatDollarsFromCents(Math.abs(payment?.amount))}
        </Item>
        <Item span={3} label="Date">
          {payment?.date.toLocaleString()}
        </Item>
        <Item span={3} label="Bank Account">
          {formatFundingAccount(payment?.bankAccount)}
        </Item>
        <Item span={3} label="Reference">
          {payment?.reference}
        </Item>
      </Descriptions>
      {showCancelFailure && (
        <Alert
          type="error"
          message={`Failed to cancel payment. Please try again.`}
          style={{ marginTop: 'var(--spacing-md)' }}
        />
      )}
    </Modal>
  );
};

export default CancelPaymentModal;
