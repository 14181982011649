import { useParams } from 'react-router-dom';
import { Row, Descriptions } from 'antd';
import { useQuery } from '@apollo/client';

import CondensedTable from 'components/CondensedTable/CondensedTable';
import QueryResult from 'components/QueryResult/QueryResult';

import { STATEMENT_TRANSACTIONS } from 'queries/statementTransactions';
import { currencyFormatter } from 'utilities/helpers';
import { formatDate } from 'utilities/datesAndTimes';

const StatementTransactions = ({ statementId }) => {
  if (!statementId) {
    return <></>;
  }

  const { customerId } = useParams();

  const statemenTransactionsQuery = useQuery(STATEMENT_TRANSACTIONS, {
    variables: {
      customerId,
      statementId,
    },
  });

  const transactions =
    statemenTransactionsQuery?.data?.cardStatementTransactions
      ?.cardStatementTransactions;

  const columns = [
    {
      title: 'Trans Date',
      dataIndex: 'transactionDate',
      render: formatDate,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Post Date',
      dataIndex: 'postedDate',
      render: formatDate,
    },
    {
      title: 'Reference Number',
      dataIndex: 'referenceNumber',
    },
    {
      title: 'Description',
      dataIndex: 'itemMessage',
    },
    {
      title: 'Amount',
      dataIndex: 'itemAmount',
      render: (amount) => currencyFormatter(amount),
    },
  ];

  return (
    <QueryResult {...statemenTransactionsQuery}>
      <Row gutter={[5, 5]} style={{ width: '100%' }}>
        <Descriptions title="Transactions" style={{ marginTop: '2rem' }} />
        <CondensedTable
          style={{ width: '100%' }}
          columns={columns}
          data={transactions}
          length={transactions?.length}
          rowKey="id"
        />
      </Row>
    </QueryResult>
  );
};

export default StatementTransactions;
