import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Alert, Button, Checkbox, Form, Input, Modal } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useCeaseAndDesistStatus from 'hooks/useCeaseAndDesistStatus';
import useTimeoutValue from 'hooks/useTimeoutValue';
import {
  CREATE_OR_UPDATE_THIRD_PARTY_INFO,
  REMOVE_THIRD_PARTY,
} from 'mutations/accountPreferences';
import { SIZE, STATUS } from 'utilities/constants';

const { Item } = Form;

const ThirdPartyEmailModal = ({ thirdParty, hide, ...modalProps }) => {
  const { accountId, customerId } = useParams();
  const [thirdPartyEmailForm] = Form.useForm();
  const { ceaseAndDesistEnabled } = useCeaseAndDesistStatus();

  useEffect(() => {
    if (thirdParty) {
      thirdPartyEmailForm.setFieldsValue({
        thirdPartyName: thirdParty.name,
        thirdPartyEmail: thirdParty.email,
        customerOptIn: thirdParty.customerOptIn,
      });
    }
  }, [thirdParty]);

  const [
    createOrUpdateThirdPartyInfoSuccess,
    setCreateOrUpdateThirdPartyInfoSuccess,
  ] = useTimeoutValue(false, 3000);
  const [
    createOrUpdateThirdPartyInfoError,
    setCreateOrUpdateThirdPartyInfoError,
  ] = useTimeoutValue(false);

  const [removeThirdPartySuccess, setRemoveThirdPartySuccess] = useTimeoutValue(
    false,
    3000,
  );

  const [removeThirdPartyError, setRemoveThirdPartyError] =
    useTimeoutValue(false);

  const [
    createOrUpdateThirdPartyInfo,
    { loading: creatingOrUpdatingThirdPartyInfo },
  ] = useMutation(CREATE_OR_UPDATE_THIRD_PARTY_INFO, {
    onCompleted: () => {
      setCreateOrUpdateThirdPartyInfoSuccess(true);
      setTimeout(hide, 2900);
    },
    onError: () => {
      setCreateOrUpdateThirdPartyInfoError(true);
    },
    refetchQueries: ['GetThirdPartyInfo'],
  });

  const [removeThirdParty, { loading: removingThirdParty }] = useMutation(
    REMOVE_THIRD_PARTY,
    {
      onCompleted: () => {
        setRemoveThirdPartySuccess(true);
        setTimeout(hide, 2900);
      },
      onError: () => setRemoveThirdPartyError(true),
      refetchQueries: ['GetThirdPartyInfo'],
    },
  );

  const onConfirm = async () => {
    const { thirdPartyName, thirdPartyEmail, customerOptIn } =
      await thirdPartyEmailForm.validateFields();

    createOrUpdateThirdPartyInfo({
      variables: {
        accountId,
        customerId,
        thirdPartyName,
        thirdPartyEmail,
        customerOptIn: !!customerOptIn,
      },
    });
  };

  const footerButtons = useMemo(() => {
    const buttons = [
      <Button
        form="thirdPartyEmailForm"
        type="primary"
        key="submit"
        htmlType="submit"
        loading={creatingOrUpdatingThirdPartyInfo}
      >
        Confirm
      </Button>,
    ];

    if (thirdParty.email) {
      buttons.unshift(
        <Button
          danger
          key="delete"
          onClick={() => removeThirdParty({ variables: { accountId } })}
          loading={removingThirdParty}
        >
          Remove Third Party
        </Button>,
      );
    }

    return buttons;
  }, [thirdParty.email, removingThirdParty, creatingOrUpdatingThirdPartyInfo]);

  return (
    <Modal
      {...modalProps}
      centered
      title="Enter Third Party Information"
      width={500}
      footer={footerButtons}
      onCancel={hide}
      destroyOnClose={true}
    >
      <Form
        form={thirdPartyEmailForm}
        name="thirdPartyEmailForm"
        onFinish={onConfirm}
        disabled={creatingOrUpdatingThirdPartyInfo || removingThirdParty}
      >
        <Item
          label="Full Name"
          name="thirdPartyName"
          rules={[
            { required: true, message: 'Full Name is required.' },
            {
              pattern: /^[A-Za-z '-]*$/, // Letters, space, apostrophe, hyphen
              message: 'Full Name cannot include special characters or numbers',
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          label="Email"
          name="thirdPartyEmail"
          rules={[
            { required: true, message: 'Email is required.' },
            { type: 'email', message: 'Invalid email address.' },
          ]}
        >
          <Input />
        </Item>
        <Item name="customerOptIn" valuePropName="checked">
          <Checkbox disabled={ceaseAndDesistEnabled}>
            Opt customer in to emails
          </Checkbox>
        </Item>
      </Form>
      <Alert
        message="Please note, you will be able to edit this preference later"
        type="info"
        showIcon
      />
      {(createOrUpdateThirdPartyInfoError || removeThirdPartyError) && (
        <Alert
          banner
          type="error"
          message={
            createOrUpdateThirdPartyInfoError
              ? 'Failed to save third party information. Please try again.'
              : 'Failed to remove third party information. Please try again.'
          }
          style={{ marginTop: 'var(--spacing-sm)' }}
        />
      )}
      <SectionMessage
        data-testid="third-party-success-message"
        status={STATUS.SUCCESS}
        size={SIZE.SM}
        cover={true}
        visible={createOrUpdateThirdPartyInfoSuccess || removeThirdPartySuccess}
        text={
          createOrUpdateThirdPartyInfoSuccess
            ? 'Third party information successfully saved!'
            : 'Third party information successfully removed!'
        }
      />
    </Modal>
  );
};

export default ThirdPartyEmailModal;
