import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DatePicker, Flex, Modal, Typography } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';

import StatementSummary from './StatementSummary';
import CondensedTable from 'components/CondensedTable/CondensedTable';
import QueryResult from 'components/QueryResult/QueryResult';
import useModalControls from 'hooks/useModalControls';
import { STATEMENT_LINKS } from 'queries/statementLinks';
import { formatDate, formatDateToSubmitCompact } from 'utilities/datesAndTimes';

import StatementPreferences from './StatementPreferences';

const DEFAULT_DATE = '2017-05-01';
const FIS_CUTOVER_DATE = new Date('09/15/2021'); // FIS cut over, no summaries exist after this date

const StatementsSection = () => {
  const { accountId, customerId } = useParams();
  const [fromDate, setFromDate] = useState(
    formatDateToSubmitCompact(dayjs(DEFAULT_DATE)),
  );
  const statementModal = useModalControls();
  const [selectedStatementDate, setSelectedStatementDate] = useState(null);
  const { statements, enableStatementsPreferencesUpdate } = useFlags();

  const handleOpenStatementClick = (date) => {
    setSelectedStatementDate(dayjs(date));
    statementModal.show();
  };

  const dateColumn = {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date) => formatDate(date),
  };

  const statementsColumn = {
    title: 'Link',
    dataIndex: 'date',
    key: 'statementLink',
    render: (date) => {
      return (
        new Date(date) <= FIS_CUTOVER_DATE && (
          <a
            onClick={() => handleOpenStatementClick(date)}
            data-testid={`statement-summary-${date}`}
          >
            Open Statement
          </a>
        )
      );
    },
  };

  const viewPDFColumn = {
    title: 'PDF',
    dataIndex: 'documentUrl',
    key: 'pdf',
    render: (value) => (
      <>
        {value && (
          <a target="_blank" rel="noreferrer" href={value}>
            View PDF
          </a>
        )}
      </>
    ),
  };

  const columns = [];
  columns.push(dateColumn);
  if (statements) {
    columns.push(statementsColumn);
  }
  columns.push(viewPDFColumn);

  const [toDate, setToDate] = useState(formatDateToSubmitCompact(dayjs()));

  const variables = {
    accountId: accountId,
    customerId: customerId,
    from: fromDate,
    to: toDate,
  };

  const [getStatementLinks, statementsQuery] = useLazyQuery(STATEMENT_LINKS, {
    variables: variables,
  });

  useEffect(() => {
    if (accountId && customerId) {
      getStatementLinks({ variables: variables });
    }
  }, [accountId, customerId]);

  const handleDateRangeValue = (dates) => {
    if (dates && dates[0] && dates[1]) {
      setFromDate(formatDateToSubmitCompact(dates[0]));
      setToDate(formatDateToSubmitCompact(dates[1]));
      getStatementLinks({ variables: variables });
    }
  };

  return (
    <>
      <Typography.Title level={5}>Statements</Typography.Title>
      <Flex vertical={true} gap="var(--spacing-lg)">
        <Flex
          justify={
            enableStatementsPreferencesUpdate ? 'space-between' : 'right'
          }
        >
          {enableStatementsPreferencesUpdate && <StatementPreferences />}

          <DatePicker.RangePicker
            defaultValue={[dayjs(DEFAULT_DATE), dayjs()]}
            format="M/D/YYYY"
            onCalendarChange={handleDateRangeValue}
          />
        </Flex>

        <QueryResult {...statementsQuery}>
          <CondensedTable
            length={12}
            columns={columns}
            data={statementsQuery?.data?.statementLinks?.statements}
            rowKey="id"
            data-testid="statements-table"
          />
          <Modal
            {...statementModal}
            title="Card Statement"
            cancelButtonProps={{ hidden: true }}
            width={650}
          >
            <StatementSummary date={selectedStatementDate} />
          </Modal>
        </QueryResult>
      </Flex>
    </>
  );
};

export default StatementsSection;
