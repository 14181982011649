import NoteBody from './NoteBody';
import HorizontalList from 'components/HorizontalList/HorizontalList';
import SanitizeHTML from 'components/SanitizeHTML/SanitizeHTML';

import { formatDateTime } from 'utilities/datesAndTimes';
import { formatFileName, sanitizeAndView } from 'utilities/helpers';

const { ListItem } = HorizontalList;

const ZendeskInteraction = ({
  interaction,
  enableTruncatedNotes,
  highlight,
}) => {
  return (
    <>
      <HorizontalList>
        <ListItem>{formatDateTime(interaction.timestamp)}</ListItem>
        {interaction.agentName && (
          <ListItem>
            <strong>{interaction.agentName}</strong>
          </ListItem>
        )}
      </HorizontalList>
      <NoteBody
        data-testid="text-interaction-message"
        enableTruncatedNotes={enableTruncatedNotes}
        highlight={highlight}
        searchableContent={interaction.message}
        otherElements={
          interaction.attachments?.length > 0 &&
          interaction.attachments.map((atts, idx) => {
            return (
              <span key={`file-attachment-${idx}`}>
                <a
                  title="Ticket Attachment"
                  key={`file-attachment-${idx}`}
                  data-testid="filename-text"
                  onClick={() => sanitizeAndView(atts.contentUrl)}
                >
                  {formatFileName(atts.name)}
                </a>
                <br />
              </span>
            );
          })
        }
      />
    </>
  );
};

export default ZendeskInteraction;
