import { useState, useRef, useCallback, useEffect } from 'react';
import { useToggle } from 'react-use';
import { useInView } from 'react-intersection-observer';
import { Input, Button, Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';

import Comment from './Comment';
import NoteBody from './NoteBody';
import HorizontalList from 'components/HorizontalList/HorizontalList';

import useAgent from 'hooks/useAgent';
import { ADD_COMMENT_MUTATION } from 'mutations/notes';

import { formatDateTime } from 'utilities/datesAndTimes';
import { isAgentNote } from 'utilities/helpers';

const { ListItem } = HorizontalList;
const { TextArea } = Input;

const CommentsSection = styled.div`
  display: grid;
  row-gap: var(--spacing-xs);
  padding-top: var(--spacing-xs);
`;

const CommentsTitle = styled.a`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: var(--spacing-xxs);
`;

const AgentNote = ({
  interaction,
  enableTruncatedNotes,
  onCommentAddOrUpdateSuccess,
  rootRef,
  highlight,
}) => {
  const agent = useAgent();
  const [commentText, setCommentText] = useState('');
  const [showCommentsSection, toggleShowCommentsSection] = useToggle(false);
  const [showCommentFailureMessage, setShowCommentFailureMessage] =
    useState(false);

  // Element Reference used to scroll the comment box into view
  const commentsAnchor = useRef();
  const { ref: setInViewRef, inView } = useInView({
    root: rootRef.current,
    threshold: 0.1,
  });
  const setRefs = useCallback(
    (node) => {
      commentsAnchor.current = node;
      setInViewRef(node);
    },
    [setInViewRef],
  );

  const [addCommentMutation, { loading }] = useMutation(ADD_COMMENT_MUTATION, {
    refetchQueries: ['NotesInteractionsZendeskRecords'],
    onCompleted: () => {
      setCommentText('');
      toggleShowCommentsSection();
      onCommentAddOrUpdateSuccess('Comment added successfully!');
    },
    onError: () => {
      setShowCommentFailureMessage(true);
    },
  });

  const handleSaveComment = async () => {
    setShowCommentFailureMessage(false);
    await addCommentMutation({
      variables: {
        noteId: interaction?.id,
        agentId: agent?.user?.requester_id,
        agentName: agent?.user?.name,
        message: commentText,
      },
    });
  };

  const isOwnInteraction = agent?.user?.requester_id === interaction.agentId;

  const comments = [...(interaction?.comments ?? [])];

  const commentsTitle =
    comments?.length > 0
      ? `${comments.length} comment${comments.length > 1 ? 's' : ''}`
      : 'Add comment';

  useEffect(() => {
    if (showCommentsSection && !inView) {
      commentsAnchor.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [showCommentsSection]);

  return (
    <>
      <HorizontalList>
        <ListItem>{formatDateTime(interaction.timestamp)}</ListItem>
        {interaction.agentName && (
          <ListItem>
            <strong>{interaction.agentName}</strong>
          </ListItem>
        )}
        {isAgentNote(interaction) &&
          (isOwnInteraction || comments?.length > 0) && (
            <ListItem>
              <CommentsTitle
                onClick={toggleShowCommentsSection}
                data-testid="show-comments-link"
              >
                {commentsTitle}
                {showCommentsSection ? <UpOutlined /> : <DownOutlined />}
              </CommentsTitle>
            </ListItem>
          )}
      </HorizontalList>
      <NoteBody
        data-testid="text-interaction-message"
        enableTruncatedNotes={enableTruncatedNotes}
        highlight={highlight}
        searchableContent={interaction.message}
      />
      {showCommentsSection && (
        <CommentsSection>
          {comments.length > 0 && (
            <>
              <span>
                <strong>Comments </strong>
                <span>({comments.length})</span>
              </span>
              {comments
                .sort((a, b) => a.createdTz - b.createdTz)
                .map((comment) => (
                  <div key={comment.id}>
                    <Comment
                      comment={comment}
                      canEdit={agent?.isSuperUser}
                      noteId={interaction.id}
                      onCommentUpdate={onCommentAddOrUpdateSuccess}
                    />
                  </div>
                ))}
            </>
          )}
          {isOwnInteraction && (
            <>
              <TextArea
                placeholder="New Comment"
                rows={3}
                maxLength={65000}
                data-testid="comment-textarea"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              {showCommentFailureMessage ? (
                <Typography.Text type="danger" style={{ textAlign: 'right' }}>
                  Failed to save comment. Please try again.
                </Typography.Text>
              ) : null}
              <Button
                type="primary"
                style={{ justifySelf: 'end' }}
                data-testid="comment-save-button"
                disabled={commentText?.length === 0 || loading}
                onClick={handleSaveComment}
              >
                Add Comment
              </Button>
            </>
          )}
        </CommentsSection>
      )}
      <span ref={setRefs} />
    </>
  );
};

export default AgentNote;
