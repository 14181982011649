import { useMemo } from 'react';
import { Alert, Modal, Typography } from 'antd';

import CondensedTable from 'components/CondensedTable/CondensedTable';
import { formatDollarsFromCents } from 'utilities/helpers';
import { PAST_DUE_INTERVAL } from 'utilities/constants';

const PAST_DUE_COLUMNS = Object.freeze([
  {
    title: 'Past Due Bucket',
    dataIndex: 'interval',
    render: (interval) => PAST_DUE_INTERVAL[interval],
  },
  {
    title: 'Occurrences',
    dataIndex: 'times',
  },
  {
    title: 'Current Amount',
    dataIndex: 'total',
    render: formatDollarsFromCents,
  },
]);

const PastDueHistoryModal = ({ pastDue, ...modalProps }) => {
  const pastDues = useMemo(() => {
    return (pastDue?.pastDues || []).map((pastDue) => {
      return {
        ...pastDue,
        key: `past-due_${pastDue.interval}`,
      };
    });
  }, [pastDue]);

  return (
    <Modal
      {...modalProps}
      centered
      width={640}
      cancelButtonProps={{ hidden: true }}
      title="Past Due History"
    >
      <Alert
        banner
        type="info"
        message={
          <span>
            <strong>Reminder</strong>: Payments aren't processed in real time.{' '}
            <br />
            Please check for pending payments to confirm the correct statement
            minimum.
          </span>
        }
        style={{ marginBottom: 'var(--spacing-lg)' }}
      />
      <Typography.Paragraph data-testid="consecutive-days">
        Consecutive Days Past Due:{' '}
        <strong>{pastDue?.consecutiveDaysPastDue}</strong>
      </Typography.Paragraph>
      <CondensedTable
        data={pastDues}
        columns={PAST_DUE_COLUMNS}
        bordered={true}
        size="small"
      />
    </Modal>
  );
};

export default PastDueHistoryModal;
