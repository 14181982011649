import { Checkbox, Form } from 'antd';

import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';

import StepContainer from '../StepContainer';

import {
  RECOVERIES_STEPS,
  setNextStep,
  useRecoveriesState,
} from './recoveriesState';

const { Item } = Form;

const DisclosureStep = ({ thirdParty }) => {
  const { acceptedOffer, paymentCadence } = useRecoveriesState();

  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }
    setNextStep(RECOVERIES_STEPS.EFTA);
  };

  const oneTimeDebit =
    paymentCadence.cadence === SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT;

  const isPifOffer = acceptedOffer.offerType?.includes(
    SETTLEMENT_OFFER_TYPES.PIF,
  );

  const settlementOrProgram = isPifOffer ? 'program' : 'settlement';
  const achOrDebitText = oneTimeDebit
    ? `a Debit Card ${settlementOrProgram}`
    : `an ACH ${settlementOrProgram}`;

  const debtPaidStatus = isPifOffer ? 'Paid in Full' : 'Settled in Full';

  return (
    <StepContainer title="Read Program Disclosures" onContinue={onFinish}>
      <Form name="disclosures" form={form} layout="vertical">
        <Item
          name="disclosure1"
          valuePropName="checked"
          rules={[
            {
              /* eslint-disable no-unused-vars */
              validator: (_, value) => {
                return new Promise((resolve, reject) => {
                  if (!value) {
                    reject();
                  }
                  resolve();
                });
              },
              message: 'Customer must acknowledge and accept to continue.',
            },
          ]}
        >
          <Checkbox
            data-testid="disclosure1"
            style={{
              textAlign: 'justify',
              textJustify: 'inter-word',
            }}
          >
            You’re accepting{' '}
            <strong>
              {achOrDebitText} of ${acceptedOffer.balance}.
            </strong>{' '}
            Once the {settlementOrProgram} balance is paid off, it will take up
            to 10 business days to consider the account{' '}
            <strong>{debtPaidStatus}</strong>, 10 business days to update the
            account status, and up to 30 days from the following statement cycle
            for the credit bureaus to adjust the credit reporting for the
            account. Failure to pay the total {settlementOrProgram} amount by
            the final due date will result in the {settlementOrProgram} being
            canceled and the entire remaining balance on the account being due
            at that point.
          </Checkbox>
        </Item>
        {!isPifOffer && (
          <Item
            name="disclosure2"
            valuePropName="checked"
            rules={[
              {
                /* eslint-disable no-unused-vars */
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (!value) {
                      reject();
                    }
                    resolve();
                  });
                },
                message: 'Customer must acknowledge and accept to continue.',
              },
            ]}
          >
            <Checkbox
              data-testid="disclosure2"
              style={{
                textAlign: 'justify',
                textJustify: 'inter-word',
              }}
            >
              If you make a payment that’s more than the settlement amount,
              we’ll{' '}
              <strong>
                apply the remainder to the outstanding balance on the account.
              </strong>{' '}
              There may be tax consequences as a result of a settlement, so
              please consult a tax professional if you have questions or need
              tax advice.{' '}
              {thirdParty.email
                ? ''
                : 'If you change your mailing address before the next tax year, please also update your address with us.'}
            </Checkbox>
          </Item>
        )}
      </Form>
    </StepContainer>
  );
};

export default DisclosureStep;
