import { gql } from '@apollo/client';

const FRAUD_FILE_SEARCH = gql`
  query SearchFraudFiles($searchRequest: FraudDocsSearchRequestInput!) {
    retrieveFraudDocs(searchRequest: $searchRequest) {
      customerName
      parentCaseId
      caseId
      signUrl
      documentName
      fileType
      maskedPanLast4
      accountId
      fraudIndicator
      clientId
      vaultToken
      statusWork
    }
  }
`;

export { FRAUD_FILE_SEARCH };
