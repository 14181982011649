import { Typography } from 'antd';

const { Text } = Typography;

const RewardsRate = ({ rewards }) => {
  const generalRewardsRate = rewards?.rewardsRate;
  const hasCategoryRewards = rewards?.categoryRewardsRate;

  // Gas, Travel, and Dining purchases > Gas, Travel, and Dining
  const categoryDescription = rewards?.categoryName ? (
    rewards?.categoryName.replace('purchases', '')
  ) : (
    <Text type="secondary" italic>
      Categories Pending
    </Text>
  );

  const categoryRewardsRate = rewards?.categoryRewardsRate ? (
    rewards?.categoryRewardsRate
  ) : (
    <Text type="secondary" italic>
      Pending
    </Text>
  );

  if (hasCategoryRewards) {
    return (
      <div data-testid="category-rewards-rates">
        {categoryDescription} - {categoryRewardsRate}%
        <br />
        All other purchases - {generalRewardsRate}%
      </div>
    );
  }

  return <div data-testid="general-rewards-rate">{generalRewardsRate}%</div>;
};

export default RewardsRate;
