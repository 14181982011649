import { makeVar, useReactiveVar } from '@apollo/client';
import _uniqBy from 'lodash/uniqBy';

export const transactionStateVar = makeVar({
  selectedTransactions: [],
  selectedTransactionKeys: [],
});

const setTransactions = (transactions) => {
  transactionStateVar({
    selectedTransactions: transactions,
    selectedTransactionKeys: transactions.map((entry) => entry.key),
  });
};

const addTransactions = (transactions) => {
  const { selectedTransactions } = transactionStateVar();
  const updatedSelections = _uniqBy(
    [...selectedTransactions, ...transactions],
    'key',
  );

  setTransactions(updatedSelections);
};

const removeTransactions = (transactions) => {
  const { selectedTransactions } = transactionStateVar();
  const updatedSelections = selectedTransactions.filter((entry) => {
    return !transactions.find((transaction) => transaction.key === entry.key);
  });

  setTransactions(updatedSelections);
};

export const setTransactionsSelected = (transactions = [], selected) => {
  if (selected) {
    addTransactions(transactions);
  } else {
    removeTransactions(transactions);
  }
};

export const setTransactionSelected = (transaction, selected) => {
  setTransactionsSelected([transaction], selected);
};

export const resetTransactionSelections = () => {
  setTransactions([]);
};

export const useTransactionState = () => {
  const transactionState = useReactiveVar(transactionStateVar);
  return {
    ...transactionState,
  };
};
