import useAgent from 'hooks/useAgent';

const Access = (props) => {
  const { roles: acceptedRoles, redacted, children } = props;
  const agent = useAgent();
  let showContent = false;

  if (acceptedRoles) {
    for (let i = 0; i < acceptedRoles.length; i++) {
      if (agent.roles?.includes(acceptedRoles[i])) {
        showContent = true;
        break;
      }
    }
  }

  if (showContent) {
    if (children.length > 1) {
      return [...children];
    }
    return children;
  } else if (redacted) {
    return redacted;
  } else {
    return null;
  }
};

export default Access;
