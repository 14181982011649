import { App, ConfigProvider } from 'antd';
import { COLORS } from 'utilities/styles';

const AntThemeProvider = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: COLORS.blue1,
          colorError: COLORS.red1,
          colorErrorBg: COLORS.red4,
          colorInfo: COLORS.blue1,
          colorInfoBg: COLORS.blue4,
          colorLink: COLORS.blue1,
          colorLinkHover: COLORS.indigo1,
          colorSuccess: COLORS.green1,
          colorSuccessBg: COLORS.green4,
          colorWarning: COLORS.mustard1,
          colorWarningBg: COLORS.mustard4,
        },
        components: {
          Button: {
            colorPrimary: COLORS.blue1,
            colorPrimaryHover: COLORS.indigo1,
            colorBorder: COLORS.blue1,
            fontWeightStrong: 600,
            colorText: COLORS.blue1,
          },
          Radio: {
            colorPrimary: COLORS.blue1,
            Group: {
              colorPrimary: COLORS.blue1,
            },
          },
          Checkbox: {
            colorPrimary: COLORS.blue1,
            colorPrimaryHover: COLORS.indigo1,
          },
          Tabs: {
            colorPrimary: COLORS.blue1,
            colorPrimaryHover: COLORS.indigo1,
          },
        },
      }}
    >
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default AntThemeProvider;
