import { gql } from '@apollo/client';

const CUSTOMER_RECORD_DOCUMENT = gql`
  query CustomerDocumentQuery($documentId: String!) {
    commCustomerIoDocument(documentId: $documentId) {
      body
    }
  }
`;

export { CUSTOMER_RECORD_DOCUMENT };
