import { gql } from '@apollo/client';

const SET_ACCOUNT_ATTRIBUTES = gql`
  mutation SetAccountAttributes(
    $customerId: String!
    $accountIds: [String!]!
    $attributes: [AccountAttributeInput!]!
  ) {
    updateAccountAttributes(
      customerId: $customerId
      customerAccountAttributesUpdateInput: {
        accountIds: $accountIds
        attributes: $attributes
      }
    )
  }
`;

const UPDATE_ACCOUNT_STATUS = gql`
  mutation UpdateStatusOnAccounts(
    $accountIds: [String!]!
    $agentName: String! = "anonymous"
    $customerId: String!
    $operation: AccountUpdateStatusOp!
    $reasonCode: AccountStatusReasonCodeV2!
    $statusCode: AccountStatusCode!
    $startDate: String
    $stopDate: String
  ) {
    updateStatusOnAccounts(
      customerId: $customerId
      updateStatusOnAccountsInput: {
        accountIds: $accountIds
        agentName: $agentName
        operation: $operation
        reasonCode: $reasonCode
        statusCode: $statusCode
        startDate: $startDate
        stopDate: $stopDate
      }
    ) {
      identifiers
    }
  }
`;

export { SET_ACCOUNT_ATTRIBUTES, UPDATE_ACCOUNT_STATUS };
