import { useEffect, useState, useMemo } from 'react';
import { useLocalStorage, useToggle } from 'react-use';
import { useReactiveVar, useMutation, useLazyQuery } from '@apollo/client';
import copy from 'copy-to-clipboard';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Input,
  Select,
  Tooltip,
} from 'antd';
import {
  ArrowRightOutlined,
  CopyOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

import SectionMessage from 'components/SectionMessage/SectionMessage';

import { noteVar } from 'apollo/reactiveVars';

import useAccountQuery from 'hooks/useAccountQuery';
import useAgent from 'hooks/useAgent';
import useAnalytics from 'hooks/useAnalytics';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useCanAccessCaseCreationForm from 'hooks/useCanAccessCaseCreationForm';
import useAddNote from './useAddNote';

import { ADD_APPLICATION_NOTE, ADD_CUSTOMER_NOTE } from 'mutations/notes';
import { CREATE_ZENDESK_CASE } from 'mutations/zendeskCaseCreation';
import { CUSTOMER_WITH_FULL_ACCOUNTS } from 'queries/customer';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { GET_CASE_TYPE_OPTIONS } from 'queries/caseManagement';
import { ANALYTICS_EVENTS, STATUS, SIZE } from 'utilities/constants';

const { TextArea } = Input;
const { Item } = Form;
const { Option } = Select;

const NoteForm = () => {
  const canAccessCaseCreationForm = useCanAccessCaseCreationForm();
  const { customerInfo, applicationId, customerId, accountId } =
    useCustomerInfo();

  const { account } = useAccountQuery(customerId, accountId);
  const [addNote, addNoteMutation] = useAddNote();

  const currentDueDate = account?.paymentInfo?.printDueDate;

  const note = useReactiveVar(noteVar);

  const { trackEvent } = useAnalytics();

  const [createCaseChecked, setCreateCaseChecked] = useToggle(false);

  const [customerNoteContext, setCustomerNoteContext] = useLocalStorage(
    'customer-note-context',
  );

  const [caseCreationFormContext, setCaseCreationFormContext] = useLocalStorage(
    'case-creation-context',
  );

  const [creditAccounts, setCreditAccounts] = useState([]);
  const [caseTypeOptions, setCaseTypeOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [caseTypesByTeam, setCaseTypesByTeam] = useState([]);

  const [zendeskUrl, setZendeskUrl] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [caseTypeSelected, setCaseTypeSelected] = useState('');

  const [teamSelected, setTeamSelected] = useState(false);
  const [zendeskLinkCopied, setZendeskLinkCopied] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [noteSaved, setNoteSaved] = useState(false);
  const [caseSaved, setCaseSaved] = useState(false);

  const [form] = Form.useForm();

  const isDate26thOr29thTo31st = (selectedDate) => {
    const date = selectedDate.date();
    return date === 26 || (date >= 29 && date <= 31);
  };

  const is4DaysLeadingToDueDate = (selectedDate) => {
    const date = selectedDate.date();
    const fourDaysBeforeDueDate = dayjs(currentDueDate).subtract(4, 'day');
    const firstBlockedDay = fourDaysBeforeDueDate.date();
    const currentDueDateDay = dayjs(currentDueDate).date();
    return date >= firstBlockedDay && date <= currentDueDateDay;
  };

  const getCycleDateRequiredCases = () => {
    return caseTypeOptions.reduce((acc, option) => {
      if (option.cycleDateRequired === true) {
        acc[option.value] = true;
      }
      return acc;
    }, {});
  };

  const getRequestedDueDateRequiredCases = () => {
    return caseTypeOptions.reduce((acc, option) => {
      if (option.requestedDueDateRequired === true) {
        acc[option.value] = true;
      }
      return acc;
    }, {});
  };

  const getStatementCloseDate = () => {
    const statementCloseDates = creditAccounts?.map((account) => {
      return account.paymentInfo.nextStatementCloseDate;
    });

    if (statementCloseDates.length > 1) {
      statementCloseDates.sort((a, b) => {
        const date1 = new Date(a);
        const date2 = new Date(b);
        return date1 - date2;
      });
    }

    return statementCloseDates[0];
  };

  const cycleDateRequiredCases = useMemo(() => {
    return getCycleDateRequiredCases();
  }, [caseTypeOptions]);

  const requestedDueDateRequiredCases = useMemo(() => {
    return getRequestedDueDateRequiredCases();
  }, [caseTypeOptions]);

  const nextStatementCloseDate = useMemo(() => {
    return getStatementCloseDate();
  }, [creditAccounts]);

  useEffect(() => {
    if (customerId && customerNoteContext?.customerId === customerId) {
      const noteText = customerNoteContext?.note || '';
      form.setFieldsValue({ note: noteText });
    } else {
      form.setFieldsValue({ note });
    }
  }, [customerId]);

  useEffect(() => {
    if (caseCreationFormContext) {
      setCaseTypeOptions(caseCreationFormContext.caseTypeOptions);
      setTeamOptions(caseCreationFormContext.teamOptions);
    }
  }, []);

  const handleNoteChange = (changedNote) => {
    noteVar(changedNote);
    setCustomerNoteContext({
      ...customerNoteContext,
      customerId: customerId || applicationId,
      note: changedNote,
    });
  };

  const handleCreateCaseChange = (createCaseCheck) => {
    setCreateCaseChecked(createCaseCheck.target.checked);

    try {
      getCustomerStatementDates({
        variables: {
          customerId,
        },
      });
      getCaseTypeOptions();
    } catch (e) {
      setFailureMessage('Failed to load form. Refresh and try again');
    }
  };

  const [getCustomerStatementDates] = useLazyQuery(
    CUSTOMER_WITH_FULL_ACCOUNTS,
    {
      onCompleted: ({ customer }) => {
        setCreditAccounts(customer.creditAccounts);
      },
    },
  );

  const [getCaseTypeOptions, { loading: loadingTeamsAndCaseTypes }] =
    useLazyQuery(GET_CASE_TYPE_OPTIONS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ caseWorkOptions }) => {
        setCaseTypeOptions(caseWorkOptions.caseTypes);
        setTeamOptions(caseWorkOptions.teams);
        setCaseCreationFormContext({
          caseTypeOptions: caseWorkOptions.caseTypes,
          teamOptions: caseWorkOptions.teams,
        });
      },
    });

  const [createZendeskCase, { loading: sendingZendeskCase }] = useMutation(
    CREATE_ZENDESK_CASE,
    {
      refetchQueries: ['GetAgentCustomerActivity'],
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleTeamSelect = (team) => {
    const caseTypesFilteredByTeam = caseTypeOptions.filter((option) => {
      return option.team === team;
    });
    form.resetFields(['caseType']);
    setCaseTypesByTeam(caseTypesFilteredByTeam);
    setTeamSelected(true);
  };

  const handleCaseTypeSelect = (caseType) => {
    setCaseTypeSelected(caseType);
  };

  const saveNote = async (formValues) => {
    trackEvent(ANALYTICS_EVENTS.NOTE_BOX_SAVE_CLICKED);

    const customerEmail = customerInfo?.email;
    const customerName = customerInfo?.fullName;

    // const variables = {
    //   message: formValues.note,
    //   product: 'CREDIT',
    //   agentId: agent?.user?.requester_id,
    //   agentName: agent?.user?.name,
    //   isSticky: false,
    // };

    // let submitNote;
    // if (customerId) {
    //   submitNote = addCustomerNote;
    //   variables.customerId = customerId;
    // } else if (applicationId) {
    //   submitNote = addApplicationNote;
    //   variables.applicationId = applicationId;
    // }

    if (!noteSaved) {
      try {
        await addNote(formValues.note, false);
        setNoteSaved(true);

        if (!createCaseChecked) {
          form.resetFields(['note']);
          noteVar('');
          setCustomerNoteContext(null);
        }
      } catch (error) {
        setFailureMessage('Failed to save note. Please try again.');
        return;
      }
    }

    if (createCaseChecked && !caseSaved) {
      try {
        const response = await createZendeskCase({
          variables: {
            note: formValues.note,
            email: customerEmail,
            name: customerName,
            cycleDate: formValues.nextStatementCloseDate,
            caseType: formValues.caseType,
            requestedDueDate: formatDateToSubmit(formValues.requestedDueDate),
          },
        });
        setZendeskUrl(response?.data?.caseWorkCreateCase.url);
        setCaseSaved(true);
        setShowSuccessMessage(true);

        setCustomerNoteContext(null);
        setCaseCreationFormContext(null);
        noteVar('');
        form.resetFields();
        setFailureMessage('');
        setCaseTypeSelected('');
        setCreateCaseChecked(false);
        setTeamSelected(false);
      } catch (error) {
        setFailureMessage(
          <div>
            Failed to submit Zendesk ticket. Please try again. <br /> If this
            error persists, go to Zendesk directly to create your ticket.{' '}
          </div>,
        );
        return;
      }
    }

    setCaseSaved(false);
    setNoteSaved(false);
  };

  let renderCopyTooltipText = () => {
    return zendeskLinkCopied ? (
      <span>
        Link Copied <LikeOutlined />
      </span>
    ) : (
      'Copy Link'
    );
  };

  const zendeskUrlButton = {
    text: (
      <>
        <ArrowRightOutlined /> View Ticket in Zendesk
      </>
    ),
    ghost: false,
    type: 'link',
    target: 'zendesk_url',
    href: zendeskUrl,
  };

  const copyZendeskLinkButton = {
    text: (
      <>
        <Tooltip title={renderCopyTooltipText()}>
          <CopyOutlined /> Copy Ticket Link{' '}
        </Tooltip>
      </>
    ),
    ghost: false,
    type: 'link',
    onClick: () => {
      setZendeskLinkCopied(true);
      copy(zendeskUrl);
    },
  };

  // const savingNote =
  //   applicationNoteMutation.loading || customerNoteMutation.loading;

  return (
    <Form
      form={form}
      layout={'vertical'}
      name="create-case-form"
      onFinish={saveNote}
      size="small"
      style={{ paddingBottom: showSuccessMessage ? '120px' : '0px' }}
    >
      <SectionMessage
        buttons={[zendeskUrlButton, copyZendeskLinkButton]}
        verticalButtons={true}
        data-testid="success-message"
        status={STATUS.SUCCESS}
        size={SIZE.MD}
        text="Case successfully submitted!"
        cover={true}
        visible={showSuccessMessage}
        canClose={true}
        handleClose={() => setShowSuccessMessage(false)}
      ></SectionMessage>

      <Item
        name="note"
        label="Call Note"
        rules={[
          {
            required: true,
            message: 'Please include call details.',
          },
        ]}
        style={{ marginBottom: 'var(--spacing-xs)' }}
      >
        <TextArea
          data-testid="note-text-area"
          autoSize={{ minRows: 4, maxRows: 20 }}
          onChange={(e) => {
            handleNoteChange(e.target.value);
          }}
          placeholder="New Note"
          disabled={noteSaved}
        />
      </Item>

      {canAccessCaseCreationForm ? (
        <Checkbox
          data-testid="create-case-checkbox"
          style={{ marginBottom: 'var(--spacing-xs)' }}
          checked={createCaseChecked}
          onChange={(e) => {
            handleCreateCaseChange(e);
          }}
        >
          Create Zendesk Case
        </Checkbox>
      ) : null}

      {createCaseChecked && (
        <Item>
          <Item
            required
            name="team"
            label="Case Team"
            rules={[
              {
                required: true,
                message: 'Please select the team.',
              },
            ]}
            style={{ marginBottom: 'var(--spacing-xs)' }}
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              data-testid="team-select"
              placeholder="Select team"
              disabled={loadingTeamsAndCaseTypes || sendingZendeskCase}
              onSelect={handleTeamSelect}
            >
              {teamOptions.map((option) => {
                return (
                  <Option
                    data-testid={`team-${option.value}`}
                    key={`team-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </Option>
                );
              })}
            </Select>
          </Item>

          {teamSelected && (
            <Item
              required
              name="caseType"
              label="Case Type"
              initialValue={null}
              rules={[
                {
                  required: true,
                  message: 'Please select the case type.',
                },
              ]}
              style={{ marginBottom: 'var(--spacing-xs)' }}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                data-testid="case-type-select"
                placeholder="Select case type"
                disabled={sendingZendeskCase}
                onSelect={handleCaseTypeSelect}
              >
                {caseTypesByTeam.map((option) => {
                  return (
                    <Option
                      data-testid={`case-${option.value}`}
                      key={`caseType-${option.value}`}
                      value={option.value}
                    >
                      {option.label}
                    </Option>
                  );
                })}
              </Select>
            </Item>
          )}

          {requestedDueDateRequiredCases[caseTypeSelected] && (
            <Item
              name="requestedDueDate"
              label="Newly Requested Due Date"
              style={{ marginTop: 'var(--spacing-xs)', marginBottom: '0px' }}
              rules={[
                {
                  required: true,
                  message: 'Please select the requested due date.',
                },
              ]}
            >
              <DatePicker
                data-testid="requestedDueDatePicker"
                format="MM/DD/YYYY"
                disabled={sendingZendeskCase}
                disabledDate={(d) => {
                  return (
                    !d ||
                    isDate26thOr29thTo31st(d) ||
                    d.isSame(dayjs(currentDueDate), 'day') ||
                    is4DaysLeadingToDueDate(d) ||
                    d.isBefore(dayjs())
                  );
                }}
                style={{ marginBottom: 'var(--spacing-sm)' }}
              />
            </Item>
          )}

          {cycleDateRequiredCases[caseTypeSelected] && (
            <Item
              name="nextStatementCloseDate"
              label="Next Statement Close Date"
              initialValue={nextStatementCloseDate}
              style={{ marginBottom: '0px' }}
            >
              <Input disabled={true} data-testid="cycle-date-input" />
            </Item>
          )}
        </Item>
      )}

      {failureMessage ? (
        <Alert
          data-testid="failure-message"
          type="error"
          message={failureMessage}
          style={{ marginBottom: 'var(--spacing-xs)' }}
        ></Alert>
      ) : null}

      <Flex justify="flex-end" height="auto">
        <Item style={{ marginBottom: 'var(--spacing-xs)' }}>
          <Button
            data-testid="submit-button"
            type="primary"
            // loading={savingNote || sendingZendeskCase}
            loading={addNoteMutation.loading || sendingZendeskCase}
            htmlType="submit"
            style={{ marginBottom: '0px' }}
          >
            Save
          </Button>
        </Item>
      </Flex>
    </Form>
  );
};

export default NoteForm;
