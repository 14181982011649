import { Col, Row } from 'antd';

import { formatDollarsFromCents } from 'utilities/helpers';
import { getOrdinalSuffix, formatDate } from 'utilities/datesAndTimes';
import { PAYMENT_PLAN_DISPLAY } from 'utilities/constants';

const RecurringPaymentDetails = ({ autopay }) => {
  const paymentPlan = PAYMENT_PLAN_DISPLAY[autopay?.paymentType];
  const amount = autopay?.amount;
  const dayOfMonth = autopay?.dayOfMonth;
  const fundingAccount = autopay?.fundingAccount;
  const nextPaymentDate = autopay?.nextPaymentDate;

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <strong>Amount</strong>
        </Col>
        <Col span={16}>
          {paymentPlan === 'Fixed Amount'
            ? `${paymentPlan} - ${formatDollarsFromCents(amount)}`
            : paymentPlan}
        </Col>
      </Row>

      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <strong>Frequency</strong>
        </Col>
        <Col span={16} data-testid="paymentDayStringEl">
          {getOrdinalSuffix(dayOfMonth)} of each month
        </Col>
      </Row>

      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <strong>Bank</strong>
        </Col>
        <Col span={16}>
          {fundingAccount
            ? `${fundingAccount?.bankName} - ${fundingAccount?.numberLast4}`
            : '--'}
        </Col>
      </Row>

      <Row style={{ marginBottom: 10 }}>
        <Col span={8}>
          <strong>Next Payment Date</strong>
        </Col>
        <Col span={16}>{formatDate(nextPaymentDate)}</Col>
      </Row>
    </>
  );
};

export default RecurringPaymentDetails;
