import { useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

import { CLIP_ELIGIBILITY, CLIP_HISTORY } from 'queries/clip';

/**
 * @description
 * Returns the result of the clipEligibilityCriteria or clipResults query,
 * depending on the value of clipElegibilityImprovements flag.
 * @returns {Object} - The result of the clipEligibilityCriteria or clipResults query
 */
const useClipEligibility = () => {
  const { clipElegibilityImprovements } = useFlags();
  const { accountId, customerId } = useParams();

  // clipElegibilityImprovements is false
  const clipHistoryQueryResult = useQuery(CLIP_HISTORY, {
    skip: clipElegibilityImprovements || !customerId || !accountId,
    variables: { customerId, accountId },
  });

  // SLS-1790 - clipElegibilityImprovements is true
  const clipEligibilityQueryResult = useQuery(CLIP_ELIGIBILITY, {
    skip: !clipElegibilityImprovements || !customerId || !accountId,
    variables: { customerId, accountId },
  });

  const activeQueryResult = clipElegibilityImprovements
    ? clipEligibilityQueryResult
    : clipHistoryQueryResult;

  return {
    ...activeQueryResult,
    clipResults:
      // clipElegibilityImprovements is true
      activeQueryResult.data?.clipEligibilityCriteria?.evaluationResults ??
      // clipEligibilityImprovements is false
      activeQueryResult.data?.clipResults ??
      // clipEligibilityImprovements is null (useFlags is loading)
      [],
  };
};

export default useClipEligibility;
