import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLazyQuery } from '@apollo/client';
import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';

import useModalControls from 'hooks/useModalControls';
import { AUTHORIZATION_HISTORY, TRANSACTIONS } from 'queries/transactions';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { formatTransactionData } from 'utilities/helpers';

import {
  resetTransactionSelections,
  setTransactionsSelected,
  useTransactionState,
} from './transactionState';
import DeclinedAuthorizationControl from './DeclinedAuthorizationControl';
import FraudAuthorizationReviewModal from './FraudAuthorizationReviewModal';
import ViewSelectedTransactionsModal from './ViewSelectedTransactionsModal';
import TransactionTable from '../TransactionTable/TransactionTable';

const { RangePicker } = DatePicker;

const VIEW_OPTIONS = {
  TRANSACTIONS: 'TRANSACTIONS',
  AUTHORIZATIONS: 'AUTHORIZATIONS', // 13 week history of authorizations
};

const TransactionSection = () => {
  const { accountId, customerId } = useParams();
  const { isFdi, isFdr, showAuthorizationCountersReset } = useFlags();
  const { selectedTransactionKeys } = useTransactionState();

  const previous90Days = [dayjs().subtract(90, 'day'), dayjs()];
  const previous14Weeks = [dayjs().subtract(14, 'week'), dayjs()];

  const [form] = Form.useForm();
  const viewOption = Form.useWatch('viewOption', form);
  const [dateRange, setDateRange] = useState(previous90Days);
  const [transactions, setTransactions] = useState([]);
  const fraudAuthorizationReviewModal = useModalControls();
  const viewSelectedTransactionsModal = useModalControls();

  const selectionActions = useMemo(() => {
    const actions = [
      {
        text: <EyeOutlined />,
        onClick: viewSelectedTransactionsModal.show,
        tooltip: {
          text: 'View selected transactions',
        },
      },
    ];
    if (isFdi || isFdr) {
      actions.push({
        text: 'Review Fraud',
        onClick: fraudAuthorizationReviewModal.show,
      });
    }
    return actions;
  }, [isFdi, isFdr]);

  const [getTransactions, transactionsQuery] = useLazyQuery(TRANSACTIONS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTransactions(
        formatTransactionData(
          data?.account?.activities || [],
          dateRange[0],
          dateRange[1],
        ),
      );
    },
  });

  const [getAuthorizationHistory, authorizationHistoryQuery] = useLazyQuery(
    AUTHORIZATION_HISTORY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setTransactions(formatTransactionData(data?.fraudAuthorizations || []));
      },
    },
  );

  const onViewOptionChange = (option) => {
    switch (option.target.value) {
      case VIEW_OPTIONS.TRANSACTIONS:
        setDateRange(previous90Days);
        form.setFieldValue('dateRange', previous90Days);
        break;
      case VIEW_OPTIONS.AUTHORIZATIONS:
        setDateRange(previous14Weeks);
        form.setFieldValue('dateRange', previous14Weeks);
        break;
      default:
    }
  };

  const fetchAuthorizationHistory = () => {
    getAuthorizationHistory({
      variables: {
        accountId,
        fromDate: formatDateToSubmit(dateRange[0]),
        toDate: formatDateToSubmit(dateRange[1]),
      },
    });
  };

  const fetchTransactionRange = () => {
    getTransactions({
      variables: {
        accountId,
        customerId,
        fromDate: formatDateToSubmit(dateRange[0]),
        toDate: formatDateToSubmit(dateRange[1]),
      },
    });
  };

  useEffect(() => {
    if (customerId && accountId) {
      if (viewOption === VIEW_OPTIONS.AUTHORIZATIONS) {
        fetchAuthorizationHistory();
      } else {
        fetchTransactionRange();
      }
    }
  }, [customerId, accountId, viewOption]);

  useEffect(() => {
    resetTransactionSelections();
  }, [customerId, accountId]);

  return (
    <>
      <FraudAuthorizationReviewModal {...fraudAuthorizationReviewModal} />
      <ViewSelectedTransactionsModal {...viewSelectedTransactionsModal} />

      <Typography.Title level={5}>
        {viewOption === VIEW_OPTIONS.AUTHORIZATIONS
          ? 'Authorizations'
          : 'Transactions'}
      </Typography.Title>
      <Row>
        <Col span={24} xl={17}>
          <Form
            form={form}
            initialValues={{
              viewOption: VIEW_OPTIONS.TRANSACTIONS,
              dateRange: previous90Days,
            }}
            name="transactions"
            onFinish={fetchTransactionRange}
          >
            <Row gutter={[8, 8]}>
              <Col span={{ sm: 24, md: 12 }}>
                <Form.Item name="viewOption" noStyle>
                  <Radio.Group
                    onChange={onViewOptionChange}
                    disabled={
                      transactionsQuery.loading ||
                      authorizationHistoryQuery.loading
                    }
                  >
                    <Radio.Button
                      data-testid="transactionsRadio"
                      value={VIEW_OPTIONS.TRANSACTIONS}
                    >
                      Transactions
                    </Radio.Button>
                    <Radio.Button
                      data-testid="authorizationsRadio"
                      value={VIEW_OPTIONS.AUTHORIZATIONS}
                    >
                      Authorizations
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={{ sm: 24, md: 12 }}>
                <Space.Compact>
                  <Tooltip
                    title={
                      viewOption === VIEW_OPTIONS.AUTHORIZATIONS
                        ? 'Authorization history is only available for this date range'
                        : ''
                    }
                  >
                    <div>
                      <Form.Item name="dateRange">
                        <RangePicker
                          allowEmpty={[false, false]}
                          data-testid="transactionDateRange"
                          format="M/D/YYYY"
                          disabled={viewOption === VIEW_OPTIONS.AUTHORIZATIONS}
                          onChange={(date) => setDateRange(date)}
                        />
                      </Form.Item>
                    </div>
                  </Tooltip>

                  <Form.Item style={{ alignItems: 'flex-start' }}>
                    <Button
                      htmlType="submit"
                      data-testid="update-transactions-button"
                      disabled={viewOption === VIEW_OPTIONS.AUTHORIZATIONS}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24} xl={7}>
          <Flex align="center" justify="flex-end" gap="var(--spacing-xs)">
            {showAuthorizationCountersReset ? (
              <DeclinedAuthorizationControl />
            ) : null}
          </Flex>
        </Col>
      </Row>
      <TransactionTable
        transactions={transactions}
        loading={transactionsQuery.loading || authorizationHistoryQuery.loading}
        rowSelection={{
          onSelect: (transaction, isSelected) => {
            setTransactionsSelected([transaction], isSelected);
          },
          onSelectAll: (isSelected, current, updated) => {
            setTransactionsSelected(updated, isSelected);
          },
          onSelectMultiple: (isSelected, rows, changeRows) => {
            setTransactionsSelected(changeRows, isSelected);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedTransactionKeys,
        }}
        selectionActions={selectionActions}
      />
    </>
  );
};

export default TransactionSection;
