import styled from '@emotion/styled';

const HorizontalList = styled.ul`
  display: flex;
  grid-gap: var(--spacing-lg);
  margin: 0;
  padding: 0;
`;

HorizontalList.ListItem = styled.li`
  list-style-type: disc;
  &:first-of-type {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
  }
`;

export default HorizontalList;
