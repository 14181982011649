import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { CUSTOMER_WITH_FULL_ACCOUNTS } from 'queries/customer';

/*
  Since we're having to load full accounts at the application level
  and currently still need SimpleAccount for some account details
  this seems like the cleanest way to take advantage of cache and reduce 
  code duplication
*/
const useAccountQuery = (customerId, accountId, options = {}) => {
  const [account, setAccount] = useState({});
  const [accounts, setAccounts] = useState([]);

  const [simpleAccount, setSimpleAccount] = useState({});
  const [simpleAccounts, setSimpleAccounts] = useState([]);

  const [contactInfo, setContactInfo] = useState({});

  const [statuses, setStatuses] = useState([]);

  const [getAccounts, accountQuery] = useLazyQuery(
    CUSTOMER_WITH_FULL_ACCOUNTS,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (customerId) {
      getAccounts({ variables: { customerId } });
    }
  }, [customerId]);

  useEffect(() => {
    if (accountQuery.data) {
      const simpleAccountData = accountQuery.data?.customer?.accounts || [];
      const simple = simpleAccountData.find(
        (account) => account.id === accountId,
      );
      setSimpleAccount(simple || {});
      setSimpleAccounts(simpleAccountData);

      const fullAccountsData =
        accountQuery.data?.customer?.creditAccounts || [];
      const full = accountQuery.data.customer.creditAccounts.find(
        (creditAccount) => creditAccount.id === accountId,
      );

      setAccount(full || {});
      setAccounts(fullAccountsData);
      setContactInfo(accountQuery.data?.customer?.contactInfo || {});
      setStatuses(full?.statuses?.raw);
    }
  }, [accountId, accountQuery.data]);

  return {
    accountQuery,
    account,
    accounts,
    contactInfo,
    simpleAccount,
    simpleAccounts,
    statuses,
  };
};

export default useAccountQuery;
