import { gql } from '@apollo/client';

const NEW_CASE_ACTION = gql`
  mutation NewCaseAction(
    $fraudCaseId: String!
    $actionType: String!
    $actor: String!
    $initiatedAt: String!
    $reasons: String!
    $evidence: String!
  ) {
    submitNewActionForCaseV2(
      fraudCaseId: $fraudCaseId
      actionType: $actionType
      actor: $actor
      initiatedAt: $initiatedAt
      reasons: $reasons
      evidence: $evidence
    )
  }
`;

export { NEW_CASE_ACTION };
