import AgentScript from 'components/AgentScript/AgentScript';
import { PAYMENT_PLAN } from 'utilities/constants';
import useTranslation from 'hooks/useTranslation';

const RecurringAuthorizationMessage = ({
  paymentPlan,
  amount,
  firstPaymentDate = '--',
  fundingAccountLast4 = '--',
}) => {
  const { translateKey } = useTranslation();

  let planKey;
  switch (PAYMENT_PLAN[paymentPlan]) {
    case PAYMENT_PLAN.MONTHLY_FIXED:
      planKey = 'fixed';
      break;
    case PAYMENT_PLAN.MONTHLY_MINIMUM:
      planKey = 'minimum';
      break;
    case PAYMENT_PLAN.MONTHLY_STATEMENT_BALANCE:
      planKey = 'statementBalance';
      break;
  }

  let paymentPlanText = '--';
  if (planKey) {
    paymentPlanText = translateKey(planKey, {
      keyPrefix: 'payments.plans',
      amount,
    });
  }

  let fixedPaymentText = '';
  if (paymentPlan === PAYMENT_PLAN.MONTHLY_FIXED) {
    fixedPaymentText = translateKey('payments.fixedPaymentScript');
  }

  return (
    <AgentScript
      title="Recurring Payment Authorization"
      translateKey="payments.recurringPaymentScript"
      values={{
        firstPaymentDate,
        paymentPlanText,
        fundingAccountLast4,
        fixedPaymentText,
      }}
    />
  );
};

export default RecurringAuthorizationMessage;
