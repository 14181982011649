import dayjs from 'dayjs';

import { PAYMENT_STATES } from 'utilities/constants';
import { createReactiveState } from 'utilities/stateHelpers';

export const initializeState = () => {
  return {
    balance: {
      remainingBalance: null,
      originalOutstandingBalance: null,
      originalSettlementBalance: null,
    },
    currentPaymentPlan: [],
    payments: [],
    futurePayments: [],
    updatedFuturePayments: [],
    pendingDeletedPayments: [],
    fundingAccount: {
      id: null,
      name: null,
    },
    offer: {
      acceptanceExpiration: null,
      duration: { value: null, unit: null },
      expiration: null,
      offerId: null,
      offerType: null,
      state: null,
      paymentPlanId: null,
    },
    eftaDetails: {
      customerName: null,
      email: null,
      cardLast4: null,
      fundingAccountLast4: null,
    },
  };
};

const paymentPlanState = createReactiveState(initializeState);

const { createPropertySetter, setPropertyValue } = paymentPlanState;
export const {
  resetState: resetPaymentPlanState,
  stateVar: paymentPlanStateVar,
  useState: usePaymentPlanState,
} = paymentPlanState;

export const setActiveOrPendingCloseOutOffer = (offer) => {
  const {
    acceptanceExpiration,
    duration,
    expiration,
    fundingAccountId,
    offerId,
    originalOutstandingBalance,
    originalSettlementBalance,
    paymentPlan,
    payments,
    remainingBalance,
    state,
    subType,
  } = offer.data;

  const createPaymentObject = (amount, date) => ({ amount, date });

  let currentPaymentPlan;
  let futurePayments;
  if (!paymentPlan) {
    const debitPaymentPlan = createPaymentObject(
      remainingBalance,
      // debit expiration is now created date + 1 day offer duration + extra 1 day padding
      // we want to display created date
      dayjs(expiration).subtract(2, 'day'),
    );
    currentPaymentPlan = [{ ...debitPaymentPlan, type: 'SCHEDULED DEBIT' }];
    futurePayments = [{ ...debitPaymentPlan, type: 'SCHEDULED DEBIT' }];
  } else {
    currentPaymentPlan = paymentPlan?.transactions.map((transaction) =>
      createPaymentObject(transaction.amount, transaction.date),
    );

    futurePayments = paymentPlan?.transactions
      .filter((transaction) => dayjs(transaction.date).isAfter(dayjs()))
      .map((transaction) =>
        createPaymentObject(transaction.amount, transaction.date),
      );
  }

  const removeDuplicatePaymentTxIds = (payments) => {
    const paymentsByTxId = payments?.reduce((acc, payment) => {
      // payments-service recognizes a RETURNED payment immediately which determines the account's remaining balance
      // but there is an indeterminate time delay in cc-transactions to determine a RETURNED payment
      // that being the case, we need to trump RETURNED state from payments if it is not yet processed in cc-transactions
      if (!acc[payment.txId] || payment.type === PAYMENT_STATES.RETURNED) {
        acc[payment.txId] = payment;
      }
      return acc;
    }, {});

    return Object.values(paymentsByTxId);
  };

  let uniquePayments;
  if (payments) {
    uniquePayments = removeDuplicatePaymentTxIds(payments);
  }

  const currentState = paymentPlanStateVar();
  paymentPlanStateVar({
    ...currentState,
    balance: {
      remainingBalance,
      originalOutstandingBalance,
      originalSettlementBalance,
    },
    currentPaymentPlan,
    fundingAccount: { id: fundingAccountId },
    futurePayments,
    offer: {
      acceptanceExpiration,
      duration,
      expiration,
      offerId,
      offerType: subType,
      state,
      paymentPlanId: paymentPlan?.id,
    },
    payments: uniquePayments ?? payments,
  });
};

export const setPayments = createPropertySetter('payments');

export const setUpdatedFuturePayments = createPropertySetter(
  'updatedFuturePayments',
);

export const setPendingDeletedPayments = createPropertySetter(
  'pendingDeletedPayments',
);

export const setPaymentFundingAccount = (id, name) => {
  setPropertyValue('fundingAccount', { id, name });
};

export const setEftaDetails = (
  customerName,
  email,
  cardLast4,
  fundingAccountLast4,
) => {
  setPropertyValue('eftaDetails', {
    customerName,
    email,
    cardLast4,
    fundingAccountLast4,
  });
};
