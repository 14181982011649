const routingNumberRules = [
  {
    required: true,
    message: 'Routing # must be a 9 digit number.',
  },
  {
    validator: (_, value) => {
      if (/^\d{9}$/.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    },
  },
];
const accountNumberRules = [
  {
    required: true,
    message: 'Account # must be a 4 to 28 digit number.',
  },
  {
    validator: (_, value) => {
      if (/^\d{4,28}$/.test(value)) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    },
  },
];

export { routingNumberRules, accountNumberRules };
