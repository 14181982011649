import { Tag } from 'antd';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import CustomerAccountStatus from 'components/CustomerAccountStatus/CustomerAccountStatus';

import { STATES_ABBR } from 'utilities/constants';
import {
  formatDate,
  formatTime,
  getUserLocalTime,
} from 'utilities/datesAndTimes';
import { COLORS } from 'utilities/styles';

const LocationTimeContainer = styled.div`
  padding: var(--spacing-md) var(--spacing-lg);
  margin: 0 calc(var(--spacing-lg) * -1);
  border-top: 1px solid var(--border-light);
  border-bottom: 1px solid var(--border-light);
  width: fill-available;
`;

const LocationAndTime = ({ client = {} }) => {
  const accounts = client.accounts || [];

  const customerAddress = client.contactInfo?.address;
  const applicantAddress = client.address;
  const address = customerAddress || applicantAddress || {};

  const localTime = formatTime(getUserLocalTime(address.state));

  let accountOpened;
  if (accounts.length) {
    const sortedAccounts = [...accounts].sort((a1, a2) => {
      return dayjs(a1.createdAt) - dayjs(a2.createdAt);
    });

    const customerSince = sortedAccounts[0].createdAt;
    const openedDate = dayjs(customerSince);
    const signUpDate = formatDate(openedDate);
    const signUpTime = formatTime(openedDate);

    accountOpened = (
      <>
        Customer since {signUpDate} at {signUpTime}
        <CustomerAccountStatus createdAt={customerSince} />
      </>
    );
  }

  const state = STATES_ABBR[address.state]?.name || 'No State';

  const stateColorMap = {
    MA: COLORS.mustard1,
    FL: COLORS.orange1,
    DC: COLORS.red1,
  };

  const stateColor = stateColorMap[address.state] ?? COLORS.grey1;

  return (
    <LocationTimeContainer>
      <Tag color={stateColor}>{state}</Tag> {`Local time is ${localTime}`}
      <br></br>
      {accountOpened ? accountOpened : null}
    </LocationTimeContainer>
  );
};

export default LocationAndTime;
