import { gql } from '@apollo/client';

const VERIFY_CVV = gql`
  query VerifyCvv(
    $accountId: String!
    $customerId: String!
    $cvv: String!
    $verifyPreviousValue: Boolean!
  ) {
    verifyCvv(
      accountId: $accountId
      customerId: $customerId
      cvv: $cvv
      verifyPreviousValue: $verifyPreviousValue
    ) {
      externalAccountId
      externalCustomerId
      isCvvVerified
      isLockedOut
      remainingSubmissions
    }
  }
`;

export { VERIFY_CVV };
