import { useMemo, useState } from 'react';
import { Button } from 'antd';
import _uniqBy from 'lodash/uniqBy';

import RemoveStatusModal from './RemoveStatusModal';

import CondensedTable from 'components/CondensedTable/CondensedTable';
import useModalControls from 'hooks/useModalControls';
import { ACCOUNT_STATUS_CODE } from 'utilities/constants';
import { formatDate } from 'utilities/datesAndTimes';

const REMOVABLE_STATUS_CODES = Object.freeze([
  ACCOUNT_STATUS_CODE.CARD_WATCH,
  ACCOUNT_STATUS_CODE.TIERED_WATCH,
  ACCOUNT_STATUS_CODE.RETURNED_MAIL,
]);

const STATUS_ORDER = REMOVABLE_STATUS_CODES.reduce((map, status, index) => {
  map[status] = index;
  return map;
}, {});

const AccountStatusTable = ({ statuses = [], ...rest }) => {
  const [statusToRemove, setStatusToRemove] = useState();
  const removeStatusModal = useModalControls({
    afterClose: () => setStatusToRemove(undefined),
  });

  const sortedStatuses = useMemo(() => {
    return _uniqBy(statuses, 'description').sort((status1, status2) => {
      return (
        (STATUS_ORDER[status1.statusCode] || Infinity) -
        (STATUS_ORDER[status2.statusCode] || Infinity)
      );
    });
  }, [statuses]);

  const statusColumns = useMemo(() => {
    return [
      {
        title: 'Status Code',
        dataIndex: 'statusCode',
        width: '10%',
      },
      {
        title: 'Reason Code',
        dataIndex: 'reasonCode',
        width: '10%',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Date Applied',
        dataIndex: 'date',
        render: (value) => formatDate(value, false, '-'),
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: (value) => formatDate(value, false, '-'),
      },
      {
        title: 'Stop Date',
        dataIndex: 'stopDate',
        render: (value) => formatDate(value, false, '-'),
      },
      {
        title: 'Actions',
        width: 'min-content',
        render: (status) => {
          if (REMOVABLE_STATUS_CODES.includes(status.statusCode)) {
            return (
              <Button
                data-testid={'remove-status-button'}
                size="small"
                onClick={() => initializeStatusRemoval(status)}
              >
                Remove
              </Button>
            );
          }
        },
      },
    ];
  }, []);

  const initializeStatusRemoval = (status) => {
    setStatusToRemove(status);
    removeStatusModal.show();
  };

  return (
    <>
      <RemoveStatusModal
        {...removeStatusModal}
        statusToRemove={statusToRemove}
      />
      <CondensedTable
        columns={statusColumns}
        data={sortedStatuses}
        messageHeight="200px"
        rowKey={(status) => `${status.statusCode}-${status.reasonCode}`}
        {...rest}
      ></CondensedTable>
    </>
  );
};

export default AccountStatusTable;
