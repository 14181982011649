import { gql } from '@apollo/client';

const GET_FULL_SSN = gql`
  query ($customerId: String!) {
    fullSsn(customerId: $customerId) {
      fullSsn
    }
  }
`;

export { GET_FULL_SSN };
