import { Descriptions } from 'antd';

import { displayValueOrBlank, formatDollarsFromCents } from 'utilities/helpers';
import { formatDateDescriptive } from 'utilities/datesAndTimes';

const TransactionDetail = ({ detail = {}, accountDetails = {} }) => {
  const cardInfo = detail.transactionDetail || {};
  const merchantInfo = detail.merchant || {};

  return (
    <>
      <Descriptions
        title="Transaction Information"
        colon={false}
        layout="vertical"
        size="small"
        bordered
        column={12}
        style={{ marginBottom: 'var(--spacing-lg)' }}
      >
        <Descriptions.Item label="Posted Date" span={4}>
          {formatDateDescriptive(detail.postedAt, true)}
        </Descriptions.Item>
        <Descriptions.Item label="Authorization Date" span={4}>
          {formatDateDescriptive(
            detail.submittedAt || detail.authorizationDate,
            true,
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Central Processing Date" span={4}>
          {formatDateDescriptive(detail.centralProcessingDate, true, '--')}
        </Descriptions.Item>
        <Descriptions.Item label="Transaction Amount" span={4}>
          {displayValueOrBlank(formatDollarsFromCents(detail.amount))}
        </Descriptions.Item>

        <Descriptions.Item label="State" span={4}>
          {displayValueOrBlank(detail.state)}
        </Descriptions.Item>

        <Descriptions.Item label="Status" span={4}>
          {displayValueOrBlank(detail.status)}
        </Descriptions.Item>

        <Descriptions.Item label="Transaction Type" span={4}>
          {displayValueOrBlank(detail.type)}
        </Descriptions.Item>

        <Descriptions.Item label="Reference ID" span={4}>
          {displayValueOrBlank(detail.reference)}
        </Descriptions.Item>

        <Descriptions.Item label="Transaction ID" span={4}>
          {displayValueOrBlank(detail.id)}
        </Descriptions.Item>

        <Descriptions.Item label="Transaction Code" span={4}>
          {displayValueOrBlank(detail.activityCode)}
        </Descriptions.Item>

        <Descriptions.Item label="Card Expiration Date" span={4}>
          {displayValueOrBlank(accountDetails.cardDetails?.expirationDate)}
        </Descriptions.Item>

        <Descriptions.Item label="Approval Code" span={4}>
          {displayValueOrBlank(detail.approvalCode)}
        </Descriptions.Item>

        <Descriptions.Item label="Transaction Category" span={4}>
          {displayValueOrBlank(detail.categoryDescription)}
        </Descriptions.Item>

        <Descriptions.Item label="Accounting Function" span={4}>
          {displayValueOrBlank(detail.accountingFunction)}
        </Descriptions.Item>

        <Descriptions.Item label="Foreign Exchange Fee" span={4}>
          {displayValueOrBlank(cardInfo.conversionRate)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="Merchant Information"
        colon={false}
        layout="vertical"
        size="small"
        bordered
        column={12}
        style={{ marginBottom: 'var(--spacing-lg)' }}
      >
        <Descriptions.Item label="Merchant Name" span={4}>
          {displayValueOrBlank(merchantInfo.name)}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Phone Number" span={4}>
          {displayValueOrBlank(merchantInfo.phoneNumber)}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Category Description" span={4}>
          {merchantInfo.categoryCode ? `${merchantInfo.categoryCode} - ` : ''}
          {displayValueOrBlank(merchantInfo.categoryDescription)}
        </Descriptions.Item>
        <Descriptions.Item label="Merchant Address" span={12}>
          City: {displayValueOrBlank(merchantInfo.address?.city)}
          <br />
          Postal Code: {displayValueOrBlank(merchantInfo.address?.postalCode)}
          <br />
          State/Province:{' '}
          {displayValueOrBlank(merchantInfo.address?.stateProvince)}
          <br />
          Street1: {displayValueOrBlank(merchantInfo.address?.street1)}
          <br />
          Street2: {displayValueOrBlank(merchantInfo.address?.street2)}
          <br />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="Card Information"
        colon={false}
        layout="vertical"
        size="small"
        column={12}
        bordered
      >
        <Descriptions.Item label="Card Last 4" span={4}>
          {displayValueOrBlank(detail.cardLast4)}
        </Descriptions.Item>
        <Descriptions.Item label="POS Entry Mode" span={4}>
          {displayValueOrBlank(cardInfo.posEntry)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default TransactionDetail;
