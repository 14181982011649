import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Modal } from 'antd';
import dayjs from 'dayjs';

import CondensedTable from 'components/CondensedTable/CondensedTable';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useAccountQuery from 'hooks/useAccountQuery';
import { GET_ACCOUNT_TRANSFER_HISTORY } from 'queries/account';
import { formatDate } from 'utilities/datesAndTimes';
import { REQUEST_REASON, SIZE, STATUS } from 'utilities/constants';

const trimCardLast4 = (cardNumber) => cardNumber.slice(-4);

const TRANSFER_HISTORY_COLUMNS = Object.freeze([
  {
    title: 'Change Date',
    dataIndex: 'date',
    render: formatDate,
  },
  {
    title: 'From Last 4',
    dataIndex: 'fromCardNumberRedacted',
    render: trimCardLast4,
  },
  {
    title: 'To Last 4',
    dataIndex: 'toCardNumberRedacted',
    render: trimCardLast4,
  },
  {
    title: 'Reason',
    dataIndex: 'statusReason',
  },
]);

const AccountTransferHistoryModal = ({ open, ...modalProps }) => {
  const { accountId, customerId } = useParams();
  const { simpleAccount } = useAccountQuery(customerId, accountId);

  const [accountTransferHistory, setAccountTransferHistory] = useState([]);

  const today = dayjs();
  const firstAccountOpenDate = dayjs(simpleAccount.createdAt);
  const monthsSinceAccountOpenDate = today.diff(firstAccountOpenDate, 'month');

  // tsys api only allows up to 99 months of history at a time
  const getPreviousMonths = () => {
    if (monthsSinceAccountOpenDate <= 99) {
      return monthsSinceAccountOpenDate;
    }

    return 99;
  };

  const [getAccountTransferHistory, transferHistoryQuery] = useLazyQuery(
    GET_ACCOUNT_TRANSFER_HISTORY,
    {
      variables: { accountId, previousMonths: getPreviousMonths() },
      onCompleted: ({ accountTransferHistory }) => {
        const transferHistory = (accountTransferHistory?.transfers || []).map(
          (transfer, index) => {
            const key = `transfer-${index}`;
            const isSameCard =
              trimCardLast4(transfer.fromCardNumberRedacted) ===
              trimCardLast4(transfer.toCardNumberRedacted);
            const isReissue =
              transfer.statusReason.toLowerCase() ===
              REQUEST_REASON.REISSUE.toLowerCase();

            if (isSameCard) {
              return {
                ...transfer,
                toCardNumberRedacted: '-',
                statusReason:
                  isReissue || transfer.statusReason === 'Product Change'
                    ? transfer.statusReason
                    : `${transfer.statusReason} - Reissue`,
                key,
              };
            } else {
              return {
                ...transfer,
                key,
              };
            }
          },
        );

        setAccountTransferHistory(transferHistory);
      },
    },
  );

  useEffect(() => {
    if (open) {
      getAccountTransferHistory();
    }
  }, [accountId, monthsSinceAccountOpenDate, open]);

  return (
    <Modal
      {...modalProps}
      centered
      width={640}
      cancelButtonProps={{ hidden: true }}
      title="Account Transfer History"
      open={open}
    >
      <QueryBoundary
        query={transferHistoryQuery}
        mode={QueryBoundary.MODE.MESSAGE}
        loadingMessage="Loading account transfer history"
        errorMessage="Failed to retrieve account transfer history."
      >
        {!accountTransferHistory.length ? (
          <SectionMessage
            status={STATUS.WARNING}
            size={SIZE.MD}
            text="Account has never changed card numbers"
          />
        ) : (
          <CondensedTable
            data={accountTransferHistory}
            columns={TRANSFER_HISTORY_COLUMNS}
            bordered={true}
            length={10}
            size="small"
          />
        )}
      </QueryBoundary>
    </Modal>
  );
};

export default AccountTransferHistoryModal;
