import { useRouteMatch } from 'react-router-dom';
import { Col, Row, Tooltip } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AccountOverview from './AccountOverview';

import CustomerAccountStatus from 'components/CustomerAccountStatus/CustomerAccountStatus';
import DisplayValue from 'components/DisplayValue/DisplayValue';
import useAnalytics from 'hooks/useAnalytics';

import { getDueDate, formatDate } from 'utilities/datesAndTimes';
import {
  ANALYTICS_EVENTS,
  ASSET_SALE_TAGS,
  CARD_ISSUANCE_STATE_STATUS,
  DEFAULT_ASSET_SALE,
  PRODUCT_COLOR,
  SECURED_CARD_STATUS,
  STATUS,
} from 'utilities/constants';
import {
  formatDollarsFromCents,
  getCardDisplayName,
  minDueWarningHelper,
} from 'utilities/helpers';

const CreditOverview = ({ account, simpleAccount }) => {
  const { url } = useRouteMatch();
  const { trackEvent } = useAnalytics();
  const { enableAssetSale, showAssetSaleTag, showSecondAssetSaleTag } =
    useFlags();

  const getAccountOverviewParams = () => {
    let formattedBalance = formatDollarsFromCents(
      account?.balanceInfo.currentBalance,
    );

    const cardIsActivated =
      account?.cardDetails?.issuanceState ===
      CARD_ISSUANCE_STATE_STATUS.ACTIVATED;

    const cardIsPreExpired =
      account?.cardDetails?.issuanceState ===
      CARD_ISSUANCE_STATE_STATUS.PRE_EXPIRED;

    const isSecondAssetSaleAccount =
      account?.accountDetails?.accountTag === ASSET_SALE_TAGS.ACM002.value;

    const isAssetSaleAccount =
      !!ASSET_SALE_TAGS[account?.accountDetails?.accountTag];

    const getAssetSaleTagDisplay = () => {
      if (!showSecondAssetSaleTag && isSecondAssetSaleAccount) {
        return '';
      }

      if (showAssetSaleTag && isAssetSaleAccount) {
        return ASSET_SALE_TAGS[account?.accountDetails?.accountTag].label;
      }
    };

    const assetSaleTagDisplay = getAssetSaleTagDisplay();

    const creditorDisplay =
      !showAssetSaleTag &&
      enableAssetSale &&
      account.accountDetails?.creditorDisplay !== DEFAULT_ASSET_SALE
        ? account.accountDetails?.creditorDisplay
        : '';

    return {
      color: PRODUCT_COLOR.CREDIT_CARD,
      accountLabel: getCardDisplayName(account),
      cardLast4: `${account?.cardDetails?.last4}`,
      stat: 'Account Balance',
      statValue: formattedBalance === '$NaN' ? undefined : formattedBalance,
      cardIsActivated,
      cardIsPreExpired,
      enableNavigation: true,
      onNavigate: () => {
        trackEvent(ANALYTICS_EVENTS.SNAPSHOT_CREDIT_OPENED);
      },
      navigateTo: `${url}/accounts/credit/${account?.id}`,
      subHeading: (
        <CustomerAccountStatus
          accountStatus={account?.statuses}
          createdAt={simpleAccount?.createdAt}
        ></CustomerAccountStatus>
      ),
      creditorValue: assetSaleTagDisplay || creditorDisplay,
      accountId: account?.id,
    };
  };

  const getMinDueValue = () => {
    let { shouldHighlightMinDue, minDueFormatted, highMinDueWarningMessage } =
      minDueWarningHelper(
        account?.paymentInfo.minimumDue,
        account?.balanceInfo.currentBalance,
      );

    let minDue = (
      <DisplayValue
        label="Total Min Due"
        status={shouldHighlightMinDue ? STATUS.ERROR : undefined}
      >
        {minDueFormatted}
      </DisplayValue>
    );

    if (shouldHighlightMinDue) {
      minDue = (
        <Tooltip title={highMinDueWarningMessage}>
          <div data-testid="min-due-tooltip-wrapper">{minDue}</div>
        </Tooltip>
      );
    }
    return minDue;
  };

  return (
    <AccountOverview {...getAccountOverviewParams()}>
      <Row gutter={[10, '1rem']}>
        <Col sm={12}>
          <DisplayValue label="Available Credit">
            {formatDollarsFromCents(account?.balanceInfo.availableCredit)}
          </DisplayValue>
        </Col>
        <Col sm={12}>{getMinDueValue()}</Col>
        <Col sm={12}>
          <DisplayValue label="Due Date">
            {getDueDate(account?.paymentInfo)}
          </DisplayValue>
        </Col>
        <Col sm={12}>
          <DisplayValue label="Statement Cycle Date">
            {formatDate(account?.paymentInfo.nextStatementCloseDate)}
          </DisplayValue>
        </Col>
        <Col sm={12}>
          <DisplayValue label="Past Due Amount">
            {formatDollarsFromCents(account?.balanceInfo.pastDueBalance)}
          </DisplayValue>
        </Col>
        {account?.securedCardInfo ? (
          <>
            <Col sm={12}>
              <DisplayValue label="Current Secured Deposit Amount">
                {formatDollarsFromCents(account.securedCardInfo.currentAmount)}
              </DisplayValue>
            </Col>
            <Col sm={12}>
              <DisplayValue label="Original Secured Deposit Amount">
                {formatDollarsFromCents(account.securedCardInfo.originalAmount)}
              </DisplayValue>
            </Col>
            <Col sm={12}>
              <DisplayValue label="Security Deposit Status">
                {SECURED_CARD_STATUS[account.securedCardInfo.status]}
              </DisplayValue>
            </Col>
            <Col sm={12}>
              <DisplayValue label="Date of Security Deposit Change">
                {formatDate(account.securedCardInfo.refundedDate, 'N/A')}
              </DisplayValue>
            </Col>
          </>
        ) : null}
      </Row>
    </AccountOverview>
  );
};

export default CreditOverview;
