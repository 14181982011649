import { useParams } from 'react-router-dom';
import { Button, Flex, Typography } from 'antd';

import ApplyStatusModal from './ApplyStatusModal/ApplyStatusModal';
import AccountStatusTable from './AccountStatusTable';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useModalControls from 'hooks/useModalControls';
import { ACCOUNT_STATUSES_RAW } from 'queries/account';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

const AccountStatusSection = () => {
  const { accountId, customerId } = useParams();
  const applyStatusModal = useModalControls();

  const accountStatusQuery = useQuery(ACCOUNT_STATUSES_RAW, {
    variables: { customerId: customerId, accountId },
  });

  const statuses = useMemo(
    () => accountStatusQuery.data?.account?.statusesRaw?.statuses,
    [accountStatusQuery.data],
  );

  return (
    <>
      <ApplyStatusModal {...applyStatusModal} />
      <Typography.Title level={5}>Account Statuses</Typography.Title>
      <Flex justify="flex-end" style={{ marginBottom: 'var(--spacing-md) ' }}>
        <Button
          data-testid="apply-status-button"
          onClick={applyStatusModal.show}
        >
          Apply Status
        </Button>
      </Flex>
      <QueryBoundary
        query={accountStatusQuery}
        mode={QueryBoundary.MODE.MESSAGE}
        loadingMessage="Loading account statuses"
      >
        <AccountStatusTable statuses={statuses}></AccountStatusTable>
      </QueryBoundary>
    </>
  );
};

export default AccountStatusSection;
