import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Checkbox, Form, Input, Modal, Typography } from 'antd';
import { useMutation } from '@apollo/client';

import { accountNumberRules, routingNumberRules } from './fundingAccountRules';

import { ADD_FUNDING_ACCOUNT } from 'mutations/fundingAccounts';
import { ACCOUNT_STATUS, CHANNEL } from 'utilities/constants';

const { Text } = Typography;

export const DEFAULT_FUNDING_ACCOUNT_ERROR =
  "There's an error with your bank account number. Please double-check it and try again.";
export const MAX_FUNDING_ACCOUNTS_ALLOWED_ERROR =
  'It looks like this bank account is already linked to a Mission Lane account. Please add a different one.';
export const FUNDING_ACCOUNT_ALREADY_EXIST_ERROR =
  "You've already added this bank account. You can use this account or connect a new one.";

const mapErrorCode = function (apolloError) {
  const error = apolloError?.graphQLErrors[0]?.extensions?.errorCode;
  switch (error) {
    case 'FUNDING_ACCOUNTS_ALREADY_EXIST':
      return FUNDING_ACCOUNT_ALREADY_EXIST_ERROR;
    case 'FUNDING_ACCOUNTS_MAX_ALLOWED_SHARED_FUNDING_ACCOUNTS':
      return MAX_FUNDING_ACCOUNTS_ALLOWED_ERROR;
    default:
      return DEFAULT_FUNDING_ACCOUNT_ERROR;
  }
};

const AddFundingAccountModal = ({
  refetchQueries = [],
  hide,
  ...modalProps
}) => {
  const [form] = Form.useForm();
  const [failureMessage, setFailureMessage] = useState('');

  const { customerId } = useParams();

  const [addAccount, addAccountMutation] = useMutation(ADD_FUNDING_ACCOUNT);

  const closeModal = () => {
    form.resetFields();
    hide();
  };

  const handleSubmit = async (values) => {
    setFailureMessage('');

    const { routingNumber, accountNumber, makeDefault } = values;
    const variables = {
      customerId,
      routingNumber,
      accountNumber,
      channel: CHANNEL.AGENT,
    };

    if (makeDefault) {
      variables.status = ACCOUNT_STATUS.DEFAULT;
    }

    try {
      await addAccount({
        variables,
        refetchQueries: refetchQueries,
      });
      closeModal();
    } catch (err) {
      setFailureMessage(mapErrorCode(err));
    }
  };

  return (
    <Modal
      {...modalProps}
      title="New Bank Account"
      onCancel={closeModal}
      onOk={form.submit}
      okText="Save"
      confirmLoading={addAccountMutation.loading}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          validateTrigger="onBlur"
          rules={routingNumberRules}
          label={<Text>Routing #</Text>}
          name="routingNumber"
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={<Text>Account #</Text>}
          validateTrigger="onBlur"
          rules={accountNumberRules}
          name="accountNumber"
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="makeDefault"
          initialValue={false}
          valuePropName="checked"
        >
          <Checkbox>
            <Text>
              <strong>Default Account: </strong>Make this account the customers
              default account.
            </Text>
          </Checkbox>
        </Form.Item>
      </Form>

      {failureMessage ? <Alert type="error" message={failureMessage} /> : null}
    </Modal>
  );
};

export default AddFundingAccountModal;
