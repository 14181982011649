import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Flex, Modal } from 'antd';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { CUSTOMER_OVERVIEW } from 'queries/customer';
import { UPDATE_STATEMENT_PREFERENCES } from 'mutations/preferences';
import { STATUS, SIZE } from 'utilities/constants';

const StatementPreferencesUpdateModal = ({
  isPaperless,
  hide,
  ...modalProps
}) => {
  const { accountId, customerId } = useParams();
  const [mailingAddress, setMailingAddress] = useState();
  const [isUpdateProgress, setIsUpdateProgress] = useState();
  const [isUpdateError, setIsUpdateError] = useTimeoutValue(false, 2000);
  const [isUpdateSuccess, setIsUpdateSuccess] = useTimeoutValue(false, 3000);

  useQuery(CUSTOMER_OVERVIEW, {
    variables: { customerId },
    skip: !isPaperless, // note this is current state, so run only on change to paper.
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        const contactInfo = data.customer?.contactInfo;
        const streetAddress = `${contactInfo.address?.street1} ${contactInfo?.address?.street2}`;
        const cityStateZip = `${contactInfo.address?.city} ${contactInfo.address?.state} ${contactInfo.address?.postalCode}`;
        setMailingAddress({ cityStateZip, streetAddress });
      }
    },
  });

  const [updatePreference] = useMutation(UPDATE_STATEMENT_PREFERENCES, {
    refetchQueries: ['GetAccountStatementPreferences'],
    notifyOnNetworkStatusChange: true,
  });

  const handleUpdateStatementPreference = useCallback(() => {
    setIsUpdateProgress(true);
    const variables = {
      accountId,
      customerId,
      statementPreferencesUpdateInput: {
        isPaperlessStatement: !isPaperless,
      },
    };
    updatePreference({ variables })
      .then(() => {
        setIsUpdateSuccess(true);
        setTimeout(hide, 2000);
      })
      .catch((err) => {
        console.error(err);
        setIsUpdateError(true);
      })
      .finally(() => {
        setIsUpdateProgress(false);
      });
  }, [customerId, accountId, isPaperless]);

  return (
    <Modal
      {...modalProps}
      title={isPaperless ? 'Switch to Paper' : 'Switch to Paperless'}
      onOk={handleUpdateStatementPreference}
      okButtonProps={{
        disabled: isUpdateProgress || (isPaperless && !mailingAddress),
        'data-testid': 'update-prefs-btn',
      }}
      confirmLoading={isUpdateProgress}
      // set standard height to avoid resizing the window back and forth when isPaperless changes.
      styles={{ body: { height: isUpdateSuccess ? 150 : undefined } }}
    >
      {isUpdateSuccess && (
        <SectionMessage
          status={STATUS.SUCCESS}
          size={SIZE.XS}
          cover={true}
          text={`Statement preferences set.`}
        ></SectionMessage>
      )}

      {!isPaperless && (
        <p data-testid="stmt-preference-paper-script">
          By enrolling in paperless billing, I am confirming that I have
          received written consent from the card holder to receive electronic
          communications from MissionLane.
        </p>
      )}
      {isPaperless && (
        <Flex vertical={true} gap="var(--spacing-lg)">
          <p data-testid="stmt-preference-paperless-script">
            By enrolling in paper billing, I am confirming that I have verified
            the card holder's mailing address.
          </p>
          <strong>Mailing Address</strong>

          {mailingAddress && (
            <Flex vertical={true} data-testid="customer-mailing-address">
              <p>{mailingAddress.streetAddress}</p>
              <p>{mailingAddress.cityStateZip}</p>
            </Flex>
          )}

          {!mailingAddress && (
            <Alert
              type="error"
              message="Cannot proceed since customer address is not found."
            ></Alert>
          )}
        </Flex>
      )}
      {isUpdateError && (
        <SectionMessage
          status={STATUS.WARNING}
          size={SIZE.XS}
          text={`Unable to update statement preferences.`}
        ></SectionMessage>
      )}
    </Modal>
  );
};

export default StatementPreferencesUpdateModal;
