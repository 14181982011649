import { useEffect, useMemo, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { EditOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Flex, Row, Tooltip, Typography } from 'antd';
import { useQuery } from '@apollo/client';

import EditCustomerInfoModal from './EditCustomerInfoModal';
import SendEmailModal from './SendEmailModal';
import ClientMetaSkeleton from 'components/ClientMetaSkeleton/ClientMetaSkeleton';
import DisplayValue from 'components/DisplayValue/DisplayValue';
import ErrorTooltip from 'components/ErrorTooltip/ErrorTooltip';
import LocationAndTime from 'components/LocationAndTime/LocationAndTime';
import MetaContainer from 'components/MetaContainer/MetaContainer';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useAnalytics from 'hooks/useAnalytics';
import useCeaseAndDesistStatus from 'hooks/useCeaseAndDesistStatus';
import useCustomerInfo from 'hooks/useCustomerInfo';
import useCustomerFullSsn from 'hooks/useCustomerFullSsn';
import useModalControls from 'hooks/useModalControls';

import { CUSTOMER_FINANCIAL_INFO } from 'queries/customer';
import { FRAUD_CASES_SIMPLE } from 'queries/fraudCases';

import { getAgeFromDate, formatDate } from 'utilities/datesAndTimes';
import { getFraudStatus } from 'utilities/helpers';
import { ANALYTICS_EVENTS, FRAUD_STATUS, SIZE } from 'utilities/constants';

const CustomerMeta = () => {
  const { customerId } = useParams();
  const { url } = useRouteMatch();
  const { customerInfo, customerInfoQuery } = useCustomerInfo();
  const [fullSsn, getFullSsn, ssnFailureMessage, { loading: loadingFullSsn }] =
    useCustomerFullSsn();
  const { ceaseAndDesistEnabled } = useCeaseAndDesistStatus();
  const { trackEvent } = useAnalytics();

  const [activeFraudCases, setActiveFraudCases] = useState([]);
  const [shouldGetCLIPTout, setShouldGetCLIPTout] = useState(false);

  const [address, ecsId] = useMemo(() => {
    return [customerInfo?.address || {}, customerInfo.id];
  }, [customerInfo]);

  const editCustomerInfoModal = useModalControls({
    onHide: () => trackEvent(ANALYTICS_EVENTS.EDIT_CUSTOMER_INFO_CLOSED),
    onShow: () => trackEvent(ANALYTICS_EVENTS.EDIT_CUSTOMER_INFO_OPENED),
  });

  const sendEmailModal = useModalControls({
    onHide: () => trackEvent(ANALYTICS_EVENTS.AUTOMATED_EMAIL_CLOSED),
    onShow: () => trackEvent(ANALYTICS_EVENTS.AUTOMATED_EMAIL_OPENED),
  });

  const street2 = address.street2 ? ` ${address.street2}` : '';
  const streetAddress = `${address.street1}${street2}`;
  const cityStateZip = `${address.city}, ${address.state} ${address.postalCode}`;
  const fullAddress = `${streetAddress} ${cityStateZip}`;

  let { data: fraudData } = useQuery(FRAUD_CASES_SIMPLE, {
    variables: { customerId },
  });

  useQuery(CUSTOMER_FINANCIAL_INFO, {
    variables: { customerId },
    onCompleted: (response) => {
      const financialInfo = response?.customer?.financialInfo;
      setShouldGetCLIPTout(financialInfo?.shouldGetCLIPTout);
    },
    notifyOnNetworkStatusChange: true,
  });

  const getCustomerName = () => {
    let { firstName, middleName, lastName } = customerInfo;
    return `${firstName} ${(middleName || '').charAt(0)} ${lastName}`;
  };

  // Handling fraud data in useEffect since refetch is not
  // triggered from other components (like the Fraud forms)
  useEffect(() => {
    if (fraudData) {
      setActiveFraudCases(
        fraudData.fraudCasesV2.filter((fraudCase) => {
          let active = true;
          switch (getFraudStatus(fraudCase.status)) {
            case FRAUD_STATUS.CLEARED:
            case FRAUD_STATUS.RESTORED:
            case FRAUD_STATUS.REJECTED:
              active = false;
              break;
          }
          return active;
        }),
      );
    }
  }, [fraudData]);

  const renderFraudSection = () => {
    if (!ceaseAndDesistEnabled && activeFraudCases.length === 0) {
      return null;
    }
    return (
      <Flex vertical={true} gap="var(--spacing-xs)">
        {ceaseAndDesistEnabled === true ? (
          <Alert
            data-testid="cease-and-desist-message"
            type="error"
            message="Cease & Desist"
          ></Alert>
        ) : null}

        {/* Not an immediate issue  but wrapping a button in a link is invalid html 
        We should make a custom component for link's rendered "as buttons"      */}
        {activeFraudCases.map((fraudCase) => {
          let type = fraudCase.type.includes('Fraud')
            ? fraudCase.type
            : `${fraudCase.type} Fraud`;
          return (
            <Alert
              data-testid={`fraud-${fraudCase.caseHash}`}
              key={`fraud-${fraudCase.caseHash}`}
              type="error"
              message={
                <Typography.Text>
                  {type}
                  <br></br>
                  {getFraudStatus(fraudCase.status)}
                </Typography.Text>
              }
              action={
                <Link
                  to={`${url}/fraud/case/${fraudCase.caseHash}`}
                  data-testid="fraud-link"
                >
                  <Button
                    block
                    size="small"
                    danger
                    onClick={() => trackEvent(ANALYTICS_EVENTS.FRAUD_OPENED)}
                  >
                    Details
                  </Button>
                </Link>
              }
            ></Alert>
          );
        })}
      </Flex>
    );
  };

  return (
    <>
      {/* Modals */}
      <EditCustomerInfoModal
        {...editCustomerInfoModal}
        onSuccess={customerInfoQuery.refetch}
      />
      <SendEmailModal {...sendEmailModal} />

      {/* Customer Information */}
      <MetaContainer>
        <QueryBoundary
          messageHeight="372px"
          errorMessage="Failed to load Customer Information."
          query={customerInfoQuery}
          skeleton={ClientMetaSkeleton}
        >
          <Flex vertical={true} gap="var(--spacing-sm)">
            <Flex
              align="center"
              justify="space-between"
              gap="var(--spacing-md)"
            >
              <div>
                <Flex vertical={true}>
                  <Typography.Title
                    data-testid="customer-name"
                    level={3}
                    style={{
                      margin: 0,
                    }}
                    copyable={{
                      text: `${getCustomerName()}`,
                    }}
                  >
                    {getCustomerName()}
                  </Typography.Title>
                  <Typography.Text
                    copyable={{
                      text: `${ecsId}`,
                    }}
                    style={{
                      fontSize: 'var(--font-size-sm)',
                      fontWeight: 'normal',
                    }}
                  >
                    Profile ID: {ecsId}
                  </Typography.Text>
                </Flex>
              </div>
              <Tooltip title="Edit customer information">
                <Button
                  data-testid="edit-customer-info"
                  type="link"
                  icon={<EditOutlined style={{ fontSize: '1.5rem' }} />}
                  onClick={editCustomerInfoModal.show}
                  style={{
                    display: 'flex',
                    alignSelf: 'flex-start',
                  }}
                ></Button>
              </Tooltip>
            </Flex>

            <LocationAndTime client={customerInfo}></LocationAndTime>

            {shouldGetCLIPTout ? (
              <Alert
                showIcon
                type="warning"
                data-testid="clip-tout-alert"
                message={
                  <div>
                    The customer's Income and Residence information requires an{' '}
                    <Link
                      to={`${url}/details`}
                      data-testid="income-residence-link"
                    >
                      update
                    </Link>
                  </div>
                }
              ></Alert>
            ) : null}

            {renderFraudSection()}

            <Row gutter={[10, '1rem']}>
              <Col sm={11}>
                <ErrorTooltip
                  placement="topLeft"
                  open={!!ssnFailureMessage}
                  title={ssnFailureMessage}
                  overlayStyle={{ position: 'fixed' }}
                >
                  <div>
                    <DisplayValue
                      label="SSN"
                      labelIcon={loadingFullSsn ? LoadingOutlined : EyeOutlined}
                      onLabelIconClick={loadingFullSsn ? undefined : getFullSsn}
                      size={SIZE.SM}
                    >
                      {fullSsn ? fullSsn : `_____ ${customerInfo.lastFourSSN}`}
                    </DisplayValue>
                  </div>
                </ErrorTooltip>
              </Col>
              <Col sm={13}>
                <DisplayValue
                  label="Primary Phone"
                  size={SIZE.SM}
                  canCopy={true}
                  copiedValue={`${customerInfo.phoneNumber}`.slice(2)}
                >
                  {customerInfo.phoneNumber}
                </DisplayValue>
              </Col>
              <Col sm={11}>
                <DisplayValue
                  label="Date of Birth"
                  size={SIZE.SM}
                  canCopy={true}
                  copiedValue={formatDate(customerInfo.datOfBirth)}
                >
                  {formatDate(customerInfo.datOfBirth)} &bull;{' '}
                  {getAgeFromDate(customerInfo.datOfBirth)} yrs
                </DisplayValue>
              </Col>
              <Col sm={13}>
                <DisplayValue
                  label="Email"
                  onValueClick={sendEmailModal.show}
                  canCopy={true}
                  size={SIZE.SM}
                >
                  {customerInfo.email}
                </DisplayValue>
              </Col>
              <Col sm={24}>
                <DisplayValue
                  label="Address"
                  size={SIZE.SM}
                  canCopy={true}
                  copiedValue={fullAddress}
                >
                  {streetAddress}
                  <br></br>
                  {cityStateZip}
                </DisplayValue>
              </Col>
            </Row>
          </Flex>
        </QueryBoundary>
      </MetaContainer>
    </>
  );
};

export default CustomerMeta;
