import dayjs from 'dayjs';

import { formatDollarsFromCents } from 'utilities/helpers';

import { useRecoveriesState } from './recoveriesState';
import { usePaymentPlanState } from './paymentPlanState';
import {
  PaymentsTableWithHighlights,
  applyRowClass,
} from './UpcomingPaymentsTable';

const PaymentScheduleTable = () => {
  const { paymentCadence } = useRecoveriesState();
  const {
    currentPaymentPlan,
    pendingDeletedPayments,
    futurePayments,
    updatedFuturePayments,
  } = usePaymentPlanState();

  let data;
  if (currentPaymentPlan?.length) {
    const hasUpdatedOrPendingDeletedPayments =
      updatedFuturePayments.length || pendingDeletedPayments.length;
    const scheduledPayments = hasUpdatedOrPendingDeletedPayments
      ? updatedFuturePayments.concat(...pendingDeletedPayments)
      : futurePayments.concat(...pendingDeletedPayments);
    data = scheduledPayments.sort((a, b) => dayjs(a.date) - dayjs(b.date));
  } else {
    data = paymentCadence?.paymentPlanSchedule;
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => {
        return dayjs(date).format('LL');
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => {
        if (typeof amount === 'number') {
          return formatDollarsFromCents(amount);
        } else {
          return amount;
        }
      },
    },
  ];

  return (
    <PaymentsTableWithHighlights
      rowClassName={applyRowClass}
      columns={columns}
      data={data}
      length={6}
    />
  );
};

export default PaymentScheduleTable;
