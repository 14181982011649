import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import StatementMeta from './StatementMeta';
import StatementTransactions from './StatementTransactions';
import QueryResult from 'components/QueryResult/QueryResult';

import { STATEMENT_SUMMARY } from 'queries/statementSummary';
import { formatDateToSubmit } from 'utilities/datesAndTimes';

const StatementSummary = ({ date }) => {
  if (!date) {
    return <div>There was an error loading the statement summary.</div>;
  }
  const { accountId, customerId } = useParams();

  const statementSummaryQuery = useQuery(STATEMENT_SUMMARY, {
    variables: {
      customerId,
      accountId,
      periodEndDate: formatDateToSubmit(date),
    },
  });

  return (
    <div
      style={{
        maxHeight: '650px',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '0 1.5rem 0 0.5rem',
      }}
    >
      <QueryResult {...statementSummaryQuery}>
        <StatementMeta data={statementSummaryQuery.data} />
        <StatementTransactions
          statementId={statementSummaryQuery.data?.statementSummary?.id}
        />
      </QueryResult>
    </div>
  );
};

export default StatementSummary;
