import { Redirect, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ActionBar from 'components/ActionBar/ActionBar';
import CustomerDirectLinkSearch from 'components/CustomerDirectLinkSearch/CustomerDirectLinkSearch';
import Header from 'components/Header/Header';
import NoteBox from 'components/NoteBox/NoteBox';
import ApplicationLayout from 'layouts/ApplicationLayout/ApplicationLayout';
import CustomerLayout from 'layouts/CustomerLayout/CustomerLayout';
import SearchLayout from 'layouts/CustomerSearchLayout/SearchLayout';
import FraudFilesSearchLayout from 'layouts/FraudFilesSearchLayout/FraudFilesSearchLayout';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';

import useSidebar from 'hooks/useSidebar';

import { SentryRoute } from 'services/sentry';

const Dashboard = () => {
  const { sidebarOpen } = useSidebar();
  const { isFdrComplaints } = useFlags();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header></Header>
      <Layout
        style={{
          padding: '0',
          margin: 'var(--spacing-xs)',
          marginTop: 'calc(var(--header-height) + var(--spacing-xs))',
        }}
      >
        <Layout.Content
          style={{
            transition: 'all 0.2s',
            marginRight: sidebarOpen
              ? 'calc(var(--soft-phone-width) + var(--spacing-xs))'
              : 0,
          }}
        >
          <Switch>
            <SentryRoute path="/" exact component={SearchLayout} />
            <SentryRoute path="/search" exact component={SearchLayout} />
            {isFdrComplaints && (
              <SentryRoute
                path="/fraud-files-search"
                exact
                component={FraudFilesSearchLayout}
              />
            )}
            <SentryRoute
              path="/search-email"
              exact
              component={CustomerDirectLinkSearch}
            />

            <Redirect exact from="/applications" to="/search" />
            <SentryRoute
              path="/applications/:applicationId"
              component={ApplicationLayout}
            />

            <Redirect exact from="/customers" to="/search" />
            <SentryRoute
              path="/customers/:customerId"
              component={CustomerLayout}
            />

            <SentryRoute component={NotFoundPage} />
          </Switch>
        </Layout.Content>
        <ActionBar></ActionBar>
      </Layout>
      <NoteBox />
    </Layout>
  );
};

export default Dashboard;
