import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Flex, Form, Modal } from 'antd';
import dayjs from 'dayjs';

import AddFundingAccountModal from 'components/AddFundingAccountModal/AddFundingAccountModal';
import useModalControls from 'hooks/useModalControls';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { ONE_TIME_PAYMENT } from 'queries/payments';
import { UPDATE_SETTLEMENT_FUNDING_ACCOUNT } from 'mutations/settlements';
import { getFundingAccountName, getEftaText } from 'utilities/helpers';

import SelectFundingAccount from '../SelectFundingAccount';
import EFTAForm from './EFTAForm';
import {
  setPaymentFundingAccount,
  usePaymentPlanState,
} from './paymentPlanState';

const ChangeFundingAccount = ({ onSuccess, thirdParty }) => {
  const { customerId, accountId } = useParams();
  const { eftaDetails, fundingAccount, futurePayments, offer } =
    usePaymentPlanState();

  const [form] = Form.useForm();

  const { name: thirdPartyName, email: thirdPartyEmail } = thirdParty;
  const { cardLast4, customerName, email } = eftaDetails;

  const addFundingAccountModal = useModalControls();
  const eftaModal = useModalControls();

  const [localFundingAccountId, setLocalFundingAccountId] = useState('');
  const [localFundingAccountName, setLocalFundingAccountName] = useState('');

  const [allFundingAccounts, setAllFundingAccounts] = useState([]);

  const [submitNewFundingAccountErrorMsg, setSubmitNewFundingAccountErrorMsg] =
    useState('');
  const [
    submitNewFundingAccountSuccessMsg,
    setSubmitNewFundingAccountSuccessMsg,
  ] = useTimeoutValue('', 3000);

  useQuery(ONE_TIME_PAYMENT, {
    variables: { customerId, accountId },
    onCompleted: (data) => {
      setAllFundingAccounts(data?.fundingAccounts);
    },
  });

  const [submitNewFundingAccount, { loading: submitNewFundingAccountLoading }] =
    useMutation(UPDATE_SETTLEMENT_FUNDING_ACCOUNT, {
      onCompleted: () => {
        setPaymentFundingAccount(
          localFundingAccountId,
          localFundingAccountName,
        );
        setSubmitNewFundingAccountSuccessMsg(
          'Funding account successfully updated!',
        );
        form.resetFields();
        setTimeout(() => {
          eftaModal.hide();
        }, 2500);
      },
      onError: () => {
        setSubmitNewFundingAccountErrorMsg(
          'Failed to update new funding account. Please try again.',
        );
      },
      refetchQueries: ['GetSettlementOffers', 'MakePaymentQuery'],
    });

  const onSelectFundingAccount = (accountId) => {
    const displayName = getFundingAccountName(allFundingAccounts, accountId);
    setLocalFundingAccountId(accountId);
    setLocalFundingAccountName(displayName);
  };

  const onFundingAccountConfirmation = async () => {
    await form.validateFields();

    form.resetFields();
    onSuccess && onSuccess();
    eftaModal.show();
  };

  const fundingAccountLast4 = localFundingAccountName?.substring(
    localFundingAccountName.length - 4,
    localFundingAccountName.length,
  );

  const onSubmitNewFundingAccount = async () => {
    const eftaText = getEftaText({
      eftaDetails: { cardLast4, customerName, fundingAccountLast4, email },
      todaysDate: dayjs().format('L'),
      paymentPlanSchedule: futurePayments,
    });

    const variables = {
      authorizationText: eftaText,
      customerId: Number(customerId),
      fundingAccountId: localFundingAccountId,
      offerId: offer.offerId,
    };

    await submitNewFundingAccount({ variables });
  };

  return (
    <>
      <Form
        form={form}
        name="fundingAccountForm"
        layout="vertical"
        initialValues={{
          fundingAccount: fundingAccount.id || null,
        }}
        onFinish={onFundingAccountConfirmation}
      >
        <SelectFundingAccount
          label="Bank Account"
          name="fundingAccount"
          fundingAccounts={allFundingAccounts}
          onChange={onSelectFundingAccount}
        />
        <Flex justify="flex-end" gap="var(--spacing-sm)">
          <Button onClick={addFundingAccountModal.show}>
            Add New Funding Account
          </Button>
          <Button type="primary" onClick={onFundingAccountConfirmation}>
            Confirm Funding Account
          </Button>
        </Flex>
        <AddFundingAccountModal
          {...addFundingAccountModal}
          refetchQueries={['MakePaymentQuery']}
        />
      </Form>
      <Modal {...eftaModal} width={600} footer={[]} destroyOnClose={true}>
        <EFTAForm
          eftaDetails={{
            cardLast4,
            customerName: thirdPartyName ?? customerName,
            email: thirdPartyEmail ?? email,
            fundingAccountLast4,
          }}
          onFinish={onSubmitNewFundingAccount}
          mutation={{
            loading: submitNewFundingAccountLoading,
            error: submitNewFundingAccountErrorMsg,
            success: submitNewFundingAccountSuccessMsg,
          }}
          resetErrorMessage={setSubmitNewFundingAccountErrorMsg}
          resetSuccessMessage={setSubmitNewFundingAccountSuccessMsg}
        />
      </Modal>
    </>
  );
};

export default ChangeFundingAccount;
