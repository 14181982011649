import { useState } from 'react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import _get from 'lodash/get';

const useEditFormStatuses = (form, onChange) => {
  const [containsChanges, setContainsChanges] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState({});
  const [initialValues, setInitialValuesState] = useState({});
  const [fieldsToReset, setFieldsToReset] = useState();

  const setInitialValues = (values = {}) => {
    setInitialValuesState(values);
    const fieldNames = Object.keys(values);
    const initChanged = fieldNames.reduce((init, key) => {
      init[key] = false;
      return init;
    }, {});
    setFieldsChanged(initChanged);
    setFieldsToReset(fieldNames);
  };

  const resetInitialValue = (fieldName, value) => {
    setInitialValuesState(
      Object.assign({}, initialValues, { [fieldName]: value }),
    );
    const reset = Object.assign({}, fieldsChanged, { [fieldName]: false });
    setFieldsChanged(reset);
    setFieldsToReset([fieldName]);
  };

  const getFieldStatus = (fieldName) => {
    return _get(fieldsChanged, fieldName) ? 'warning' : '';
  };

  const updateFieldStatus = (changedEvents) => {
    const fieldName = changedEvents
      ?.filter((event) => event.touched)[0]
      .name.toString();

    if (fieldName) {
      const initialValue = _get(initialValues, fieldName);
      const currentValue = form.getFieldValue(fieldName);

      const changed = dayjs.isDayjs(currentValue)
        ? !currentValue.isSame(initialValue)
        : currentValue !== initialValue;

      if (onChange) {
        onChange();
      }

      const changes = Object.assign({}, fieldsChanged, {
        [fieldName]: changed,
      });

      setFieldsChanged(changes);
      setContainsChanges(Object.values(changes).some((value) => value));
    }
  };

  // A little awkward but this enables resetting
  // of a single field or all fields
  useEffect(() => {
    form.resetFields(fieldsToReset);
  }, [form, fieldsToReset]);

  return {
    containsChanges,
    fieldsChanged,
    getFieldStatus,
    initialValues,
    setInitialValues,
    resetInitialValue,
    updateFieldStatus,
  };
};

export default useEditFormStatuses;
