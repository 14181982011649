import { gql } from '@apollo/client';

const DIGITAL_WALLET_INFO_QUERY = gql`
  query DigitalWalletInfo($accountId: String!, $customerId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      digitalWallet {
        info {
          status
          walletReferenceIds {
            tokenRequesterId
            tokenNumber
            dnitheId
          }
        }
      }
    }
  }
`;

const DIGITAL_WALLET_EVENTS_QUERY = gql`
  query DigitalWalletEvents($accountId: String!, $customerId: String!) {
    account(accountId: $accountId, customerId: $customerId) {
      digitalWallet {
        events {
          vendor
          status
          date
          time
          tokenNumber
          tokenRequesterId
          reason
          indicator
        }
      }
    }
  }
`;

export { DIGITAL_WALLET_INFO_QUERY, DIGITAL_WALLET_EVENTS_QUERY };
