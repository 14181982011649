import { useContext } from 'react';

import { AgentContext } from 'providers/AgentProvider';

const useAgent = () => {
  const agent = useContext(AgentContext);
  return agent;
};

export default useAgent;
