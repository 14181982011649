import { useEffect } from 'react';
import { useHistory, Route } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Security, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Cookies from 'js-cookie';

import config from 'config';

const oktaAuth = new OktaAuth({
  issuer: 'https://missionlane.okta.com/oauth2/aus8t9fhmb0ggdhir357',
  clientId: config.OKTA_CLIENT_ID,
  redirectUri: config.OKTA_REDIRECT_URI,
  pkce: true,
});

let AuthStateWrapper = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const ldClient = useLDClient();

  const identifyLD = async () => {
    const user = await oktaAuth.getUser();
    ldClient.identify({ key: user.requester_id, anonymous: false });
  };

  useEffect(() => {
    if (authState) {
      const { idToken, isAuthenticated, isPending } = authState;
      if (idToken && isAuthenticated && !isPending) {
        Cookies.set('id_token', idToken.idToken, {
          domain: config.GQL_COOKIE_DOMAIN,
          sameSite: 'None',
          secure: true,
        });
        localStorage.setItem('id_token', idToken.idToken);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (ldClient && authState?.isAuthenticated) {
      identifyLD();
    }
  }, [ldClient, authState]);

  return children;
};

const SecurityProvider = ({ children }) => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Route path="/login/callback" exact={true} component={LoginCallback} />
      <AuthStateWrapper>{children}</AuthStateWrapper>
    </Security>
  );
};

export default SecurityProvider;
