import { useState } from 'react';
import { useToggle } from 'react-use';
import { Input, Button, message as AntMessage } from 'antd';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';

import HorizontalList from 'components/HorizontalList/HorizontalList';
import useAgent from 'hooks/useAgent';
import { UPDATE_COMMENT_MUTATION } from 'mutations/notes';

import { formatDate, getDateFromJavaInstant } from 'utilities/datesAndTimes';
import { COLORS } from 'utilities/styles';

const { ListItem } = HorizontalList;
const { TextArea } = Input;

const CommentBox = styled.div`
  display: grid;
  row-gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  border: solid 1px ${COLORS.grey3};
  background-color: ${COLORS.haze3};
`;

const Comment = ({ comment = {}, canEdit, noteId, onCommentUpdate }) => {
  const agent = useAgent();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [message, setMessage] = useState(comment.message);

  const [updateCommentMutation, { loading }] = useMutation(
    UPDATE_COMMENT_MUTATION,
    {
      refetchQueries: ['GetAgentCustomerActivity'],
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        toggleIsEditing(false);
        onCommentUpdate?.('Comment updated successfully!');
      },
      onError: (error) => {
        AntMessage.error(error.message);
      },
    },
  );

  const handleUpdateComment = async () => {
    await updateCommentMutation({
      variables: {
        noteId,
        commentId: comment.id,
        agentId: agent?.user?.requester_id,
        agentName: agent?.user?.name,
        message,
      },
    });
  };

  return (
    <CommentBox key={comment.id} data-testid="comment-box">
      <HorizontalList>
        <ListItem>
          {formatDate(getDateFromJavaInstant(comment.createdTz))}
        </ListItem>
        <ListItem>
          <strong>{comment.agentName}</strong>
        </ListItem>
        {canEdit && (
          <ListItem>
            <a onClick={toggleIsEditing} data-testid="edit-comment-link">
              {isEditing ? 'Cancel' : 'Edit'}
            </a>
          </ListItem>
        )}
      </HorizontalList>
      {isEditing ? (
        <>
          <TextArea
            placeholder="Edit Comment"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={65000}
            data-testid="edit-comment-textarea"
          />
          <Button
            type="primary"
            style={{ justifySelf: 'end' }}
            data-testid="comment-update-button"
            onClick={handleUpdateComment}
            disabled={loading || !message}
          >
            Save
          </Button>
        </>
      ) : (
        <span data-testid="comment-message">{comment.message}</span>
      )}
    </CommentBox>
  );
};

export default Comment;
