import { gql } from '@apollo/client';

const TRANSACTIONS = gql`
  query TransactionsQuery(
    $customerId: String
    $accountId: String!
    $fromDate: String
    $toDate: String
  ) {
    account(customerId: $customerId, accountId: $accountId) {
      id
      activities(fromDate: $fromDate, toDate: $toDate) {
        id
        date
        amount
        reference
        description
        postedAt
        state
        type
        transactionId
        ... on Payment {
          sourceActivityType
        }
        ... on Transaction {
          activityStatus
          cardLast4
          sourceActivityType
        }
      }
    }
  }
`;

const TRANSACTION_DETAIL = gql`
  query TransactionByIdQuery(
    $customerId: String!
    $accountId: String!
    $fromDate: String!
    $toDate: String!
    $transactionId: String!
  ) {
    getTransactionById(
      customerId: $customerId
      accountId: $accountId
      fromDate: $fromDate
      toDate: $toDate
      transactionId: $transactionId
    ) {
      accountingFunction
      activityCode
      activityType
      amount
      approvalCode
      cardLast4
      categoryCode
      categoryDescription
      centralProcessingDate
      classification
      date
      declineReasonDescription
      description
      designatedPromotionStatus
      id
      merchant {
        address {
          city
          postalCode
          stateProvince
          street1
          street2
        }
        category
        categoryCode
        categoryDescription
        id
        name
        phoneNumber
      }
      messageType {
        code
        description
      }
      postedAt
      reference
      state
      status
      submittedAt
      termBalanceId
      transactionDetail {
        accountType
        activityToday {
          approved {
            count
            totalAmount
          }
          atmCash {
            count
            totalAmount
          }
          availableAfterAuthorization
          badPinAttemptCount
          cashAdvances {
            count
            totalAmount
          }
          currentBalanceAmount
          declined {
            count
            totalAmount
          }
          mailOrTelephoneOrder {
            count
            totalAmount
          }
          referred {
            count
            totalAmount
          }
        }
        authorizationDate
        authorizationStatus
        cardVerificationIndicator2Response
        cashAvailableBeforeAmount
        cashBackAmount
        conversionRate
        creditAmountBefore
        customerType
        declineReason
        expires
        massCompromise
        messageType {
          code
          description
        }
        operatorId
        pinVerified
        posEntry
        recurring
        sourceCurrencyAmount
      }
      transactionId
      type
    }
  }
`;

const AUTHORIZATION_COUNTERS = gql`
  query GetAuthorizationCounters($accountId: String!) {
    accountDailyAuthorizationCounters(accountId: $accountId) {
      declined {
        count
      }
    }
  }
`;

const AUTHORIZATION_HISTORY = gql`
  query AuthorizationHistory(
    $accountId: String!
    $fromDate: String!
    $toDate: String!
  ) {
    fraudAuthorizations(
      accountId: $accountId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      accountType
      acquirerBin
      authorizationRequestMessage
      availableCash
      availablePurchase
      cardExpiry
      cardVerificationStatus
      cardVerificationValue
      currentBalance
      customData
      dateTimeAuthorization
      electronicTransactionIndicator
      fraud
      magneticNameIndicator
      massCompromised
      merchantInformation {
        address {
          city
          postalCode
          stateProvince
          street1
          street2
        }
        category
        categoryCode
        categoryDescription
        id
        name
        phoneNumber
      }
      message
      messageModifier
      overrideId
      pinVerified
      pointOfSaleInformation
      presenceIndicator {
        card
        cardholder
        terminal
      }
      priorPurchaseDistance
      purchaseDistance
      responseInformation {
        approvalCode
        approvedAmount
        code
        codeDescription
        declineReason
        declineReasonDescription
      }
      secure3dInformationIndicator
      standInReasonCode2
      terminalId
      terminalInformation {
        capability
        conditionCode
        type
      }
      token
      transactionAmount
      transactionId
      transactionSourceType
      verificationIndicatorName
    }
  }
`;

const FEE_WAIVER_ELIGIBILITY = gql`
  query FeeWaiverEligibility(
    $accountId: String!
    $postedDate: String!
    $transactionId: String!
  ) {
    adjustmentEligibility(
      accountId: $accountId
      customerAdjustmentInput: {
        postedDate: $postedDate
        transactionId: $transactionId
      }
    ) {
      eligible
    }
  }
`;

export {
  TRANSACTIONS,
  TRANSACTION_DETAIL,
  AUTHORIZATION_COUNTERS,
  AUTHORIZATION_HISTORY,
  FEE_WAIVER_ELIGIBILITY,
};
