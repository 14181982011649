import { gql } from '@apollo/client';

const NEW_FRAUD_CASE_FOR_APPLICATION = gql`
  mutation NewFraudCaseForApplication(
    $applicationId: String!
    $fraudType: String!
    $product: String!
    $actionType: String!
    $actor: String!
    $initiatedAt: String!
    $reasons: String!
    $evidence: String!
  ) {
    submitNewFraudCaseForApplicationV2(
      applicationId: $applicationId
      fraudType: $fraudType
      product: $product
      actionType: $actionType
      actor: $actor
      initiatedAt: $initiatedAt
      reasons: $reasons
      evidence: $evidence
    )
  }
`;

const NEW_FRAUD_CASE_FOR_CUSTOMER = gql`
  mutation NewFraudCaseForCustomer(
    $customerId: String!
    $fraudType: String!
    $product: String!
    $actionType: String!
    $actor: String!
    $initiatedAt: String!
    $reasons: String!
    $evidence: String!
  ) {
    submitNewFraudCaseForCustomerV2(
      customerId: $customerId
      fraudType: $fraudType
      product: $product
      actionType: $actionType
      actor: $actor
      initiatedAt: $initiatedAt
      reasons: $reasons
      evidence: $evidence
    )
  }
`;

export { NEW_FRAUD_CASE_FOR_APPLICATION, NEW_FRAUD_CASE_FOR_CUSTOMER };
