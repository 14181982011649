import { useEffect, useState } from 'react';
import { Button, Card, Flex, Form, Layout, Radio, Typography } from 'antd';
import { makeVar, useLazyQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';

import QueryResult from 'components/QueryResult/QueryResult';

import { CUSTOMER_SEARCH } from 'queries/customerSearch';
import { APPLICATION_SEARCH } from 'queries/applications';
import { CUSTOMER_TYPE, ANALYTICS_EVENTS } from 'utilities/constants';
import useAnalytics from 'hooks/useAnalytics';
import {
  formatDateToSubmit,
  formatTime,
  getUserLocalTime,
} from 'utilities/datesAndTimes';

import CustomerSearchForm from './CustomerSearchForm';
import ApplicationSearchForm from './ApplicationSearchForm';
import ApplicationSearchResults from './ApplicationSearchResults';
import CustomerSearchResults from './CustomerSearchResults';

const { Title } = Typography;

const customerSearchResultsVar = makeVar(null);
const applicationSearchResultsVar = makeVar(null);

const FORM_OPTIONS = {
  CUSTOMERS: 'CUSTOMERS',
  APPLICATIONS: 'APPLICATIONS',
};

const SearchLayout = () => {
  const customerSearchResults = useReactiveVar(customerSearchResultsVar);
  const applicationSearchResults = useReactiveVar(applicationSearchResultsVar);

  const [form] = Form.useForm();
  const [searchForm, setSearchForm] = useState(FORM_OPTIONS.CUSTOMERS);
  const { trackEvent } = useAnalytics();

  const [searchCustomers, customerQuery] = useLazyQuery(CUSTOMER_SEARCH, {
    onCompleted: ({ searchCustomer }) => {
      const customerResults = searchCustomer.map(convertCustomerToSearchResult);
      customerSearchResultsVar(customerResults);
    },
  });

  const [searchApplications, applicationsQuery] = useLazyQuery(
    APPLICATION_SEARCH,
    {
      onCompleted: ({ applicationsV2 }) => {
        applicationSearchResultsVar(
          convertApplicationsToSearchResult(applicationsV2),
        );
      },
    },
  );

  useEffect(() => {
    document.title = 'Mission Control Search';
  }, []);

  const handleFormSubmit = (formValues) => {
    // Only submit those formValues that have a value, NOT including empty string
    const searchFields = {};
    Object.keys(formValues).forEach((key) => {
      let fieldValue = formValues[key];
      if (
        fieldValue !== '' &&
        fieldValue !== undefined &&
        fieldValue !== null
      ) {
        if (key !== 'birthDate') {
          fieldValue = fieldValue.trim();
        }
        if (key === 'primaryPhone') {
          if (!fieldValue.startsWith('+1')) {
            fieldValue = '+1' + fieldValue;
          }
        }
        if (key === 'birthDate') {
          fieldValue = formatDateToSubmit(fieldValue);
        }
        if (key === 'fullSsn') {
          fieldValue = fieldValue.replace(/\D/g, ''); // strip non-digit characters
        }
        if (key === 'cardLast4') {
          fieldValue = fieldValue.replace(/\D/g, ''); // strip non-digit characters
        }

        searchFields[key] = fieldValue;
      }
    });

    if (searchForm === FORM_OPTIONS.APPLICATIONS) {
      trackEvent(ANALYTICS_EVENTS.APPLICATION_SEARCH_CLICKED, { searchFields });
      return searchApplications({
        variables: {
          applicationSearchRequest: {
            fullSsn: searchFields?.fullSsn,
            emailAddress: searchFields?.email,
          },
        },
      });
    }

    trackEvent(ANALYTICS_EVENTS.CUSTOMER_SEARCH_CLICKED, { searchFields });
    searchCustomers({
      variables: { customerSearchRequestBody: searchFields },
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFormOptionChange = (option) => {
    switch (option.target.value) {
      case FORM_OPTIONS.CUSTOMERS:
        setSearchForm(FORM_OPTIONS.CUSTOMERS);
        form.resetFields();
        applicationSearchResultsVar(null);
        break;
      case FORM_OPTIONS.APPLICATIONS:
        setSearchForm(FORM_OPTIONS.APPLICATIONS);
        form.resetFields();
        customerSearchResultsVar(null);
        break;
      default:
    }
  };

  const SearchTitle = (
    <Title level={5}>
      Search For{' '}
      <Radio.Group
        onChange={onFormOptionChange}
        optionType="button"
        buttonStyle="solid"
        defaultValue={FORM_OPTIONS.CUSTOMERS}
      >
        <Radio.Button
          data-testid="customersRadio"
          value={FORM_OPTIONS.CUSTOMERS}
        >
          Customers
        </Radio.Button>
        <Radio.Button
          data-testid="applicationsRadio"
          value={FORM_OPTIONS.APPLICATIONS}
        >
          Applications
        </Radio.Button>
      </Radio.Group>
    </Title>
  );

  return (
    <Layout.Content>
      <Card bordered={false}>
        {SearchTitle}
        <br />
        <Form
          form={form}
          layout="vertical"
          size="middle"
          onFinish={handleFormSubmit}
        >
          {searchForm === FORM_OPTIONS.CUSTOMERS ? (
            <CustomerSearchForm />
          ) : (
            <ApplicationSearchForm />
          )}

          <Form.Item>
            <Flex gap="var(--spacing-sm)">
              <Button danger onClick={onReset} data-testid="resetButton">
                Clear Form
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="searchButton"
              >
                Search
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Card>

      <QueryResult
        {...applicationsQuery}
        renderChildrenWithNullData={true}
        called
      >
        {applicationSearchResults && (
          <ApplicationSearchResults
            title="Application Search Results"
            data={applicationSearchResults}
          />
        )}
      </QueryResult>

      <QueryResult {...customerQuery} renderChildrenWithNullData={true} called>
        {customerSearchResults && (
          <CustomerSearchResults
            title="Customer Search Results"
            data={customerSearchResults}
          />
        )}
      </QueryResult>
    </Layout.Content>
  );
};

const getCustomerLocalTime = (state) => {
  const localTime = getUserLocalTime(state);
  return dayjs(localTime).isValid() ? formatTime(localTime) : 'Unknown';
};

const convertCustomerToSearchResult = (customer) => {
  const customerResult = {
    key: `${customer.customerType}-${customer.customerId}}`,
    birtDate: customer.birthDate,
    customerId: customer.customerId,
    accountId: customer.accountId,
    email: customer.email,
    firstName: customer.firstName,
    last4Ssn: customer.lastFourSsn,
    lastName: customer.lastName,
    state: customer.state,
    type: CUSTOMER_TYPE.CUSTOMER,
    localTime: getCustomerLocalTime(customer.state),
  };

  return customerResult;
};

const convertApplicationsToSearchResult = (applications = []) => {
  return applications
    .map((application) => {
      return application.submission.applicants.map((applicant) => {
        return {
          type: CUSTOMER_TYPE.APPLICATION,
          key: application.applicationId,
          applicationId: application.applicationId,
          status: application.status,
          statusReason: application.statusReason,
          primaryApplicantName: `${applicant.firstName} ${applicant.lastName}`,
          primaryApplicantId:
            applicant.applicantType === 'PRIMARY' && applicant.applicantId
              ? applicant.applicantId
              : undefined,
        };
      });
    })
    .flat();
};

export default SearchLayout;
