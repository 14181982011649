import { useParams } from 'react-router-dom';

import AddressReviewStep from '../AddressReviewStep';
import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_EVENTS } from 'utilities/constants';

import {
  setAddressIsCurrent,
  setAddressUpdated,
  setInitialAddressInfo,
  setNextStep,
  resetCreditBalanceRefundState,
  useCreditBalanceRefundState,
  CREDIT_BALANCE_REFUND_STEPS,
} from './creditBalanceRefundState';

const AddressReviewStepWrapper = ({ containerStyles }) => {
  const { trackEvent } = useAnalytics();
  const { accountId, customerId } = useParams();
  const { initialAddressInfo, addressIsCurrent, addressUpdated } =
    useCreditBalanceRefundState();

  const handleContinue = (returnMailStatus) => {
    trackEvent(ANALYTICS_EVENTS.CBR_ADDRESS_REVIEW, {
      customerId,
      accountId,
      addressUpdated,
      hasReturnMailStatus: !!returnMailStatus,
    });
    setNextStep(CREDIT_BALANCE_REFUND_STEPS.BAD_CHECKS_INQUIRY_STEP);
  };

  return (
    <AddressReviewStep
      addressIsCurrent={addressIsCurrent}
      addressUpdated={addressUpdated}
      initialAddressInfo={initialAddressInfo}
      setAddressIsCurrent={setAddressIsCurrent}
      setAddressUpdated={setAddressUpdated}
      setInitialAddressInfo={setInitialAddressInfo}
      onContinue={handleContinue}
      onExit={resetCreditBalanceRefundState}
      containerStyles={containerStyles}
    />
  );
};

export default AddressReviewStepWrapper;
