import { gql } from '@apollo/client';

const STATEMENT_TRANSACTIONS = gql`
  query ($customerId: String!, $statementId: String!) {
    cardStatementTransactions(
      customerId: $customerId
      statementId: $statementId
    ) {
      cardStatementTransactions {
        id
        statementId
        transactionDate
        postedDate
        referenceNumber
        itemMessage
        itemAmount
      }
    }
  }
`;

export { STATEMENT_TRANSACTIONS };
