import { gql } from '@apollo/client';

export const GET_FUNDING_ACCOUNT_FULL_BAN = gql`
  query FundingAccountFullBanQuery(
    $customerId: String!
    $fundingAccountId: String!
  ) {
    fundingAccountFullBan(
      customerId: $customerId
      fundingAccountId: $fundingAccountId
    ) {
      fullBan
    }
  }
`;
