import { gql } from '@apollo/client';

const MAKE_PAYMENT = gql`
  mutation MakePayment(
    $customerId: String
    $accountId: String!
    $amount: Long!
    $fundingAccountId: Long!
    $forcePayment: Boolean
    $idempotencyKey: String
  ) {
    makePayment(
      customerId: $customerId
      accountId: $accountId
      amount: $amount
      fundingAccountId: $fundingAccountId
      forcePayment: $forcePayment
      idempotencyKey: $idempotencyKey
      txMethod: ACH
      source: AGENT_TOOL
    ) {
      state
    }
  }
`;

const SCHEDULE_PAYMENT = gql`
  mutation CreateScheduledPayment(
    $accountId: String!
    $customerId: String
    $amount: Int!
    $fundingAccountId: Long!
    $paymentDate: String!
  ) {
    createScheduledPayment(
      accountId: $accountId
      customerId: $customerId
      amount: $amount
      fundingAccountId: $fundingAccountId
      paymentDate: $paymentDate
      paymentType: ONE_TIME_FIXED
      source: AGENT_TOOL
      accountType: CREDIT_CARD
    ) {
      id
      nextPaymentDate
    }
  }
`;

const CANCEL_PAYMENT = gql`
  mutation CancelSchedulePayment($customerId: String, $scheduleId: String!) {
    cancelScheduledPayment(customerId: $customerId, scheduleId: $scheduleId) {
      id
      nextPaymentDate
    }
  }
`;

const CREATE_RECURRING_PAYMENT = gql`
  mutation CreateAutopay(
    $customerId: String
    $accountId: String!
    $authorizationText: String!
    $fundingAccountId: Long!
    $paymentType: PaymentType!
    $amount: Int!
    $dayOfMonth: Int!
  ) {
    createAutopay(
      customerId: $customerId
      accountId: $accountId
      source: AGENT_TOOL
      authorizationText: $authorizationText
      fundingAccountId: $fundingAccountId
      paymentType: $paymentType
      accountType: CREDIT_CARD
      amount: $amount
      dayOfMonth: $dayOfMonth
    ) {
      confirmationId
    }
  }
`;

const CANCEL_RECURRING_PAYMENT = gql`
  mutation CancelAutopay($customerId: String, $scheduleId: String!) {
    cancelAutopay(customerId: $customerId, scheduleId: $scheduleId) {
      confirmationId
    }
  }
`;

const UPDATE_RECURRING_PAYMENT = gql`
  mutation UpdateAutopay(
    $customerId: String
    $accountId: String!
    $authorizationText: String!
    $fundingAccountId: Long!
    $paymentType: PaymentType!
    $amount: Int!
    $dayOfMonth: Int!
    $id: String!
  ) {
    updateAutopay(
      customerId: $customerId
      accountId: $accountId
      source: AGENT_TOOL
      id: $id
      authorizationText: $authorizationText
      fundingAccountId: $fundingAccountId
      paymentType: $paymentType
      amount: $amount
      dayOfMonth: $dayOfMonth
    ) {
      confirmationId
    }
  }
`;

export {
  CANCEL_PAYMENT,
  CANCEL_RECURRING_PAYMENT,
  CREATE_RECURRING_PAYMENT,
  MAKE_PAYMENT,
  SCHEDULE_PAYMENT,
  UPDATE_RECURRING_PAYMENT,
};
