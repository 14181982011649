import { gql } from '@apollo/client';

const UPDATE_CUSTOMER_FINANCIAL_INFO = gql`
  mutation UpdateFinancialInfo(
    $customerId: String
    $annualIncome: Int!
    $monthlyLiving: Int!
  ) {
    updateFinancialInfo(
      customerId: $customerId
      financialInfo: {
        annualIncome: $annualIncome
        monthlyLiving: $monthlyLiving
      }
    ) {
      lastUpdated
    }
  }
`;

export { UPDATE_CUSTOMER_FINANCIAL_INFO };
