import { Router } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { SecureRoute } from '@okta/okta-react';

import config from 'config';

import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';

import AntThemeProvider from 'providers/AntThemeProvider';
import AgentProvider from 'providers/AgentProvider';
import CustomerInfoProvider from 'providers/CustomerInfoProvider';
import GQLApolloProvider from 'providers/GQLApolloProvider';
import SecurityProvider from 'providers/SecurityProvider';
import SegmentProvider from 'providers/SegmentProvider';
import TranslationProvider from 'providers/TranslationProvider';

import { sentryBrowserHistory } from 'services/sentry';

const AppWithProviders = () => {
  return (
    <Router history={sentryBrowserHistory}>
      <AntThemeProvider>
        <SecurityProvider>
          <AgentProvider>
            <SegmentProvider>
              <GQLApolloProvider>
                <CustomerInfoProvider>
                  <TranslationProvider>
                    <SecureRoute path="/" component={DashboardLayout} />
                  </TranslationProvider>
                </CustomerInfoProvider>
              </GQLApolloProvider>
            </SegmentProvider>
          </AgentProvider>
        </SecurityProvider>
      </AntThemeProvider>
    </Router>
  );
};

export default withLDProvider({
  clientSideID: `${config.LAUNCH_DARKLY_CLIENT_ID}`,
  context: {
    anonymous: true,
    kind: 'user',
    key: 'anonymous',
  },
  options: {
    streaming: true,
    // Comment this dummy logger object to debug launch darkly
    logger: {
      debug: () => {},
      error: () => {},
      info: () => {},
      warn: () => {},
    },
  },
})(AppWithProviders);
