import { useState } from 'react';
import { Alert, Button, Flex, Form, Input } from 'antd';

import useAddNote from './useAddNote';

const { TextArea } = Input;
const { Item } = Form;

const StickyNote = () => {
  const [form] = Form.useForm();
  const [addNote, addNoteMutation] = useAddNote();

  const [failureMessage, setFailureMessage] = useState('');

  const handleSave = async (note) => {
    try {
      await addNote(note.stickyNote, true);
      form.resetFields();
    } catch (error) {
      setFailureMessage(
        'Failed to save sticky note. Please refresh the page and try again. Error: ' +
          error.message,
      );
    }
  };

  return (
    <Form
      form={form}
      layout={'vertical'}
      size="small"
      name="sticky-note-form"
      onFinish={handleSave}
    >
      <Item name="stickyNote" style={{ marginBottom: 'var(--spacing-xs)' }}>
        <TextArea
          data-testid="sticky-text-area"
          autoSize={{ minRows: 4, maxRows: 20 }}
          placeholder="Sticky note"
        />
      </Item>

      {failureMessage ? (
        <Alert
          data-testid="failure-message"
          type="error"
          message={failureMessage}
          style={{ marginBottom: 'var(--spacing-xs)' }}
        ></Alert>
      ) : null}

      <Flex justify="flex-end" height="auto">
        <Item style={{ marginBottom: 'var(--spacing-xs)' }}>
          <Button
            data-testid="submit-button"
            type="primary"
            loading={addNoteMutation.loading}
            htmlType="submit"
          >
            Save Sticky
          </Button>
        </Item>
      </Flex>
    </Form>
  );
};

export default StickyNote;
