import { useMemo } from 'react';
import { Checkbox, Form } from 'antd';

import AgentScript from 'components/AgentScript/AgentScript';
import { formatDollarsFromCents } from 'utilities/helpers';

import { CLOSED_REASON, FORM_FIELD } from './applyStatusUtilities';

const ClosedStatusFields = ({ account, form }) => {
  const selectedReasonCode = Form.useWatch(FORM_FIELD.REASON_CODE, form);

  const { showScript, customerBalance, translateKey } = useMemo(() => {
    const isSecured = !!account?.securedCardInfo;
    const balanceInfo = account?.balanceInfo || {};

    const showScript = [
      CLOSED_REASON.ANNUAL_FEE,
      CLOSED_REASON.CHANGE_IN_TERMS,
      CLOSED_REASON.DID_NOT_USE,
      CLOSED_REASON.INTEREST_RATE,
      CLOSED_REASON.VOLUNTARY_1,
    ].includes(selectedReasonCode);

    const reasonKey =
      selectedReasonCode === CLOSED_REASON.CHANGE_IN_TERMS
        ? 'changeInTerms'
        : 'default';
    let currentBalance = '--';
    let subKey = isSecured ? 'secured' : 'unsecured';

    if (balanceInfo) {
      currentBalance = formatDollarsFromCents(balanceInfo.currentBalance);
      subKey = `${subKey}${
        balanceInfo.currentBalance > 0
          ? 'BalanceAboveZero'
          : 'BalanceZeroOrBelow'
      }`;
    }

    return {
      showScript: showScript,
      customerBalance: currentBalance,
      translateKey: `closeAccount.${subKey}.${reasonKey}`,
    };
  }, [account, selectedReasonCode]);

  return showScript ? (
    <div style={{ gridColumn: '1/-1' }}>
      <AgentScript
        title="Account Closure"
        translateKey={translateKey}
        values={{
          customerBalance,
        }}
      />
      <Form.Item
        name={FORM_FIELD.CLOSURE_AUTHORIZED}
        valuePropName="checked"
        style={{ gridColumn: '1/-1' }}
        rules={[
          {
            message: 'Please acknowledge customer authorization',
            validator: (_, checked) => {
              return new Promise((resolve, reject) => {
                if (!checked) {
                  reject();
                }
                resolve();
              });
            },
          },
        ]}
      >
        <Checkbox data-testid="authorize-closure-checkbox">
          Acknowledge customer authorization
        </Checkbox>
      </Form.Item>
    </div>
  ) : null;
};

export default ClosedStatusFields;
