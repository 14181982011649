import styled from '@emotion/styled';
import { COLORS } from 'utilities/styles';

const LocationContainer = styled.div`
  padding: var(--spacing-lg);
  margin: 0 calc(var(--spacing-lg) * -1);
  border-top: 1px solid ${COLORS.grey4};
  border-bottom: 1px solid ${COLORS.grey4};
  width: fill-available;
`;

export default LocationContainer;
