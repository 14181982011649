import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, AutoComplete, Button, Checkbox, Form, Input } from 'antd';
import * as antColors from '@ant-design/colors';
import { useLazyQuery, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import dayjs from 'dayjs';

import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAgent from 'hooks/useAgent';
import useAnalytics from 'hooks/useAnalytics';
import useEditFormStatuses from 'hooks/useEditFormStatuses';
import useTimeoutValue from 'hooks/useTimeoutValue';

import { UPDATE_CUSTOMER_ADDRESS } from 'mutations/customerMeta';
import { ADDRESS_SUGGESTIONS } from 'queries/address';
import { CUSTOMER_OVERVIEW } from 'queries/customer';

import {
  ANALYTICS_EVENTS,
  BREAKPOINT,
  ERROR_CODES,
  SIZE,
  STATES_ABBR,
  STATUS,
} from 'utilities/constants';

const AddressGrid = styled.div`
  @media (min-width: ${BREAKPOINT.SM_PX}) {
    display: grid;
    grid-gap: 0 var(--spacing-md);
    grid-template-areas:
      'street1 street1'
      'street2 street2'
      'city    state'
      '. postalCode';
  }

  @media (min-width: ${BREAKPOINT.LG_PX}) {
    display: grid;
    grid-gap: 0 var(--spacing-md);
    grid-template-areas:
      'street1 street1 street1 street1 street2 street2 street2    street2'
      'city    city    state   state   state   state   postalCode postalCode';
  }
`;

const FormItem = styled(Form.Item)`
  grid-area: ${(props) => props.name};
`;

const ForceUpdateContainer = styled(Form.Item, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ active }) => {
  let border = 'var(--border)';
  let backgroundColor = '#FFF';
  if (active) {
    border = antColors.gold[2];
    backgroundColor = antColors.gold[0];
  }

  return `
    margin: 0 auto;
    margin-bottom: var(--spacing-lg);
    border: ${border} 1px solid;
    border-radius: var(--border-radius-base);
    background-color: ${backgroundColor};
    padding: var(--spacing-xs) var(--spacing-md);
  `;
});

const ADDRESS = Object.freeze({
  street1: 'street1',
  street2: 'street2',
  city: 'city',
  state: 'state',
  postalCode: 'postalCode',
  forceUpdate: 'forceUpdate',
});

const STATE_OPTIONS = Object.keys(STATES_ABBR).map((key) => {
  return { value: STATES_ABBR[key].name };
});

const errorContainsCode = (error, errorCode) => {
  return !!error?.graphQLErrors?.find((error) => {
    return error?.extensions.errorCode === errorCode;
  });
};

export const getStateName = (stateAbbr) => {
  return STATES_ABBR[stateAbbr]?.name || '';
};

export const formatSuggestion = (addressInfo) => {
  const { city, secondary, state, street, zip } = addressInfo;
  const street2 = secondary ? secondary + ', ' : '';
  return `${street}, ${street2}${city}, ${state}, ${zip}`;
};

const EditCustomerAddressForm = ({ onSuccess, submitProps }) => {
  const formName = 'customerAddress';

  const agent = useAgent();
  const { enableAddressAutocomplete } = useFlags();
  const { customerId } = useParams();
  const { trackEvent } = useAnalytics();

  const [form] = Form.useForm();
  const [customer, setCustomer] = useState();

  const cityRef = useRef();
  const postalCodeRef = useRef();
  const stateRef = useRef();
  const street2Ref = useRef();

  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [customerTooNew, setCustomerTooNew] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [submissionError, setSubmissionError] = useState('');
  const [submissionWarning, setSubmissionWarning] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useTimeoutValue(
    false,
    3000,
  );

  const {
    containsChanges,
    getFieldStatus,
    initialValues,
    setInitialValues,
    updateFieldStatus,
  } = useEditFormStatuses(form, () => {
    setSubmissionWarning('');
    setForceUpdate(false);
  });

  const [getCustomerMeta, { loading: loadingCustomer }] = useLazyQuery(
    CUSTOMER_OVERVIEW,
    {
      fetchPolicy: 'network-only',
      variables: { customerId },
      onCompleted: (response) => {
        const accounts = response.customer?.accounts || [];
        const sortedAccounts = [...accounts].sort(
          (a1, a2) => Date.parse(a1.createdAt) > Date.parse(a2.createdAt),
        );
        const createdAt = sortedAccounts[0]?.createdAt;
        if (createdAt) {
          const threshold = dayjs(createdAt).add(90, 'day');
          setCustomerTooNew(dayjs().isBefore(threshold));
        }
        setCustomer(response.customer);
      },
    },
  );

  const [getAddressSuggestions, { loading: loadingAddressSuggestions }] =
    useLazyQuery(ADDRESS_SUGGESTIONS, {
      onCompleted: (data) => {
        const suggestions = data?.findAddress || [];
        setAddressSuggestions(
          suggestions.map((suggestion, index) => {
            return {
              label: formatSuggestion(suggestion),
              value: `${index}`,
              suggestion,
            };
          }),
        );
      },
    });

  const [updateAddress, { loading: updatingAddress }] = useMutation(
    UPDATE_CUSTOMER_ADDRESS,
    {
      refetchQueries: ['GetCustomerOverview'],
      onCompleted: () => {
        setSubmissionSuccess(true);
        getCustomerMeta();
        onSuccess && onSuccess();
      },
      onError: (error) => {
        const addressNotRecognized = errorContainsCode(
          error,
          ERROR_CODES.INVALID_ADDR_NO_MATCHES,
        );

        const addressInvalidSpelling = errorContainsCode(
          error,
          ERROR_CODES.INVALID_ADDR_SPELLING,
        );

        let errorMessage = error.message;
        if (addressNotRecognized) {
          errorMessage =
            'Address not recognized. Please consult escalations for a force update.';
        } else if (addressInvalidSpelling) {
          errorMessage =
            'Please double check spelling before consulting escalations for a force update.';
        }

        setSubmissionError(errorMessage);
      },
    },
  );

  const handleStreetChange = useCallback(
    _debounce((value) => {
      if (value && value.length >= 3) {
        getAddressSuggestions({
          variables: {
            address: value,
          },
        });
      } else {
        setAddressSuggestions([]);
      }
    }, 300),
    [],
  );

  const fillAddressFields = (value, option) => {
    const { suggestion } = option;
    if (suggestion) {
      const { city, secondary, state, street, zip } = suggestion;
      form.setFieldsValue({
        [ADDRESS.city]: city,
        [ADDRESS.postalCode]: zip,
        [ADDRESS.state]: getStateName(state),
        [ADDRESS.street1]: street,
        [ADDRESS.street2]: secondary,
      });

      // Awkward, but need to force field status check on anything that may have an updated value
      setTimeout(() => {
        street2Ref.current?.focus();
        cityRef.current?.focus();
        stateRef.current?.focus();
        postalCodeRef.current?.focus();
        postalCodeRef.current?.blur();
      }, 200);
    }
  };

  const resetFormValues = () => {
    const address = _get(customer, 'contactInfo.address', {});
    setInitialValues({
      [ADDRESS.city]: address.city,
      [ADDRESS.postalCode]: address.postalCode?.slice(0, 5) || '',
      [ADDRESS.state]: getStateName(address.state),
      [ADDRESS.street1]: address.street1,
      [ADDRESS.street2]: address.street2,
    });

    setForceUpdate(false);
  };

  const handleSubmit = async () => {
    setSubmissionError('');

    const formContainsErrors = form
      .getFieldsError()
      .some((field) => field.errors.length);

    if (formContainsErrors) {
      setSubmissionWarning(
        'Some fields have not passed validation. Please resolve any errors.',
      );
      return;
    } else {
      setSubmissionWarning('');
    }

    if (!containsChanges) {
      setSubmissionWarning(
        "You haven't made any updates to the customers address.",
      );
      return;
    }

    const stateValue = form.getFieldValue(ADDRESS.state);
    const stateAbbr = Object.keys(STATES_ABBR).find((key) => {
      return STATES_ABBR[key].name === stateValue;
    });

    trackEvent(ANALYTICS_EVENTS.ADDRESS_EDIT_SAVED);
    forceUpdate && trackEvent(ANALYTICS_EVENTS.ADDRESS_EDIT_FORCED);

    const variables = {
      customerId: customer.id,
      street1: form.getFieldValue(ADDRESS.street1),
      street2: form.getFieldValue(ADDRESS.street2),
      city: form.getFieldValue(ADDRESS.city),
      state: stateAbbr,
      postalCode: form.getFieldValue(ADDRESS.postalCode),
      overrideAddressValidation: forceUpdate,
    };
    updateAddress({
      variables,
    });
  };

  useEffect(() => {
    resetFormValues();
  }, [customer]);

  useEffect(() => {
    getCustomerMeta();
  }, []);

  const formDisabled = updatingAddress || loadingCustomer;
  const street2ForbiddenValues = ['same', 'n/a', 'none'];

  return (
    <Form
      form={form}
      name={formName}
      layout="vertical"
      initialValues={initialValues}
      onFieldsChange={updateFieldStatus}
      onFinish={handleSubmit}
      style={{ position: 'relative' }}
    >
      {customerTooNew ? (
        <Alert
          banner
          showIcon={true}
          type="warning"
          message="Caller has been a customer less than 90 days, supporting documents are required before proceeding with updating the address"
          style={{ marginBottom: 'var(--spacing-md)' }}
        />
      ) : null}
      <AddressGrid>
        <SectionMessage
          data-testid="address-success-message"
          status={STATUS.SUCCESS}
          size={SIZE.SM}
          text="Address Updated"
          cover={true}
          visible={submissionSuccess}
        ></SectionMessage>
        <FormItem
          label="Street 1"
          name={ADDRESS.street1}
          rules={[{ required: true, message: 'Street 1 is required' }]}
        >
          {enableAddressAutocomplete ? (
            <AutoComplete
              data-testid="street1-input"
              disabled={formDisabled}
              onChange={handleStreetChange}
              onSelect={fillAddressFields}
              options={addressSuggestions}
              loading={loadingAddressSuggestions}
              status={getFieldStatus(ADDRESS.street1)}
            />
          ) : (
            <Input
              data-testid="street1-input"
              disabled={formDisabled}
              status={getFieldStatus(ADDRESS.street1)}
            />
          )}
        </FormItem>
        <FormItem
          label="Street 2"
          extra="(Apt/Suite #)"
          name={ADDRESS.street2}
          rules={[
            () => ({
              validator(_, value) {
                if (street2ForbiddenValues.includes(value.toLowerCase())) {
                  return Promise.reject(new Error('That value is not allowed'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            placeholder="Leave blank if not applicable"
            data-testid="street2-input"
            disabled={formDisabled}
            ref={street2Ref}
            status={getFieldStatus(ADDRESS.street2)}
          />
        </FormItem>
        <FormItem
          label="City"
          name={ADDRESS.city}
          rules={[{ required: true, message: 'City is required' }]}
        >
          <Input
            data-testid="city-input"
            disabled={formDisabled}
            ref={cityRef}
            status={getFieldStatus(ADDRESS.city)}
          />
        </FormItem>
        <FormItem
          label="State"
          name={ADDRESS.state}
          rules={[{ required: true, message: 'State is required' }]}
        >
          <AutoComplete
            data-testid="state-input"
            disabled={formDisabled}
            ref={stateRef}
            options={STATE_OPTIONS}
            status={getFieldStatus(ADDRESS.state)}
            filterOption={(inputValue, option) => {
              return (
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              );
            }}
          />
        </FormItem>
        <FormItem
          label="Postal Code"
          name={ADDRESS.postalCode}
          rules={[
            {
              message: 'Postal code should be at least 5 characters long',
              min: 5,
              required: true,
            },
          ]}
        >
          <Input
            data-testid="postal-code-input"
            disabled={formDisabled}
            maxLength={5}
            ref={postalCodeRef}
            status={getFieldStatus(ADDRESS.postalCode)}
          />
        </FormItem>
      </AddressGrid>

      {submissionWarning ? (
        <Alert
          data-testid="address-submission-warning"
          banner
          type="warning"
          message={submissionWarning}
          style={{ marginBottom: 'var(--spacing-md)' }}
        ></Alert>
      ) : null}

      {submissionError ? (
        <Alert
          data-testid="address-failure-message"
          banner
          type="error"
          message={submissionError}
          style={{ marginBottom: 'var(--spacing-md)' }}
        ></Alert>
      ) : null}

      {agent.isSuperUser ? (
        <ForceUpdateContainer active={forceUpdate}>
          <Checkbox
            data-testid="force-update-checkbox"
            disabled={formDisabled}
            checked={forceUpdate}
            onChange={(e) => setForceUpdate(e.target.checked)}
          >
            Force address update. Once you have verified with the customer and{' '}
            <a
              href="https://tools.usps.com/zip-code-lookup.htm?byaddress"
              target="_blank"
              rel="noreferrer"
            >
              USPS
            </a>{' '}
            that it exists you may check this box to force an update.
          </Checkbox>
        </ForceUpdateContainer>
      ) : null}

      <Button type="primary" htmlType="submit">
        Update Address
      </Button>
    </Form>
  );
};

export default EditCustomerAddressForm;
