import { Button, Flex, Skeleton, Typography } from 'antd';
import { CopyOutlined, LikeOutlined } from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from '@emotion/styled';

const AccountTitleContainer = styled('div')(({ color, level }) => {
  let skeletonHeight = `var(--heading-height-${level})`;

  return `
    background-color: ${color}; 
    margin: calc(var(--spacing-lg) * -1);
    margin-bottom: 0;
    padding: var(--spacing-sm) var(--spacing-lg);
    color: white;
    .ant-typography {
      color: white !important;
      margin: 0;
    }
    .ant-skeleton-title {
      margin: 0;
      height: ${skeletonHeight};
    }
    .tsys-id {
      line-height: ${skeletonHeight};
    }
  `;
});

const CopyButton = styled(Button)`
  padding: 0px 2px;
  height: max-content;
`;

const CopyIcon = () => {
  return (
    <CopyButton>
      <CopyOutlined />
    </CopyButton>
  );
};

const AccountTitle = ({
  children,
  color,
  level = 5,
  loading,
  tsysAccountId,
  tsysCustomerId,
  cardLast4,
}) => {
  const { showTsysCustomerId } = useFlags();
  const showCopyIcon = cardLast4
    ? {
        text: `${cardLast4}`,
        tooltips: [
          'Copy Card Last 4',
          <span key="copied-message">
            Copied <LikeOutlined />
          </span>,
        ],
        icon: [
          <CopyIcon key="copy-icon" />,
          <CopyIcon key="copied-icon" disabled={true} />,
        ],
      }
    : false;

  return (
    <AccountTitleContainer color={color} level={level}>
      <Flex justify="space-between" gap="var(--spacing-md)">
        {loading ? (
          <Skeleton loading={true} active={true} paragraph={false}></Skeleton>
        ) : (
          <Typography.Title level={level} copyable={showCopyIcon}>
            {children}
          </Typography.Title>
        )}
        <Flex gap={'var(--spacing-sm)'} align="flex-end">
          {tsysAccountId ? (
            <Typography.Text
              className="tsys-account-id"
              copyable={{
                text: `${tsysAccountId}`,
                tooltips: [
                  'Copy',
                  <span key="copied-message">
                    Copied <LikeOutlined />
                  </span>,
                ],
                icon: [
                  <CopyIcon key="copy-icon" />,
                  <CopyIcon key="copied-icon" disabled={true} />,
                ],
              }}
            >
              TSYS AccountID: {tsysAccountId}
            </Typography.Text>
          ) : null}
          {showTsysCustomerId && tsysCustomerId ? (
            <Typography.Text
              className="tsys-customer-id"
              copyable={{
                text: `${tsysCustomerId}`,
                tooltips: [
                  'Copy',
                  <span key="copied-message">
                    Copied <LikeOutlined />
                  </span>,
                ],
                icon: [
                  <CopyIcon key="copy-icon" />,
                  <CopyIcon key="copied-icon" disabled={true} />,
                ],
              }}
            >
              TSYS CustomerID: {tsysCustomerId}
            </Typography.Text>
          ) : null}
        </Flex>
      </Flex>
    </AccountTitleContainer>
  );
};

export default AccountTitle;
