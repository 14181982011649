import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Alert, Divider, Flex, Radio, Modal, Space } from 'antd';

import Access from 'components/Access/Access';
import SectionMessage from 'components/SectionMessage/SectionMessage';

import useAnalytics from 'hooks/useAnalytics';
import useCeaseAndDesistStatus from 'hooks/useCeaseAndDesistStatus';
import useTimeoutValue from 'hooks/useTimeoutValue';
import useCustomerInfo from 'hooks/useCustomerInfo';

import { SEND_AUTOMATED_ZENDESK_MESSAGE } from 'mutations/automatedMessaging';
import {
  CUSTOMER_PUBLISH_COMMS,
  SEND_PASSWORD_RESET_EMAIL,
} from 'mutations/customerMeta';

import {
  AGENT_ROLES,
  ANALYTICS_EVENTS,
  STATUS,
  SIZE,
  ZENDESK_MACROS,
} from 'utilities/constants';

const EMAIL_TYPE = Object.freeze({
  ADMIN_PASSWORD_RESET: 'password-reset',
  CARD_CALL_ATTEMPTED: 'card-call-attempted',
  CARD_PAYMENT_HOW_TO: 'card-payment-how-to',
  CONTACT_INFO_REQUEST: 'contact-info-request',
  GENERIC_PASSWORD_RESET: 'generic-password-reset',
});

const SendEmailModal = ({ hide, ...modalProps }) => {
  const { customerInfo } = useCustomerInfo();
  const { trackEvent } = useAnalytics();
  const [emailType, setEmailType] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [successMessage, setSuccessMessage] = useTimeoutValue('', 3000);
  const [errorMessage, setErrorMessage] = useState('');
  const { ceaseAndDesistEnabled } = useCeaseAndDesistStatus();
  const disableInput = ceaseAndDesistEnabled || sendingEmail || successMessage;

  const [sendCommsEmail] = useMutation(CUSTOMER_PUBLISH_COMMS);
  const [sendRecoveryEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);
  const [sendZendeskCommunication] = useMutation(
    SEND_AUTOMATED_ZENDESK_MESSAGE,
  );

  const sendBasicEmail = async () => {
    try {
      trackEvent(ANALYTICS_EVENTS.AUTOMATED_EMAIL_SEND, {
        emailType,
      });
      if (emailType === EMAIL_TYPE.ADMIN_PASSWORD_RESET) {
        await sendRecoveryEmail({ variables: { customerId: customerInfo.id } });
      } else {
        await sendCommsEmail({
          variables: {
            customerId: customerInfo.id,
            firstName: customerInfo.firstName,
            lastName: customerInfo.lastName,
            email: customerInfo.email,
            phoneNumber: customerInfo.phoneNumber,
            template: emailType,
          },
        });
      }

      let successType = '';
      switch (emailType) {
        case EMAIL_TYPE.ADMIN_PASSWORD_RESET:
          successType = 'password reset';
          break;
        case EMAIL_TYPE.CARD_CALL_ATTEMPTED:
          successType = 'call attempted';
          break;
        case EMAIL_TYPE.CARD_PAYMENT_HOW_TO:
          successType = 'payment information';
          break;
        case EMAIL_TYPE.CONTACT_INFO_REQUEST:
          successType = 'contact info request';
          break;
        case EMAIL_TYPE.GENERIC_PASSWORD_RESET:
          successType = 'self-service password reset';
          break;
        default:
      }
      setSuccessMessage(
        `Successfully sent ${successType + ' '}email to customer.`,
      );
      setTimeout(hide, 3000);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const sendZendeskEmail = async (zendeskMacro) => {
    try {
      trackEvent(ANALYTICS_EVENTS.AUTOMATED_EMAIL_SEND, {
        emailType: zendeskMacro,
      });
      const response = await sendZendeskCommunication({
        variables: {
          customerId: customerInfo.id,
          email: customerInfo.email,
          firstName: customerInfo.firstName,
          lastName: customerInfo.lastName,
          macroId: zendeskMacro.id,
        },
        refetchQueries: ['NotesInteractionsZendeskRecords'],
      });

      setSuccessMessage(
        <>
          Successfully sent {zendeskMacro.title} email to customer.
          <br />
          <a target="zendesk_tab" href={response?.data?.sendCommunication?.url}>
            Zendesk Ticket
          </a>
        </>,
      );
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleSendEmail = async () => {
    setErrorMessage('');
    setSendingEmail(true);

    const zendeskMacro = ZENDESK_MACROS.find((macro) => macro.id === emailType);

    if (zendeskMacro) {
      await sendZendeskEmail(zendeskMacro);
    } else {
      await sendBasicEmail();
    }

    setSendingEmail(false);
  };

  return (
    <Modal
      {...modalProps}
      confirmLoading={sendingEmail}
      onOk={handleSendEmail}
      okText="Send"
      okButtonProps={{ disabled: disableInput }}
      title="Send Automated Email"
    >
      <Flex vertical={true} gap="var(--spacing-lg)">
        {ceaseAndDesistEnabled ? (
          <Alert
            data-testid="cease-and-desist-message"
            message={'Cease and Desist'}
            type="error"
          />
        ) : null}
        <div style={{ position: 'relative' }}>
          <SectionMessage
            data-testid="send-success-message"
            status={STATUS.SUCCESS}
            size={SIZE.SM}
            cover={true}
            visible={!!successMessage}
            text={successMessage}
          />
          <Radio.Group
            onChange={(event) => setEmailType(event.target.value)}
            radioValue={emailType}
          >
            <Space direction="vertical">
              <Radio
                data-testid="call-attempted-radio"
                disabled={disableInput}
                value={EMAIL_TYPE.CARD_CALL_ATTEMPTED}
              >
                Call Attempted
              </Radio>
              <Radio
                data-testid="contact-info-request-radio"
                disabled={disableInput}
                value={EMAIL_TYPE.CONTACT_INFO_REQUEST}
              >
                Contact Info Request
              </Radio>
              <Radio
                data-testid="payment-instructions-radio"
                disabled={disableInput}
                value={EMAIL_TYPE.CARD_PAYMENT_HOW_TO}
              >
                Web Payment Instructions (Post Call)
              </Radio>
              <Radio
                data-testid="self-service-password-radio"
                disabled={disableInput}
                value={EMAIL_TYPE.GENERIC_PASSWORD_RESET}
              >
                Self-Service Password Reset
              </Radio>
              <Access roles={[AGENT_ROLES.SUPER_USER]}>
                <Radio
                  data-testid="admin-password-radio"
                  disabled={disableInput}
                  value={EMAIL_TYPE.ADMIN_PASSWORD_RESET}
                >
                  Admin Password Reset
                </Radio>
              </Access>
            </Space>
            <Divider orientation="left">Zendesk Macros</Divider>
            <Space direction="vertical">
              {ZENDESK_MACROS.map((item) => (
                <Radio
                  data-testid={`zendesk-radio-${item.id}`}
                  disabled={disableInput}
                  key={`zendesk-radio-${item.id}`}
                  value={item.id}
                >
                  {item.title}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>

        {errorMessage ? (
          <Alert
            data-testid="send-failure-message"
            message={errorMessage}
            type="error"
          />
        ) : null}
      </Flex>
    </Modal>
  );
};

SendEmailModal.EMAIL_TYPE = EMAIL_TYPE;

export default SendEmailModal;
