import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import useAgent from './useAgent';
import useAnalytics from './useAnalytics';
import useTimeoutValue from './useTimeoutValue';

import client from 'apollo/client';
import { ADD_AGENT_AUDIT_LOG_FOR_CUSTOMER_SSN_MUTATION } from 'mutations/addAgentAuditLog';
import { CUSTOMER_OVERVIEW } from 'queries/customer';
import { GET_FULL_SSN } from 'queries/fullSsn';
import { ANALYTICS_EVENTS } from 'utilities/constants';

const useCustomerFullSsn = () => {
  const { trackEvent } = useAnalytics();
  const agent = useAgent();
  const { customerId } = useParams();
  const [fullSsn, setFullSsn] = useState('');
  const [failureMessage, setFailureMessage] = useTimeoutValue('', 5000);

  const [addAuditLogForFullSsn, auditLogQueryStatus] = useMutation(
    ADD_AGENT_AUDIT_LOG_FOR_CUSTOMER_SSN_MUTATION,
  );

  let [getFullSsn, fullSsnQueryStatus] = useLazyQuery(GET_FULL_SSN, {
    fetchPolicy: 'network-only',
    onError: () => {
      setFailureMessage('Failed to get full SSN.');
    },
    onCompleted: (data) => {
      setFullSsn(data.fullSsn?.fullSsn);
    },
  });

  let requestFullSsn = async () => {
    if (!fullSsn) {
      try {
        trackEvent(ANALYTICS_EVENTS.FULL_SSN_REQUESTED);
        try {
          await addAuditLogForFullSsn({
            variables: {
              customerId,
              agentId: agent?.user?.requester_id,
              agentName: agent?.user?.name,
            },
          });
        } catch (error) {
          setFailureMessage(
            'Failed to save audit log for full SSN request. unable to get full SSN.',
          );
          return;
        }

        getFullSsn({ variables: { customerId } });
      } catch (error) {
        console.error(error);
      }
    }
  };

  let reset = () => {
    setFullSsn('');
  };

  /**
   *  Handles two reset cases:
   *  1: customerId changes
   *  2: customer overview data has been refetched
   */
  useEffect(() => {
    reset();
    if (customerId) {
      let subscription = client
        .watchQuery({
          query: CUSTOMER_OVERVIEW,
          variables: { customerId },
        })
        .subscribe(() => {
          reset();
        });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [customerId]);

  return [
    fullSsn,
    requestFullSsn,
    failureMessage,
    Object.assign({}, fullSsnQueryStatus, auditLogQueryStatus),
  ];
};

export default useCustomerFullSsn;
