import { useEffect, useState } from 'react';
import {
  Alert,
  Breadcrumb,
  Button,
  Divider,
  Flex,
  List,
  Tooltip,
  Typography,
} from 'antd';
import { EditOutlined, LeftOutlined } from '@ant-design/icons';

import ThirdPartyEmailModal from './ThirdPartyEmailModal';
import { RecoveriesGrid } from './RecoveriesSection';
import ManagedByThirdPartyAlert from './ManagedByThirdPartyAlert';
import {
  useRecoveriesState,
  goToPreviousStep,
  resetRecoveriesState,
  PAYMENT_PLAN_PROGRESS_STEPS,
  ONE_TIME_DEBIT_PROGRESS_STEPS,
} from './recoveriesState';
import useModalControls from 'hooks/useModalControls';

const { Title } = Typography;

const OfferSetup = ({
  renderStep,
  thirdParty,
  hasInitiatedSettlementOfferFromAppWeb,
}) => {
  const thirdPartyEmailModal = useModalControls();
  const { currentStep, previousStep, stepIndex, acceptedOffer } =
    useRecoveriesState();

  const [progressListDataSource, setProgressListDataSource] = useState([]);

  const getProgressListDataSource = () => {
    if (acceptedOffer?.offerType?.includes('OneTimeDebit')) {
      return setProgressListDataSource(ONE_TIME_DEBIT_PROGRESS_STEPS);
    }

    return setProgressListDataSource(PAYMENT_PLAN_PROGRESS_STEPS);
  };

  useEffect(() => {
    getProgressListDataSource();
  }, [acceptedOffer]);

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 'var(--spacing-lg)' }}
        items={[{ title: 'Recoveries Home' }, { title: currentStep.title }]}
      />

      {hasInitiatedSettlementOfferFromAppWeb && (
        <Alert
          banner
          type="warning"
          showIcon
          style={{
            marginBottom: 'var(--spacing-md)',
            width: 725,
          }}
          message="Note: The customer has already made some progress towards accepting an offer. 
        Selecting an offer here will replace that offer and the customer will not be able to access it again."
        />
      )}

      {thirdParty.error && (
        <Alert
          banner
          type="error"
          message="Failed to retrieve 3rd party info."
          style={{ marginBottom: 'var(--spacing-sm)', width: 725 }}
        />
      )}
      {thirdParty.email && (
        <ManagedByThirdPartyAlert
          showThirdPartyEmailModal={thirdPartyEmailModal.show}
          width={350}
        />
      )}
      <ThirdPartyEmailModal {...thirdPartyEmailModal} thirdParty={thirdParty} />

      <RecoveriesGrid>
        {acceptedOffer?.balance && (
          <Flex>
            <Flex vertical={true}>
              <div>
                <Flex justify="space-between" align="baseline">
                  <Title level={5}>Program Set Up Progress </Title>
                  <Tooltip title="Change Selected Offer">
                    <Button
                      icon={<EditOutlined />}
                      type="text"
                      onClick={() => resetRecoveriesState()}
                    >
                      Change Offer
                    </Button>
                  </Tooltip>
                </Flex>
                <List
                  dataSource={progressListDataSource}
                  renderItem={(step, index) => {
                    return (
                      <List.Item>
                        {index >= stepIndex
                          ? step.renderPreprocess()
                          : step.renderPostprocess()}
                      </List.Item>
                    );
                  }}
                ></List>
              </div>
            </Flex>
            <Divider
              type="vertical"
              style={{ height: '100%', margin: '0 var(--spacing-md)' }}
            />
          </Flex>
        )}
        <div>{renderStep()}</div>
        {previousStep && (
          <div>
            <Button
              type="link"
              icon={<LeftOutlined />}
              onClick={goToPreviousStep}
              style={{ marginTop: 'var(--spacing-lg)', float: 'left' }}
            >
              {previousStep.title}
            </Button>
          </div>
        )}
      </RecoveriesGrid>
    </>
  );
};

export default OfferSetup;
