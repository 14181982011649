import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Form, Radio, Space, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';

import StepContainer from '../StepContainer';
import ApplyStatusModal from '../AccountStatusSection/ApplyStatusModal/ApplyStatusModal';
import TransactionTable from '../TransactionTable/TransactionTable';
import {
  setNextStep,
  setFraudulentActivityStatus,
  useSendCardState,
  resetSendCardState,
  FRAUDULENT_ACTIVITY_STATUS,
  SEND_CARD_STEPS,
} from './sendCardState';

import DisplayValue from 'components/DisplayValue/DisplayValue';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useAgent from 'hooks/useAgent';
import useAnalytics from 'hooks/useAnalytics';
import useModalControls from 'hooks/useModalControls';
import { TRANSACTIONS } from 'queries/transactions';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { formatTransactionData } from 'utilities/helpers';
import { ANALYTICS_EVENTS, TRANSACTION_TYPE } from 'utilities/constants';

const { Paragraph, Title } = Typography;

const FraudReviewStep = () => {
  const [fraudReviewForm] = Form.useForm();
  const agent = useAgent();
  const { isFDR, isFDI } = useFlags();
  const { accountId, customerId } = useParams();
  const { trackEvent } = useAnalytics();
  const applyStatusModal = useModalControls();
  const [transactions, setTransactions] = useState([]);
  const { fraudulentActivityStatus } = useSendCardState();
  const dateRange = useMemo(() => {
    return { from: dayjs().subtract(6, 'month'), to: dayjs() };
  }, []);

  const canBypassConfirmedFraud = isFDR || isFDI || agent.isSuperUser;

  const fraudulentActivityPresent =
    fraudulentActivityStatus === FRAUDULENT_ACTIVITY_STATUS.YES;

  const transactionsQuery = useQuery(TRANSACTIONS, {
    variables: {
      accountId,
      customerId,
      fromDate: formatDateToSubmit(dateRange.from),
      toDate: formatDateToSubmit(dateRange.to),
    },
    onCompleted: (data) => {
      const filterTypes = [
        TRANSACTION_TYPE.AUTHORIZATION,
        TRANSACTION_TYPE.CREDIT,
        TRANSACTION_TYPE.PURCHASE,
      ];
      const filteredTransactions = data.account.activities.filter(
        (transaction) => {
          return filterTypes.includes(transaction.type);
        },
      );
      setTransactions(
        formatTransactionData(
          filteredTransactions,
          dateRange.from,
          dateRange.to,
        ),
      );
    },
  });

  const handleContinue = async () => {
    try {
      await fraudReviewForm.validateFields();
    } catch {
      return;
    }

    setNextStep(SEND_CARD_STEPS.ACCOUNT_STANDING);
  };

  const handleSelection = (event) => {
    const fraudStatus = event.target.value;
    setFraudulentActivityStatus(fraudStatus);
    trackEvent(ANALYTICS_EVENTS.SEND_CARD_FRAUD_REVIEW, {
      customerId,
      accountId,
      fraudulentActivityPresent: fraudStatus,
    });
  };

  return (
    <StepContainer
      title="Fraudulent Activity Review"
      onContinue={handleContinue}
      onExit={resetSendCardState}
      buttonProps={{
        disabled: fraudulentActivityPresent,
      }}
    >
      <ApplyStatusModal {...applyStatusModal} />
      <Form
        form={fraudReviewForm}
        name="fraudReview"
        layout="vertical"
        initialValues={{ fraudulentActivityStatus }}
      >
        <QueryBoundary
          query={transactionsQuery}
          mode={QueryBoundary.MODE.MESSAGE}
          loadingMessage="Loading Transactions"
        >
          <Paragraph>
            Review the following transactions with the customer for any signs of
            fraudulent activity:
          </Paragraph>

          <div style={{ marginBottom: 'var(--spacing-lg)' }}>
            <TransactionTable
              transactions={transactions}
              length={5}
              scrollY={300}
            />
          </div>

          <Form.Item
            label="Have you identified any fraudulent activity?"
            name="fraudulentActivityStatus"
            rules={[{ required: true, message: 'Please make a selection' }]}
          >
            <Radio.Group
              onChange={handleSelection}
              value={fraudulentActivityStatus}
            >
              <Space direction="vertical">
                <Radio
                  data-testid="no-fraud-radio"
                  value={FRAUDULENT_ACTIVITY_STATUS.NO}
                >
                  No fraudulent activity is present.
                </Radio>
                <Radio
                  data-testid="fraud-present-radio"
                  value={FRAUDULENT_ACTIVITY_STATUS.YES}
                >
                  Yes there is fraudulent activity.
                </Radio>
                {canBypassConfirmedFraud ? (
                  <Radio
                    data-testid="fraud-reported-radio"
                    value={FRAUDULENT_ACTIVITY_STATUS.REPORTED}
                  >
                    Yes, there is confirmed fraud, and it has already been
                    reported.
                  </Radio>
                ) : null}
              </Space>
            </Radio.Group>
          </Form.Item>
          {fraudulentActivityPresent ? (
            <>
              <Divider></Divider>
              <Title level={5}>Forward Customer to FDI</Title>
              <Button
                data-testid="apply-fraud-status-button"
                onClick={applyStatusModal.show}
              >
                Apply Status
              </Button>
              <div style={{ padding: 'var(--spacing-md)' }}>
                <DisplayValue
                  label="FDI Phone Number"
                  canCopy={true}
                  copiedValue="1-866-335-2390"
                >
                  1-866-335-2390
                </DisplayValue>
              </div>
            </>
          ) : null}
        </QueryBoundary>
      </Form>
    </StepContainer>
  );
};

export default FraudReviewStep;
