import { gql } from '@apollo/client';

const ADD_FUNDING_ACCOUNT = gql`
  mutation AddFundingAccount(
    $customerId: String
    $accountNumber: String!
    $routingNumber: String!
    $customizedName: String
    $status: FundingAccountStatus
    $channel: FundingAccountChannel
  ) {
    addFundingAccount(
      customerId: $customerId
      accountNumber: $accountNumber
      routingNumber: $routingNumber
      customizedName: $customizedName
      status: $status
      channel: $channel
    ) {
      id
      bankName
      isDefault
      numberLast4
      routingNumber
      status
      createdDate
    }
  }
`;

const EDIT_FUNDING_ACCOUNT = gql`
  mutation EditFundingAccount(
    $customerId: String
    $fundingAccountId: String!
    $status: FundingAccountStatus
    $customizedName: String
    $channel: FundingAccountChannel
  ) {
    patchFundingAccount(
      customerId: $customerId
      fundingAccountId: $fundingAccountId
      status: $status
      customizedName: $customizedName
      channel: $channel
    ) {
      id
      bankName
      isDefault
      numberLast4
      routingNumber
      status
      createdDate
    }
  }
`;

const SET_DEFAULT_FUNDING_ACCOUNT = gql`
  mutation SetFundingAccountDefault(
    $customerId: String
    $fundingAccountId: String!
    $requestedBy: String
    $holderName: String
    $channel: FundingAccountChannel
  ) {
    setDefaultFundingAccount(
      customerId: $customerId
      fundingAccountId: $fundingAccountId
      requestedBy: $requestedBy
      holderName: $holderName
      channel: $channel
    ) {
      id
      bankName
      isDefault
      numberLast4
      routingNumber
    }
  }
`;

const DISABLE_FUNDING_ACCOUNT = gql`
  mutation DisableFundingAccount(
    $customerId: String
    $fundingAccountId: String!
    $requestedBy: String
    $holderName: String
    $channel: FundingAccountChannel
  ) {
    disableFundingAccount(
      customerId: $customerId
      fundingAccountId: $fundingAccountId
      requestedBy: $requestedBy
      holderName: $holderName
      channel: $channel
    ) {
      id
      bankName
      isDefault
      numberLast4
      routingNumber
    }
  }
`;

export {
  ADD_FUNDING_ACCOUNT,
  EDIT_FUNDING_ACCOUNT,
  SET_DEFAULT_FUNDING_ACCOUNT,
  DISABLE_FUNDING_ACCOUNT,
};
