import { Modal, Table, Typography } from 'antd';

import { formatDollarsFromCents } from 'utilities/helpers';
import { COLORS } from 'utilities/styles';

const getEligibilityColor = (isEligible) => {
  if (isEligible === true) {
    return COLORS.green1;
  }
  if (isEligible === false) {
    return COLORS.brick1;
  }
  return undefined;
};

const getEligibilityText = (isEligible) => {
  if (isEligible === true) {
    return 'Pass';
  }
  if (isEligible === false) {
    return 'Fail';
  }
  return '--';
};

const OfferEligibilityModal = ({
  eligibilityChecks,
  eligible,
  offer,
  reAgeAmount,
  ...modalProps
}) => {
  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (value) => <Typography.Text strong>{value}</Typography.Text>,
    },
    {
      dataIndex: 'pass',
      key: 'pass',
      width: '10%',
      render: (isEligible) => {
        return (
          <Typography.Text
            strong
            style={{ color: getEligibilityColor(isEligible) }}
          >
            {getEligibilityText(isEligible)}
          </Typography.Text>
        );
      },
    },
  ];

  const dataSource = eligibilityChecks?.map((check) => {
    return { ...check, key: check.name };
  });

  return (
    <Modal
      {...modalProps}
      centered
      title={`${offer} Eligibility Criteria`}
      width={550}
      cancelButtonProps={{ hidden: true }}
    >
      <div style={{ marginBottom: 'var(--spacing-md)' }}>
        <Typography.Paragraph strong>
          Eligibility:{' '}
          <Typography.Text style={{ color: getEligibilityColor(eligible) }}>
            {eligible ? 'Eligible' : 'Ineligible'}
          </Typography.Text>
        </Typography.Paragraph>
        {eligible && reAgeAmount && (
          <Typography.Text strong>
            Remaining amount to pay: {formatDollarsFromCents(reAgeAmount)}
          </Typography.Text>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered={true}
        size="small"
        pagination={false}
        showHeader={false}
      />
    </Modal>
  );
};

export default OfferEligibilityModal;
