import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Button, Divider, Flex, List, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import {
  useSendCardState,
  goToPreviousStep,
  resetSendCardState,
} from './sendCardState';

import QueryBoundary from 'components/QueryBoundary/QueryBoundary';
import SectionMessage from 'components/SectionMessage/SectionMessage';
import useAccountQuery from 'hooks/useAccountQuery';
import { STATUS } from 'utilities/constants';

const { Title } = Typography;

const SendCardGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 3fr 0fr 9fr;
  grid-column-gap: var(--spacing-md);
`;

const SendCardSection = () => {
  const { currentStep, previousStep, submissionInitiated, steps } =
    useSendCardState();
  const { accountId, customerId } = useParams();
  const { accountQuery, account, contactInfo, simpleAccount } = useAccountQuery(
    customerId,
    accountId,
  );

  const disableFlowMessage = useMemo(() => {
    if (submissionInitiated) {
      return;
    }

    const createdAt = simpleAccount?.createdAt;
    const reissueDate = account?.cardDetails?.lastCardRequestDate;

    if (createdAt && dayjs().diff(createdAt, 'hour') < 24) {
      return 'This account is less than 24 hours old';
    }

    if (reissueDate && dayjs().diff(reissueDate, 'hour') < 24) {
      return 'Card requested within the last 24 hours';
    }
  }, [account, simpleAccount, submissionInitiated]);

  // Replace the use of contactInfo here with useCustomerInfo
  const renderStep = () => {
    const StepComponent = currentStep?.component;
    if (StepComponent) {
      return (
        <StepComponent
          account={account}
          contactInfo={contactInfo}
        ></StepComponent>
      );
    }
  };

  useEffect(() => {
    resetSendCardState();
  }, [accountId, customerId]);

  return (
    <QueryBoundary
      query={accountQuery}
      mode={QueryBoundary.MODE.MESSAGE}
      loadingMessage={'Loading Account Details'}
    >
      {disableFlowMessage ? (
        <SectionMessage
          status={STATUS.WARNING}
          text={disableFlowMessage}
        ></SectionMessage>
      ) : (
        <>
          <Breadcrumb
            style={{ marginBottom: 'var(--spacing-lg)' }}
            items={[{ title: 'Replace Card' }, { title: currentStep.title }]}
          />
          <SendCardGrid>
            <Flex vertical={true} justify="space-between">
              <div>
                <Title level={5}>Replace Card Progress</Title>
                <List
                  dataSource={steps}
                  renderItem={(step, index) => {
                    return (
                      <List.Item>
                        {index === steps.length - 1
                          ? step.renderPreprocess()
                          : step.renderPostprocess()}
                      </List.Item>
                    );
                  }}
                ></List>
              </div>
              {previousStep ? (
                <div style={{ paddingTop: 'var(--spacing-md)' }}>
                  <Button
                    type="link"
                    icon={<LeftOutlined />}
                    onClick={goToPreviousStep}
                    disabled={submissionInitiated}
                  >
                    {previousStep.title}
                  </Button>
                </div>
              ) : null}
            </Flex>
            <Divider
              type="vertical"
              style={{ height: '100%', margin: '0 var(--spacing-md)' }}
            />
            <div>{renderStep()}</div>
          </SendCardGrid>
        </>
      )}
    </QueryBoundary>
  );
};

export default SendCardSection;
