import { gql } from '@apollo/client';

const REQUEST_CREDIT_BALANCE_REFUND = gql`
  mutation RequestCreditBalanceRefund($accountId: String!) {
    requestCreditBalanceRefund(accountId: $accountId) {
      success
    }
  }
`;

export { REQUEST_CREDIT_BALANCE_REFUND };
