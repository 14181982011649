import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import typePolicies from './typePolicies';
import config from 'config';

import { ANALYTICS_EVENTS } from 'utilities/constants';

const cache = new InMemoryCache({ typePolicies });

const httpLink = createHttpLink({
  uri: config.GQL_HOST,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const idToken = localStorage.getItem('id_token');
  let gqlHeaders = config.GQL_HEADERS || {};

  // if id token is found in localstorage, add it to the headers
  if (idToken) {
    gqlHeaders['Authorization'] = `Bearer ${idToken}`;
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...gqlHeaders,
    },
  };
});

const trackError = onError(({ graphQLErrors, operation, response }) => {
  if (window.analytics) {
    window.analytics.track(ANALYTICS_EVENTS.GRAPHQL_ERROR, {
      graphQLErrors,
      operation,
      response,
    });
  }
});

const client = new ApolloClient({
  link: authLink.concat(trackError).concat(httpLink),
  cache: cache,
});

export default client;
