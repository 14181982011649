import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Form, Modal } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import RecurringPaymentForm from './RecurringPaymentForm';
import RecurringPaymentDetails from './RecurringPaymentDetails';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import { AUTOPAY } from 'queries/payments';
import {
  CANCEL_RECURRING_PAYMENT,
  CREATE_RECURRING_PAYMENT,
  UPDATE_RECURRING_PAYMENT,
} from 'mutations/payments';

import { getOrdinalSuffix, formatDate } from 'utilities/datesAndTimes';
import { formatDollarsFromCents, getAccountLast4 } from 'utilities/helpers';
import { PAYMENT_PLAN, PAYMENT_PLAN_DISPLAY } from 'utilities/constants';

export const getAuthorizationText = ({
  paymentPlan,
  amount,
  dayOfMonth,
  fundingAccountLast4,
}) => {
  let paymentDescription = PAYMENT_PLAN_DISPLAY[paymentPlan];
  if (paymentPlan === PAYMENT_PLAN.MONTHLY_FIXED) {
    paymentDescription = `${paymentDescription} of ${formatDollarsFromCents(
      amount,
    )}`;
  }

  let selectedDate = dayjs().date(dayOfMonth);
  if (selectedDate.isSameOrBefore(dayjs())) {
    selectedDate = selectedDate.add(1, 'month');
  }

  const formattedDate = formatDate(selectedDate);

  return [
    `To complete your enrollment in automatic payments, we need your authorization to begin automatic payments beginning on ${formattedDate} for ${paymentDescription} from your bank account ending in ${fundingAccountLast4} . We will continue to withdraw these payments on a monthly basis until you cancel or the account is closed with a $0 balance.`,
    'Additional payments made by 5:59pm eastern time the day before your scheduled withdraw date may reduce your scheduled withdrawal amount.',
    'You can modify or cancel your enrollment online or by calling us at (855) 790-8860 at least (1) business day before the scheduled withdrawal date. Mission Lane can cancel this agreement at anytime.',
    'Please confirm your authorization to be enrolled in automatic payments by responding “yes.” This will act as your electronic signature.',
    'We will send you a confirmation email with the terms of your autopay enrollment!',
  ].join();
};

const ErrorAlert = ({ action, error }) => (
  <Alert type="error" message={`Error ${action}: ${error.message}`} />
);

// This component needs to be refactored pretty bad.
// AutopayModal containing the form instance of RecurringPaymentForm just doesn't really make sense
// AutopayModal should just be deciding to display the summary information, or the edit form
// and the form should be handling all submit logic.
// The way things are results in poor separation of concerns and tests are very fragile and unfocused
const AutopayModal = ({ hide, ...modalProps }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recurringPaymentForm] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  const [scheduleAutopayLoading, setScheduleAutopayLoading] = useState(false);
  const [cancelAutopayLoading, setCancelAutopayLoading] = useState(false);

  const { accountId, customerId } = useParams();

  const [getAutopayInfo, autopayQuery] = useLazyQuery(AUTOPAY, {
    onCompleted: (data) => {
      const autopayInfo = data?.account?.autopay[0];
      if (autopayInfo) {
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }
    },
  });

  const [setupRecurringPayment, { error: setupError }] = useMutation(
    CREATE_RECURRING_PAYMENT,
    {
      variables: { customerId, accountId },
      refetchQueries: ['AutopayQuery', 'GetCustomerWithFullAccounts'],
      awaitRefetchQueries: true,
    },
  );

  const [updateRecurringPayment, { error: updateError }] = useMutation(
    UPDATE_RECURRING_PAYMENT,
    {
      variables: { customerId, accountId },
      refetchQueries: ['AutopayQuery', 'GetCustomerWithFullAccounts'],
      awaitRefetchQueries: true,
    },
  );

  const [cancelRecurringPayment, { error: cancelError }] = useMutation(
    CANCEL_RECURRING_PAYMENT,
    {
      refetchQueries: ['AutopayQuery', 'GetCustomerWithFullAccounts'],
    },
  );

  // these should he handled in state and set onComplete
  // but right now if done that way, tests break because of render updates
  // caused in the recurring payments form
  const autopay = autopayQuery?.data?.account?.autopay[0];
  const fundingAccounts = autopayQuery?.data?.fundingAccounts || [];

  const handleSubmit = async () => {
    setScheduleAutopayLoading(true);
    try {
      let values = await recurringPaymentForm.validateFields();

      let amount = 0;
      if (values.paymentPlan === PAYMENT_PLAN.MONTHLY_FIXED) {
        amount = values.fixedAmount * 100;
      }

      const authorizationText = getAuthorizationText({
        amount,
        dayOfMonth: values.paymentDayOfMonth,
        paymentPlan: values.paymentPlan,
        fundingAccountLast4: getAccountLast4(
          fundingAccounts,
          values.fundingAccount,
        ),
      });

      const variables = {
        paymentType: values.paymentPlan,
        dayOfMonth: values.paymentDayOfMonth,
        fundingAccountId: values.fundingAccount,
        authorizationText,
        amount,
      };
      if (autopay) {
        await updateRecurringPayment({
          variables: { ...variables, id: autopay.id },
        });
      } else {
        await setupRecurringPayment({ variables });
      }
      setIsEditing(false);
      setScheduleAutopayLoading(false);
    } catch (error) {
      setScheduleAutopayLoading(false);
    }
  };

  const closeModal = () => {
    recurringPaymentForm.resetFields();
    hide();
  };

  const handleCancel = async () => {
    setCancelAutopayLoading(true);
    try {
      const autopayId = autopay.id;
      await cancelRecurringPayment({
        variables: { customerId: customerId, scheduleId: autopayId },
      });
      setIsEditing(true);
      setCancelAutopayLoading(false);
      closeModal();
    } catch (error) {
      setCancelAutopayLoading(false);
    }
  };

  const dayOptionsData =
    autopayQuery?.data?.account?.autopayStartDateRange || [];
  const dayOptions = dayOptionsData.map((day, index) => {
    const isDueDate = index === dayOptionsData.length - 1;
    return {
      value: day,
      label: isDueDate
        ? `${getOrdinalSuffix(day)} (due date)`
        : getOrdinalSuffix(day),
    };
  });

  const editButtons = [
    <Button key="back" onClick={closeModal}>
      Close
    </Button>,
    <Button
      data-testid="schedule-button"
      type="primary"
      key="submit"
      onClick={handleSubmit}
      loading={scheduleAutopayLoading}
    >
      Schedule
    </Button>,
  ];

  const viewButtons = [
    <Button key="back" onClick={closeModal}>
      Close
    </Button>,
    <Button
      data-testid="cancel-plan-button"
      type="primary"
      key="cancel"
      onClick={handleCancel}
      loading={cancelAutopayLoading}
    >
      Cancel Plan
    </Button>,
    <Button
      data-testid="modify-plan-button"
      type="primary"
      key="edit"
      onClick={() => setIsEditing(true)}
    >
      Modify Plan
    </Button>,
  ];

  useEffect(() => {
    if (customerId && accountId) {
      getAutopayInfo({
        variables: { accountId, customerId },
      });
    }
  }, [customerId, accountId]);

  useEffect(() => {
    if (open) {
      setIsEditing(!autopay);
    }
  }, [open]);

  return (
    <>
      <Modal
        {...modalProps}
        title={
          isEditing
            ? autopay
              ? 'Edit Recurring Payment'
              : 'Setup Recurring Payment'
            : 'Manage Recurring Payment'
        }
        onCancel={closeModal}
        footer={isEditing ? editButtons : viewButtons}
        width={700}
      >
        <QueryBoundary
          errorMessage="Failed to load Autopay details."
          query={autopayQuery}
        >
          {isEditing ? (
            <RecurringPaymentForm
              form={recurringPaymentForm}
              dayOptions={dayOptions}
              fundingAccounts={fundingAccounts}
              autopay={autopay}
            />
          ) : (
            <RecurringPaymentDetails autopay={autopay} />
          )}

          {setupError && (
            <ErrorAlert action="scheduling autopay" error={setupError} />
          )}
          {updateError && (
            <ErrorAlert action="updating autopay" error={updateError} />
          )}
          {cancelError && (
            <ErrorAlert action="cancelling autopay" error={cancelError} />
          )}
        </QueryBoundary>
      </Modal>
    </>
  );
};

export default AutopayModal;
