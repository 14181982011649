import sanitize from 'sanitize-html';

const SanitizeHTML = ({ html, options, ...props }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: sanitize(html, {
        allowedTags: ['br', 'strong', 'a', 'span'],
        allowedAttributes: {
          a: ['href', 'target'],
          span: ['style'],
        },
        ...options,
      }),
    }}
    {...props}
  />
);

export default SanitizeHTML;
