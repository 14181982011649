import { gql } from '@apollo/client';

const INTERACTIONS = gql`
  query NotesInteractionsZendeskRecords(
    $customerId: String
    $applicationId: String
    $email: String
    $startDate: String!
    $endDate: String!
  ) {
    notesInteractionsZendeskRecords(
      customerId: $customerId
      applicationId: $applicationId
      email: $email
      startDate: $startDate
      endDate: $endDate
    ) {
      activityType
      agentId
      agentName
      id
      comments {
        agentId
        id
        agentName
        message
        createdTz
      }
      attachments {
        contentUrl
        insertTz
        name
        commentId
      }
      isSticky
      message
      product
      timestamp
    }
  }
`;

export { INTERACTIONS };
