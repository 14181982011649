import { gql } from '@apollo/client';

const PAYMENTS = gql`
  query PaymentsQuery(
    $customerId: String
    $accountId: String!
    $fromDate: String
    $toDate: String
  ) {
    account(customerId: $customerId, accountId: $accountId) {
      id
      payments(fromDate: $fromDate, toDate: $toDate) {
        amount
        date
        id
        reference
        state
        source
        fundingAccount {
          bankName
          numberLast4
          id
          validationMethod
        }
      }
    }
  }
`;

const RETURNED_PAYMENTS = gql`
  query PaymentReturns($accountId: String!, $customerId: String!) {
    paymentReturn(accountId: $accountId, customerId: $customerId) {
      code
      reason
      returnDate
      transactionDate
      amount
      referenceNumber
      fundingAccount {
        id
        bankName
        numberLast4
        validationMethod
      }
      source
    }
  }
`;

const ONE_TIME_PAYMENT = gql`
  query MakePaymentQuery($customerId: String, $accountId: String!) {
    account(customerId: $customerId, accountId: $accountId) {
      id
      balanceInfo {
        currentBalance
        pastDueBalance
        remainingStatementBalance
        statementBalance
      }
      paymentInfo {
        minimumDue
        printDueDate
      }
      cardDetails {
        last4
      }
      upcomingPayments {
        amount
        date
        state
      }
      pastDue {
        pastDues {
          interval
          total
        }
      }
    }
    fundingAccounts(customerId: $customerId) {
      bankName
      id
      isDefault
      numberLast4
      status
    }
  }
`;

const AUTOPAY = gql`
  query AutopayQuery($customerId: String, $accountId: String!) {
    fundingAccounts(customerId: $customerId) {
      bankName
      id
      isDefault
      numberLast4
      status
    }
    account(customerId: $customerId, accountId: $accountId) {
      id
      autopay {
        amount
        dayOfMonth
        fundingAccount {
          bankName
          numberLast4
          id
        }
        id
        nextPaymentDate
        paymentType
      }
      autopayStartDateRange
    }
  }
`;

export { AUTOPAY, ONE_TIME_PAYMENT, PAYMENTS, RETURNED_PAYMENTS };
