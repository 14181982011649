import { Segmented } from 'antd';

import useTranslation from 'hooks/useTranslation';

const LanguageSelector = ({ onLanguageChange }) => {
  const { changeLanguage, currentLanguage } = useTranslation();

  return (
    <Segmented
      value={currentLanguage}
      options={[
        {
          label: 'EN',
          value: 'en',
        },
        {
          label: 'ES',
          value: 'es',
        },
      ]}
      onChange={(language) => changeLanguage(language, onLanguageChange)}
    />
  );
};

export default LanguageSelector;
