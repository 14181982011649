import { createContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';

import { CUSTOMER_TYPE } from 'utilities/constants';
import { APPLICANT_META } from 'queries/applicant';
import { CUSTOMER_OVERVIEW } from 'queries/customer';

export class CustomerInfo {
  constructor(customerType = CUSTOMER_TYPE.CUSTOMER, info) {
    this.initialized = !!info;

    info = info || {};
    this.customerType = customerType;
    this.id = info.id || info.applicantId;
    this.accounts = info.accounts || [];
    this.firstName = info.contactInfo?.firstName || info.firstName;
    this.middleName = info.contactInfo?.middleName || info.middleName;
    this.lastName = info.contactInfo?.lastName || info.lastName;
    this.lastFourSSN = info.lastFourSsn || info.last4Ssn;
    this.datOfBirth = info.birthDate || info.dateOfBirth;
    this.email = info.contactInfo?.email || info.contactDetails?.emailAddress;
    this.phoneNumber =
      info.contactInfo?.rawPhoneNumber || info.contactDetails?.phoneNumber;

    this._fullName = info;
    this._address = info;
  }

  set _fullName(info) {
    if (info?.contactInfo) {
      info = info.contactInfo;
    }

    const firstName = info.firstName || '';
    const lastName = info.lastName || '';

    this.fullName = `${firstName} ${lastName}`.trim();
  }

  set _address(info) {
    const address =
      info?.contactInfo?.address || info?.contactDetails?.address || {};
    this.address = {
      city: address.city || '',
      state: address.state || '',
      street1: address.address1 || address.street1 || '',
      street2: address.address2 || address.street2 || '',
      postalCode: address.postalCode || address.zip || '',
    };
  }
}

export const CustomerInfoContext = createContext(null);

const CustomerInfoProvider = ({ children }) => {
  const [customerInfo, setCustomerInfo] = useState(new CustomerInfo());
  const [activeCustomerType, setActiveCustomerType] = useState();

  // The following "route matches" track relevant path parameters
  // for use by components outside of the router context (where react-router useParams does not work)
  const customerRoute = useRouteMatch('/customers/:customerId');
  const customerAccountRoute = useRouteMatch(
    '/customers/:customerId/accounts/credit/:accountId',
  );
  const applicationRoute = useRouteMatch('/applications/:applicationId');

  const [customerId, accountId, applicationId] = useMemo(() => {
    const customerId = customerRoute?.params.customerId;
    const accountId = customerAccountRoute?.params.accountId;
    const applicationId = applicationRoute?.params.applicationId;
    return [customerId, accountId, applicationId];
  }, [customerRoute, customerAccountRoute, applicationRoute]);

  const [getApplicantMeta, applicantMetaQuery] = useLazyQuery(APPLICANT_META, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ applicationV2 }) => {
      const applicants = applicationV2?.submission?.applicants || [];
      const applicant = applicants[0];
      setCustomerInfo(new CustomerInfo(CUSTOMER_TYPE.APPLICANT, applicant));
    },
  });

  const [getCustomerMeta, customerMetaQuery] = useLazyQuery(CUSTOMER_OVERVIEW, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCustomerInfo(new CustomerInfo(CUSTOMER_TYPE.CUSTOMER, data?.customer));
    },
  });

  useEffect(() => {
    if (applicationId) {
      setCustomerInfo(new CustomerInfo(CUSTOMER_TYPE.APPLICANT));
      getApplicantMeta({ variables: { applicationId } });
      setActiveCustomerType(CUSTOMER_TYPE.APPLICANT);
    }
  }, [applicationId]);

  useEffect(() => {
    if (customerId) {
      setCustomerInfo(new CustomerInfo(CUSTOMER_TYPE.CUSTOMER));
      getCustomerMeta({ variables: { customerId } });
      setActiveCustomerType(CUSTOMER_TYPE.CUSTOMER);
    }
  }, [customerId]);

  return (
    <CustomerInfoContext.Provider
      value={{
        accountId,
        applicationId,
        customerId,
        customerInfo,
        customerInfoQuery:
          activeCustomerType === CUSTOMER_TYPE.APPLICANT
            ? applicantMetaQuery
            : customerMetaQuery,
      }}
    >
      {children}
    </CustomerInfoContext.Provider>
  );
};

export default CustomerInfoProvider;
