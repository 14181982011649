import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Space, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';

import QueryResult from 'components/QueryResult/QueryResult';
import useModalControls from 'hooks/useModalControls';
import useAccountQuery from 'hooks/useAccountQuery';
import { GET_ACCOUNT_STATEMENT_PREFERENCES } from 'queries/account';
import StatementPreferencesUpdateModal from './StatementPreferencesUpdateModal';
import { ACCOUNT_STATUS_CODE } from 'utilities/constants';
import useCeaseAndDesistStatus from 'hooks/useCeaseAndDesistStatus';

const StatementPreferences = () => {
  const { accountId, customerId } = useParams();
  const preferencesModal = useModalControls();
  const [isPaperless, setIsPaperless] = useState();
  const [canUpdate, setCanUpdate] = useState();

  const { statuses } = useAccountQuery(customerId, accountId);

  // ineligibility defined in cc-accounts:
  // https://github.com/missionlane/cc-accounts/blob/10b9285eecec266ce452cab89478be9162f8b4f1/application/src/main/kotlin/com/missionlane/ccaccounts/service/StatementInformationServiceImpl.kt#L135
  const ineligibleStatusCodes = [
    ACCOUNT_STATUS_CODE.BANKTRUPTCY,
    ACCOUNT_STATUS_CODE.CHARGED_OFF,
    ACCOUNT_STATUS_CODE.CONFIRED_FRAUD,
    ACCOUNT_STATUS_CODE.DECEASED,
    ACCOUNT_STATUS_CODE.CREDIT_REVOKED,
    ACCOUNT_STATUS_CODE.RETURNED_MAIL,
    ACCOUNT_STATUS_CODE.TIERED_WATCH,
    ACCOUNT_STATUS_CODE.SECURITY_FRAUD,
  ];

  const ineligibleTWReasons = ['L4', 'L5', 'L7', 'L8', 'L9'];

  const ineligibileToUpdateText = statuses
    .filter((status) => {
      if (status.statusCode === ACCOUNT_STATUS_CODE.TIERED_WATCH) {
        return ineligibleTWReasons.includes(status.reasonCode);
      }

      return ineligibleStatusCodes.includes(status.statusCode);
    })
    .map((status) => `${status.statusCode}-${status.reasonCode}`)
    .join(', ');

  const getStmtPreferencesQuery = useQuery(GET_ACCOUNT_STATEMENT_PREFERENCES, {
    variables: { customerId, accountId },
    onCompleted: (data) => {
      setIsPaperless(data.account.statementPreferences.isPaperlessStatement);
      setCanUpdate(
        data.account.statementPreferences.canUpdateStatementPreference,
      );
    },
  });

  return (
    <>
      <StatementPreferencesUpdateModal
        {...preferencesModal}
        isPaperless={isPaperless}
      />
      <QueryResult {...getStmtPreferencesQuery}>
        <Space size={0}>
          <Alert
            type={isPaperless ? 'success' : 'warning'}
            message={isPaperless ? 'Paperless' : 'Paper'}
          ></Alert>
          <Tooltip
            title={
              !canUpdate
                ? `Account is unable to make change due to existing account status(es): ${ineligibileToUpdateText}`
                : undefined
            }
          >
            <Button
              type="link"
              disabled={!canUpdate}
              block
              data-testid={'change-statement-preference'}
              onClick={preferencesModal.show}
            >
              {isPaperless
                ? 'Enroll in Paper Billing'
                : 'Enroll in Paperless Billing'}
            </Button>
          </Tooltip>
        </Space>
      </QueryResult>
    </>
  );
};

export default StatementPreferences;
