import { Card, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { COLORS } from 'utilities/styles';

const columns = [
  {
    title: 'Primary Applicant',
    dataIndex: 'primaryApplicantName',
    key: 'primaryApplicantName',
  },
  {
    title: 'Application ID',
    dataIndex: 'applicationId',
    key: 'applicationId',
    render: (applicationId) => {
      return <Link to={`/applications/${applicationId}`}>{applicationId}</Link>;
    },
  },
  {
    title: 'Type',
    render: () => <Tag color={COLORS.brick1}>Application</Tag>,
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Status Reason',
    dataIndex: 'statusReason',
    key: 'statusReason',
  },
];

let locale = {
  emptyText: 'Application Not Found',
};

const ApplicationSearchResults = ({ data, title }) => {
  return (
    <Card
      bordered={false}
      title={title ?? 'Search Result'}
      style={{ marginTop: 5 }}
    >
      <Table locale={locale} columns={columns} dataSource={data} />
    </Card>
  );
};

export default ApplicationSearchResults;
