import { makeVar, useReactiveVar } from '@apollo/client';

const profileViewsVar = makeVar({});

export const setProfileViews = (profileViews) => {
  profileViewsVar({
    views: profileViews.views,
  });
};

const useProfileViews = () => {
  const profileViews = useReactiveVar(profileViewsVar);

  return { profileViews, setProfileViews };
};

export default useProfileViews;
