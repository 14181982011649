import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Alert, Flex, Modal, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import SectionMessage from 'components/SectionMessage/SectionMessage';
import { REVERSE_AUTHORIZATION } from 'mutations/transactions';
import { SIZE, STATUS } from 'utilities/constants';
import {
  formatTransactionDescription,
  isReverseAuthAllowed,
} from 'utilities/helpers';

const ReverseAuthorizationModal = ({
  transaction,
  account,
  hide,
  ...modalProps
}) => {
  const [isBlocked, setIsBlocked] = useState();
  const [isProgress, setIsProgress] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState();
  const { accountId, customerId } = useParams();

  const [reverseAuthorization] = useMutation(REVERSE_AUTHORIZATION, {
    refetchQueries: ['TransactionsQuery'],
  });

  useEffect(() => {
    setIsUpdateSuccess(null);
    setIsBlocked(undefined);

    if (transaction && open) {
      if (!isReverseAuthAllowed(account)) {
        setIsBlocked('status');
      } else {
        // allow only if < 7 days or before 12 30 ET on the 7th day
        // transaction date is day 1 so include that
        const diff = dayjs().diff(dayjs(transaction?.date), 'day') + 1;
        let isAllowedByTimeWindow = false;

        if (diff < 7) {
          isAllowedByTimeWindow = true;
        } else if (diff === 7) {
          const estTime = dayjs().tz('America/New_York');
          if (estTime.hour() < 12 && estTime.minute() < 30) {
            isAllowedByTimeWindow = true;
          }
        }
        if (!isAllowedByTimeWindow) {
          setIsBlocked('time');
        }
      }
    }
  }, [transaction, customerId, accountId, open, account]);

  const handleReverseAuth = useCallback(() => {
    setIsProgress(true);
    setIsUpdateSuccess(null);
    reverseAuthorization({
      variables: {
        accountId,
        customerId,
        removeAuthorizationInput: {
          amount: (transaction?.amount / 100).toFixed(2),
          authorizationId: transaction?.transactionId,
          isFraud: false,
        },
      },
    })
      .then(() => {
        setIsUpdateSuccess(true);
        setTimeout(hide, 1500);
      })
      .catch(() => setIsUpdateSuccess(false))
      .finally(() => setIsProgress(false));
  }, [accountId, customerId, transaction]);

  return (
    <Modal
      {...modalProps}
      data-testid="reverse-authorization-modal"
      width={800}
      title="Reverse Authorization"
      onOk={handleReverseAuth}
      destroyOnClose={true}
      confirmLoading={isProgress}
      okText="Reverse Authorization"
      okButtonProps={{
        disabled: !!isBlocked || isUpdateSuccess,
        'data-testid': 'reverse-auth-submit-btn',
      }}
    >
      <Flex
        justify="center"
        align="center"
        vertical={true}
        style={{
          position: 'relative',
          paddingTop: 'var(--spacing-lg)',
        }}
      >
        <Typography.Text style={{ marginBottom: 'var(--spacing-md)' }}>
          Are you sure you would like to reverse this transaction?
        </Typography.Text>

        <Typography.Text strong style={{ marginBottom: 'var(--spacing-lg)' }}>
          {formatTransactionDescription(transaction)}
        </Typography.Text>

        {isUpdateSuccess && (
          <SectionMessage
            data-testid="auth-update-success"
            cover={true}
            status={STATUS.SUCCESS}
            size={SIZE.SM}
            text="Authorization successfully removed."
          ></SectionMessage>
        )}

        {isBlocked === 'time' && (
          <Alert
            data-testid="auth-update-block-elapse"
            banner
            type="warning"
            message="Cannot reverse authorization after 12:30 PM ET on Final Hold Day"
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
        )}

        {isBlocked === 'status' && (
          <Alert
            data-testid="auth-update-block-status"
            banner
            type="warning"
            message="Cannot reverse authorization due to blocked status."
            style={{ marginBottom: 'var(--spacing-md)' }}
          />
        )}

        {isUpdateSuccess === false /* not when null */ && (
          <Alert
            banner
            type="error"
            message="Failed to reverse authorization. Please retry or escalate to R2 to complete the process"
          />
        )}
      </Flex>
    </Modal>
  );
};

export default ReverseAuthorizationModal;
