import { Form, Select } from 'antd';

const { Option } = Select;

const SelectFundingAccount = ({ label, name, fundingAccounts, onChange }) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,
          message: 'Please select a funding account!',
        },
      ]}
    >
      <Select onChange={onChange}>
        {fundingAccounts
          .filter(({ status }) => status === 'ENABLED' || status === 'DEFAULT')
          .map(({ id, bankName, numberLast4, isDefault }, index) => (
            <Option
              value={id}
              key={index}
            >{`${bankName} - ${numberLast4} - ${id}${
              isDefault ? ' (default)' : ''
            }`}</Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default SelectFundingAccount;
