import { gql } from '@apollo/client';

const MARK_AS_FRAUD = gql`
  mutation MarkAsFraud(
    $customerId: String!
    $accountId: String!
    $references: [AuthorizationReferenceInput!]!
  ) {
    markAuthorizationsAsFraudV2(
      customerId: $customerId
      accountId: $accountId
      references: $references
      action: FRAUD
      actionLevel: CONFIRM_FRAUD
    )
  }
`;

const RESET_AUTHORIZATION_COUNTERS = gql`
  mutation ResetAuthorizationCounters($accountId: String!) {
    resetAccountDailyAuthorizationCounters(accountId: $accountId) {
      success
    }
  }
`;

const REVERSE_AUTHORIZATION = gql`
  mutation RemoveAuthorization(
    $customerId: String!
    $accountId: String!
    $removeAuthorizationInput: RemoveAuthorizationInput!
  ) {
    removeAuthorization(
      customerId: $customerId
      accountId: $accountId
      removeAuthorizationInput: $removeAuthorizationInput
    ) {
      success
    }
  }
`;

const WAIVE_FEE = gql`
  mutation WaiveFee(
    $accountId: String!
    $postedDate: String!
    $transactionId: String!
  ) {
    updateCustomerAdjustment(
      accountId: $accountId
      updateCustomerAdjRequest: {
        postedDate: $postedDate
        transactionId: $transactionId
      }
    ) {
      id
    }
  }
`;

export {
  MARK_AS_FRAUD,
  RESET_AUTHORIZATION_COUNTERS,
  REVERSE_AUTHORIZATION,
  WAIVE_FEE,
};
