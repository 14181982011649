import { gql } from '@apollo/client';

// change offerType to CBR
const CREDIT_BALANCE_REFUND_ELIGIBILITY = gql`
  query CreditBalanceRefundEligibility(
    $customerId: String
    $accountId: String!
  ) {
    account(customerId: $customerId, accountId: $accountId) {
      creditBalanceRefundEligibility {
        doesNotHaveActiveDisputes
        doesNotHaveAPendingCbrRequest
        doesNotHavePaymentInLast7Days
        hasNegativeBalance
        isEligible
      }
    }
  }
`;

export { CREDIT_BALANCE_REFUND_ELIGIBILITY };
