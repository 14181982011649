import { gql } from '@apollo/client';

const CUSTOMER_FUNDING_ACCOUNTS = gql`
  query GetCustomerFundingAccounts($customerId: String!) {
    fundingAccounts(customerId: $customerId) {
      id
      bankName
      isDefault
      numberLast4
      routingNumber
      status
      validationMethod
      createdDate
    }
  }
`;

export { CUSTOMER_FUNDING_ACCOUNTS };
