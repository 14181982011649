import { useLazyQuery } from '@apollo/client';
import { Button, DatePicker, Form, Radio, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CancelPaymentModal from './CancelPaymentModal';
import EditPaymentModal from './EditPaymentModal';

import CondensedTable from 'components/CondensedTable/CondensedTable';
import QueryBoundary from 'components/QueryBoundary/QueryBoundary';

import useAnalytics from 'hooks/useAnalytics';
import useModalControls from 'hooks/useModalControls';
import { PAYMENTS } from 'queries/payments';

import { ANALYTICS_EVENTS } from 'utilities/constants';
import { formatDate, formatDateToSubmit } from 'utilities/datesAndTimes';
import {
  formatDollarsFromCents,
  formatFundingAccount,
} from 'utilities/helpers';

const { RangePicker } = DatePicker;

const DATE_SELECT_OPTIONS = Object.freeze({
  PREV_6_MONTHS: 'Previous 6 Months',
  PREV_12_MONTHS: 'Previous 12 Months',
  CUSTOM: 'Custom Date Range',
});

const PAYMENT_TYPES = Object.freeze([
  'CANCELLED',
  'PENDING',
  'POSTED',
  'REVERSED',
  'SCHEDULED',
]);

let columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    render: formatDate,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.date - b.date,
  },
  {
    title: 'Channel',
    dataIndex: 'channel',
  },
  {
    title: 'Transaction ID',
    dataIndex: 'reference',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (amount) => formatDollarsFromCents(amount),
  },
  {
    title: 'Payment Method',
    render: (_, payment) => {
      if (!payment.bankAccount) {
        return payment.channel === 'UNKNOWN' || payment.channel === 'ETL'
          ? 'CHECK'
          : 'DEBIT';
      }
      return formatFundingAccount(payment.bankAccount);
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    filters: PAYMENT_TYPES.map((type) => ({ text: type, value: type })),
    onFilter: (selectedStatus, payment) => payment.status === selectedStatus,
  },
  {
    title: 'Validation Method',
    render: (_, payment) => {
      return payment?.bankAccount?.validationMethod === 'PLAID'
        ? payment?.bankAccount?.validationMethod
        : '';
    },
  },
];

const paymentCanBeModified = (payment) => {
  return (
    payment.status === 'SCHEDULED' &&
    dayjs(payment.date).isAfter(dayjs().endOf('day'))
  );
};

const PaymentSection = () => {
  const prev6Months = [dayjs().subtract(6, 'month'), dayjs().add(1, 'day')];
  const prev12Months = [dayjs().subtract(12, 'month'), dayjs().add(1, 'day')];

  const [form] = Form.useForm();
  const dateOption = Form.useWatch('paymentDateOptions', form);
  const [dateRange, setDateRange] = useState(prev6Months);

  const [paymentToCancel, setPaymentToCancel] = useState(null);
  const [paymentToEdit, setPaymentToEdit] = useState(null);
  const cancelPaymentModal = useModalControls();
  const editPaymentModal = useModalControls();

  const { trackEvent } = useAnalytics();

  const { accountId, customerId } = useParams();

  const [getPayments, paymentsQuery] = useLazyQuery(PAYMENTS, {
    fetchPolicy: 'network-only',
  });

  const fetchPaymentsRange = () => {
    getPayments({
      variables: {
        accountId,
        customerId,
        fromDate: formatDateToSubmit(dateRange?.[0]),
        toDate: formatDateToSubmit(dateRange?.[1]),
      },
    });
  };

  useEffect(() => {
    if (customerId && accountId && dateOption !== DATE_SELECT_OPTIONS.CUSTOM) {
      fetchPaymentsRange();
    }
  }, [customerId, accountId, dateOption, getPayments]);

  const onDateQuickSelect = (value) => {
    if (value.target.value === DATE_SELECT_OPTIONS.PREV_6_MONTHS) {
      setDateRange(prev6Months);
      form.setFieldValue('paymentRange', prev6Months);
    } else if (value.target.value === DATE_SELECT_OPTIONS.PREV_12_MONTHS) {
      setDateRange(prev12Months);
      form.setFieldValue('paymentRange', prev12Months);
    }
  };

  const payments = paymentsQuery?.data?.account?.payments.map(
    (payment, index) => ({
      key: index,
      channel: payment.source,
      date: dayjs(payment.date),
      id: payment.id,
      reference: payment.reference,
      amount: payment.amount,
      bankAccount: payment?.fundingAccount,
      status: payment.state,
    }),
  );

  const openEditModal = (payment) => {
    setPaymentToEdit(payment);
    editPaymentModal.show();
    trackEvent(ANALYTICS_EVENTS.PAYMENT_EDIT_OPENED);
  };

  const openCancelModal = (payment) => {
    setPaymentToCancel(payment);
    cancelPaymentModal.show();
  };

  const managePaymentColumn = {
    title: 'Manage',
    render: (payment) =>
      paymentCanBeModified(payment) ? (
        <>
          <Button onClick={() => openEditModal(payment)} size="small">
            Modify
          </Button>
          <Button onClick={() => openCancelModal(payment)} size="small">
            Cancel
          </Button>
        </>
      ) : null,
  };

  const buildCopyablePaymentsText = (selectedRows) => {
    const getPaymentMethod = (row) => {
      if (!row.bankAccount) {
        return row.channel === 'UNKNOWN' ? 'CHECK' : 'DEBIT';
      } else {
        return formatFundingAccount(row.bankAccount);
      }
    };

    const selectedRowData = selectedRows?.map((row) => {
      return {
        date: formatDate(row.date),
        channel: row.channel,
        transaction_id: row.reference,
        amount: formatDollarsFromCents(row.amount),
        paymentMethod: getPaymentMethod(row),
        status: row.status,
      };
    });

    const formattedSingleRowData = selectedRowData.map((row) =>
      Object.values(row).join('   '),
    );
    return Object.values(formattedSingleRowData).join('\n');
  };

  return (
    <>
      <Typography.Title level={5}>Payments</Typography.Title>
      <Form
        form={form}
        initialValues={{
          paymentDateOptions: DATE_SELECT_OPTIONS.PREV_6_MONTHS,
          paymentRange: prev6Months,
        }}
        name="payments"
        style={{
          display: 'flex',
          gap: 'var(--spacing-lg)',
          alignItems: 'flex-start',
        }}
        onFinish={fetchPaymentsRange}
      >
        <Form.Item name="paymentDateOptions">
          <Radio.Group onChange={onDateQuickSelect}>
            <Radio.Button
              data-testid="6months"
              value={DATE_SELECT_OPTIONS.PREV_6_MONTHS}
            >
              6 Months
            </Radio.Button>
            <Radio.Button
              data-testid="12months"
              value={DATE_SELECT_OPTIONS.PREV_12_MONTHS}
            >
              12 Months
            </Radio.Button>
            <Radio.Button
              data-testid="custom"
              value={DATE_SELECT_OPTIONS.CUSTOM}
            >
              Custom Date Range
            </Radio.Button>
            s
          </Radio.Group>
        </Form.Item>
        <Space direction="horizontal">
          <Form.Item name="paymentRange">
            <RangePicker
              allowEmpty={[false, false]}
              data-testid="paymentDateRange"
              format="M/D/YYYY"
              onChange={(date) => {
                form.setFieldValue(
                  'paymentDateOptions',
                  DATE_SELECT_OPTIONS.CUSTOM,
                );
                setDateRange(date);
              }}
            />
          </Form.Item>
          <Form.Item style={{ alignItems: 'flex-start' }}>
            <Button htmlType="submit" data-testid="update-payments-button">
              Update
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <QueryBoundary
        query={paymentsQuery}
        mode={QueryBoundary.MODE.MESSAGE}
        loadingMessage="Loading Payments"
      >
        <CondensedTable
          showSelectionActions={true}
          columns={[...columns, managePaymentColumn]}
          data={payments}
          data-testid="paymentsTable"
          length={payments?.length}
          onCopy={buildCopyablePaymentsText}
        />
      </QueryBoundary>
      <CancelPaymentModal {...cancelPaymentModal} payment={paymentToCancel} />
      <EditPaymentModal {...editPaymentModal} payment={paymentToEdit} />
    </>
  );
};

export default PaymentSection;
