import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DatePicker, Form, Input, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import StepContainer from '../StepContainer';
import {
  setNextStep,
  setCardLossDetails,
  useSendCardState,
  resetSendCardState,
  SEND_CARD_STEPS,
} from './sendCardState';
import useCustomerInfo from 'hooks/useCustomerInfo';

import useAnalytics from 'hooks/useAnalytics';
import {
  ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE,
  ANALYTICS_EVENTS,
} from 'utilities/constants';

const { TextArea } = Input;
const { Paragraph } = Typography;
const { Option } = Select;

const CardLossDetailsFormGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-md);
`;

const CardLossDetailsStep = () => {
  const { customerId, accountId } = useParams();
  const { trackEvent } = useAnalytics();
  const { customerInfo } = useCustomerInfo();

  const { cardLossDetails } = useSendCardState();
  const [cardLossDetailsForm] = Form.useForm();
  const reporterValue = Form.useWatch('reporter', cardLossDetailsForm);

  const handleContinue = async () => {
    let formValues;
    try {
      formValues = await cardLossDetailsForm.validateFields();
    } catch {
      return;
    }

    trackEvent(ANALYTICS_EVENTS.SEND_CARD_CARD_LOSS_DETAILS, {
      customerId,
      accountId,
      reason: formValues.reason,
    });

    setCardLossDetails(formValues);
    setNextStep(SEND_CARD_STEPS.ADDRESS_REVIEW);
  };

  useEffect(() => {
    if (!reporterValue && customerInfo?.fullName) {
      cardLossDetailsForm.setFieldValue('reporter', customerInfo.fullName);
    }
  }, [customerInfo, reporterValue, cardLossDetailsForm]);

  return (
    <StepContainer
      title="Card Loss Details"
      onContinue={handleContinue}
      onExit={resetSendCardState}
    >
      <Form
        form={cardLossDetailsForm}
        name="cardLossDetails"
        layout="vertical"
        initialValues={cardLossDetails}
      >
        <Paragraph>
          Collect the following details about the customer's card and situation:
        </Paragraph>

        <CardLossDetailsFormGrid>
          <div>
            <Form.Item
              label="Reported Missing By"
              name="reporter"
              rules={[{ required: true, message: 'Name of reporter required' }]}
            >
              <Input
                data-testid="reportedBy-input"
                maxLength={30}
                showCount={true}
              />
            </Form.Item>
            <Form.Item
              label="Reason Card Is Missing"
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Reason card is missing is required',
                },
              ]}
            >
              <Select data-testid="reason-select">
                <Option value={ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE.CARD_LOST}>
                  Lost
                </Option>
                <Option value={ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE.CARD_STOLEN}>
                  Stolen
                </Option>
                <Option
                  value={ACCOUNT_TRANSFER_FRAUD_STATUS_TYPE.CARD_NOT_RECEIVED}
                >
                  Never received
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Date and Time Discovered Missing"
              name="dateTimeDiscovered"
              rules={[
                {
                  required: true,
                  message: 'Date/Time discovered missing is required',
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) => {
                  return dayjs(current).isAfter(dayjs().endOf('day'));
                }}
                showTime={{
                  use12Hours: true,
                  format: 'HH:mm',
                  minuteStep: 15,
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Circumstances"
              name="lostStolenCircumstances"
              rules={[
                {
                  required: true,
                  message: 'Circumstances are required',
                },
              ]}
            >
              <TextArea
                maxLength={223}
                rows={5}
                showCount={true}
                placeholder="Confirm lost stolen details"
              />
            </Form.Item>
          </div>
        </CardLossDetailsFormGrid>
      </Form>
    </StepContainer>
  );
};

export default CardLossDetailsStep;
