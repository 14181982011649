import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Flex,
  Popconfirm,
  Tooltip,
  Typography,
} from 'antd';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  MailFilled,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import useAccountQuery from 'hooks/useAccountQuery';
import useTimeoutValue from 'hooks/useTimeoutValue';
import { SEND_PRE_OFFER_EMAIL } from 'mutations/settlements';
import { formatDateToSubmit } from 'utilities/datesAndTimes';
import { getDefaultPayments } from 'utilities/helpers';
import {
  SETTLEMENTS_CADENCE_VALUES,
  SETTLEMENT_OFFER_TYPES,
} from 'utilities/constants';

import {
  RECOVERIES_STEPS,
  setAcceptedOffer,
  setNextStep,
  setFundingAccount,
  setPaymentCadence,
  setPaymentDates,
} from './recoveriesState';

const { Text, Title } = Typography;

const SIFOfferCard = ({
  sifOffer,
  hasPendingPayment,
  hasScheduledPayment,
  thirdParty,
}) => {
  const { customerId, accountId } = useParams();

  const [sendPreOfferEmailSuccess, setSendPreOfferEmailSuccess] =
    useTimeoutValue(false, 3000);
  const [sendPreOfferEmailErrorMsg, setSendPreOfferEmailErrorMsg] =
    useState('');

  const sifBalance = (sifOffer?.amount / 100).toFixed(2);
  const durationMonths = sifOffer?.durationMonths;
  const offerDurationFromToday = dayjs()
    .add(sifOffer?.durationMonths, 'month')
    .format('LL'); // MMMM D, YYYY

  const payments = getDefaultPayments(
    sifBalance,
    dayjs(),
    offerDurationFromToday,
    durationMonths,
  );

  const [settlementPercentage, setSettlementPercentage] = useState('');

  const [sendPreOfferEmail, { loading: sendingPreOfferEmail }] = useMutation(
    SEND_PRE_OFFER_EMAIL,
    {
      onCompleted: () => {
        setSendPreOfferEmailSuccess(true);
        setSendPreOfferEmailErrorMsg('');
      },
      onError: () => {
        setSendPreOfferEmailErrorMsg(
          'Failed to send pre-offer email. Please try again.',
        );
      },
    },
  );

  const onSendEmail = () => {
    sendPreOfferEmail({
      variables: {
        availableOfferId: sifOffer.offerId,
        accountId,
        customerId,
      },
    });
  };

  useEffect(() => {
    const settlementBalancePercentage =
      ((sifOffer.amount / 100).toFixed(2) /
        (sifOffer.outstandingBalance / 100).toFixed(2)) *
      100;

    setSettlementPercentage(`${Math.round(settlementBalancePercentage)}%`);
  }, [sifOffer]);

  const estimatedPaymentsText = (
    <Text>
      <CalendarOutlined /> <strong>With a start date of today,</strong> a
      customer would owe around:
      <br />
      <strong>${payments.monthly} | monthly</strong>, over {durationMonths}{' '}
      months
      <br />
      <strong>${payments.bimonthly} | every 2 weeks</strong>, over{' '}
      {durationMonths} months
      <br />
      <strong>${payments.weekly} | weekly</strong>, over {durationMonths} months
    </Text>
  );

  const offerTitle = (
    <>
      {sifOffer.key}. Settlement Option
      <br />
      <Text type="secondary">
        {settlementPercentage} of balance, over {durationMonths} months
      </Text>
    </>
  );

  const onOfferSelection = () => {
    setAcceptedOffer(
      sifBalance,
      sifOffer?.durationMonths,
      sifOffer?.offerId,
      sifOffer?.offerName,
      SETTLEMENT_OFFER_TYPES.SIF,
      settlementPercentage,
    );
    setNextStep(RECOVERIES_STEPS.ADD_FUNDING_ACCOUNT);
  };

  const onDebitPaymentSelection = () => {
    setAcceptedOffer(
      sifBalance,
      1,
      sifOffer?.offerId,
      sifOffer?.offerName,
      'SIFOneTimeDebit',
      settlementPercentage,
    );
    setPaymentCadence(SETTLEMENTS_CADENCE_VALUES.ONE_TIME_DEBIT, [
      { amount: `$${sifBalance}`, date: formatDateToSubmit(dayjs()) },
    ]);
    setFundingAccount('--', 'Debit Card Payment');
    setPaymentDates(dayjs().format('L'), dayjs().add(1, 'day').format('L'));
    setNextStep(RECOVERIES_STEPS.DISCLOSURE);
  };

  const buttons = (
    <>
      <Flex gap="var(--spacing-sm)" justify="center">
        <Tooltip
          title={
            hasPendingPayment || hasScheduledPayment
              ? 'Cannot activate with a pending or scheduled payment'
              : ''
          }
        >
          <Popconfirm
            title={<Title level={5}>Select the Payment Method</Title>}
            onCancel={onDebitPaymentSelection}
            onConfirm={onOfferSelection}
            okText="Payment Plan"
            cancelText="One Time Debit Payment"
            disabled={hasPendingPayment || hasScheduledPayment}
          >
            <Tooltip
              title={
                hasPendingPayment || hasScheduledPayment
                  ? 'Account has a pending or scheduled payment.'
                  : ''
              }
            >
              <Button
                key="selectOffer"
                data-testid="selectSifOffer"
                type="primary"
                style={{ width: 200 }}
                disabled={hasPendingPayment || hasScheduledPayment}
              >
                Select Offer
              </Button>
            </Tooltip>
          </Popconfirm>
        </Tooltip>
        <Popconfirm
          title={<Title level={5}>Send offer letter via email?</Title>}
          description={
            thirdParty?.email ? (
              <Text type="warning">
                An email will also be sent to the <br />
                3rd party managing this account
              </Text>
            ) : (
              ''
            )
          }
          icon={<MailFilled />}
          onConfirm={onSendEmail}
          okText="Send"
          cancelText="Cancel"
          okButtonProps={
            thirdParty.email
              ? { style: { marginTop: 'var(--spacing-sm)' } }
              : {}
          }
          disabled={hasPendingPayment || hasScheduledPayment}
        >
          <Button
            key="sendOfferEmail"
            data-testid="sendOfferEmail"
            type="primary"
            loading={sendingPreOfferEmail}
            disabled={hasPendingPayment || hasScheduledPayment}
          >
            {sendPreOfferEmailSuccess ? (
              <>
                Email Sent <CheckCircleOutlined />
              </>
            ) : (
              <>Send Email</>
            )}
          </Button>
        </Popconfirm>
      </Flex>
      {sendPreOfferEmailErrorMsg && (
        <Alert
          banner
          type="error"
          message={sendPreOfferEmailErrorMsg}
          style={{ marginTop: 'var(--spacing-sm)' }}
        />
      )}
    </>
  );

  return (
    <Card
      type="inner"
      style={{ minWidth: 350 }}
      title={offerTitle}
      actions={[buttons]}
    >
      Settlement Balance: ${sifBalance}
      <br />
      <strong>Plan Due Date: {offerDurationFromToday}</strong>
      <br />
      --
      <br /> <br />
      <strong>Estimated Payments</strong>
      <br />
      <br />
      {estimatedPaymentsText}
    </Card>
  );
};

export default SIFOfferCard;
