const stagingConfig = {
  AWS_CONNECT_INSTANCE: 'missionlane-connect',
  CI_TOOL_URL: 'https://ci-tool.external.gcp.missionlane-staging.com/cs',
  ENVIRONMENT: 'staging',
  FILEPICKER_API_KEY: 'A8uwROM6BQ4iNPL2K7Fv4z',
  GQL_HOST:
    'https://cc-api-orc.agent.external.gcp.missionlane-staging.com/graphql',
  GQL_COOKIE_DOMAIN: '.missionlane-staging.com',
  LAUNCH_DARKLY_CLIENT_ID: '61faaaf0b2a72f1778f07602',
  OKTA_CLIENT_ID: '0oaat9bmfqHwHZfxU357',
  OKTA_REDIRECT_URI:
    'https://mission-control.missionlane-staging.com/login/callback',
  SEGMENT_KEY: 'lmyprzEqgmdrCKY7G9qHSPOIP6l8W5oj',
  TCS_SSO_URL:
    'https://missionlane.okta.com/home/missionlane_tsystcs_1/0oa9o0kbh7nhf73w0357/aln9o0swc7IiCdAt5357',
  VGS_VAULT_ID: 'tntpgw7e4fh',
  VGS_ENV: 'sandbox',
  VGS_CNAME: 'mc-vgs.missionlane-staging.com',
  ONBASE_FILE_VAULT_URL: 'https://tntxm1v5mzc.sandbox.verygoodproxy.com',
};

export default stagingConfig;
