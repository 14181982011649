import { gql } from '@apollo/client';

const INCOME_VERIFICATION_DOCUMENTS = gql`
  query IncomeVerificationDocuments($applicationId: String!) {
    incomeVerificationDocuments(applicationId: $applicationId) {
      agentId
      applicationId
      customRejectionMessage
      updatedAt
      verificationDocuments {
        agentId
        documentId
        documentLink
        documentStatus
        documentStatusReason
        documentType
        fileName
        updatedAt
      }
      verificationStatus
    }
  }
`;

export { INCOME_VERIFICATION_DOCUMENTS };
