import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';

import RecurringAuthorizationMessage from './RecurringAuthorizationMessage';
import SelectFundingAccount from '../../SelectFundingAccount';

import AddFundingAccountModal from 'components/AddFundingAccountModal/AddFundingAccountModal';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import useModalControls from 'hooks/useModalControls';
import { formatDate } from 'utilities/datesAndTimes';
import { getAccountLast4 } from 'utilities/helpers';

const { Option } = Select;

const RecurringPaymentForm = ({
  form,
  dayOptions,
  fundingAccounts,
  autopay,
}) => {
  const addFundingAccountModal = useModalControls();

  const [paymentPlan, setPaymentPlan] = useState();
  const [amount, setAmount] = useState();
  const [dayOfMonth, setDayOfMonth] = useState();
  const [fundingAccountLast4, setFundingAccountLast4] = useState();

  const firstPaymentDate = useMemo(() => {
    if (!dayOfMonth) {
      return '';
    }
    let selectedDate = dayjs().date(dayOfMonth);
    if (selectedDate.isSameOrBefore(dayjs())) {
      selectedDate = selectedDate.add(1, 'month');
    }
    return formatDate(selectedDate);
  }, [dayOfMonth]);

  useEffect(() => {
    const defaultAccount = fundingAccounts.find((account) => account.isDefault);
    if (!form.getFieldValue('fundingAccount') && !autopay) {
      form.setFieldsValue({
        fundingAccount: defaultAccount?.id,
      });
    }
  }, [fundingAccounts, autopay, form]);

  useEffect(() => {
    if (autopay) {
      form.setFieldsValue({
        paymentPlan: autopay.paymentType,
        fixedAmount: (autopay.amount / 100).toFixed(2),
        paymentDayOfMonth: autopay.dayOfMonth,
        fundingAccount: autopay.fundingAccount.id,
      });
      setPaymentPlan(autopay.paymentType);
      setAmount((autopay.amount / 100).toFixed(2));
      setDayOfMonth(autopay.dayOfMonth);
      setFundingAccountLast4(autopay.fundingAccount.numberLast4);
    }
  }, [autopay]);

  const onValuesChange = (_changedValues, allValues) => {
    setPaymentPlan(allValues?.paymentPlan);
    setAmount((Math.round(allValues.fixedAmount * 100) / 100).toFixed(2));
    setDayOfMonth(allValues.paymentDayOfMonth);
    setFundingAccountLast4(
      getAccountLast4(fundingAccounts, allValues.fundingAccount),
    );
  };

  const isFixedAmount = form.getFieldValue('paymentPlan') === 'MONTHLY_FIXED';
  const defaultFundingAccount = fundingAccounts.find(
    (account) => account.isDefault,
  );

  return (
    <Form
      form={form}
      name="editAutopay"
      layout="vertical"
      data-testid="autopayModalFormEl"
      initialValues={{
        fixedAmount: 25,
        fundingAccount: defaultFundingAccount?.id,
      }}
      onValuesChange={onValuesChange}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label="Monthly Payment Plan" name="paymentPlan">
            <Select>
              <Option value="MONTHLY_MINIMUM">Minimum Payment</Option>
              <Option value="MONTHLY_STATEMENT_BALANCE">
                Statement Balance
              </Option>
              <Option value="MONTHLY_FIXED">Fixed Amount</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={`Payment Date (First Payment on ${firstPaymentDate})`}
            name="paymentDayOfMonth"
            rules={[
              {
                required: true,
                message: 'Please select a payment day',
              },
            ]}
          >
            <Select data-testid="paymentDayOfMonthInputEl">
              {dayOptions.map(({ value, label }) => (
                <Option value={value} key={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Amount" name="fixedAmount" hidden={!isFixedAmount}>
        <InputNumber
          min={25}
          step={0.01}
          formatter={(value) => `$${value}`}
          parser={(value) => value.replace('$', '')}
        />
      </Form.Item>

      <Row gutter={10} align="middle">
        <Col flex="auto">
          <SelectFundingAccount
            label="Pay From"
            name="fundingAccount"
            fundingAccounts={fundingAccounts}
          />
        </Col>
        <Col>
          <AddFundingAccountModal
            {...addFundingAccountModal}
            refetchQueries={['AutopayQuery']}
          />
          <Button onClick={addFundingAccountModal.show}>
            Add Funding Account
          </Button>
        </Col>
      </Row>

      <Row align="bottom" style={{ marginBottom: 10 }}>
        <Col flex="auto">
          <h4>Payment Authorization:</h4>
        </Col>
        <Col>
          <LanguageSelector />
        </Col>
      </Row>
      <Row>
        <Col>
          <RecurringAuthorizationMessage
            paymentPlan={paymentPlan}
            amount={amount}
            firstPaymentDate={firstPaymentDate}
            fundingAccountLast4={fundingAccountLast4}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default RecurringPaymentForm;
