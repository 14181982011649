import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { Menu } from 'antd';

import useAccountQuery from 'hooks/useAccountQuery';
import useNavigationKeyManager from 'hooks/useNavigationKeyManager';
import { getCardDisplayName } from 'utilities/helpers';

const CustomerMenu = () => {
  const { customerId } = useParams();
  const { url } = useRouteMatch();
  const { accounts, simpleAccounts } = useAccountQuery(customerId);

  const navigationItems = [
    {
      key: 'customerDetails',
      path: `${url}/details`,
      exact: 'true',
      label: <Link to={`${url}/details`}>Customer Details</Link>,
    },
    {
      key: 'accounts',
      label: 'Accounts',
      onTitleClick: ({ key }) => {
        toggleOpenKey(key);
      },
      children: accounts?.map((account) => {
        return {
          key: `account-${account.id}`,
          path: `${url}/accounts/credit/${account.id}`,
          exact: 'true',
          label: (
            <Link to={`${url}/accounts/credit/${account.id}`}>
              {getCardDisplayName(account)}
            </Link>
          ),
        };
      }),
    },
    {
      key: 'applications',
      label: 'Applications',
      onTitleClick: ({ key }) => {
        toggleOpenKey(key);
      },
      children: accounts?.map((account) => {
        const simpleAccount = simpleAccounts?.find(
          (simpleAccount) => simpleAccount.id === account.id,
        );
        return {
          key: `account-application-${account.id}`,
          path: `${url}/applications/${simpleAccount.productApplicationId}`,
          exact: 'true',
          label: (
            <Link
              to={`${url}/applications/${simpleAccount.productApplicationId}`}
            >
              {getCardDisplayName(account)}
            </Link>
          ),
        };
      }),
    },
    {
      key: 'fundingAccounts',
      path: `${url}/funding-accounts`,
      exact: 'true',
      label: <Link to={`${url}/funding-accounts`}>Funding Accounts</Link>,
    },
    {
      key: 'attachments',
      path: `${url}/attachments`,
      exact: 'true',
      label: <Link to={`${url}/attachments`}>Attachments</Link>,
    },
    {
      key: 'preferences',
      path: `${url}/preferences`,
      exact: 'true',
      label: <Link to={`${url}/preferences`}>Preferences</Link>,
    },
    {
      key: 'fraudCases',
      path: `${url}/fraud`,
      exact: 'true',
      label: <Link to={`${url}/fraud`}>Fraud Cases</Link>,
    },
  ];

  const [activeRouteKey, openKeys, toggleOpenKey] =
    useNavigationKeyManager(navigationItems);

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      selectedKeys={activeRouteKey}
      items={navigationItems}
      style={{ borderInlineEnd: 'none' }}
    ></Menu>
  );
};

export default CustomerMenu;
