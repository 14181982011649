import { useEffect, useMemo, useState } from 'react';
import { CopyOutlined, LikeOutlined } from '@ant-design/icons';
import { Button, Flex, Table, Tooltip, Typography } from 'antd';
import copy from 'copy-to-clipboard';

import useTimeoutValue from 'hooks/useTimeoutValue';

const { Text } = Typography;

const CondensedTable = ({
  data,
  length,
  onCopy,
  rowSelection,
  scrollY,
  selectionActions = [],
  showSelectionActions = false,
  ...rest
}) => {
  const [showMore, setShowMore] = useState(false);
  const [copyableText, setCopyableText] = useState('');
  const [selectedRowsCopied, setSelectedRowsCopied] = useTimeoutValue(
    false,
    2000,
  );

  const [selectionCount, setSelectionCount] = useState(
    rowSelection?.selectedRowKeys?.length || 0,
  );

  const rowSelectionConfig = useMemo(() => {
    if (!showSelectionActions) {
      return;
    }

    const config = {
      ...(rowSelection || {}),
    };

    config.onChange = (selectedRowKeys, selectedRows) => {
      if (!rowSelection?.selectedRowKeys) {
        setSelectionCount(selectedRowKeys.length);
      }

      if (rowSelection && rowSelection.onChange) {
        rowSelection.onChange(selectedRowKeys, selectedRows);
      }

      if (onCopy) {
        setCopyableText(onCopy(selectedRows));
      } else {
        const rowStrings = selectedRows.map((row) => {
          const dup = { ...row };
          delete dup.key;
          return Object.values(dup).join(', ');
        });
        setCopyableText(rowStrings.join('\n'));
      }
    };

    return config;
  }, [rowSelection]);

  const handleCopy = () => {
    copy(copyableText);
    setSelectedRowsCopied(true);
  };

  const renderCopyTooltipText = () => {
    return selectedRowsCopied ? (
      <span>
        Selected Rows Copied <LikeOutlined />
      </span>
    ) : (
      'Copy Selected Rows'
    );
  };

  useEffect(() => {
    if (rowSelection?.selectedRowKeys) {
      setSelectionCount(rowSelection.selectedRowKeys.length);
    }
  }, [rowSelection?.selectedRowKeys]);

  const renderSelectionActions = () => {
    if (!showSelectionActions) {
      return;
    }
    return (
      <Flex
        style={{ margin: 'var(--spacing-xs) 0' }}
        align="center"
        justify="flex-end"
        gap="var(--spacing-sm)"
      >
        <Text
          type={!selectionCount ? 'secondary' : ''}
        >{`${selectionCount} Selected Row${
          selectionCount === 1 ? '' : 's'
        }: `}</Text>

        <Tooltip title={renderCopyTooltipText()}>
          <Button disabled={!selectionCount} onClick={handleCopy} size="small">
            <CopyOutlined />
          </Button>
        </Tooltip>
        {selectionActions.map((action, index) => {
          let button = (
            <Button
              key={`selection-action-${index}`}
              disabled={!selectionCount}
              onClick={action.onClick}
              size="small"
            >
              {action.text}
            </Button>
          );
          if (action.tooltip) {
            button = (
              <Tooltip
                title={action.tooltip?.text}
                key={`action-tooltip-${index}`}
              >
                {button}
              </Tooltip>
            );
          }
          return button;
        })}
      </Flex>
    );
  };

  const renderShowMore = () => {
    const overflow = length && data?.length > length;
    if (overflow) {
      return (
        <Flex
          style={{ marginTop: 'var(--spacing-xs' }}
          align="center"
          gap="var(--spacing-sm)"
        >
          <Button
            type="link"
            size="small"
            onClick={() => setShowMore((val) => !val)}
          >
            {showMore ? 'Show Less' : 'Show All'}
          </Button>
        </Flex>
      );
    }
  };

  const getData = () => {
    if (length && !showMore) {
      return data?.slice(0, length);
    }
    return data.length ? data : undefined;
  };

  return (
    <>
      {renderSelectionActions()}
      <Table
        dataSource={getData()}
        bordered={true}
        size="small"
        pagination={false}
        scroll={showMore ? { y: scrollY } : undefined}
        rowSelection={rowSelectionConfig}
        {...rest}
      />
      {renderShowMore()}
    </>
  );
};

export default CondensedTable;
